<script>
import ActionCableProvider from '@providers/ActionCableProvider'
import AuthStore from '@stores/AuthStore'
import ChatMessagesRequests from '@requests/MessageCenter/ChatMessagesRequests'
import ChatStore from '@stores/ChatStore'
import PatientMessageIssuesRequests from '@requests/MessageCenter/PatientMessageIssuesRequests'
import { assign, findLast, trim } from 'lodash'
import { initials } from '@helpers/StringHelper'
import { scrollToBottom } from '@helpers/ScrollHelper'

// Public: Allows a user to communicate with a patient.
export default {
  name: 'MessageCenterChat',
  components: {
    ActionCableProvider,
  },
  props: {
    // this object contains issue data, such as patient and chatroom info
    issue: { type: Object, default: null },
  },
  data () {
    return {
      // Internal: Whether there is an active ActionCable connection.
      offline: false,

      // Internal: The chat messages in the conversation to be displayed.
      messages: null,

      // Internal: The message the user is typing in the input.
      messageBeingTyped: '',

      // Internal: Whether the widget should be displayed.
      displayWidget: true,
    }
  },
  computed: {
    ...ChatStore.mapState('currentActivePatient', 'collapsed'),
    patient () {
      return this.showBlankslate ? {} : this.issue.patient
    },
    showBlankslate () {
      return !this.issue
    },
    currentUser () {
      return AuthStore.currentUser
    },
    currentActivePatientId () {
      return this.currentActivePatient && this.currentActivePatient.id
    },
    chatRoomId () {
      return this.issue.chatRoom.id
    },
    trimmedMessage () {
      return trim(this.messageBeingTyped)
    },
    canSendMessage () {
      return this.trimmedMessage.length > 0 || !this.showBlankslate
    },
    currentUserIsParticipant () {
      return this.issue && this.issue.participantIds.includes(this.currentUser.id)
    },
  },
  watch: {
    // we only read chat room of the current patient in case we uncollapse and its active
    collapsed (collapsed, wasCollapsed) {
      if (!collapsed && this.patient.id === this.currentActivePatientId) {
        this.readChatRoom()
      }
    },
    currentActivePatient (patient) {
      if (this.patient.id === this.currentActivePatient.id && this.messages) {
        this.readChatRoom()
        this.$nextTick(() => scrollToBottom(this.$refs.conversationMessages))
      }
    },
    // Internal: Scroll to the last message whenever a new message arrives.
    messages (messages, hadMessages) {
      if (messages) {
        if (this.patient.id === this.currentActivePatientId && !this.collapsed) {
          this.readChatRoom()
        }
        this.$nextTick(() => scrollToBottom(this.$refs.conversationMessages, { animate: hadMessages }))
      }
    },
  },
  methods: {
    initials,
    collapseWidget () {
      ChatStore.toggleChat()
    },
    readChatRoom () {
      ChatMessagesRequests.read({ data: { chat_room_id: this.chatRoomId, last_read_at: new Date() } }).then(response =>
        ChatStore.readChatRoom(response))
    },
    // Internal: Sends a message using the ActionCable cableSubscription.
    sendMessage (cableSubscription) {
      const message = {
        authorId: this.currentUser.id,
        content: this.trimmedMessage,
        createdAt: new Date().toISOString(),
        readerIds: [this.currentUser.id],
        pending: true,
      }
      if (!this.issue.participantIds.includes(this.currentUser.id)) {
        ChatStore.addParticipant({ issue: this.issue, userId: this.currentUser.id })
      }
      cableSubscription.perform('send_message', message)
      this.messageBeingTyped = ''
      this.messages.push(message)
    },
    // Internal: Attempts to resend a message that was not successfully sent.
    retryMessage (message, cableSubscription) {
      message.displayRetry = false
      message.createdAt = new Date().toISOString() // It wasn't sent before, so we need to adjust.
      cableSubscription.perform('send_message', message)
    },
    // Internal: Attempts to find a message in the conversation.
    findMessage ({ pending = true, content, createdAt }) {
      return findLast(this.messages, { pending, content, createdAt })
    },
    // Internal: Fetch messages for the conversation.
    connected (cableSubscription) {
      cableSubscription.perform('get_messages')
    },
    // Internal: Called when information is received through the channel.
    received (data, cableSubscription) {
      // NOTE: For simplicity, methods have the same name as the actions.
      // Could be replaced with a switch statement if needed.
      if (!this[data.type]) throw new Error(`Unknown type for the chat channel: '${data.type}'`)
      this[data.type](data, cableSubscription)
    },
    // Called by `received`: Adds a new message to the conversation, or updates
    // a recently sent one.
    newMessage ({ message, userId }) {
      if (message.authorId === userId) {
        const sentMessage = this.findMessage(message)
        if (sentMessage) {
          this.$delete(sentMessage, 'pending')
          assign(sentMessage, message)
          return
        }
      }
      this.displayWidget = true // Ensure we reopen the chat if closed.
      this.messages && this.messages.push(message) && ChatStore.addMessagetoChatRoom({ chatRoomId: this.chatRoomId, message })
    },
    // Called by `received`: Called when the message was not sent successfully,
    // flags it for retry.
    sendMessageFailed ({ message }) {
      const pendingMessage = this.findMessage(message)
      if (pendingMessage) this.markMessageForRetry(pendingMessage)
    },
    // Called by `received`: Receives all the chat messages for the conversation.
    allMessages ({ messages }) {
      // Allow to retry these messages that were not sent after connection was lost.
      const pendingMessages = (this.messages || []).filter(message => message.pending)
      const unsentMessages = pendingMessages
        .filter(({ authorId, content, createdAt }) => !findLast(messages, { authorId, content, createdAt }))
      unsentMessages.forEach(this.markMessageForRetry)

      // Display the new messages, and any pending non-sent messages at the bottom.
      this.messages = messages.concat(unsentMessages)
      ChatStore.setMessagesForIssueChatRoom({ issue: this.issue, messages })
    },
    // Internal: Allows the user to retry and send a failed message.
    markMessageForRetry (message) {
      this.$set(message, 'displayRetry', true)
    },
    addParticipant () {
      PatientMessageIssuesRequests.addCurrentUserAsParticipant(this.issue).then(issue => {
        ChatStore.updateIssue(issue)
      })
    },
    removeParticipant () {
      PatientMessageIssuesRequests.removeCurrentUserAsParticipant(this.issue).then(issue => {
        ChatStore.updateIssue(issue)
      })
    },
    markAsClosed () {
      PatientMessageIssuesRequests.markAsClosed(this.issue).then(() => {
        ChatStore.removeIssue(this.issue)
        ChatStore.setCurrentActivePatient(null)
      })
    },
  },
}
</script>

<template>
  <ActionCableProvider
    v-show="displayWidget"
    class="user-chat-widget"
    channelName="UserPatientChatChannel"
    :params="{ patientId: patient.id }"
    :listeners="{ connected, received }"
    :autoReconnect="false"
    @actionCable:connected="offline = false"
    @actionCable:disconnected="offline = true"
  >
    <div slot-scope="{ cableConsumer, cableSubscription }" class="chat-messenger" :class="{ offline }">
      <div class="chat-header">
        <span class="chat-header-title">{{ patient.name }}</span>
        <Button v-if="offline" label="Reconnect" compact @click="cableConsumer.ensureActiveConnection()"/>
        <template v-else>
          <Dropdown v-if="!showBlankslate" position="right">
            <Button slot="dropdownToggle" icon="settings"/>
            <template slot="dropdownContent">
              <ActionLink
                class="dropdown-content-label"
                icon="lock"
                label="Mark as Closed"
                @click="markAsClosed()"
              />
              <ActionLink
                v-if="currentUserIsParticipant"
                class="dropdown-content-label"
                icon="logout"
                label="Exit the Room"
                @click="removeParticipant()"
              />
              <ActionLink
                v-else
                class="dropdown-content-label"
                icon="upload"
                label="Join the Room"
                @click="addParticipant()"
              />
            </template>
          </Dropdown>
          <ActionLink
            icon="minimizeToDock"
            size="medium"
            class="collapse-action"
            name="Collapse"
            compact
            @click="collapseWidget"
          />
        </template>
      </div>
      <Crouton v-if="offline" croutonStyle="offline" :error="false" :duration="false" :closeBtn="false">
        <Icon name="offline" size="small"/>
        <span class="crouton-offline-message">No internet connection</span>
      </Crouton>
      <CroutonPlaceholder/>
      <div v-if="showBlankslate" class="blankslate-message">
        No message room is selected, please select one on the left to start.
      </div>
      <div v-else ref="conversationMessages" class="conversation-messages">
        <template v-if="messages">
          <div
            v-for="message in messages"
            :key="message.id"
            class="conversation-message"
            :class="[message.pending && 'pending', message.authorId === currentUser.id ? 'mine' : 'theirs']"
          >
            <Button
              v-if="message.displayRetry"
              v-tooltip="{ trigger: 'mouseenter', content: `The message was not sent.\n Click to retry.` }"
              class="message-retry-button"
              icon="errorFill"
              @click="retryMessage(message, cableSubscription)"
            />
            <div v-tooltip="message.authorName" class="message-author">{{ initials(message.authorName) }}</div>
            <div class="message-body">
              <span class="message-content">{{ message.content }}</span>
              <div class="message-timestamp">{{ message.createdAt | userFormatDate('time') }}</div>
            </div>
          </div>
        </template>
        <LoadingIndicator v-else class="conversation-loading" size="large"/>
      </div>
      <div class="conversation-composer">
        <textarea
          v-model="messageBeingTyped"
          v-autosize
          class="conversation-composer-input"
          :disabled="offline"
          :placeholder="offline ? 'Please try again later.' : 'Type message…'"
          rows="1"
          tabindex="0"
          @keydown.enter.exact.prevent="canSendMessage && sendMessage(cableSubscription)"
        />
        <div class="conversation-composer-actions">
          <Button
            :disabled="offline || !canSendMessage"
            icon="send"
            name="Send"
            compact
            buttonStyle="composer"
            @click="sendMessage(cableSubscription)"
          />
        </div>
      </div>
    </div>
  </ActionCableProvider>
</template>

<style lang="scss" scoped>
.user-chat-widget {
  @include z-index(popover);

  bottom: 0;
  font-size: $fs-normal;
  font-weight: $fw-regular;
  line-height: $lh-normal;
}

.chat-messenger {
  background-color: $WHITE;
  border: $br-light;
  border-radius: $radius-normal $radius-normal 0 $radius-normal;
  box-shadow: $box-shadow-light;
  display: flex;
  flex-direction: column;
  height: 424px;
  margin-left: auto;
  margin-right: 40px;
  width: 336px;
}

.chat-header {
  align-items: center;
  border-bottom: $br-light;
  display: flex;
  flex: 0 0 44px;
  justify-content: space-between;
  padding: 12px;

  & > .collapse-action {
    color: $tundora-l-0;
  }

  & > .actionable {
    margin-left: 8px;
  }
}

.chat-header-title {
  flex: 1 1 auto;
}

.crouton.crouton-offline {
  background: $tundora-l-3;
  border-bottom: $br-light;
  color: $fc-html;
}

.crouton-offline-message {
  margin-left: 8px;
}

.conversation-messages {
  flex: 1 1 auto;
  margin: 2px 0;
  overflow-y: auto;
  padding-top: $lh-normal;
}

.conversation-loading {
  display: block;
  height: 100%;
  margin: auto;
}

.conversation-message {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  padding: 4px 8px;
  position: relative;
}

.blankslate-message {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 15px;
  text-align: center;
}

.message-content {
  white-space: pre-wrap;
}

.message-retry-button {
  background: none;
  color: $delete-color;
  margin: auto 4px;
}

.message-author {
  $size: 30px;

  background: $jelly-bean-l-2;
  border-radius: $size / 2;
  color: $WHITE;
  font-size: $fs-secondary;
  font-weight: $fw-light;
  height: $size;
  letter-spacing: 0.8px;
  line-height: $size - 1px;
  margin: 0 4px 1px 8px;
  text-align: center;
  width: $size;
}

.message-body {
  background: $tundora-l-3;
  border-radius: $radius-normal;
  padding: 6px 12px;
  position: relative;
  width: 200px;

  .conversation-message.pending & {
    opacity: 0.6;
  }
}

.message-timestamp {
  color: $fc-html-light;
  font-size: $fs-small;
  font-weight: $fw-light;
  left: 0;
  position: absolute;
  top: -($lh-normal);
}

.conversation-message.mine {
  justify-content: flex-end;

  .message-author {
    display: none;
  }

  .message-body {
    background: $bg-gradient-primary;
    color: $WHITE;
  }

  .message-timestamp {
    left: inherit;
    right: 0;
  }
}

.conversation-message + .theirs,
.theirs + .mine {
  padding-top: $lh-normal;
}

.mine + .mine {
  padding-top: 0;

  .message-timestamp {
    display: none;
  }
}

.conversation-composer {
  border-top: $br-light;
  flex: 0 0 auto;
  position: relative;
}

.conversation-composer-input {
  border: none;
  display: block;
  min-height: 46px;
  outline: none;
  padding: 12px 80px 12px 16px;
  resize: none;
  width: 100%;

  &::placeholder {
    color: $fc-html-light;
  }

  &:focus {
    box-shadow: 0 0 100px 0 $shadow-light;
  }

  &:disabled {
    background-color: $input-disabled-background-color;
    color: $input-disabled-color;
  }
}

.conversation-composer-actions {
  align-items: center;
  bottom: 0;
  color: $tundora-l-0;
  display: flex;
  position: absolute;
  right: 16px;
  top: 0;
}

.button.composer {
  opacity: 0.6;

  &:focus,
  &:hover {
    opacity: 1;
  }

  &[disabled] {
    opacity: 0.1;
  }
}

.dropdown-content-label {
  color: $tundora-l-0 !important;
}
</style>
