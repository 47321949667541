import { get, isArray, isObject } from 'lodash'
import {
  getProp,
  isComponentVNode,
  updateChildren,
  updateComponentOptions,
  updateComponentOptionsChildren,
} from '@helpers/NodeHelper'
import { snakeCaseToCamelCaseKeepingSpecialCharacters } from '@helpers/StringHelper'

export default {
  props: {
    // Public: The model that will be data-bound to the form.
    model: { type: Object, default: null },

    // Public: If true, the form will show errors immediately, without waiting for submit
    showErrorsBeforeSubmit: { type: Boolean, default: false },

    // Public: The translation namespace for the form labels and placeholders.
    prefix: { type: String, default: null },

    // Public: When enabled, this setting hides form hints in non-local environments in order to display Pendo delivered hints.
    usePendoHints: { type: Boolean, default: false },

    // Internal: Errors received from a parent form
    parentFormErrors: { type: Object, default: null },

    // Internal: Indicates if the parent form was submitted
    parentFormSubmitted: { type: Boolean, default: false },

    // Optional: Indicates the property used to identify the child model
    // within the parent model.
    nestedModelProp: { type: String, default: null },

    // Optional: Indicates the index to identify the child model.
    // Only required when the nestedModelProp returns an array.
    nestedModelIndex: { type: Number, default: null },
  },
  computed: {
    // Internal: Errors to be bound to the inputs. Overriden in Form
    errors () {
      return this.parentFormErrors
    },
    // Internal: Indicates if the parent form was submitted. Overriden in Form
    wasSubmitted () {
      return this.parentFormSubmitted
    },
    // Internal: Translation options for the labels, hints, and placeholders.
    translationOptions () {
      return { prefix: this.prefix, usePendoHints: this.usePendoHints }
    },
  },
  methods: {
    // Internal: Configures FormInputs inside the Form to be bound to the model.
    setupNodeAndChildren (vNode) {
      if (this.isFormInput(vNode)) return updateComponentOptions(vNode, this.formInputConfig(this.fieldPropFor(vNode)))
      if (this.isChildForm(vNode)) return updateComponentOptions(vNode, this.childModelConfigFor(vNode))
      if (vNode.children) return updateChildren(vNode, vNode.children.map(this.setupNodeAndChildren))
      if (vNode.componentOptions && vNode.componentOptions.children) {
        return updateComponentOptionsChildren(vNode, vNode.componentOptions.children.map(this.setupNodeAndChildren))
      }
      if (this.isFormInputContainer(vNode)) return updateComponentOptions(vNode, this.formInputContainerConfig(vNode))
      return vNode
    },
    // Internal: Returns the prefix that will be used when forwarding <FormInput> events to the parent <Form>
    modelPrefixFor (childForm) {
      const nestedModelProp = snakeCaseToCamelCaseKeepingSpecialCharacters(this.nestedModelPropFor(childForm))
      if (isArray(this.model[nestedModelProp])) return `${nestedModelProp}.${this.nestedModelIndexFor(childForm)}`
      return nestedModelProp
    },
    // Internal: Returns the configuration a nested <Form> should use
    childModelConfigFor (vNode) {
      const nestedModelProp = snakeCaseToCamelCaseKeepingSpecialCharacters(this.nestedModelPropFor(vNode))

      let childModel = this.model[nestedModelProp]
      let childErrors = this.errors[nestedModelProp]
      if (isArray(childModel)) {
        const nestedModelIndex = this.nestedModelIndexFor(vNode)
        childModel = childModel[nestedModelIndex]
        childErrors = childErrors && childErrors[nestedModelIndex]
      }

      return {
        propsData: {
          model: childModel,
          parentFormErrors: isObject(childErrors) ? childErrors : null,
          showErrorsBeforeSubmit: this.wasSubmitted || this.showErrorsBeforeSubmit,
        },
        listeners: {
          'input:valid': field => {
            this.onInputValid(`${this.modelPrefixFor(vNode)}.${field}`)
          },
          'input:errors': (field, errors) => {
            this.onInputErrors(`${this.modelPrefixFor(vNode)}.${field}`, errors)
          },
        },
      }
    },
    // Internal: Configures a v-model equivalent for the specified model field,
    // as well as providing translation options and server errors.
    formInputConfig (fieldProp) {
      const modelValue = get(this.model, fieldProp)
      return {
        listeners: {
          input: value => this.onFormInputValueChange(fieldProp, value),
          'input:valid': field => {
            this.onInputValid(fieldProp)
          },
          'input:errors': (field, errors) => {
            this.onInputErrors(fieldProp, errors)
          },
        },
        propsData: {
          displayErrors: this.showErrorsBeforeSubmit || this.wasSubmitted,
          serverErrors: this.errors[fieldProp] || null, // Undefined would break reactiveness.
          translationOptions: this.translationOptions,
          value: modelValue === undefined ? null : modelValue,
        },
      }
    },
    // Internal: Passes all the Form props to allow a component to process inner
    // FormInput nodes that are not accessible from this scope.
    //
    // NOTE: The component should use FormSidebarSectionMixin or a similar
    // helper and pass that to a <FormInputContainer.
    formInputContainerConfig (inputNode) {
      return {
        listeners: {
          input: (field, value) => this.onFormInputValueChange(field, value),
          'input:valid': field => { this.onInputValid(field) },
          'input:errors': (field, errors) => { this.onInputErrors(field, errors) },
        },
        propsData: {
          model: this.model,
          showErrorsBeforeSubmit: this.showErrorsBeforeSubmit,
          prefix: this.prefix,
          usePendoHints: this.usePendoHints,
          parentFormErrors: this.errors,
          parentFormSubmitted: this.wasSubmitted,
        },
      }
    },
    // Internal: Propagate input events, overridden in Form to do the actual handling
    onFormInputValueChange (field, value) {
      this.$emit('input', field, value)
    },
    // Internal: Propagate input events, overridden in Form to do the actual handling
    onInputValid (field) {
      this.$emit('input:valid', field)
    },
    // Internal: Propagate input events, overridden in Form to do the actual handling
    onInputErrors (field, errors) {
      this.$emit('input:errors', field, errors)
    },
    // Internal: vNodes for the elements that are nested in the Form.
    initializeFormInputChildren () {
      return this.$slots.default.map(this.setupNodeAndChildren)
    },
    // Internal: Matches a vNode if it is a <FormInput> component.
    isFormInput (vNode) {
      return isComponentVNode(vNode, 'FormInput') && vNode.componentOptions.propsData.field
    },
    // Internal: Matches a vNode if it has a formInputContainer attribute, in
    // which case all the Form properties will be forwarded.
    isFormInputContainer (vNode) {
      return vNode.data && vNode.data.attrs && vNode.data.attrs.hasOwnProperty('formInputContainer')
    },
    // Internal: Matches a vNode if it is a nested Form
    isChildForm (vNode) {
      return (isComponentVNode(vNode, 'Form') || isComponentVNode(vNode, 'FormGrid')) && this.nestedModelPropFor(vNode)
    },
    // Internal: Return the nestedModelProp of a child form
    nestedModelPropFor (vNode) {
      return getProp(vNode, 'nestedModelProp')
    },
    // Internal: Return the nestedModelProp of a child form
    nestedModelIndexFor (vNode) {
      const index = getProp(vNode, 'nestedModelIndex')
      return index !== undefined ? index : throw new Error(`In order to define a nested form (${this.nestedModelPropFor(vNode)}), you must provide a 'nestedModelIndex'.`)
    },
    // Internal: Returns the name of the model prop that the <FormInput> is targeting.
    fieldPropFor (vNode) {
      const keepCase = getProp(vNode, 'keepCase')
      const field = getProp(vNode, 'field')
      return keepCase === '' || keepCase ? field : snakeCaseToCamelCaseKeepingSpecialCharacters(field)
    },
  },
}
