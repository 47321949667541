<script>
import { highlightString } from '@helpers/FilterHelper'

export default {
  name: 'Badge',
  props: {
    // Public: The text displayed inside the badge.
    label: { type: [Boolean, String, Number], default: false },

    // Public: Reduces the height and padding of the badge.
    compact: { type: Boolean, default: false },

    // Public: Animate the badge when showing/hiding it.
    animate: { type: Boolean, default: false },

    // Public: An string that will be highlighted if present in the label
    highlightedString: { type: String, default: '' },
  },
  computed: {
    highlightedLabel () {
      return highlightString(this.label, { query: this.highlightedString })
    },
  },
}
</script>

<template>
  <span
    v-if="label"
    :class="{ compact, animate }"
    class="badge"
    @click="$emit('click', $event)"
    v-html="highlightedLabel"
  />
</template>

<style lang="scss" scoped>
.badge {
  @include status-colors;

  background: $WHITE;
  border: 1px solid currentColor;
  border-radius: $radius-normal;
  color: $tundora-l-1;
  display: inline-block;
  font-size: $fs-secondary;
  padding: 2px 10px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: baseline;
  white-space: nowrap;

  &:not(:first-child) {
    margin-left: 4px;
  }

  &.compact {
    // This style is also used in Table, so any changes should go in the mixin.
    @include badge-compact;
  }

  &.animate {
    animation: fade 0.2s normal forwards ease-out;
  }
}
</style>
