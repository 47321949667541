// Public: JSON.parse, but with a nicer error message.
export function parseJSON (jsonString) {
  try {
    return JSON.parse(jsonString)
  } catch (error) {
    error.message += `.\n${jsonString} is not valid JSON.`
    throw error
  }
}

// Public: Like parseJSON, but without the error.
export function tryParseJSON (jsonString, { defaultValue } = {}) {
  try {
    return jsonString ? JSON.parse(jsonString) : defaultValue
  } catch (error) {
    return defaultValue
  }
}

// Public: Returns a JSON string formatted to be readable.
export function prettyJSON (item, { inline } = {}) {
  return JSON.stringify(item, null, inline ? 0 : 2)
}

// Public: Attempts to convert a "JS" object string to a valid JSON string.
// NOTE: Dirty? Sure. Convenient? Damn right.
export function jsStringToJson (jsString) {
  return jsString.replace(/(\w+):/g, '"$1":').replace(/'([^']+)'/g, '"$1"')
}

// Public: Attempts to convert a "JS" string to an Object, without using `eval`.
export function jsStringToObject (jsString, { errorMessage = '' } = {}) {
  try {
    return parseJSON(jsStringToJson(jsString))
  } catch (error) {
    error.message = `${errorMessage}\n${error.message}`
    console.error(error)
    return {}
  }
}
