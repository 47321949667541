<script>
export default {
  name: 'Card',
}
</script>

<template>
  <div class="card" @click="$emit('click')">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.card {
  background: $bg-card;
  border-radius: $radius-normal;
  box-shadow: $box-shadow-card;
  display: flex;
  flex-direction: column;

  & ::v-deep > .title-section {
    border-radius: $radius-normal;
  }
}
</style>
