<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'Checkbox',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Used to indicate a third-state, used for "select all" checkboxes,
    // or in mass-edit when a value is true for some models and false for others.
    indeterminate: { type: Boolean, default: false },

    // Public: If true, the indeterminate state will be true when the value prop is true.
    indeterminateIfNull: { type: Boolean, default: false },

    // Public: Whether the Checkbox is checked or not.
    value: { type: Boolean, default: false },

    // Public: Applies styling that transforms checkbox into a circle.
    circular: { type: Boolean, default: false },

    // Internal: Applies styling that makes the checkbox white with green tick when checked. This is needed only for button groups.
    light: { type: Boolean, default: false },
  },
  computed: {
    isIndeterminate () {
      return this.indeterminate || (this.indeterminateIfNull && this.value === null)
    },
  },
  methods: {
    // Internal: Propagates the change event as an input event.
    // NOTE: We listen to "change" for compatibility with old browsers.
    onChange (event) {
      if (this.isIndeterminate) event.target.checked = false
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<template>
  <div class="checkbox" :class="{ 'checkbox--checked': value, 'checkbox--light': light }">
    <div class="checkbox__wrapper">
      <label :disabled="disabled" class="checkbox__inner" :class="{ error }">
        <input
          :checked="value"
          :disabled="disabled"
          :indeterminate="isIndeterminate"
          type="checkbox"
          class="checkbox__input"
          @change="onChange"
          @click.stop
        ><!--  Prevent double click propagation (caused by the input) -->
        <span :class="{ circular }" class="checkbox__highlight"/>
        <span v-if="label || $slots.inputLabel" class="checkbox__label item__label">
          <slot name="inputLabel">{{ label }}</slot>
        </span>
        <slot v-if="$slots.indentationIcon" name="indentationIcon"/>
      </label>
      <Hint :content="hint" class="checkbox__hint"/>
    </div>
    <InputError v-if="error" :error="error" class="checkbox__error"/>
  </div>
</template>

<style lang="scss" scoped>
$checkbox-height: 16px;
$padding-left: 24px;

.checkbox {
  position: relative;
}

.checkbox__wrapper {
  align-items: center;
  display: flex;
}

.checkbox__inner {
  @include clickable;

  display: block;
  height: fit-content;
  margin-bottom: 0;
  padding-left: $padding-left;
  position: relative;
  user-select: none;

  &[disabled] {
    color: $input-disabled-color;
  }

  &.error .checkbox__label {
    color: $input-error-color;
  }
}

.checkbox__input {
  display: none;
}

.checkbox__highlight {
  background-color: $WHITE;
  border: 1px solid $input-border-color;
  border-radius: 2px;
  height: $checkbox-height;
  left: 0;
  position: absolute;
  top: 2px;
  transition: all 0.3s ease-out;
  width: $checkbox-height;

  &.circular {
    border-radius: 50%;
    height: 28px;
    top: -12px;
    width: 28px;
  }

  &::before {
    background: transparent;
    border-bottom: solid 2px $WHITE;
    border-right: solid 2px $WHITE;
    display: block;
    height: 8px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -60%) rotate(45deg);
    width: 5px;
  }

  .checkbox__inner:hover & {
    border-color: $primary-color;
  }

  .checkbox__input[indeterminate] ~ &::before {
    border-right: 0;
    height: 0;
    transform: translate(-50%, -50%) rotate(0);
    width: 7px;
  }

  .checkbox__input[indeterminate] ~ &,
  .checkbox__input:checked ~ & {
    background-color: $primary-color;
    border-color: $primary-color;

    &::before {
      content: '';
    }
  }

  .checkbox__input[disabled] ~ & {
    background-color: $input-disabled-background-color;
    border-color: $disabled-color;

    &::before {
      border-color: $disabled-color;
    }
  }

  .checkbox__inner.error & {
    background-color: $input-error-background-color;
    border-color: $input-error-color;

    &::before {
      border-color: $input-error-color;
    }
  }

  .checkbox--light .checkbox__input[indeterminate] ~ &,
  .checkbox--light .checkbox__input:checked ~ & {
    background-color: $WHITE;
    border-color: $WHITE;
  }

  .checkbox--light .checkbox__input[indeterminate] ~ &::before,
  .checkbox--light .checkbox__input:checked ~ &::before {
    border-color: $primary-color;
  }
}

.checkbox__error.input-error {
  padding-left: $padding-left;
  position: static;
}
</style>
