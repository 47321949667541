<script>
export default {
  name: 'NavigationBarMenuTitle',
  props: {
    // Public: The label to display in the menu item.
    title: { type: String, default: null },

    // Optional: Path to navigate to when the menu item is clicked.
    to: { type: [String, Object], default: null },

    expanded: { type: Boolean, default: false },
  },
}
</script>

<template>
  <div :class="{ expanded }" class="navbar-menu" @click="$emit('click', $event)">
    <NavigationLink :to="to" class="navbar-menu__link no-underline" :tabindex="expanded && -1">
      <slot name="title">
        {{ title }}
        <div :class="{ 'navbar-menu__down-arrow': !expanded }"/>
      </slot>
    </NavigationLink>
    <slot/>
  </div>
</template>

<style lang="scss" scoped>

.navbar-menu__link {
  color: $WHITE;
  cursor: inherit;
  font-weight: $fw-regular;
  position: relative;
}

.navbar-menu__down-arrow {
  border-color: $tertiary-color transparent transparent transparent;
  border-style: solid;
  border-width: 4px 4px 0 4px;
  display: none;
  left: calc(50% - 2px);
  margin: 2px auto 0;
  position: absolute;
  width: 0;
}

.navbar-menu {
  @include clickable;
  @include z-index(navbar);

  align-items: center;
  display: inline-flex;
  justify-content: center;
  padding: 12px 8px 14px;
  position: relative;
  width: 100%;

  &:hover .navbar-menu__down-arrow {
    display: block;
  }

  &.expanded {
    @include z-index(above-navbar);
  }
}

$arrow-size: $navbar-dropdown-arrow-size;

.navbar-menu.expanded::after {
  border-color: transparent transparent $bg-card transparent;
  border-style: solid;
  border-width: 0 $arrow-size $arrow-size $arrow-size;
  bottom: calc(#{$navbar-dropdown-menu-margin} - 1px);
  content: "";
  left: calc(50% - #{$arrow-size});
  margin: 5px auto 0;
  position: absolute;
  width: 0;
}
</style>
