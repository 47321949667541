// Public: Simple implementation of some of the Element.classList API that we
// are using, mostly in directives and in Icon.
export default class ClassList {
  constructor (el) {
    this.el = el
  }

  contains (str) {
    return this.getClass().includes(str)
  }

  add (str) {
    if (!this.contains(str)) {
      this.setClass(this.getClass().concat(' ', str))
    }
  }

  remove (str) {
    if (this.contains(str)) {
      this.setClass(this.getClass().replace(str, ''))
    }
  }

  toggle (str, force = !this.contains(str)) {
    if (force) {
      this.add(str)
    } else {
      this.remove(str)
    }
  }

  // Internal: Sometimes `getAttribute('class')` returns `undefined` in IE11.
  getClass () {
    return this.el.getAttribute('class') || ''
  }

  // Internal: Just a convenient shortcut.
  setClass (str) {
    this.el.setAttribute('class', str)
  }
}
