// v-autofocus: Focuses an element when it's inserted in the DOM.
//
// Can also receive a boolean expression to determine whether to focus.
export default {
  inserted (el, { value = true }) {
    if (value) {
      const inputOrElement = el.querySelector('input:not([type=hidden]), textarea') || el
      inputOrElement.focus()
    }
  },
}
