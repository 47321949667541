<script>
import InputMixin from '@mixins/InputMixin'
import ScaleHorizontally from '@transitions/ScaleHorizontally'
import { isBlank } from '@helpers/ObjectHelper'
import { isSafari } from '@helpers/BrowserHelper'
import { maskJs, removeNonDigits } from 'mask-js'

export default {
  name: 'MaterialTextInput',
  components: {
    ScaleHorizontally,
  },
  mixins: [InputMixin],
  props: {
    // Public: Text content of the input.
    value: { type: [String, Number, Boolean], default: null },

    // Public: Whether it's a text input or a textarea.
    multiline: { type: Boolean, default: false },

    // Public: Number of initial rows when a textarea.
    rows: { type: Number, default: 1 },

    // Public: Whether it's a text input or a password input.
    password: { type: Boolean, default: false },

    // Public: Override the input type
    inputType: { type: String, default: null },

    // Public: Whether it should use an inverted color scheme.
    inverse: { type: Boolean, default: false },

    // Public: Mask for the field, e.g to mask a phone number field: '(999) 999-9999'
    mask: { type: String, default: null },
  },
  data () {
    return {
      // Internal: To simplify styling.
      isFocused: false,

      // Internal: Allows to reveal the password.
      displayPassword: false,
    }
  },
  computed: {
    formattedValue () {
      return this.mask ? maskJs(this.mask, this.value) : this.value
    },
    displayIcon () {
      return this.$slots.materialInputIcon || this.displayPasswordToggle || this.error
    },
    displayPasswordToggle () {
      return this.password && !this.isEmpty
    },
    displayPlaceholder () {
      return this.isEmpty && !this.isFocused && (this.inputType !== 'date' || isSafari)
    },
    inputListeners () {
      return { ...this.$listeners, input: this.onInput, focus: this.onFocus, blur: this.onBlur }
    },
    inputTag () {
      return this.multiline ? 'textarea' : 'input'
    },
    isEmpty () {
      return isBlank(this.value)
    },
    // Internal: If a placeholder is provided we use it only when the input is empty.
    labelOrPlaceholder () {
      return (this.displayPlaceholder && this.placeholder) || this.label
    },
    type () {
      return this.inputType || (this.password && !this.displayPassword ? 'password' : 'text')
    },
  },
  methods: {
    onInput (event) {
      const inputValue = event.target.value
      if (this.mask) {
        const maskedValue = maskJs(this.mask, inputValue)
        const unmaskedValue = removeNonDigits(maskedValue)
        this.$emit('input', unmaskedValue)

        // Ensure that the form value gets updated even if the value did not change
        // E.g in the case an invalid character was typed the input should reset it
        if (maskedValue !== inputValue) this.$forceUpdate()
      } else {
        this.$emit('input', inputValue)
      }
    },
    onFocus (event) {
      this.isFocused = true
      this.$emit('focus', event)
    },
    onBlur (event) {
      this.isFocused = false
      this.$emit('blur', event)
    },
  },
}
</script>

<template>
  <div class="material-text-input text-input" :class="{ disabled, inverse, error, focused: isFocused }">
    <label v-if="displayPlaceholder || label" class="material-label material-color" :class="[displayPlaceholder ? 'placeholder' : 'floating', { inverse }]">
      {{ labelOrPlaceholder }}<span v-if="required && label" class="material-label-required label-required">*</span>
    </label>
    <component
      :is="inputTag"
      v-autosize
      class="material-input input"
      :class="[multiline ? 'textarea' : 'text', { labeled: label, 'with-icon': displayIcon, 'inverted-input': inverse }]"
      :rows="rows"
      :type="type"
      :disabled="disabled"
      :value.prop="formattedValue"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <div v-if="displayIcon" class="material-input-action">
      <slot name="materialInputIcon">
        <ActionLink v-if="displayPasswordToggle" class="show-password-toggle" icon="show" @click="displayPassword = !displayPassword"/>
        <ActionLink v-else-if="error" class="material-input-error-icon material-color" icon="errorFill"/>
      </slot>
    </div>
    <div class="material-underline-input material-underline"/>
    <ScaleHorizontally>
      <div v-if="isFocused" class="material-underline-focus material-underline material-color"/>
    </ScaleHorizontally>
    <div v-if="error" class="material-input-error input-error material-color">{{ error.join ? error.join(', ') : error }}</div>
  </div>
</template>

<style lang="scss" scoped>
$lh-small: 16px;
$lh-normal: 20px;
$material-icon-size: 24px;
$material-margin: $lh-normal - $lh-small;
$material-padding-horizontal: 12px;
$material-input-height: 56px;

.material-text-input {
  color: $fc-html;
  font-size: $fs-large;
  line-height: $lh-normal;
  position: relative;

  &.inverse {
    color: $WHITE;
  }

  &.focused .material-color {
    color: $input-border-color-active;
  }

  &.error .material-color {
    color: $input-error-color;
  }

  &.inverse .material-color {
    color: $WHITE;
  }

  &.disabled {
    opacity: 0.5;
  }
}

.material-input {
  background: $bg-material-light;
  border: none;
  border-radius: $radius-normal $radius-normal 0 0;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  outline: none;
  padding: $lh-small $material-padding-horizontal;
  width: 100%;

  &:hover,
  &:focus {
    background: $bg-material-active;
  }

  &:disabled {
    color: $input-disabled-color;
    pointer-events: none;
  }

  &.labeled {
    padding-top: $lh-normal;

    // Ensure we set the height since there is a bug in iOS safari that collapses the height
    &[type="date"] {
      min-height: $lh-normal + $lh-small + $fs-large; // padding + font size
    }
  }

  &.with-icon {
    padding-right: 2 * $material-padding-horizontal + $material-icon-size;
  }

  &:-webkit-autofill {
    border-radius: $radius-normal $radius-normal 0 0;
    -webkit-box-shadow: inset 0 0 0 9999px $jelly-bean-l-3;
    -webkit-text-fill-color: $fc-html;
  }

  .inverse &:-webkit-autofill {
    -webkit-box-shadow: inset 0 0 0 9999px $bondi-blue-l-0;
    -webkit-text-fill-color: $WHITE;
  }

  // Ensure we set the height since there is a bug in iOS safari that collapses the height
  &[type="date"] {
    min-height: 2 * $lh-small + $fs-large; // padding + font size
  }
}

.textarea {
  display: block;
  resize: none;
}

// Internal: We increase the hit area of the actions by using padding.
.material-input-action {
  $size: $material-icon-size + 2 * $material-padding-horizontal;

  height: $size;
  position: absolute;
  right: 0;
  top: $lh-small - $material-padding-horizontal;
  width: $size;

  ::v-deep > .actionable {
    padding: $material-padding-horizontal;

    .icon {
      height: $material-icon-size;
      width: $material-icon-size;
    }
  }
}

.material-input-error-icon {
  color: $input-error-color;
}

.material-label {
  color: $fc-html-medium;
  font-weight: $fw-regular;
  left: $material-padding-horizontal;
  line-height: $lh-normal;
  margin: 0;
  pointer-events: none;
  position: absolute;
  transition: 0.2s ease all;

  &.floating {
    font-size: $fs-secondary;
    margin-top: $material-margin;
    top: 0;

    &.inverse {
      opacity: 0.6;
    }
  }

  &.placeholder {
    opacity: 0.6;
    top: $lh-small;

    &.inverse {
      opacity: 0.8;
    }

    .material-text-input.labeled & {
      top: $lh-normal;
    }
  }
}

.material-label-required {
  opacity: 0.6;
}

.material-underline {
  background-color: currentColor;
  bottom: 0;
  height: 1px;
  left: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  width: 100%;
}

.material-underline-input {
  opacity: 0.2;

  .material-text-input:hover & {
    opacity: 0.4;
  }
}

.material-underline-focus {
  height: 2px;
  opacity: 1;
  transition: color 1s;
}

.material-input-error {
  bottom: 0;
  font-size: $fs-secondary;
  left: $material-padding-horizontal;
  line-height: $lh-small;
  margin-bottom: -($lh-small + $material-margin);
  position: absolute;
}

.show-password-toggle {
  position: absolute;
}
</style>
