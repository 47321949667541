<script>
// Public: A cancel button which automatically closes a FormModal by using the
// `reset` form event.
export default {
  name: 'CancelButton',
  inheritAttrs: false,
  props: {
    // Public: The preferred way to specify the content when it's a String.
    label: { type: String, default: 'global.buttons.cancel' },
  },
}
</script>

<template>
  <Button
    v-bind="$attrs"
    type="reset"
    class="cancel-button"
    v-on="$listeners"
  ><slot>{{ translateIfKey(label) }}</slot></Button>
</template>
