<script>
import SeekBar from '@components/SeekBar'
import { formatSecondsAsMinutes } from '@helpers/FormatHelper'
import { formatUrl } from '@helpers/UrlHelper'

export default {
  name: 'Audio',
  components: {
    SeekBar,
  },
  props: {
    // Public: Object representing the audio file
    file: { type: Object, default: () => ({}) },

    // Public: Whether to display the file duration when paused or elapsed time while playing
    showDuration: { type: Boolean, default: true },

    // Public: Whether to display a single play button or a full featured audio player
    showSeekBar: { type: Boolean, default: false },

    // Public: Indicates if the templates show two buttons (play/pause and stop) or just one (play/stop)
    withPause: { type: Boolean, default: false },

    // Public: Time in which we should start the reproduction
    startTime: { type: Number, default: 0 },

    // Public: Whether to display control buttons before the seek bar or after
    buttonsFirst: { type: Boolean, default: false },
  },
  data () {
    return {
      canPlay: Boolean(this.file && this.file.file) || Boolean(this.file && this.file.url),
      duration: (this.file && this.file.duration) ? this.file.duration : null,
      currentTime: 0,

      // Internal: Indicates the internal status of the player. Can take the values `stopped`, `playing`, `paused`
      status: 'stopped',

      // Internal: Indicates if an error occurred.
      error: false,
    }
  },
  computed: {
    formattedDuration () {
      const durationNumber = Number(this.duration)
      if (!durationNumber || !isFinite(durationNumber)) return null

      return formatSecondsAsMinutes(durationNumber.toFixed(0))
    },
    timeToDisplay () {
      return formatSecondsAsMinutes(this.currentTime.toFixed(0))
    },
    playOrPause () {
      return this.isPlaying ? 'Pause' : 'Play'
    },
    playOrStop () {
      return this.isPlaying ? 'Stop' : 'Play'
    },
    isPlaying () {
      return this.status === 'playing'
    },
    preload () {
      if (!this.showDuration && !this.showSeekBar) return 'none'
      return 'metadata'
    },
    seekBarProps () {
      return {
        class: 'audio__seek-bar',
        duration: this.duration,
        currentTime: this.currentTime,
      }
    },
  },
  watch: {
    file: {
      deep: true,
      handler (file) {
        this.status = 'stopped'
        this.canPlay = Boolean(file && file.file) || Boolean(file && file.url)
        this.duration = (file && file.duration) ? file.duration : null
        this.makeFilePlayable()
      },
    },
    status (status) {
      this.$emit('audio:statusChange', status)
    },
    startTime (startTime) {
      this.$refs.audio.currentTime = startTime
    },
    duration (duration) {
      this.$emit('audio:durationChange', duration)
    },
  },
  mounted () {
    const audio = this.$refs.audio

    audio.addEventListener('play', () => {
      this.status = 'playing'
    })
    audio.addEventListener('playing', () => {
      this.status = 'playing'
    })
    audio.addEventListener('pause', () => {
      this.status = 'paused'
    })
    audio.addEventListener('stalled', () => {
      this.status = 'stopped'
    })
    audio.addEventListener('ended', () => {
      this.status = 'stopped'
    })
    audio.addEventListener('error', event => {
      this.error = true
      this.status = 'stopped'
      this.canPlay = false
      this.$emit('audio:error', event)
    })
    audio.addEventListener('durationchange', () => {
      this.duration = audio.duration
    })
    audio.addEventListener('timeupdate', () => {
      this.currentTime = audio.currentTime
      this.$emit('audio:currentTimeChange', this.currentTime)
    })

    this.makeFilePlayable()
  },
  methods: {
    makeFilePlayable () {
      if (this.file && this.file.file) {
        const reader = new FileReader()
        reader.onload = event => {
          this.$refs.audio.src = event.target.result
          this.canPlay = true
          this.status = 'stopped'
        }

        reader.readAsDataURL(this.file.file)
      } else if (this.file && this.file.url) {
        this.canPlay = true
        // The `play` parameter tells the server to send us mp3 instead of wave so it can be played by IE11
        this.$refs.audio.src = formatUrl(this.file.url, { query: { play: true } })
      }
    },
    playAudio () {
      const audio = this.$refs.audio
      if (audio && audio.play) {
        audio.play().catch(() => { this.canPlay = false })
        if (this.currentTime === 0) this.$emit('audio:started')
      }
    },
    stopAudio () {
      const audio = this.$refs.audio
      if (audio && audio.pause) {
        audio.pause()
        audio.currentTime = 0
      }
    },
    pauseAudio () {
      const audio = this.$refs.audio
      if (audio && audio.pause) {
        audio.pause()
      }
    },
    playOrPauseAudio () {
      this.isPlaying ? this.pauseAudio() : this.playAudio()
    },
    playOrStopAudio () {
      this.isPlaying ? this.stopAudio() : this.playAudio()
    },
    actionTooltip (action) {
      return `Click to ${action.toLowerCase()}`
    },
    onCurrentTimeChanged (value) {
      if (this.canPlay) this.$refs.audio.currentTime = value
    },
  },
}
</script>

<template>
  <div class="audio-preview" :class="{ error }">
    <SeekBar v-if="showSeekBar && !buttonsFirst" v-bind="seekBarProps" @seekBar:currentTimeChanged="onCurrentTimeChanged"/>
    <span v-if="withPause" class="audio__controls">
      <Button v-tooltip="actionTooltip(playOrPause)" icon :name="playOrPause" :disabled="!canPlay" @click="playOrPauseAudio"/>
      <Button v-tooltip="actionTooltip('stop')" icon name="Stop" :disabled="!isPlaying" @click="stopAudio"/>
    </span>
    <template v-else>
      <Button v-tooltip="actionTooltip(playOrStop)" icon :name="playOrStop" :disabled="!canPlay" @click="playOrStopAudio"/>
      <template v-if="showDuration">
        <span v-if="isPlaying" class="file-duration">{{ timeToDisplay }}</span>
        <span v-else class="file-duration">{{ formattedDuration }}</span>
      </template>
    </template>
    <SeekBar v-if="showSeekBar && buttonsFirst" v-bind="seekBarProps" @seekBar:currentTimeChanged="onCurrentTimeChanged"/>
    <audio ref="audio" :preload="preload"/>
  </div>
</template>

<style lang="scss" scoped>
.audio__controls {
  margin-left: 12px;

  .button {
    margin-left: 4px;
  }
}

.audio__seek-bar {
  margin-right: 8px;
}

.audio-preview {
  align-items: center;
  display: flex;
  white-space: nowrap;

  &.error ::v-deep .seek-bar-indicator {
    background: $bg-error-color;
  }
}

.file-duration {
  color: $tundora-l-1;
  display: inline-block;
  font-size: $fs-secondary;
  margin-left: 8px;
  user-select: none;
}
</style>
