<script>
// Public: Light styling wrapper for ActionLinks inside a SelectionToolbar.
export default {
  name: 'SelectionAction',
  props: {
    loading: { type: Boolean, default: false },
  },
}
</script>

<template functional>
  <Button
    class="selection-action"
    v-bind="data.attrs"
    :icon="props.loading ? 'loading' : data.attrs.icon"
    :disabled="props.loading || data.attrs.disabled"
    :class="data.staticClass"
    v-on="listeners"
  >
    {{ data.attrs.label }}
    <Icon v-if="!data.attrs.hasOwnProperty('icon')" name="chevronDown" size="small" class="selection-action__down-arrow"/>
  </Button>
</template>

<style lang="scss" scoped>
@import '@style/search/selection.scss';

.button.selection-action {
  margin: 8px 24px 0 0;
}

.selection-action__down-arrow {
  display: inline-flex;
  vertical-align: -1px;
}
</style>
