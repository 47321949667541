// CacheKey 0b90a7cdfbcebb35c75cc1238c75afb1
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { request } from '@services/EvolveApiService'

export default {
  get: options =>
    request('get', '/vue/my_account', options),

  update: options =>
    request('patch', '/vue/my_account', options),

  info: options =>
    request('get', '/vue/my_account/info', options),

  messages: options =>
    request('get', '/vue/my_account/messages', options),
}
