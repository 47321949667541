<script>
import WindowStore from '@stores/WindowStore'
import { fakeFormSubmit } from '@helpers/SubmitHelper'
import { merge } from 'lodash'
import { pathIfFull, pathTo } from '@helpers/RoutingHelper'

// Public: All-purpose clickable linky element :)
export default {
  name: 'NavigationLink',
  inheritAttrs: false,
  props: {
    // Public: Path for the link, it might be one of:
    //   - String with a leading '/', will render a simple <a href>.
    //   - Object, will be passed straight to <router-link>.
    //   - String or Array, will use 'pathTo' to obtain the name and parameters
    //     of the route, and then hand it over to <router-link>.
    to: { type: [String, Array, Object], default: undefined },

    // Public: The preferred way to specify the content when it's a String.
    label: { type: String, default: '' },

    // Public: Will not go to href when disabled
    disabled: { type: Boolean, default: false },

    // Public: Additional parameters for the route, will be combined with any
    // parameters provided in `to`.
    params: { type: Object, default: undefined },

    // Compatibility-Only: Use to replace any Rails link with data-method (method: 'get').
    method: { type: String, default: null },
  },
  computed: {
    url () {
      return pathIfFull(this.to)
    },
    route () {
      return !this.url && this.to && merge(pathTo(this.to), this.params && { params: this.params })
    },
    resolvedRoute () {
      return this.route && this.$router && this.$router.resolve(this.route)
    },
    routeLocation () {
      return this.resolvedRoute && this.resolvedRoute.location
    },
    // Internal: Used in pages without vue-router, like legacy ones.
    redirectPath () {
      return this.route && `/v/redirect?route=${encodeURIComponent(JSON.stringify(this.route))}`
    },
    // Internal: The path or url of the link.
    href () {
      return this.url || (this.resolvedRoute && this.resolvedRoute.href) || this.redirectPath || '#'
    },
  },
  methods: {
    // Internal: Allows to trigger a navigation link by pressing ENTER.
    //
    // NOTE: We trigger a native click event so that it propagates as usual,
    // which is used in different situations, like to automatically close
    // dropdowns or detect clicks on SidebarContainer.
    onEnter (event) {
      this.$el.click()
    },
    // Internal: Allows to listen on clicks that cause navigation to happen.
    onClick (event) {
      if (this.disabled) return event.preventDefault()

      // Don't navigate if it's a download link
      if (this.$attrs.hasOwnProperty('download')) return this.$emit('downloading', event)

      if (this.shouldHandleNavigation(event)) {
        this.$emit('navigated', event)
        if (!this.to) return event.preventDefault()
        if (this.routeLocation) return this.navigateWithRouter(event)
        if (this.method) return this.navigateWithMethod(event)

        // Don't display the loading indicator if we are using normal navigation
        // to the same page.
        if (this.url === window.location.pathname || this.url === window.location.href) return

        WindowStore.setNavigating(true)
      }
    },
    // Internal: Performs navigation using vue-router.
    navigateWithRouter (event) {
      event.preventDefault()
      this.$router.push(this.resolvedRoute.location)
    },
    // Internal: Allows making a synchronous request with a method other than GET.
    navigateWithMethod (event) {
      event.preventDefault()
      WindowStore.setNavigating(true)
      fakeFormSubmit(this.href, { method: this.method })
    },
    // Internal: Copied from vue-router because using <router-link> is
    // inconvenient in our current use case.
    shouldHandleNavigation (event) {
      // Don't navigate with control keys
      if (event.metaKey || event.altKey || event.ctrlKey || event.shiftKey) return
      // Don't navigate when preventDefault called
      if (event.defaultPrevented) return
      // Don't navigate on right click
      if (event.button !== undefined && event.button !== 0) return
      // Don't navigate if `target="_blank"`
      if (event.currentTarget && event.currentTarget.getAttribute) {
        const target = event.currentTarget.getAttribute('target')
        if (/\b_blank\b/i.test(target)) return
      }
      return true
    },
  },
}
</script>

<template>
  <a
    class="navigation-link"
    :class="{ disabled }"
    :href="href"
    :rel="safeRel"
    v-bind="$attrs"
    @click="onClick"
    @keydown.enter.exact.prevent="onEnter"
  ><slot>{{ label }}</slot></a>
</template>

<style lang="scss" scoped>
.navigation-link.disabled {
  color: $disabled-color;
  pointer-events: none;
}
</style>
