<script>
export default {
  name: 'Dropdown',
  // Public: Allows using v-model to control the popover expanded state.
  model: {
    prop: 'show',
    event: 'dropdown:expanded',
  },
  props: {
    // Public: Whether the Dropdown should be closed when the content is clicked.
    closeOnClick: { type: Boolean, default: true },

    // Public: Whether prevented click events should be ignored.
    skipCloseIfPrevented: { type: Boolean, default: false },

    // Public: How to align the dropdown content below the toggle (left|right).
    position: { type: String, default: null },

    // Public: Expanded dropdown content will expand upwards above the toggle instead of below
    expandUpward: { type: Boolean, default: false },

    // Public: CSS class for the wrapper of the dropdown content.
    contentClass: { type: [String, Array], default: null },

    // Public: HTML attributes for the wrapper of the dropdown content.
    contentAttrs: { type: Object, default: null },

    // Public: Whether the layer should be visible (still transparent but dark).
    mask: { type: Boolean, default: false },

    // Public: used to manage the expanded/collapsed states externally
    show: { type: Boolean, default: undefined },
  },
  data () {
    return {
      // Private: Internal flag for storing and manipulating the "show" state. It is being used only if the `show` prop is not passed in.
      internalShow: false,
    }
  },
  computed: {
    // Public: True when the `show` prop or `v-model` are not used.
    managedInternally () {
      return this.show === undefined
    },
    expanded () {
      return this.managedInternally ? this.internalShow : this.show
    },
  },
  methods: {
    hideDropdown () {
      this.setDropdownExpanded(false)
    },
    // Public: Opens the dropdown if closed, closes it when expanded.
    toggleDropdown () {
      this.setDropdownExpanded(!this.expanded)
    },
    // Internal: Shows or hides the popover based on a boolean parameter.
    setDropdownExpanded (expanded) {
      if (this.managedInternally) {
        this.internalShow = expanded
      } else {
        this.$emit('dropdown:expanded', expanded)
      }
    },
    // Internal: Allows to close the dropdown depending on different options.
    onContentClick (event) {
      if (!this.closeOnClick) return
      if (this.skipCloseIfPrevented && event.defaultPrevented) return
      this.hideDropdown()
    },
  },
}
</script>

<template>
  <div v-on-outside="expanded && { click: hideDropdown, focusin: hideDropdown }" class="dropdown">
    <div ref="dropdownToggle" :class="{ expanded }" class="dropdown-toggle" @click="managedInternally && toggleDropdown()">
      <slot name="dropdownToggle"/>
    </div>

    <BlurArea v-if="expanded" :mask="mask" @blur="hideDropdown"/>

    <div v-if="expanded" class="expanded-content dropdown__content" :class="[position, contentClass, { 'expand-upward': expandUpward }]" v-bind="contentAttrs" @click="onContentClick">
      <slot name="dropdownContent"/>
      <div class="pointer"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  width: fit-content;
}

.dropdown-toggle {
  @include clickable;

  align-items: center;
  display: flex;
  height: 100%;
  transition: box-shadow 0.4s;

  &.expanded {
    @include z-index(dropdown);

    position: relative;

    & > .button {
      @include inset-shadowy-lighter;
    }
  }
}

.dropdown__content {
  @include z-index(dropdown);
  @include bordered-box;

  animation: enter-from-above 0.2s normal forwards ease-out;
  background-color: $WHITE;
  box-shadow: 0 7px 20px rgba($BLACK, 0.1);
  color: $fc-html;
  fill: $fc-html;
  font-size: $fs-html;
  font-weight: $fw-html;
  line-height: $lh-normal;
  margin-top: 2px;
  min-width: 150px;
  padding: 8px 0;
  position: absolute;
  width: max-content;

  &.left {
    left: 0;
  }

  &.right {
    right: 0;
  }

  &.expand-upward {
    bottom: calc(100% + 2px);
  }

  // TODO: Create a MenuLink that wraps ActionLink with this style.
  ::v-deep > .action-link,
  ::v-deep > .title-section-action-wrapper {
    display: flex;
    padding: 8px 16px;

    & > .icon {
      height: $icon-size-regular;
      width: $icon-size-regular;
    }

    &:hover {
      background: $bg-highlight;
    }
  }
}

.expand-upward .pointer {
  background-color: $WHITE;
  border-color: transparent $tundora-l-2 $tundora-l-2 transparent;
  border-style: solid;
  border-width: 1px;
  bottom: -6px;
  height: 12px;
  position: absolute;
  right: 9px;
  rotate: 45deg;
  width: 12px;
}

// TODO: Create a MenuLink that wraps ActionLink with this style.
.dropdown-toggle.expanded ::v-deep > .action-link {
  opacity: 1;
}
</style>
