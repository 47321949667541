<script>
// Public: Delimits a section of a settings form and registers it in the sidebar
// allowing users to quickly scroll to it as needed.
export default {
  name: 'SettingsSection',
  props: {
    title: { type: String, required: true },
    loading: { type: Boolean, default: false },
  },
}
</script>

<template>
  <div v-scrollable-section="title" class="settings-section" :data-setting-title="title">
    <div class="settings-section__header">
      <div class="settings-section__title">
        <span>{{ title }}</span>
        <LoadingIndicator v-if="loading" size="small"/>
        <slot name="actions"/>
      </div>
      <slot name="title"/></div>
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.settings-section__header {
  align-items: center;
  display: flex;
  font-size: $fs-larger;
  font-weight: $fw-bold;
  margin-bottom: 24px;

  & ::v-deep .badge {
    margin-left: 16px;
  }

  & ::v-deep .actionable {
    margin-left: 16px;
  }
}

.settings-section__title {
  align-items: center;
  display: flex;
  flex-grow: 1;

  .loading-indicator {
    display: flex;
    margin-left: 10px;
  }
}
</style>
