<script>
import { camelCase } from 'lodash'
import { getProp, updateChildren, updateComponentOptions } from '@helpers/NodeHelper'

// Internal: Matches a vNode if it is a <ModelField> component.
function isModelField ({ componentOptions }) {
  return componentOptions && componentOptions.tag === 'ModelField' && componentOptions.propsData.field
}

// Internal: Returns the name of the model prop that the <ModelField> is targeting.
function fieldPropFor (vNode) {
  return camelCase(getProp(vNode, 'field'))
}

export default {
  name: 'ModelFields',
  props: {
    // Public: The model that will be used to obtain the fields from.
    model: { type: Object, default: null },

    // Public: The translation namespace for the field labels.
    prefix: { type: String, default: null },
  },
  methods: {
    // Internal: Configures ModelField components to use the specified model and
    // translations.
    setupNodeAndChildren (vNode) {
      if (isModelField(vNode)) return updateComponentOptions(vNode, this.modelFieldConfig(fieldPropFor(vNode)))
      if (vNode.children) return updateChildren(vNode, vNode.children.map(this.setupNodeAndChildren))
      return vNode
    },
    // Internal: vNodes for the elements passed in the default slot.
    children () {
      return this.$slots.default.map(this.setupNodeAndChildren)
    },
    // Internal: Configures the label and value for a ModelField component.
    modelFieldConfig (fieldProp) {
      return {
        propsData: {
          translationOptions: { prefix: this.prefix },
          value: this.model[fieldProp] === undefined ? null : this.model[fieldProp],
        },
      }
    },
  },
  render (h) {
    return h('div', { class: 'model-fields' }, this.children())
  },
}
</script>

<style lang="scss" scoped>
.model-fields {
  @include flex-column;

  &.left-fields {
    flex-grow: 0;
    padding: 0 $padding-horizontal-html;

    ::v-deep .data-field {
      max-width: 800px;

      .data-field-label {
        max-width: 100px;
        min-width: 100px;
      }

      .data-field-value {
        margin-left: 0;
        text-align: left;
      }
    }
  }
}
</style>
