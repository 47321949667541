export default {
  AJAX_REDIRECT_STATUS_CODE: 279, // Status used by the rails-ajax_redirect gem, and ourselves manually as convention.
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  GONE: 410,
  SERVER_ERROR: 500,
  UNAUTHORIZED: 401,
  UNPROCESSABLE_ENTITY: 422,
}
