<script>
import { kebabCase } from 'lodash'
import { scrollIntoView } from '@helpers/ScrollHelper'

export default {
  name: 'Section',
  props: {
    title: { type: String, default: null },
  },
  computed: {
    sectionId () {
      return kebabCase(this.title)
    },
  },
  mounted () {
    // Smoothly scroll to the section, if this section is specified.
    if (this.title && this.$route.query.section === this.sectionId) scrollIntoView(this.$el)
  },
}
</script>

<template>
  <div class="section"><slot>{{ title }}</slot></div>
</template>

<style lang="scss" scoped>
.section {
  align-items: center;
  display: flex;
  font-weight: $fw-bold;
  margin-bottom: 24px;
}
</style>
