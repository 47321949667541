<script>
export default {
  name: 'LoadingIndicator',
  inheritAttrs: false,
  props: {
    white: { type: Boolean, default: false },
  },
}
</script>

<template>
  <Icon
    v-bind="$attrs"
    class="loading-indicator"
    :class="{ white }"
    name="loading"
  />
</template>

<style lang="scss" scoped>
.loading-indicator {
  // NOTE: IE11 does not support animateTransform.
  @include ie11 {
    animation: 0.8s linear infinite spin;
  }

  color: $primary-color;
  display: inline-block;
  margin: 4px;
  opacity: 0.5;
  text-align: center;

  &.white.white.white {
    color: $WHITE;
  }

  &.small {
    $length: 20px;

    height: $length;
    width: $length;
  }

  &.medium {
    $length: 30px;

    height: $length;
    width: $length;
  }

  &.huge {
    $length: 60px;

    display: block;
    height: $length;
    margin: $length auto;
    width: $length;
  }

  // stylelint-disable-next-line selector-pseudo-class-no-unknown
  &:global(.v-enter-active) {
    transition: opacity 0.4s;
  }

  // stylelint-disable-next-line selector-pseudo-class-no-unknown
  &:global(.v-enter) {
    opacity: 0;
  }
}
</style>
