import AuthStore from '@stores/AuthStore'
import UserManagementActionsRequests from '@requests/UserManagementActionsRequests'
import WindowStore from '@stores/WindowStore'
import { escape } from 'lodash'
import { evolveCareProviderUrl } from '@helpers/UrlHelper'
import { pluralize } from '@helpers/StringHelper'
import { providerUrl } from '@helpers/RoutingHelper'

// Public: Returns the URL to edit the user.
//
// NOTE: Must be injected in `methods`.
export function legacyEditUrl (user) {
  const id = user.id || user
  return evolveCareProviderUrl('users', id, 'edit')
}

// Internal: Navigates to the specified route once the user switch is complete.
function withNavigation ($vm, route, promiseFn) {
  // Wait until the request middleware calls `checkForUpdates` and detects a
  // difference in the cache keys, fetch the new data, and replace it.
  const unwatch = AuthStore.watch('currentUserId', userId => {
    unwatch()
    $vm.navigateTo(route)
  })

  WindowStore.setNavigating(true)
  return promiseFn().catch(error => {
    $vm.showPageCrouton(error)
    WindowStore.setNavigating(false)
  })
}

// Helper: To be injected in User/Edit and UsersManagement.
export function seeAs (user) {
  return withNavigation(this, 'Welcome', () => UserManagementActionsRequests.seeAs(user))
}

// Helper: To be injected in the UserImpersonationWidget.
export function stopImpersonating (user) {
  return withNavigation(this, 'UsersManagement', () => UserManagementActionsRequests.unsee())
}

// Helper: To be injected in User/Edit and UsersManagement.
export function openAlertsManagementModal (user) {
  this.asyncOpenModal('Users/AlertsManagementModal', { props: { user } })
}

// Helper: To be injected in User/Edit and UsersManagement.
export function openCopyUserAccessModal (user) {
  this.asyncOpenModal('Users/CopyUserAccessModal', { props: { user } })
}

// Helper: To be injected in User/Edit and UsersManagement.
export function openResetPasswordModal (user, { showCrouton = this.showPageCrouton } = {}) {
  this.confirmAction(user, user =>
    UserManagementActionsRequests.resetPassword(user)
      .then(() => showCrouton(`${escape(user.name)} will receive an email with password reset instructions.`, { info: true }))
      .catch(showCrouton),
  {
    title: 'Reset password',
    action: 'Reset Password',
    messageTemplate: '<p>The password for :modelLabel will be reset.</p><p>The user will receive an email with instructions to set a new password.</p>',
  })
}

// Helper: To be injected in User/Edit and UsersManagement.
export function openResetTwoFactorTimeOutModal (user, { showCrouton = this.showPageCrouton } = {}) {
  this.confirmAction(user, user =>
    UserManagementActionsRequests.resetTwoFactorTimeOut(user)
      .then(() => showCrouton(`${escape(user.name)} is no longer timed out.`, { info: true }))
      .catch(showCrouton),
  {
    title: 'Reset Two Factor Time Out',
    action: 'Reset Time out',
    messageTemplate: '<p>The Two Factor time out for :modelLabel will be reset.</p><p>The user will be able to retry login immediately.</p>',
  })
}

// Helper: To be injected in User/Edit and UsersManagement.
export function openReportPackagesModal (user) {
  const on = { saved: unableToRemove => {
    if (!unableToRemove.length) return
    const reportNames = (unableToRemove || []).map(report => `<li>${report}</li>`)
    this.modalMessage({
      title: `Unable to remove ${reportNames.length} ${pluralize(reportNames.length, 'report')}`,
      htmlSafe: true,
      message: `<strong>${user.name}</strong> (${user.email}) is the only recipient of the following ${pluralize(reportNames.length, 'package')} and,
        therefore, was not removed from the recipient ${pluralize(reportNames.length, 'list')}: <br/><br/><ul>${reportNames.join('')}</ul>`,
    })
  } }

  this.asyncOpenModal('Users/AddUserReportPackageModal', { props: { user }, on })
}

export const legacyInviteUserPath = providerUrl('/user_invitations/new')
export const legacyInvitedUsersPath = providerUrl('/admin/user_invitations')
export const legacyImportUsersPath = providerUrl('/data_exchange/file_imports/new?collection=users')
export const legacyExportRecentUsersPath = providerUrl('/users/export_recent_users')
