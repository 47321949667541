<script>
import { debounce } from 'lodash'
import { ifEverChanged } from '@helpers/WatchHelper'

const POPOVER_HORIZONTAL_PLACEMENT = [
  'center',
  'left',
  'right',
]

const POPOVER_VERTICAL_PLACEMENT = [
  'bottom',
  'top',
]

const POPOVER_TRIGGER = {
  CLICK: 'click',
  HOVER: 'hover',
}

// Public: Popovers are small layers displayed over the page.
// Use them when you need an overlay to interact with but not a full-blown modal.
export default {
  name: 'Popover',
  // Public: Allows using v-model to control the popover expanded state.
  model: {
    prop: 'show',
    event: 'popover:toggle',
  },
  props: {
    // Public: Boolean that indicates whether the Popover should be shown or not.
    // An internal value will be used if not provided.
    show: { type: Boolean, default: undefined },

    // Public: Determines if the close button should be shown within the Popover.
    closeBtn: { type: Boolean, default: true },

    // Public: Determines what the event should be that triggers the popover.
    trigger: {
      type: String,
      default: POPOVER_TRIGGER.CLICK,
      validator: v => [POPOVER_TRIGGER.CLICK, POPOVER_TRIGGER.HOVER].includes(v),
    },

    // Public: Determines how long it takes to open/close the popover.
    delay: { type: Number, default: 150 },

    // Public: String containing the header of the Popover.
    header: { type: String, default: undefined },

    // Public: Defines how the popover should be horizontally aligned in relation to the trigger.
    placement: { type: String, default: POPOVER_HORIZONTAL_PLACEMENT[0], validator: v => POPOVER_HORIZONTAL_PLACEMENT.includes(v) },

    // Public: Defines how the popover should be vertically aligned in relation to the trigger
    verticalPlacement: { type: String, default: POPOVER_VERTICAL_PLACEMENT[0], validator: v => POPOVER_VERTICAL_PLACEMENT.includes(v) },
  },
  data () {
    return {
      // Internal: Controls whether the content is displayed, used only if the
      // `show` prop is not provided externally.
      internalShow: false,

      // Internal: Allows to delay closing the popover on hover, used only if
      // the popover is triggered on hover.
      hovering: false,

      // Internal: Whether the dropdown was ever shown.
      ...ifEverChanged(this, { watch: 'internalShow', everChanged: 'everShown' }),
    }
  },
  computed: {
    // Public: True when the `show` prop or `v-model` are not used.
    managedInternally () {
      return this.show === undefined
    },
    expanded () {
      return this.managedInternally ? this.internalShow : this.show
    },
    triggerOnHover () {
      return this.managedInternally && this.trigger === POPOVER_TRIGGER.HOVER
    },
    triggerOnClick () {
      return this.managedInternally && this.trigger === POPOVER_TRIGGER.CLICK
    },
    toggleListeners () {
      if (this.triggerOnClick) return { click: this.togglePopover }
      return this.hoverListeners
    },
    hoverListeners () {
      return this.triggerOnHover ? { mouseenter: this.onToggleHover, mouseleave: this.onToggleLeave } : {}
    },
  },
  watch: {
    // Public: Allows client code to perform initial load of a Popover.
    everShown () {
      this.$emit('popover:load', this.expanded)
    },
  },
  created () {
    this.delayedSetExpanded = debounce(this.delayedSetExpanded, this.delay)
  },
  methods: {
    // Public: Ensures the popover is collapsed.
    hidePopover (event) {
      this.setPopoverExpanded(false)
    },
    // Internal: Opens the popover if closed, closes it when expanded.
    togglePopover () {
      this.setPopoverExpanded(!this.expanded)
    },
    // Internal: This debounced function
    // Internal: Shows or hides the popover based on a boolean parameter.
    setPopoverExpanded (expanded) {
      if (this.managedInternally) {
        this.internalShow = expanded
      } else {
        this.$emit('popover:toggle', expanded)
      }
    },
    // Internal: Opens the popover after a small delay.
    onToggleHover () {
      this.hovering = true
      this.delayedSetExpanded(true)
    },
    // Internal: Hides the popover after a small delay.
    onToggleLeave () {
      this.hovering = false
      this.delayedSetExpanded(false)
    },
    // Internal: Allows to prevent flickering when hovering from the toggle to
    // the content, allowing to perform click actions in the popover content.
    delayedSetExpanded (expanded) {
      if (this.hovering === expanded) this.setPopoverExpanded(expanded)
    },
  },
}
</script>

<template>
  <div v-on-outside="expanded && { click: hidePopover, focusin: triggerOnClick && hidePopover }" class="popover">
    <div :class="{ expanded }" class="popover-toggle" v-on="toggleListeners">
      <slot name="popoverToggle"/>
    </div>
    <BlurArea v-if="expanded && !triggerOnHover" @blur="hidePopover"/>

    <transition name="fade">
      <div v-if="expanded" class="popover-layer" :class="[placement, verticalPlacement]" v-on="hoverListeners">
        <div class="popover-content">
          <slot name="content">
            <Button
              v-if="closeBtn"
              class="popover-close-button"
              icon="closeMedium"
              @click="hidePopover"
            />
            <h3 v-if="header || $slots.header" class="popover-layer-header"><slot name="header">{{ header }}</slot></h3>
            <div class="popover-layer-content">
              <slot/>
            </div>
          </slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
@include fade-transition(0.2s);

$padding: 24px;

.popover {
  display: inline-block;
  position: relative;
}

.popover-toggle {
  @include clickable;

  align-items: center;
  display: flex;
  height: 100%;
  position: relative;
  user-select: none;

  &.expanded {
    @include z-index(popover);
  }
}

.popover-close-button {
  position: absolute;
  right: $padding;
  top: $padding - 2px;
}

.popover-layer {
  $default-tip-transform: rotate(-45deg);

  @include z-index(popover);
  @include bordered-box;

  background: $WHITE;
  box-shadow: 0 7px 20px rgba($BLACK, 0.1);
  font-size: $fs-normal;
  line-height: $lh-normal;
  margin-top: 16px;
  min-width: 330px;
  padding: $padding;
  position: absolute;
  top: 100%;

  &::before {
    @include z-index(default);

    background: $WHITE;
    border: solid 1px $tundora-l-2;
    border-bottom: 0;
    border-left: 0;
    content: "";
    display: block;
    height: 16px;
    position: absolute;
    top: -9px;
    transform: $default-tip-transform;
    width: 16px;
  }

  &.center {
    left: 50%;
    transform: translateX(-50%);

    &::before {
      left: 50%;
      margin-left: -8px;
      transform: translateX(-50%), $default-tip-transform;
    }
  }

  &.left {
    left: -16px;

    &::before {
      left: 32px;
    }
  }

  &.right {
    left: auto;
    right: -16px;

    &::before {
      left: auto;
      right: 32px;
    }
  }

  &.top {
    transform: rotate(180deg);
    transform-origin: 50% -30px;

    &.center {
      transform: translateX(-50%) rotate(180deg);
    }

    &.left {
      transform: rotate(180deg);

      &::before {
        left: auto;
        right: 32px;
      }
    }

    &.right {
      transform: rotate(180deg);
    }

    .popover-content {
      transform: rotate(180deg);
    }

    .popover-close-button {
      right: 0;
      top: 0;
    }
  }
}

.popover-layer-header {
  margin-bottom: 24px;

  ::v-deep .actionable {
    margin-left: 8px;
  }
}
</style>
