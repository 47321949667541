<script>
// Syntax Sugar: Avoid repeating the same thing over and over.
export default {
  name: 'FormModalFooter',
  props: {
    // Public: Whether the save button is loading.
    saving: { type: Boolean, default: false },

    // Public: The text for the save button.
    saveLabel: { type: String, default: 'global.buttons.save' },

    // Public: The text for the cancel button
    cancelLabel: { type: String, default: 'global.buttons.cancel' },

    // Public: Whether the save button should be danger style
    danger: { type: Boolean, default: false },

    // Public: Whether the save button should be enabled or disabled
    canSave: { type: Boolean, default: true },
  },
}
</script>

<template functional>
  <div class="modal-footer">
    <slot/>
    <CancelButton :label="props.cancelLabel"/>
    <SaveButton :danger="props.danger" :disabled="!props.canSave" :loading="props.saving" :label="props.saveLabel"/>
  </div>
</template>
