// CacheKey 213aa99e95cd41af24c92f96489ebbf6
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { request } from '@services/EvolveApiService'

export default {
  issuesForMessageCenter: options =>
    request('get', '/vue/message_center/patient_message_issues/issues_for_message_center', options),

  addCurrentUserAsParticipant: options =>
    request('put', '/vue/message_center/patient_message_issues/:id/add_current_user_as_participant', options),

  removeCurrentUserAsParticipant: options =>
    request('put', '/vue/message_center/patient_message_issues/:id/remove_current_user_as_participant', options),

  markAsClosed: options =>
    request('put', '/vue/message_center/patient_message_issues/:id/mark_as_closed', options),
}
