// Public: Returns an Object with a Boolean property initialized as `false`,
// which is set to `true` only if the specified `watch` expression ever changes.
export function ifEverChanged ($vm, { watch, everChanged }) {
  $vm.$once('hook:created', () => {
    watchOnce($vm, watch, () => { $vm[everChanged] = true })
  })
  return { [everChanged]: false }
}

// Public: Allows to easily register a one-time watcher.
export function watchOnce ($vm, watchedProperty, callback, options) {
  const unwatch = $vm.$watch(watchedProperty, function (value, oldValue) {
    if (value !== oldValue) {
      callback(value, oldValue)
      unwatch()
    }
  }, options)
  return unwatch
}

// Public: Allows to easily register a watcher until a certain condition is met.
export function watchUntil ($vm, watchedProperty, predicateFn, callback, options) {
  const unwatch = $vm.$watch(watchedProperty, function (value, oldValue) {
    const result = predicateFn(value, oldValue)
    if (result) {
      callback(result, value, oldValue)
      unwatch()
    }
  }, options)
  return unwatch
}
