import { DateFormats } from '@helpers/DateFormatHelper'
import { containsTimeInfo, formatDate as formatDateHelper, isStrictISODate, isValidDate } from '@helpers/DateHelper'
import { isArray, isBoolean, isNil, isNumber, isPlainObject, isString, round } from 'lodash'

import { maskJs } from 'mask-js'
import { titleize } from '@helpers/StringHelper'

export const formatDate = formatDateHelper

export function formatBoolean (value) {
  return value ? 'Yes' : 'No'
}

export function formatArray (value) {
  return value.map(quickFormat).filter(x => x).join(', ')
}

export function formatPhone (phone) {
  return maskJs('(999) 999-9999', phone)
}

export function formatNumber (value) {
  return value % 1 === 0 ? Number(value).toLocaleString() : round(value, 2).toFixed(2)
}

// Public: Formats the specified seconds as minutes:second, example: 1:03.
export function formatSecondsAsMinutes (secondsDuration) {
  const minutes = Math.floor(secondsDuration / 60)
  const seconds = secondsDuration % 60
  return `${minutes}:${(seconds < 10) ? '0' : ''}${seconds}`
}

// Public: Formats the seconds by rounding and adding a suffix.
export function formatSeconds (seconds) {
  return seconds ? `${seconds.toFixed(1)}s` : ''
}

//  TODO: find a better way of setting which prop to use
function formatObject (value) {
  return value.name || value.label || value.value
}

// Public: Uses the `date` format if the date doesn't include time information.
export function quickFormatDate (dateStr) {
  // Only format if we can properly deduce time zone information from the string, or we are rendering a plain date.
  if (!isStrictISODate(dateStr)) { return dateStr }
  return formatDate(dateStr, { format: !isString(dateStr) || containsTimeInfo(dateStr) ? 'date_time_timezone' : 'date' })
}

export function quickFormat (value) {
  if (isNil(value)) return ''
  if (isBoolean(value)) return formatBoolean(value)
  if (isArray(value)) return formatArray(value)
  if (isNumber(value)) return formatNumber(value)
  if (isValidDate(value)) return quickFormatDate(value)
  if (isString(value)) return value
  if (isPlainObject(value)) return formatObject(value)

  throw new TypeError(`Don't know how to auto-format '${value}', please specify a format option instead.`)
}

export function formatAs (format, value) {
  switch (format) {
    case 'none': return value
    case 'boolean': return formatBoolean(value)
    case 'array': return value.join(', ')
    case 'titleize': return titleize(value)
    case 'enum': return titleize(value)
    case 'number': return formatNumber(value)
    default:
      if (DateFormats[format.toUpperCase()]) return formatDate(value, { format })
      throw new TypeError(`Unknown format option '${format}' for '${value}' value`)
  }
}
