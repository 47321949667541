<script>
import VNodes from '@components/VNodes'
import WindowStore from '@stores/WindowStore'
import { hasProp, updateComponentOptions } from '@helpers/NodeHelper'
import { isEqual } from 'lodash'

export default {
  name: 'TableCellActions',
  components: {
    VNodes,
  },
  props: {
    // Helper: Allows to render an empty cell when a single permission controls
    // all the actions.
    availableIf: { type: Boolean, default: true },

    // Public: If enabled all actions will be displayed in the dropdown.
    dropdownOnly: { type: Boolean, default: false },

    // Public: Amount of actions that should be displayed outside the dropdown.
    displayUpTo: { type: Number, default: 3 },

    // Public: If enabled buttons will have labels, else just icons.
    buttonsWithLabels: { type: Boolean, default: false },
  },
  data () {
    return {
      actions: [],
    }
  },
  computed: {
    isDesktop: WindowStore.isScreen('desktop'),
    actionsCount () {
      return this.actions.length
    },
    noDropdown () {
      return !this.dropdownOnly && this.actionsCount <= this.displayUpTo
    },
    displayButtonLabels () {
      return this.buttonsWithLabels && this.isDesktop
    },
    expandedActions () {
      if (this.dropdownOnly) return []
      const actions = this.noDropdown ? this.actions : this.actions.slice(0, this.displayUpTo - 1)
      return this.enhanceActions(actions, { actionComponent: 'Button', displayLabel: this.displayButtonLabels })
    },
    dropdownActions () {
      if (this.noDropdown) return []
      const actions = this.dropdownOnly ? this.actions : this.actions.slice(this.displayUpTo - 1)
      return this.enhanceActions(actions, { actionComponent: 'ActionLink' })
    },
  },
  methods: {
    // Internal: Allows to prevent double clicks and provide visual feedback for
    // long running operations.
    isActionAsync: hasProp('async'),

    // Internal: Updates the props of the TableCellAction components.
    enhanceActions (actionNodes, extraProps) {
      return actionNodes.map(action => {
        const isPromiseButton = extraProps.actionComponent === 'Button' && this.isActionAsync(action)
        const propsData = isPromiseButton ? { ...extraProps, actionComponent: 'PromiseButton' } : extraProps
        return updateComponentOptions(action, { propsData })
      })
    },
    // Internal: This is where the magic happens, every time the component is
    // rendered, we assign the slot content to a data property, causing the
    // computed properties that depend on it to be re-evaluated.
    slotIsPresent () {
      // Check for slots rendered, but v-if="false", these are sent as `isComment=true`.
      // Probably a side effect of $slots not being reactive (https://github.com/SolarCS/banff/pull/13849)
      const actions = (this.$slots.default || []).filter(node => !node.isComment)
      if (!isEqual(this.actions, actions)) this.actions = actions
      return this.actionsCount > 0
    },
  },
}
</script>

<template>
  <td v-if="availableIf && slotIsPresent()" class="table-cell table-cell-actions">
    <div class="table-cell-actions-container">
      <div v-if="$slots.expandedActions || expandedActions.length" class="table-cell-actions__expanded-container">
        <div class="table-cell-actions__expanded">
          <slot name="expandedActions"/>
          <VNodes :content="expandedActions"/>
        </div>
      </div>
      <Dropdown v-if="dropdownActions.length" class="table-cell-actions__dropdown" contentClass="table-action-cell__content" position="right">
        <Button slot="dropdownToggle" class="actions-toggle" icon="more"/>
        <template slot="dropdownContent">
          <VNodes :content="dropdownActions"/>
        </template>
      </Dropdown>
    </div>
  </td>
  <td v-else class="table-cell"/>
</template>

<style lang="scss" scoped>
.table-cell.table-cell-actions {
  padding: 0 16px 0 8px;
}

.table-cell-actions-container {
  display: flex;
}

.table-cell-actions__dropdown {
  display: inline-block;

  ::v-deep .table-action-cell__content {
    top: 25px;
  }

  &:first-of-type {
    margin-left: auto;
  }
}

.table-cell-actions__expanded {
  display: flex;
}

.table-cell-actions__expanded-container {
  display: inline-block;
  margin-left: auto;
  margin-right: 8px;
}

.actions-toggle {
  background: none;
}

.table-cell-actions__empty {
  padding: 8px 16px;
}
</style>
