<script>
import KeyboardNavigationService from '@services/KeyboardNavigationService'
import WindowStore from '@stores/WindowStore'
import { centerVertically } from '@helpers/StyleHelper'
import { marginVerticalModalMin as marginMin, marginVerticalModalPreferred as marginPreferred } from '@style/JsVariables.scss'
import { setBodyScroll } from '@helpers/ScrollHelper'

export default {
  name: 'Modal',
  props: {
    // Public: To enable closing a modal when clicking the blur area.
    // Off by default since users have complained about the behavior in the past.
    closeOnBlurClick: { type: Boolean, default: false },

    // Public: Whether to display a button that allows closing the modal.
    closeBtn: { type: Boolean, default: true },

    // Public: Shows a loading indicator next to the title until the Promise is resolved.
    loadingUntil: { type: [Promise, Object]/* type: Promise, but IE11 polyfill */, default: null },

    // Public: Controls the modal width.
    small: { type: Boolean, default: false },

    // Public: Controls the modal width.
    normal: { type: Boolean, default: false },

    // Public: Controls the modal width.
    medium: { type: Boolean, default: false },

    // Public: Controls the modal width.
    huge: { type: Boolean, default: false },

    // Internal: Allows to provide a navigation service.
    // The default is only used to avoid window-scoped navigation with the keyboard.
    navigationService: {
      type: [Boolean, Object],
      default () {
        return new KeyboardNavigationService(this, { windowKeystrokes: true })
      },
    },
  },
  data () {
    return {
      disabled: this.loadingUntil,
    }
  },
  mounted () {
    // Hide the body scrollbar, to avoid confusing the user with a double scrollbar.
    this.$nextTick(() => setBodyScroll(false))
    // Center the modal when the screen is resized.
    const unwatchWindowSize = WindowStore.watch('windowSize', this.centerModal, { immediate: true })
    // Clean up
    this.$once('hook:destroyed', () => {
      unwatchWindowSize()
      setBodyScroll(true)
    })

    if (this.loadingUntil) this.loadingUntil.then(() => { this.disabled = false })
  },
  methods: {
    // Internal: 'reset' is emitted by form elements when CancelButton is clicked.
    //
    // NOTE: We are leveraging the native 'reset' event to allow modals to be
    // automatically closed by the CancelButton without having to manually
    // bind a click listener on every modal component that has a form.
    onFormReset (event) {
      if (!event.defaultPrevented) this.stopAndCloseModal(event)
    },
    // Internal: Emit an event to notify that the user intends to close the Modal.
    //
    // NOTE: Components may attach a @close listener instead, in which case
    // `closeModal` should be called manually.
    stopAndCloseModal () {
      this.$listeners.close ? this.$emit('close') : this.closeModal()
    },
    // Internal: Centers the modal according to the current viewport.
    centerModal () {
      centerVertically(this.$refs.modalContainer, { marginMin, marginPreferred })
    },
  },
}

</script>

<template>
  <transition appear name="modal">
    <BlurArea v-hotkey="{ esc: stopAndCloseModal }" class="modal" mask @blur="closeOnBlurClick && stopAndCloseModal($event)">
      <div
        ref="modalContainer"
        :class="{ small, normal, medium, huge, disabled }"
        class="modal-container"
        @reset="onFormReset"
      >
        <div class="modal-header">
          <slot name="modalHeader"/>
          <PromiseIndicator v-if="loadingUntil" :loadingUntil="loadingUntil"/>
          <Button v-if="closeBtn" class="modal-close" icon="closeMedium" @click="stopAndCloseModal"/>
        </div>
        <slot name="modalContent">
          <div class="modal-body">
            <slot name="modalBody"/>
          </div>
          <div v-if="$slots.modalFooter" class="modal-footer">
            <slot name="modalFooter"/>
          </div>
        </slot>
      </div>
    </BlurArea>
  </transition>
</template>

<style lang="scss" scoped>
$min-width-modal: 400px;

.blur-area {
  /* We need to ensure the feedback model is shown above the notifications dropdown */
  @include z-index(modal-blur-area);
}

.modal {
  word-break: break-word;
}

.modal-container {
  @include z-index(modal);

  background-color: $WHITE;
  border-radius: $radius-normal;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  left: 50%;
  margin-bottom: $margin-vertical-modal-min;
  min-width: $min-width-modal;
  padding: $padding-vertical-modal $padding-horizontal-modal;
  position: absolute;
  top: $margin-vertical-modal-min;
  transform: translateX(-50%);

  &.small {
    width: $modal-width-small;
  }

  &.normal {
    width: $modal-width-normal;
  }

  &.medium {
    width: $modal-width-medium;
  }

  &.huge {
    width: $modal-width-huge;
  }

  &.disabled {
    pointer-events: none;

    ::v-deep .modal-body,
    ::v-deep .modal-footer {
      opacity: 0.4;
    }
  }
}

/*
  Transitions
*/
.modal-enter,
.modal-leave-active {
  opacity: 0;
}

.modal-leave-active.modal {
  transform: scale(1.3);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

/*
  Elements
*/
.modal-header {
  align-items: center;
  display: flex;
  font-size: $fs-headline;

  & > .loading-indicator {
    display: inline-flex;
    margin-left: 8px;
  }

  .modal-close {
    flex: 0 0 auto;
    margin-left: auto;
  }
}

/* The following definitions are deep to allow wrapping both the body and the
  footer with a Form element, to avoid having to use a ref for the form element
  in order to cause a SaveButton in the footer to trigger a Form submit. */
::v-deep .modal-body {
  margin-top: 32px;
  text-align: left;

  // Example: Criteria modal for Rounding Flags.
  .form-actions.sticky {
    background: none;
    margin: 0 -32px -32px; // Compensate the default sticky margin.
    position: relative; // Prevent sticky positioning for forms in modals.
  }
}

::v-deep .modal-footer {
  margin-top: 32px;
  text-align: right;

  & > .button {
    margin-left: 8px;
  }

  & > .save-button::after {
    left: -100%;
    text-align: right;
  }
}
</style>
