<script>
// Public: Typically used within a Form to trigger the onSubmit handler.
export default {
  name: 'SaveButton',
  inheritAttrs: false,
  props: {
    // Public: When loading, the button text is replaced with a loading indicator.
    loading: { type: Boolean, default: false },

    // Public: By default the button triggers a submit event when used in Form.
    type: { type: String, default: 'submit' },

    // Public: The preferred way to specify the content when it's a String.
    label: { type: String, default: 'global.buttons.save' },
  },
}
</script>

<template>
  <Button
    :class="{ loading }"
    class="save-button"
    primary
    :type="type"
    :label="label"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <LoadingIndicator class="save-button__loading-indicator" white/>
    <span class="save-button__label"><slot>{{ translateIfKey(label) }}</slot></span>
  </Button>
</template>

<style lang="scss" scoped>
.save-button {
  min-width: 80px;
  position: relative;

  &::after {
    @include input-error;

    color: $BLACK;
    font-weight: $fw-light;
    letter-spacing: 0;
    text-transform: none;
  }
}

.save-button__loading-indicator {
  display: none;
  margin: 0;
  vertical-align: -4px;
}

.save-button.loading {
  @include save-button-loading;
}
</style>
