<script>
import { camelCase } from 'lodash'

// Public: Our powerful button that provides styling and convenient defaults.
export default {
  name: 'Button',
  inheritAttrs: false,
  props: {
    // Public: The button can not be clicked when disabled.
    disabled: { type: Boolean, default: false },

    // Public: The preferred way to specify the content when it's a String.
    label: { type: String, default: '' },

    // Public: The name of an icon to render inside the button.
    icon: { type: [Boolean, String], default: false },

    // Public: If true the icon is shown on the right.
    rightIcon: { type: Boolean, default: false },

    // Public: Button Style, see the design system for more info (CDS)
    primary: { type: Boolean, default: false },

    // Public: Button Style, see the design system for more info (CDS)
    danger: { type: Boolean, default: false },

    // Optional: A custom button style instead of the default (secondary).
    buttonStyle: { type: String, default: null },

    // Public: Small button for less important actions or crowded containers.
    compact: { type: Boolean, default: false },

    // Optional: A custom button size instead of the default (full).
    buttonSize: { type: String, default: null },

    // Public: The type of the button, can be 'submit', 'reset', or 'button'.
    //
    // NOTE: We use 'button' as the default, unlike browsers which use 'submit'.
    // See https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#attr-type
    type: { type: String, default: 'button' },

    // Internal: Used to make the action easy to interact with in tests with
    // helpers such as `click_action` or `have_action`.
    name: { type: String, default: null },
  },
  computed: {
    // Internal: Infer the icon from the label or name if it was provided as a Boolean.
    iconName () {
      return this.icon === true ? camelCase(this.label || this.name) : this.icon
    },
    withIcon () {
      return Boolean(this.iconName)
    },
    withLabel () {
      return Boolean(this.label || this.$slots.default)
    },
    buttonOrLinkTag () {
      return this.$attrs.to ? 'NavigationLink' : 'button'
    },
    styleClass () {
      return this.buttonStyle || (this.primary && 'primary') || 'secondary'
    },
    sizeClass () {
      return this.buttonSize || (this.compact && 'compact') || 'full'
    },
    contentClass () {
      if (this.withIcon && this.withLabel) return 'icon-with-label'
      return this.withIcon ? 'icon-only' : 'label-only'
    },
  },
  methods: {
    // Internal: Allows to trigger a button by pressing ENTER.
    //
    // NOTE: We trigger a native click event so that it propagates as usual,
    // which is used in different situations, like to automatically close
    // dropdowns or detect clicks on SidebarContainer.
    onEnter () {
      this.$el.click()
    },
  },
}
</script>

<template>
  <component
    :is="buttonOrLinkTag"
    class="button actionable iac"
    :class="[styleClass, sizeClass, contentClass, { danger }]"
    :disabled="disabled"
    :name="name || label"
    :type="type"
    :rel="safeRel"
    v-bind="$attrs"
    v-on="$listeners"
    @keydown.enter.exact.prevent="onEnter"
  >
    <span v-if="withIcon && !rightIcon" class="button-icon-container">
      <Icon class="button-icon" :name="iconName"/>
    </span>
    <span v-if="withLabel" class="button-label">
      <slot>{{ translateIfKey(label) }}</slot>
    </span>
    <span v-if="withIcon && rightIcon" class="button-icon-container">
      <Icon class="button-icon right" :name="iconName"/>
    </span>
  </component>
</template>

<style lang="scss" scoped>
/* Base Style and Behavior */

.button {
  @include clickable;

  @include mouse-user-focus {
    outline: none;
  }

  align-items: center;
  appearance: none;
  border-radius: $radius-rounded;
  border-style: none;
  display: inline-flex;
  fill: currentColor;
  flex-shrink: 0;
  font-weight: $fw-regular;
  justify-content: center;
  transition: all ease-out 0.3s;
  white-space: nowrap;

  &:focus,
  &:hover {
    text-decoration: none;
  }

  &[disabled] {
    cursor: not-allowed;
  }
}

/***  Button Size  ***/

.full {
  font-size: $fs-normal;
  height: $button-full-height;
  line-height: $button-full-line-height;
  padding: 0 $button-full-padding-horizontal;

  .button-icon-container {
    height: $button-full-line-height;
  }

  .button-icon {
    height: $button-full-line-height;
    margin-left: -($button-full-padding-horizontal / 2);
    margin-right: -($button-full-padding-horizontal / 2);
    width: $button-full-line-height;
  }

  &.icon-only {
    width: $button-full-height;
  }

  &.icon-with-label {
    padding: 0 $button-full-padding-horizontal;

    .button-icon {
      margin-right: $button-full-padding-horizontal / 2;

      &.right {
        margin-left: $button-full-padding-horizontal / 2;
        margin-right: -($button-full-padding-horizontal / 2);
      }
    }
  }
}

.compact {
  font-size: $fs-secondary;
  height: $button-compact-height;
  line-height: $button-compact-line-height;
  padding: 0 $button-compact-padding-horizontal;

  .button-icon-container {
    height: $button-compact-line-height;
  }

  // stylelint-disable-next-line no-descending-specificity
  .button-icon {
    height: $button-compact-line-height;
    margin-left: -($button-compact-padding-horizontal / 2);
    margin-right: -($button-compact-padding-horizontal / 2);
    width: $button-compact-line-height;
  }

  &.icon-only {
    width: $button-compact-height;
  }

  &.icon-with-label {
    padding: 0 $button-compact-padding-horizontal;

    .button-icon {
      margin-right: $button-compact-padding-horizontal / 2;

      &.right {
        margin-left: $button-compact-padding-horizontal / 2;
        margin-right: -($button-compact-padding-horizontal / 2);
      }
    }
  }
}

.icon-only {
  padding: 0;
}

/***  Button Style  ***/

.primary {
  background: $bg-gradient;
  color: $WHITE;
  transition: background ease-out 0.3s;

  &:focus,
  &:hover {
    background: $bg-gradient-light;
  }

  &[disabled] {
    background: $tundora-l-2;
  }
}

.secondary {
  background: $tundora-l-3;
  color: $fc-html;

  &:focus,
  &:hover {
    background: $tundora-l-2;
  }

  &[disabled] {
    background: none;
    color: $tundora-l-2;
  }
}

.danger {
  &.primary {
    background: $delete-color;

    &:hover {
      background: $cabaret-l-1;
    }

    &[disabled] {
      background: $tundora-l-2;
    }
  }

  &.secondary {
    background: $cabaret-l-3;

    &:focus,
    &:hover {
      background: $cabaret-l-2;
    }

    &[disabled] {
      background: none;
      color: $tundora-l-2;
    }
  }
}

.transparent {
  background: transparent;
  border-color: transparent;
  border-style: solid;
  font-size: inherit;
  padding: 0;

  &:focus,
  &:hover {
    text-decoration: underline;
  }

  &[disabled] {
    color: $tundora-l-2;
  }
}

/*** Specific overrides ***/

.full.primary {
  font-weight: $fw-regular;
  letter-spacing: 0.5px;
}
</style>
