<script>
export default {
  page: {
    title: 'Page Timeout',
    meta: [
      { name: 'description', content: 'The page timed out while loading.' },
    ],
  },
}
</script>

<template>
  <div class="timeout">
    <h1>Timeout</h1>
    <p>The page timed out while loading, please try again later or check your network connection.</p>
  </div>
</template>

<style lang="scss" scoped>
.timeout {
  flex-grow: 1;
  margin: 50px;
  text-align: center;
}
</style>
