<script>
// Public: Displays an animation of dots that form an ellipsis.
// Useful to convey a loading state in small components or labels.
export default {
  name: 'LoadingEllipsis',
}
</script>

<template functional>
  <span class="loading-ellipsis"><slot/></span>
</template>

<style lang="scss" scoped>
@keyframes loading-ellipsis {
  0% { content: ''; }
  33% { content: '.'; }
  66% { content: '..'; }
  100% { content: '…'; }
}

.loading-ellipsis::after {
  animation: loading-ellipsis 1.6s linear infinite;
  content: '';
  display: inline-block;
  width: 4px;
}
</style>
