// CacheKey 2c17d210af6b42a5a7237fe37ee9bf31
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { formatUrl } from '@helpers/UrlHelper'

export default {
  switchToClassicPath: options =>
    formatUrl('/vue/experimental/switch_to_classic/:page', options),

  switchToSlickPath: options =>
    formatUrl('/vue/experimental/switch_to_slick/:page', options),
}
