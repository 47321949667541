// We do this here because it should always be imported before anything else.
// TODO: Remove once we no longer need to support IE11.
import '@evolve/legacy/ie11'

import Bugsnag from '@bugsnag/js'
import BugsnagVue from '@bugsnag/plugin-vue'
import Vue from 'vue'
import { isCI, isDevelopmentMode, isProductionMode } from '@helpers/EnvironmentHelper'
import { isIE } from '@helpers/BrowserHelper'

// Don't warn about using the dev version of Vue in development
Vue.config.productionTip = isProductionMode

const bugsnagClient = isCI || isDevelopmentMode ? { notify () { }, use () { } } : Bugsnag({
  apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
  appVersion: process.env.CURRENT_REVISION,
  notifyReleaseStages: ['sandbox', 'staging', 'parallel', 'production'],
  releaseStage: process.env.RAILS_ENV,
})
bugsnagClient.use(BugsnagVue, Vue)
export default bugsnagClient

// Public: Set user and additional information in Bugsnag errors.
// https://docs.bugsnag.com/platforms/javascript/configuration-options/#user
export function setBugsnagUser ({ currentUser, careProvider }) {
  bugsnagClient.user = currentUser
  bugsnagClient.metaData = { care_provider: careProvider }
}

// IE11 does not support foreign object.
if (isIE) {
  const foreignObject = require('@evolve/legacy/foreignObject')
  Vue.component('foreignObject', foreignObject.default)
}
