<script>
import { isLocal } from '@helpers/EnvironmentHelper'
import { scriptSrc } from '@helpers/StatusIOHelper'

export default {
  name: 'StatusPageWidget',
  mounted () {
    if (isLocal) { return }
    let statusPageScript = document.createElement('script')
    statusPageScript.setAttribute('src', scriptSrc)
    document.head.appendChild(statusPageScript)
  },
}
</script>

<template>
  <div class="status-page-widget"/>
</template>
