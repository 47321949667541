<script>
export default {
  name: 'NavigationPanel',
  inheritAttrs: false,
}
</script>

<template>
  <Panel class="navigation-panel" v-bind="$attrs" v-on="$listeners">
    <template slot="title">
      <slot name="title"/>
    </template>
    <template slot="actions">
      <slot name="actions"/>
    </template>
    <slot/>
  </Panel>
</template>

<style lang="scss" scoped>

.navigation-panel {
  margin-bottom: 16px;

  & ::v-deep .panel-content {
    padding: 8px;
  }

  ::v-deep .panel-content .navigation-link {
    align-items: center;
    background: $WHITE;
    border-radius: $radius-normal;
    color: $sidebar-content-header;
    cursor: pointer;
    display: flex;
    font-size: $fs-large;
    font-weight: $fw-bold;
    letter-spacing: 0.24px;
    margin: 2px 0;
    max-width: 100%;
    padding: 8px 16px;
    transition: background-color 0.2s ease-out 0.1s;
    width: 100%;

    &:hover {
      background-color: $tertiary-color-light;
      text-decoration: none;
    }

    &.active {
      background-color: $bg-selected;
    }

    &:hover,
    &.active {
      .actionable:not(:hover) {
        color: $fc-html;
      }
    }
  }
}
</style>
