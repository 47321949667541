import AuthStore from '@stores/AuthStore'
import WindowStore from '@stores/WindowStore'
import { assign, camelCase, flatten, isEmpty, isPlainObject, isString, merge } from 'lodash'
import { isLocal } from '@helpers/EnvironmentHelper'

const FULL_URL = /^(\w+:|\/)/

// Public: Navigates to the specified path.
export function navigateTo ($vm, ...pathSegments) {
  const fullUrl = isString(pathSegments[0]) && pathIfFull(pathSegments[0])
  if (fullUrl) {
    WindowStore.setNavigating(true)
    window.location = fullUrl
  } else {
    $vm.$router.push(pathTo(...pathSegments))
  }
}

// Public: Returns the url with :careProviderId replaced with the actual id of a current care provider
export function withCareProvider (url) {
  return url.replace(':careProviderId', AuthStore.careProviderId)
}

// Public: Returns the url without the current origin.
export function withoutExampleDomain (url) {
  return isLocal ? url.replace(/https?:\/\/example\.com/, '') : url
}

// Public: Returns the path if has the protocol (http://) or starts with a leading slash.
export function pathIfFull (path) {
  return isFullPath(path) && withoutExampleDomain(withCareProvider(path))
}

// Public: Returns true if the provided value is a full path
export function isFullPath (value) {
  return FULL_URL.test(value)
}

// Public: Constructs a named route for vue-router, using path segments and
// models. See spec for usage details.
export function pathTo (...pathSegments) {
  const params = {}

  // Automatically add id to simplify paths that are scoped to the current care provider.
  const careProviderId = AuthStore.careProviderId
  if (careProviderId && !params.careProviderId) params.careProviderId = careProviderId

  // Ensure that chaining pathTo has no effect.
  if (pathSegments.length === 1 && isPlainObject(pathSegments[0])) {
    return merge({ params }, pathSegments[0])
  }

  let lastPathSegment
  const names = []
  const query = {}

  // Process String and Object path segments.
  flatten(pathSegments).forEach((pathSegment, index) => {
    if (isString(pathSegment)) {
      names.push(pathSegment)
    } else {
      if (index === 0) throw new Error(`Paths must start with a string: ${JSON.stringify(pathSegments)}`)
      const id = pathSegment && pathSegment.id
      if (id) {
        if (!isString(lastPathSegment)) throw new Error(`Paths can't have consecutive objects: ${JSON.stringify(pathSegments)}`)
        params.id = id
        params[`${camelCase(lastPathSegment)}Id`] = id
      } else if (index === pathSegments.length - 1 && (pathSegment.params || pathSegment.query)) {
        if (pathSegment.params) assign(params, pathSegment.params)
        if (pathSegment.query) assign(query, pathSegment.query)
      } else {
        throw new Error(`Invalid path segment: ${JSON.stringify(pathSegment)} in ${JSON.stringify(pathSegments)}`)
      }
    }
    lastPathSegment = pathSegment
  })

  const path = { name: names.join('/'), params, query }
  if (isEmpty(params)) delete path.params
  if (isEmpty(query)) delete path.query
  return path
}

// Public: Returns the starting path for non-vue pages
export function providerUrl (path = '') {
  return `/care_providers/:careProviderId${path}`
}
