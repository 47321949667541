import ActionCable from 'actioncable'
import { deepCamelizeKeys, deepDecamelizeKeys } from '@helpers/ObjectHelper'
import { isEqual } from 'lodash'
import { notifyError } from '@helpers/BugsnagHelper'

// Sanity check to protect the code from ActionCable upgrades.
const { protocols } = ActionCable.INTERNAL
if (!isEqual(protocols, ['actioncable-v1-json', 'actioncable-unsupported'])) {
  throw new Error('The ActionCable internals have changed. Update this code.')
}

const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
const defaultUrl = `${protocol}://${window.location.host}/cable`

// We only register one ActionCable consumer instance per URL.
const consumersByUrl = {}

// Public: Name of the available ActionCable events.
export const ActionCableEvents = ['initialized', 'connected', 'received', 'disconnected', 'rejected']

// Public: Returns a new ActionCable.Subscription with the specified configuration.
//
// NOTE: Creates a new consumer if none exists for the given URL.
export function subscribe ({ url = defaultUrl, authToken, listeners, ...params }) {
  // We only register one ActionCable consumer instance per URL.
  if (!consumersByUrl[url]) consumersByUrl[url] = ActionCable.createConsumer(url)

  // Get the consumer instance for the specified url, and ensures the connection.
  const consumer = consumersByUrl[url]

  // Set the authentication token for the connection (used in Atlas).
  consumer.authToken = authToken

  // Wrap the `received` listener to camelize the keys.
  const originalReceived = listeners.received
  if (originalReceived) listeners.received = data => originalReceived(deepCamelizeKeys(data))

  // Creates a new subscription.
  const subscription = consumer.subscriptions.create(deepDecamelizeKeys(params), listeners)

  // Wrap the `send` method to decamelize the keys automatically.
  const originalSend = subscription.send
  subscription.send = data => originalSend.apply(subscription, [deepDecamelizeKeys(data)])

  return subscription
}

// Public: Unsuscribes an existing ActionCable.Subscription instance, and
// disconnects the consumer if no other subscriptions remain.
export function unsubscribe (subscription) {
  subscription.unsubscribe()

  // If it was the last subscription, it ends the connection.
  const consumer = subscription.consumer
  if (consumer.subscriptions.length === 0) {
    consumer.disconnect()
    delete consumersByUrl[consumer.url]
  }
}

// Override: To concatenate the authentication token as a protocol.
//
// NOTE: Because WebSockets doesn't support headers, we send the auth token as a
// protocol (fake one), which is safer than sending it in the URL, which would
// appear in logs.
ActionCable.Connection.prototype.open = function () {
  if (this.isActive()) {
    ActionCable.log('Attempted to open WebSocket, but existing socket is ' + (this.getState()))
    return false
  } else {
    ActionCable.log('Opening WebSocket, current state is ' + (this.getState()) + ', subprotocols: ' + protocols)
    if (this.webSocket != null) {
      this.uninstallEventHandlers()
    }

    // PATCH BEGINS: These are lines that change from the original implementation.
    const protocolsWithAuthToken = this.consumer.authToken ? protocols.concat(this.consumer.authToken) : protocols
    try {
      this.webSocket = new ActionCable.WebSocket(this.consumer.url, protocolsWithAuthToken)
    } catch (error) {
      notifyError(error, {
        description: 'Error connecting to WebSocket / ActionCable',
        url: this.consumer.url,
        protocolsWithAuthToken,
      })
    }
    // PATCH ENDS

    this.installEventHandlers()
    this.monitor.start()
    return true
  }
}
