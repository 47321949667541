// Public: Empty router view, to mount full pages.
export const routerView = {
  name: 'EvolveRouterView',
  render (h) {
    return h('router-view')
  },
}

// Public: Returns a wrapper component that will display a loading screen if the
// component takes a while to load, fallsback to a timeout page if the page
// fails to load.
//
// Receives a promise such as the one returned by `import(`@views/TheLab`).
//
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
export function lazyLoadView (ViewPromise, componentOptions = {}) {
  const AsyncHandler = () => ({
    component: ViewPromise, // A promise for the component request.
    delay: 0, // Delay before showing the loading component. Default: 200 (milliseconds).
    loading: require('@views/Loading').default, // A component to display while the component is loading.
    error: require('@views/Timeout').default, // A fallback in case the timeout is exceeded when loading the component.
    timeout: 30000, // Time before giving up trying to load the component. Default: Infinity.
  })

  return Promise.resolve({
    functional: true,
    render (h, { data, children }) {
      // Transparently pass any props or children to the view component.
      return h(AsyncHandler, data, children)
    },
    ...componentOptions,
  })
}
