<script>
export default {
  name: 'SidebarContainer',
  props: {
    // Public: A list of available items to display in the sidebar.
    // NOTE: Items are identified by the id property.
    sidebarItems: { type: Array, default: () => [] },
  },
  data () {
    return {
      activeItem: this.sidebarItemFromRoute() || this.sidebarItems[0],
    }
  },
  created () {
    this.notifyItemChange(this.activeItem)
  },
  methods: {
    notifyItemChange (item) {
      this.$emit('sidebar:item-selected', item)
    },
    selectItem (item) {
      if (!item.disabled) {
        this.activeItem = item
      }
      this.notifyItemChange(item)
    },
    sidebarItemFromRoute () {
      const id = this.$route.query.sidebar
      return id && this.sidebarItems.find(item => item.id === id)
    },
  },
}
</script>

<template>
  <div class="sidebar-container">
    <div class="sidebar">
      <template v-for="(item, index) in sidebarItems">
        <div :key="index" :class="{ active: item === activeItem, disabled: item.disabled }" class="sidebar-item iac" @click="selectItem(item)">
          <slot :item="item" name="sidebarItems">
            <ActionLink v-if="item.label" :disabled="item.disabled">{{ item.label }}</ActionLink>
            <ActionLink v-else :disabled="item.disabled">{{ item.id | titleize }}</ActionLink>
          </slot>
        </div>
      </template>
      <slot name="sidebar"/>
    </div>
    <div class="sidebar-main-content">
      <slot :activeItem="activeItem" name="mainContent"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-container {
  @include flex-row;

  align-items: flex-start;
}

.sidebar-main-content {
  @include flex-column;

  margin-left: 16px;
}

.sidebar {
  @include bordered-box;

  align-items: center;
  background: $bg-card;
  border-color: $layout-br-color;
  display: flex;
  flex: 0 0 20%;
  flex-direction: column;
  position: relative;
}

.sidebar-item {
  align-items: flex-start;
  color: $sidebar-content-header;
  cursor: pointer;
  display: flex;
  font-weight: $fw-bold;
  letter-spacing: 0.24px;
  padding: 8px 8px 8px 16px;
  transition: background-color 0.2s ease-out 0.1s;
  width: 100%;

  &:hover {
    background-color: $tertiary-color-light;
  }

  &.active {
    background-color: $tertiary-color;
  }

  &.disabled {
    cursor: unset;
    pointer-events: none;
  }

  &:hover,
  &.active {
    .actionable:not(:hover) {
      color: $fc-html;
      opacity: 1;
    }
  }

  & + ::v-deep .button {
    bottom: -40px;
    position: absolute;
  }
}
</style>
