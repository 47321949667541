import AuthStore from '@stores/AuthStore'
import { deepCamelizeKeys } from '@helpers/ObjectHelper'
import { parseJSON } from '@helpers/JsonHelper'

// Legacy: Used when rendering the Vue navigation bar in a Rails-rendered page.
//
// NOTE: The beta.html.haml renders window.currentUser and window.careProvider,
// so we set the information without making a request or reading from local
// storage.
//
// On the other hand, vue.html.haml is stateless, which makes it easier to
// optimize its rendering and load (caching and conditional GET requests).
export function retrieveJsonNavigationBar (navbar) {
  const navigationBar = parseJSON(navbar.getAttribute('navigation'))
  const info = {
    currentUser: window.currentUser,
    careProvider: window.careProvider,
    currentSignInAt: window.currentSignInAt,
    unreadNotificationsCount: window.unreadNotificationsCount,
    navigationBar,
  }
  AuthStore.updateUserInfo(deepCamelizeKeys(info))
}

export function hasSlickNavigationBar () {
  return Boolean(AuthStore.navigationBar)
}

export function hasOldNavigationBar () {
  return !hasSlickNavigationBar()
}
