<script>
import { formatSecondsAsMinutes } from '@helpers/FormatHelper'

export default {
  name: 'SeekBar',
  props: {
    // Public: Duration of the content that is being reproduced
    duration: { type: Number, default: 0 },

    // Public: Current time of the content that is being reproduced
    currentTime: { type: Number, default: 0 },
  },
  data () {
    return {
      // Internal: Indicates the original mouse X position when dragging the seek seek-bar-progress control
      initialMouseX: 0,

      // Internal: Indicates the original seek-bar-progress when the dragging started (0 -> start, 1-> end)
      initialFraction: 0,

      // Internal: Width of the seek bar
      seekBarWidth: 0,
    }
  },
  computed: {
    formattedDuration () {
      const durationNumber = Number(this.duration)
      if (!durationNumber || !isFinite(durationNumber)) return null

      return formatSecondsAsMinutes(durationNumber.toFixed(0))
    },
    timeToDisplay () {
      return formatSecondsAsMinutes(this.currentTime.toFixed(0))
    },
    fractionElapsed () {
      if (!this.duration || this.duration === 0) {
        return 0
      }

      return this.currentTime / this.duration
    },
  },
  updated () {
    if (this.$refs.seekBar) {
      this.seekBarWidth = this.$refs.seekBar.clientWidth
    }
  },
  beforeDestroy () {
    this.onSeekBarTouchEnd()
  },
  methods: {
    setCurrentTime (value) {
      this.$emit('seekBar:currentTimeChanged', value)
    },
    onSeekBarTouchStart (event) {
      // get the mouse cursor position at startup:
      this.initialMouseX = event.screenX
      this.initialFraction = this.currentTime / this.duration

      document.addEventListener('mouseup', this.onSeekBarTouchEnd)
      document.addEventListener('mousemove', this.onSeekBarProgressChanged)
    },
    onSeekBarProgressChanged (event) {
      var diffX = event.screenX - this.initialMouseX
      var initialDotX = this.seekBarWidth * this.initialFraction
      var newElapsedFraction = (diffX + initialDotX) / this.seekBarWidth

      newElapsedFraction = newElapsedFraction < 0 ? 0 : newElapsedFraction
      // There is a strange bug that corrupts the audio player when we allow to set the max time
      // Hence we reset the time to zero before it reaches the end.
      newElapsedFraction = newElapsedFraction >= 0.99 ? 0 : newElapsedFraction
      this.setCurrentTime(newElapsedFraction * this.duration)
    },
    onSeekBarTouchEnd () {
      // stop moving when mouse button is released:
      document.removeEventListener('mouseup', this.onSeekBarTouchEnd)
      document.removeEventListener('mousemove', this.onSeekBarProgressChanged)
    },
    onOuterSeekBarTouchStart (event) {
      if (event.target === this.$refs.seekBarIndicator) { return }

      const marginLeft = 8
      this.setCurrentTime(((event.offsetX - marginLeft) / this.seekBarWidth) * this.duration)
      this.onSeekBarTouchStart(event)
    },
  },
}
</script>

<template>
  <div class="seek-bar">
    <span class="file-duration seek-bar__current">{{ timeToDisplay }}</span>
    <div ref="seekBar" class="seek-bar-wrapper" @mousedown="onOuterSeekBarTouchStart">
      <div class="seek-bar-line">
        <div class="seek-bar-progress" :style="{ width: `${100 * fractionElapsed}%` }"/>
        <div
          ref="seekBarIndicator"
          class="seek-bar-indicator"
          :style="{ left: `${100 * fractionElapsed}%` }"
          @mousedown="onSeekBarTouchStart"
        />
      </div>
    </div>
    <span class="file-duration seek-bar__duration">{{ formattedDuration }}</span>
  </div>
</template>

<style lang="scss" scoped>

.seek-bar {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
}

.seek-bar-wrapper {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1 0 auto;
  height: 15px; // We want to create some area around the seek bar for the user to click on.
}

.seek-bar-line {
  border-top: 2px solid $br-color-light;
  display: inline-block;
  height: 1px;
  margin-left: 8px;
  width: 100%;
}

.seek-bar-progress {
  border-top: 2px solid $jelly-bean-l-0;
  margin-top: -2px;
  position: relative;
  width: 100%;
}

.seek-bar-indicator {
  background: $jelly-bean-l-0;
  border-radius: 5px;
  cursor: grab;
  height: 10px;
  margin-left: -5px;
  margin-top: -6px;
  position: relative;
  width: 10px;

  &:active {
    cursor: grabbing;
  }
}

.file-duration {
  color: $tundora-l-1;
  display: inline-block;
  font-size: $fs-secondary;
  margin-left: 8px;
  user-select: none;
}
</style>
