<script>
import { formatAs, quickFormat } from '@helpers/FormatHelper'
import { isNil } from 'lodash'
import { isPresent } from '@helpers/ObjectHelper'
import { translateLabel } from '@helpers/TranslationHelper'

export default {
  name: 'ModelField',
  inheritAttrs: false,
  props: {
    // Public: The name of the field, which will be camel-cased to obtain the
    // value from the <ModelFields> model.
    field: { type: String, required: true },

    // Public: Specifies how it should be formatted. See formattedValue.
    format: { type: [String, Function], default: () => quickFormat },

    // Internal: Provided by ModelFields, allows to customize the label.
    translationOptions: { type: Object, default: () => ({}) },

    // Internal: Provided by ModelFields, the value for the specified field.
    value: { required: true }, // eslint-disable-line
  },
  computed: {
    // Internal: Whether the field should be displayed.
    shouldDisplay () {
      return this.$slots.default || (isPresent(this.value) && !isNil(this.formattedValue))
    },
    // Internal: Obtains a label for the field using the translationOptions.
    label () {
      return this.$attrs.label || translateLabel(this.field, this.translationOptions)
    },
    // Internal: Returns a string after formatting the value according to the
    // specified format option.
    formattedValue () {
      try {
        if (typeof this.format === 'function') return this.format(this.value)
        return formatAs(this.format, this.value)
      } catch (error) {
        console.error(`Unable to render :${this.field} field. ${error.message}`, error)
        throw error
      }
    },
    // Internal: Returns props and attributes to bind to the DataField component.
    dataFieldAttrs () {
      return {
        ...this.$attrs,
        label: this.label,
        value: this.$slots.default ? undefined : this.formattedValue,
        'data-field': this.field,
        'data-model': this.translationOptions.prefix,
      }
    },
  },
}
</script>

<template>
  <DataField v-if="shouldDisplay" v-bind="dataFieldAttrs"><slot/></DataField>
</template>

<style lang="scss" scoped>
</style>
