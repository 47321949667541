<script>
import { constant, times } from 'lodash'
export default {
  name: 'ColumnsContainer',
  props: {
    // Public: Sets the amount of colums the layout should provide.
    columnCount: { type: Number, default: 1 },

    // Can be the number of columns, or an Array where each element is the column width.
    columnWidths: { type: Array, default: null },
  },
  computed: {
    columns () {
      const widths = this.columnWidths || times(this.columnCount, constant(`${100 / this.columnCount}%`))
      return widths.map(width => ({ width }))
    },
  },
}
</script>

<template>
  <div class="columns-container">
    <div
      v-for="(column, index) in columns"
      :key="index"
      :class="`column-${index + 1}`"
      :style="`flex-basis: ${column.width};`"
      class="column-wrapper"
    >
      <slot :name="`column-${index + 1}`"/>
    </div>
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.columns-container {
  @include flex-row;

  align-items: flex-start;
  height: 100%;
}

.column-wrapper {
  @include flex-column;

  flex-basis: 0;
  flex-shrink: 1;

  // Needed to assure that the column doesn't expand over a flex-basis
  overflow-x: auto;
  overflow-y: hidden;

  &:not(:last-child) {
    margin-right: 16px;
  }

  & ::v-deep > .panel {
    margin-bottom: 16px;

    &:last-child {
      flex: auto;
      margin-bottom: 0;
    }
  }
}
</style>
