import { assign, keys, pickBy } from 'lodash'

export default {
  inheritAttrs: false,
  props: {
    // Public: A number to specify same duration for enter and leave transitions
    // or an Object { enter: 300, leave: 300 } to specify different durations.
    duration: { type: [Number, Object], default: 300 },

    // Public: Whether the component should be a `transition-group`, else a `transition`.
    group: { type: Boolean, default: false },

    // Public: Transition tag, in case the component is a `transition-group`
    tag: { type: String, default: 'span' },

    // Public: Shortcut to set the transform-origin property.
    // NOTE: https://tympanus.net/codrops/css_reference/transform-origin/.
    origin: { type: String, default: '' },

    // Internal: Shortcut to specify an animationFillMode.
    fillMode: { type: String, default: 'both' },

    // Internal: Shortcut to specify an animationTimingFunction.
    timingFunction: { type: String, default: 'ease-out' },

    // Internal: Set to true if element's position should NOT be changed to absolute when in group mode
    noAbsolute: { type: Boolean, default: false },

    // Public: Element styles that are applied during transition.
    // Applied on @beforeEnter and @beforeLeave hooks.
    transitionStyles: {
      type: Object,
      default () {
        return {
          animationFillMode: this.fillMode,
          animationTimingFunction: this.timingFunction,
        }
      },
    },
  },
  computed: {
    transitionListeners () {
      return {
        beforeEnter: this.beforeEnter,
        enter: this.enter,
        afterEnter: this.afterEnter,
        beforeLeave: this.beforeLeave,
        leave: this.leave,
        afterLeave: this.afterLeave,
        ...this.$listeners,
      }
    },
    transitionStyle () {
      return pickBy(this.transitionStyles, value => value)
    },
    enterDuration () {
      return this.durationInSeconds(this.duration.enter || this.duration)
    },
    leaveDuration () {
      return this.durationInSeconds(this.duration.leave || this.duration)
    },
    transitionComponent () {
      return this.group ? 'transition-group' : 'transition'
    },
  },
  methods: {
    beforeEnter (el) {
      this.setTransitionStyle(el, { animationDuration: this.enterDuration })
    },
    enter () {
    },
    afterEnter (el) {
      this.cleanUpTransitionStyle(el)
    },
    beforeLeave (el) {
      this.setTransitionStyle(el, { animationDuration: this.leaveDuration })
    },
    leave (el) {
      this.setAbsolutePosition(el)
    },
    afterLeave (el) {
      this.cleanUpTransitionStyle(el)
    },
    cleanUpTransitionStyle (el) {
      keys(this.transitionStyle).forEach(property => { delete el.style[property] })
      delete el.style.animationDuration
      delete el.style.transition
    },
    setTransitionStyle (el, attrs = {}) {
      assign(el.style, this.transitionStyle, attrs)
    },
    durationInSeconds (duration) {
      return duration && `${duration / 1000}s`
    },
    setAbsolutePosition (el) {
      if (this.group && !this.noAbsolute) el.style.position = 'absolute'
    },
  },
}
