import Vue from 'vue'
import VueMeta from 'vue-meta'
import { get } from 'lodash'
import { translateIfKey } from '@helpers/TranslationHelper'

Vue.use(VueMeta, {
  keyName: 'page', // The component option name that vue-meta looks for meta info on.
})

Vue.mixin({
  computed: {
    pageTitle () {
      return translateIfKey(get(this.$meta().refresh(), 'metaInfo.titleChunk') || '')
    },
  },
})
