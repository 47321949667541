<script>
export default {
  name: 'FormHeading',
  props: {
    // Public: Content of the heading
    text: { type: String, required: true },
    // Public: Hint to display as tooltip next to the heading
    hint: { type: [String, Boolean], default: false },
  },
}
</script>

<template>
  <h3 class="form-header">
    {{ text }}
    <Hint :content="hint" size="regular" class="form-header-hint"/>
    <slot/>
  </h3>
</template>

<style lang="scss" scoped>
.form-header {
  align-items: center;
  display: flex;
  font-size: $fs-large;
  font-weight: $fw-bold;
  margin: 16px 0 32px;

  .form-header-hint.form-header-hint {
    margin-left: 16px;
  }
}

.form-header:first-child {
  margin-top: 0;
}
</style>
