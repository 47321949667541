import { camelCase, capitalize, invert, snakeCase, upperFirst } from 'lodash'

const INTERPOLATION_PLACEHOLDER = /:[^\W\d]+/g
const UNDERSCORES = /_/g
const WORDS = /\S+/g
const UNDERSCORED_NUMBERS = /_([0-9]+?)_/g
const VOWELS = ['a', 'e', 'i', 'o', 'u']
const IRREGULAR_PLURALS = {
  person: 'people',
  history: 'history',
}
const IRREGULAR_SINGULARS = invert(IRREGULAR_PLURALS)

// Internal: Pulled the most commonly used rules from ActiveSupport.
// Very incomplete, but works pretty well for our purposes (routes).
const SINGULAR_INFLECTIONS = [
  { match: /([^aeiouy]|qu)ies$/i, replacement: '$1y' },
  { match: /(ss)$/i, replacement: '$1' },
  { match: /s$/i, replacement: '' },
]

// Public: Build a plural from the singular
export function buildPlural (word) {
  if (IRREGULAR_PLURALS[word]) return IRREGULAR_PLURALS[word]
  const lastLetter = word.slice(-1)
  if (lastLetter === 's') return word // In some cases we are provided a plural (autocomplete.permissions)
  if (lastLetter === 'y' && !VOWELS.includes(word.slice(-2, -1))) return `${word.slice(0, -1)}ies`
  return `${word}s`
}

// Public: Separates camelCase or snake_case sentences into human readable sentences
//
// Example:
//   humanize('firstName') returns 'first name'
export function humanize (words) {
  return words ? snakeCase(words).replace(UNDERSCORES, ' ') : ''
}

// Public: Make each word in the string start with an uppercase letter, followed
// by lowercase letters.
//
// Example:
//   titleize('firstName') returns 'First Name'
export function titleize (words) {
  return humanize(words).replace(WORDS, capitalize)
}

// Public: Make first word in the string start with an uppercase letter, followed
// by lowercase letters.
//
// Example:
//   sentencize('firstName') returns 'First name'
export function sentencize (words) {
  return firstUpper(snakeCase(words).replace(UNDERSCORES, ' '))
}

// Public: Return singular or plural value based on a count
//
// Examples:
//   pluralize(1, 'encounter') returns 'encounter'
//   pluralize(2, 'encounter') returns 'encounters'
export function pluralize (count, singular) {
  return count === 1 ? singular : buildPlural(singular)
}

// Public: Returns a singular from the plural.
// NOTE: This is very incomplete.
export function singularize (word) {
  if (IRREGULAR_SINGULARS[word]) return IRREGULAR_SINGULARS[word]
  SINGULAR_INFLECTIONS.forEach(({ match, replacement }) => { word = word.replace(match, replacement) })
  return word
}

// Public: Conjugates the verb to the past tense.
export function pastParticiple (action) {
  // TODO: Handle irregular verbs with an IRREGULAR_VERBS constant.
  const lastLetter = action[action.length - 1]
  return lastLetter === 'e' ? `${action}d` : `${action}ed`
}

// Public: Split on whitespace and return the resulting downcased tokens.
export function tokenize (words) {
  return (words && words.toLowerCase().match(WORDS)) || []
}

// Public: Returns the initials of the specified name.
export function initials (name) {
  return name ? name.split(' ').map(token => token[0]).join('') : ''
}

// Public: Return the first name of a full name string
export function firstName (name) {
  return name.split(' ')[0]
}

/**
 * Wraps a string between two strings if the string passed is not empty
 * @param  {String} string      The string to be wrapped
 * @param  {String} prepend     The left wrapper
 * @param  {String} append      The right wrapper
 * @param {String} defaultValue The default value to be used in case the string passed is null
 * @return {String}             The string wrapped or the default value
 */
export function wrapString (string, prepend, append, defaultValue = '') {
  return string && string.length > 0 ? prepend + string + append : defaultValue
}

// Public: Implements PascalCase by tweaking the camelCase to to make the first letter upper case.
export function pascalCase (string) {
  return upperFirst(camelCase(string))
}

// Public: Ensures that the first character in string is upper case.
export function firstUpper (string) {
  return string ? string.charAt(0).toUpperCase() + string.substr(1) : ''
}

// Public: Ensures that the first character in string is lower case.
export function firstLower (string) {
  return string ? string.charAt(0).toLowerCase() + string.substr(1) : ''
}

// Public: Tweaks the snake case to keep numbers and words together. lodash camelizes words by
//   following this notation: hl7OutboundApp => hl_7_outbound_app. This function will keep numbers
//   and words together: hl7OutboundApp => hl7_outbound_app.
// Example:
//   snakeCaseWithNumbers('hl7OutboundApp') returns 'hl7_outbound_app'
export function snakeCaseWithNumbers (string) {
  return snakeCase(string).replace(UNDERSCORED_NUMBERS, '$1_')
}

// Public: Converts a snake_case to camelCase by simply _letter to _LETTER substitution not touching any other characters.
export function snakeCaseToCamelCaseKeepingSpecialCharacters (string) {
  return string.replace(/_([a-zA-Z0-9])/g, (m, captureGroup) => captureGroup.toUpperCase())
}

// Public: Creates a random string (adding the specified prefix if specified).
export function randomId (prefix = '') {
  return `${prefix}${new Date().getTime()}_${Math.floor(Math.random() * 9999999)}`
}

// Public: Fast way to check if a string is HTML or just plain text.
// Naive, but it works for our current setup. Analogous to DashboardCell#html?
export function mightBeHTML (value) {
  return value.includes('<')
}

// Public: Replaces any placeholder in the string with the provided parameters.
export function interpolate (template, params) {
  let value = template.toString()
  for (const paramName in params) {
    value = value.replace(`:${snakeCase(paramName)}`, params[paramName])
  }
  const missingParams = value.match(INTERPOLATION_PLACEHOLDER)
  if (missingParams) {
    const missing = missingParams.join(', ')
    const provided = params && Object.keys(params).join(', ')
    throw new TypeError(`Missing ${missing} for ${template}. Params provided: ${provided}`)
  }
  return value
}
