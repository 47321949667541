const POINTER_START_EVENTS = ['mousedown', 'touchstart']
const POINTER_MOVE_EVENTS = ['mousemove', 'touchmove']
const POINTER_END_EVENTS = ['mouseup', 'touchend']

export default {
  inserted (el, binding) {
    if (!document) return
    let draggedElem

    el._dragStartHandler = function onStart (e) {
      if (e.defaultPrevented) return

      e.preventDefault() // prevent text selection when dragging
      draggedElem = el

      el.lastPosition = {
        x: e.clientX,
        y: e.clientY,
      }
      binding.value({
        phase: 'start',
        el,
        clientX: e.clientX,
        clientY: e.clientY,
      })
    }
    el._dragMoveHandler = function onMove (e) {
      if (el !== draggedElem) return
      e.preventDefault()

      if (el.lastPosition) {
        const deltaX = e.clientX - el.lastPosition.x
        const deltaY = e.clientY - el.lastPosition.y
        const clientX = e.clientX
        const clientY = e.clientY
        el.lastPosition = {
          x: e.clientX,
          y: e.clientY,
        }
        binding.value({
          phase: 'move',
          el,
          deltaX,
          deltaY,
          clientX,
          clientY,
        })
      }
    }
    el._dragEndHandler = function onEnd (e) {
      if (el !== draggedElem) return
      e.preventDefault()
      draggedElem = null

      el.lastPosition = null
      binding.value({
        phase: 'end',
        el,
        clientX: e.clientX,
        clientY: e.clientY,
      })
    }

    POINTER_START_EVENTS.forEach(e => el.addEventListener(e, el._dragStartHandler))
    POINTER_MOVE_EVENTS.forEach(e => document.documentElement.addEventListener(e, el._dragMoveHandler))
    POINTER_END_EVENTS.forEach(e => document.documentElement.addEventListener(e, el._dragEndHandler))
  },

  unbind (el) {
    POINTER_START_EVENTS.forEach(e => el.removeEventListener(e, el._dragStartHandler))
    POINTER_MOVE_EVENTS.forEach(e => document.documentElement.removeEventListener(e, el._dragMoveHandler))
    POINTER_END_EVENTS.forEach(e => document.documentElement.removeEventListener(e, el._dragEndHandler))
  },
}
