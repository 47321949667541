<script>
import { preventDefaultAndStopPropagation } from '@helpers/EventHelper'

export default {
  name: 'DropZone',
  data () {
    return {
      isDragging: false,
    }
  },
  mounted () {
    // NOTE: Prevents the default browser behavior, which opens the file in the
    // tab if the user misses the drop area (very annoying).
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.attachDocumentListener(eventName, preventDefaultAndStopPropagation)
    })
  },
  methods: {
    onDragStart (event) {
      this.isDragging = true
    },
    onDragStop (event) {
      this.isDragging = false
    },
    onDrop (event) {
      this.isDragging = false
      this.$emit('drop', event)
    },
  },
}
</script>

<template>
  <div class="drop-zone" @dragenter="onDragStart" @dragover="onDragStart" @dragleave="onDragStop" @drop="onDrop">
    <slot name="default"/>
    <div v-show="isDragging" class="drop-zone__area">
      <slot name="dropArea"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.drop-zone__area {
  align-items: center;
  background: $tertiary-color;
  border: dashed 1px $primary-color;
  border-radius: $radius-normal;
  bottom: -1px;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  left: -1px;
  pointer-events: none;
  position: absolute;
  right: -1px;
  top: -1px;
}
</style>
