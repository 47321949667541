import { isBoolean } from 'lodash'
import { setFocus } from '@helpers/FocusHelper'

function focusOrBlur (el, { value }) {
  if (isBoolean(value)) {
    const inputOrElement = el.querySelector('input, textarea') || el
    setFocus(inputOrElement, value)
  }
}

// v-focused: Focuses an element when the value of the directive becomes true
// and blurs it when it becomes false.
//
// NOTE: `value` must be a Boolean, otherwise it will be ignored.
export default {
  inserted: focusOrBlur,
  update: focusOrBlur,
}
