<script>
export default {
  name: 'EditableLabel',
  props: {
    text: { type: String, default: '' },
    editing: { type: Boolean, default: false },
  },
  data () {
    return {
      editedText: '',
    }
  },
  watch: {
    text (newValue) {
      this.editedText = newValue
    },
  },
  mounted () {
    this.editedText = this.text
  },
  methods: {
    cancelEdit () {
      this.editedText = this.text
      this.$emit('edit:canceled')
    },
    finishEdit () {
      this.$emit('label:edited', this.editedText)
    },
  },
}
</script>

<template>
  <div class="editable-label">
    <TextInput
      v-if="editing"
      v-model="editedText"
      v-autofocus
      @blur="finishEdit"
      @keyup.enter="finishEdit"
      @keyup.esc="cancelEdit"
    />
    <span v-else class="label">{{ editedText }}</span>
  </div>
</template>

<style lang="scss" scoped>
</style>
