<script>
import Collapse from '@transitions/Collapse'

export default {
  name: 'FormSection',
  components: {
    Collapse,
  },
  props: {
    // Public: Header title for the form section
    title: { type: String, default: '' },

    // Public: Badge for the form section
    badge: { type: String, default: '' },

    // Public: If true, allow the form to collapse and expand
    collapsible: { type: Boolean, default: true },
  },
  data () {
    return {
      expanded: !this.collapsible,
    }
  },
  computed: {
    label () {
      return this.expanded ? 'Collapse' : 'Expand'
    },
  },
  methods: {
    toggleSection () {
      if (!this.collapsible) return

      this.expanded = !this.expanded
      this.$emit('expandedChanged', this.expanded)
    },
  },
}
</script>

<template>
  <div class="form-section" :data-title="title">
    <div class="form-section__header">
      <h4 class="form-section__header-title" @click="toggleSection">
        <slot name="title">{{ title }}</slot>
        <Badge v-if="badge" class="badge" :label="badge"/>
      </h4>
      <Button
        v-if="collapsible"
        :label="label"
        icon="chevronDown"
        class="form-section__toggle-button"
        :class="{ expanded }"
        @click="toggleSection"
      />
    </div>

    <Collapse>
      <div v-if="expanded" class="form-section__content">
        <slot/>
      </div>
    </Collapse>
  </div>
</template>

<style lang="scss" scoped>
.form-section__header {
  @include clickable;

  align-items: center;
  border-bottom: $br-light;
  display: flex;
  margin-bottom: 32px;
  padding-bottom: 12px;
  padding-top: 4px;
  width: 100%;
}

.form-section__header-title {
  flex-grow: 1;
  font-size: $fs-large;
  font-weight: $fw-bold;
  margin-bottom: 0;
}

.form-section__header-title .badge {
  margin-left: 6px;
  padding: 0 8px;
}

.form-section__toggle-button {
  width: 105px; // fix width so that the size of the button doesn't change when the text is toggled

  & ::v-deep .icon {
    transition: transform 0.2s ease-out;
  }

  &.expanded ::v-deep .icon {
    transform: rotate(180deg);
  }
}
</style>
