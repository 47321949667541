import MediaBreakpoints from '@constants/MediaBreakpoints'
import { isString } from 'lodash'

// Public: When value is a String, it allows to retrieve one of the convenient
// grops of responsive categories in MediaBreakpoints.
export function transformMediaBreakpoints (value) {
  return isString(value)
    ? MediaBreakpoints[value] || throw new Error(`Unknown MediaBreakpoint group: ${value}`)
    : value
}
