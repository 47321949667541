import NavigationStrategy from '@constants/NavigationStrategy'
import { assign, mapValues } from 'lodash'
import { createNewEvent, getVueEventHandler, getVueListeners } from '@helpers/EventHelper'
import { ensureId } from '@helpers/DomHelper'

export default class NavigationItem {
  static AutoNavigation = 'AutoNavigation'
  static FocusedCssClass = 'focused'
  static DefaultOptions = {
    up: NavigationStrategy.AUTO,
    down: NavigationStrategy.AUTO,
    left: NavigationStrategy.AUTO,
    right: NavigationStrategy.AUTO,
    next: NavigationStrategy.AUTO,
    previous: NavigationStrategy.AUTO,
    skip: false,
    prefer: false,
  }

  constructor (vNode, options = {}) {
    const { elm } = vNode
    ensureId(elm, 'navigation_item') // Enforce a dom id on all navigatable elements.
    elm.setAttribute('tabindex', '-1') // Avoid tab navigation on navigation items.
    this.id = elm.id
    this.hasFocus = false
    this.options = { ...NavigationItem.DefaultOptions, ...options }

    // Do not cache the handlers to prevent memory leaks, just the existence.
    this.hasListener = mapValues(getVueListeners(vNode), listener => !!listener)
    this.isVueComponent = !!vNode.componentInstance
  }

  // Internal: Gets the DOM element for this navigation item.
  get $el () {
    return this._$el || (this._$el = document.getElementById(this.id))
  }

  // Public: Returns the navigation strategy for this item in the specified
  // direction. It can be AUTO, or the id of a next navigation item.
  getNavigationStrategyFor (directionName) {
    return this.options[directionName]
  }

  // Public: Calls a click listener registered on the element, if any.
  click () {
    return this.emit('click')
  }

  // Public: Handles an ENTER keypress on the element and delegates to the click handler.
  enter () {
    return this.emit('enter') || this.click() || this.$el.click()
  }

  // Public: Calls a registered select handler, if any.
  select () {
    return this.emit('select')
  }

  // Public: Calls a registered remove handler, if any.
  remove () {
    return this.emit('remove')
  }

  // Public: Focuses the item.
  focus () {
    this.hasFocus = true
    if (this.$el) {
      this.$el.classList.add(NavigationItem.FocusedCssClass)
      return this.emit('focus')
    }
  }

  // Public: Removes focus from the element.
  blur () {
    this.hasFocus = false
    if (this.$el) {
      this.$el.classList.remove(NavigationItem.FocusedCssClass)
      return this.emit('blur')
    }
  }

  // Public: Calls a listener registered for the event.
  emit (eventName, args = {}) {
    if (this.hasListener[eventName]) {
      const eventArgs = { item: this, navigationAction: eventName, ...args }
      if (this.isVueComponent) {
        const eventHandler = getVueEventHandler(this.$el, eventName)
        if (eventHandler) {
          eventHandler(eventArgs)
          return true
        }
      } else {
        const event = createNewEvent(eventName, { bubbles: false })
        this.$el.dispatchEvent(assign(event, eventArgs))
      }
    }
  }

  // Internal: Cleans up when the directive is destroyed.
  destroy () {
    this._$el = undefined
    this.hasFocus = false
    this.hasListener = {}
    this.options = {}
  }
}
