var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{directives:[{name:"on-resize",rawName:"v-on-resize",value:(_vm.toggleSideScrolling),expression:"toggleSideScrolling"}],staticClass:"table",class:{
    'compact-side-padding': _vm.compactSidePadding,
    bordered: _vm.bordered,
    compact: _vm.compactCells,
    scrollable: _vm.scrollable,
    sticky: _vm.stickyScrolling,
    striped: _vm.striped,
  }},[_c('TableHeader',_vm._b({scopedSlots:_vm._u([{key:"preHeadersContent",fn:function(){return [_vm._t("preHeadersContent")]},proxy:true},{key:"headerContent",fn:function(slotScope){return [_vm._t("headerContent",null,null,slotScope)]}}],null,true)},'TableHeader',{ headers: _vm.headers, stickyScrolling: _vm.stickyScrolling, compactCells: _vm.compactCells, stickyHeaders: _vm.stickyHeaders },false)),_c('tbody',{staticClass:"table-rows"},[_vm._t("default")],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }