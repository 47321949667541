import NavigationItem from '@classes/NavigationItem'
import { merge } from 'lodash'

function bind (el, { value: { navigationService, ...options } }, vNode) {
  if (navigationService) {
    const navigationItem = new NavigationItem(vNode, options)
    navigationService.registerNavigationItem(navigationItem)
    return navigationItem
  }
}

function inserted (el, { value: { navigationService, ...options } }) {
  if (navigationService && !navigationService.currentlyFocusedItem) {
    navigationService.focusFirstItem()
  }
}

function update (el, { value, oldValue }) {
  const { navigationService, ...options } = value

  if (navigationService !== oldValue.navigationService) {
    unbind(el, oldValue)
    bind(el, value)
  } else if (navigationService) {
    const navigationItem = navigationService.getNavigationItemFor(el)
    if (navigationItem) merge(navigationItem.options, options)
  }
}

function unbind (el, { value: { navigationService } }) {
  if (navigationService) {
    const navigationItem = navigationService.getNavigationItemFor(el)
    if (navigationItem) {
      if (navigationItem.hasFocus) {
        // Focus the next item if it was focused, or focus the previous one if it was the last.
        navigationService.down() || navigationService.up()
      }
      navigationService.unregisterNavigationItem(navigationItem)
    }
  }
}

// v-navigation-item: Allows to navigate between different items with the keyboard.
export default {
  bind,
  inserted,
  update,
  unbind,
}
