<script>
export default {
  name: 'TableCellAction',
  inheritAttrs: false,
  props: {
    // Public: The label of the action.
    label: { type: String, default: null },

    // Public: A custom tooltip to be displayed.
    tooltip: { type: [String, Object], default: null },

    // TableCellActions: Renders Buttons or ActionLinks, which have compatible
    // props APIs.
    actionComponent: { type: String, required: true },

    // TableCellActions: Labels can be disabled conditionally, using tooltips
    // instead.
    displayLabel: { type: Boolean, default: true },
  },
  computed: {
    // Internal: By default a tooltip is displayed when the action doesn't
    // display a label.
    actionTooltip () {
      return this.tooltip || (!this.displayLabel && { content: this.label, delay: { show: 500, hide: 100 } })
    },
  },
}
</script>

<template>
  <component
    :is="actionComponent"
    v-tooltip="actionTooltip"
    class="table-cell-action"
    v-bind="$attrs"
    :label="displayLabel ? label : ''"
    :name="$attrs.name || label"
    v-on="$listeners"
  />
</template>

<style lang="scss" scoped>
.table-cell-action {
  background: none;
}

.table-cell-action:first-child {
  margin-left: auto;
}

.button.table-cell-action + .table-cell-action {
  margin-left: 8px;
}
</style>
