<script>
import ChatPanel from '@components/ChatPanel'
import ChatStore from '@stores/ChatStore'
import MessageCenterChat from '@components/MessageCenterChat'
import PatientIssuesCableProvider from '@components/PatientIssuesCableProvider'
import PatientMessageIssuesRequests from '@requests/MessageCenter/PatientMessageIssuesRequests'

export default {
  name: 'EvolveMessageCenter',
  components: {
    MessageCenterChat,
    PatientIssuesCableProvider,
    ChatPanel,
  },
  data () {
    return {
      loading: true,
    }
  },
  computed: {
    ...ChatStore.mapState('issues', 'collapsed', 'currentActivePatient'),
    ...ChatStore.mapGetters('unreadActiveIssuesById', 'openIssues'),
    allIssues () {
      return this.issues.length > 0 && this.issues
    },
    activeIssuesCount () {
      return Object.keys(this.unreadActiveIssuesById).length
    },
    canAccessMessages () {
      return this.hasPermission('patient_chat_user')
    },
  },
  watch: {
    canAccessMessages: {
      immediate: true,
      handler (canAccessMessages) {
        if (canAccessMessages) {
          PatientMessageIssuesRequests.issuesForMessageCenter().then(response => {
            ChatStore.setIssues(response)
            this.loading = false
          })
        }
      },
    },
  },
  methods: {
    toggleChat () {
      ChatStore.toggleChat()
    },
  },
}
</script>

<template>
  <div v-if="!loading && canAccessMessages" class="message-center-container">
    <div v-show="collapsed" class="message-center-item">
      <div class="collapsed-panel">{{ `Active (${activeIssuesCount}) Open (${openIssues.length})` }}</div>
      <div class="collapsed-chat" @click="toggleChat">{{ currentActivePatient ? currentActivePatient.name : 'Message Center' }}</div>
    </div>
    <div v-show="!collapsed" class="message-center-item">
      <PatientIssuesCableProvider/>
      <ChatPanel/>
      <MessageCenterChat v-if="!currentActivePatient"/>
      <!-- We create a chat for each issue to be able to get notifications of every message on real time, so we are listening to N subscriptions -->
      <MessageCenterChat v-for="issue in allIssues" v-show="currentActivePatient && currentActivePatient.id == issue.patient.id" :key="issue.id" :issue="issue"/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message-center-container {
  @include z-index(modal-blur-area);

  bottom: 0;
  display: flex;
  flex-direction: row;
  position: fixed;
  right: 0;
  text-transform: none;
}

.message-center-item {
  display: flex;
  flex-direction: row;
}

.collapsed-panel {
  align-items: center;
  background-color: $bg-card-header;
  border: $br-light;
  display: flex;
  font-size: $fs-large;
  font-weight: $fw-bold;
  justify-content: flex-start;
  letter-spacing: 0.24px;
  padding: 12px 8px;
}

.collapsed-chat {
  align-items: center;
  background-color: $WHITE;
  border-bottom: $br-light;
  cursor: pointer;
  display: flex;
  font-weight: $fw-bold;
  justify-content: space-between;
  padding: 12px;
  width: 330px;
}
</style>
