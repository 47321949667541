<script>
// NOTE: Allows to display Webpack build progress in development.
//
// We load it here simply because this component is included in all JS packs.
import { isDevelopmentMode } from '@helpers/EnvironmentHelper'
if (isDevelopmentMode) {
  import('@components/WebpackBuildProgressOverlay')
    .then(js => js.setupStatusOverlay('#page_crouton_placeholder'))
}

export default {
  name: 'PageCroutonPlaceholder',
}
</script>

<template>
  <div id="page_crouton_placeholder"/>
</template>
