<script>
import StatusPageLink from '@components/StatusPageLink'
import { isNotFoundError } from '@helpers/ErrorHelper'
import { translate } from '@helpers/TranslationHelper'

export default {
  name: 'FlexLayout',
  components: {
    StatusPageLink,
  },
  props: {
    // Public: Allows to delay displaying the content until the promise is
    // resolved, or an error message if the promise is rejected.
    loadingUntil: { type: [Promise, Object]/* type: Promise, but IE11 polyfill */, default: null },

    // Public: Similar to loading, but will display the content unless the
    // promise is rejected, in which case it shows an error message.
    catch: { type: [Promise, Object]/* type: Promise, but IE11 polyfill */, default: null },

    // Public: Allows for the loading spinner to remain visible after the loadingUntil
    // promise is resolved or rejected.
    //
    // Use case: If a user is being redirected to another page, it isn't desirable to
    // display an error fot them while the redirecting is happening.
    keepLoadingIndicator: { type: Boolean, default: false },
  },
  data () {
    return {
      displayLoadingIndicator: !!this.loadingUntil,
      error: false,
    }
  },
  computed: {
    is404 () {
      return isNotFoundError(this.error)
    },
    errorMessage () {
      if (this.is404) return translate('exceptions.not_found')
      return translate('exceptions.unexpected')
    },
  },
  watch: {
    loadingUntil () {
      if (this.loadingUntil) this.displayLoadingIndicator = true
      this.handleLoadingUntil()
    },
  },
  created () {
    this.handleLoadingUntil()
  },
  methods: {
    handleLoadingUntil () {
      const promise = this.loadingUntil || this.catch
      promise && promise
        .catch(error => {
          console.error(error)
          this.error = error
        })
        .finally(() => { this.displayLoadingIndicator = this.keepLoadingIndicator })
    },
  },
}
</script>

<template>
  <div class="flex-layout main-view" :class="{ 'page-loading': displayLoadingIndicator }">
    <LoadingIndicator v-if="displayLoadingIndicator" size="huge"/>
    <template v-else-if="error">
      <TitleSection :title="errorMessage"/>
      <StatusPageLink/>
    </template>
    <slot v-else/>
  </div>
</template>

<style lang="scss" scoped>
.main-view {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;

  &.page-loading {
    padding: 50px;
  }
}
</style>
