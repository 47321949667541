// Public: Map KeyboardEvent.keyCode numbers to KeyNames.
// Only used to circumvent an issue with synthetic Selenium events.
export default {
  8: 'Backspace',
  9: 'Tab',
  13: 'Enter',
  27: 'Escape',
  32: ' ',
  37: 'ArrowLeft',
  38: 'ArrowUp',
  39: 'ArrowRight',
  40: 'ArrowDown',
  46: 'Delete',
}
