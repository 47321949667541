import HttpStatus from '@constants/HttpStatus'
import { get, set } from 'lodash'

// Internal: We don't use translations to keep this module generic, and avoid
// the dependency on translations being present in the current environment.
const UNEXPECTED_ERROR_MESSAGE = 'An unexpected error occurred. Please try again, or contact us if the problem persists.'

function hasResponseStatus (error, status) {
  return get(error, 'response.status') === status
}

// Public: Checks if an error is a bad request error.
export function isBadRequestError (error) {
  return hasResponseStatus(error, HttpStatus.BAD_REQUEST)
}

// Public: Checks if an error is an access denied error.
export function isForbiddenError (error) {
  return hasResponseStatus(error, HttpStatus.FORBIDDEN)
}

// Public: Used for resources that are no longer available.
export function isGoneError (error) {
  return hasResponseStatus(error, HttpStatus.GONE)
}

// Public: Checks if an error is a server error.
export function isNotFoundError (error) {
  return hasResponseStatus(error, HttpStatus.NOT_FOUND)
}

// Public: Checks if an error is a server error.
export function isServerError (error) {
  return hasResponseStatus(error, HttpStatus.SERVER_ERROR)
}

// Public: Checks if an error is an unprocessable entity error.
export function isUnauthorizedError (error) {
  return hasResponseStatus(error, HttpStatus.UNAUTHORIZED)
}

// Public: Checks if an error is an unprocessable entity error.
export function isUnprocessableEntityError (error) {
  return hasResponseStatus(error, HttpStatus.UNPROCESSABLE_ENTITY)
}

// Public: Adds an error message for a specific field.
export function addFieldError (error, field, message) {
  const messages = getFieldErrors(error, field)
  messages.push(message)
  return set(error, `response.data.messages.${field}`, messages)
}

// Public: Converts all the error messages in an Error object to a single String.
// NOTE: Use only when you need to display some error message.
export function errorToMessage (error) {
  return getFullErrorMessages(error).join(', ') || UNEXPECTED_ERROR_MESSAGE
}

// Public: Returns an Array with the full messages, one per error.
export function getFullErrorMessages (error, field) {
  return get(error, 'response.data.fullMessages', [])
}

// Public: Returns an Array with the partial error messages for a particular field.
export function getFieldErrors (error, field) {
  return get(error, `response.data.messages.${field}`, [])
}

// Public: Returns a String with a comma-separated list of errors.
export function getFieldError (error, field) {
  return getFieldErrors(error, field).join(', ')
}
