<script>
import { isString, kebabCase, last } from 'lodash'
import { translateLabel } from '@helpers/TranslationHelper'

// Internal: A special header added by RemoteSearch.
function isActionsHeader (header) {
  return header === 'actions'
}

// Internal: Translates the header if a translation key was provided instead of
// a label.
function translateHeader (header) {
  if (isActionsHeader(header)) return ''
  if (header.includes('.')) {
    const prefixes = header.split('.')
    return translateLabel(prefixes.pop(), { prefix: prefixes.join('.') })
  }
  return header
}

export default {
  name: 'TableHeader',
  props: {
    headers: { type: Array, required: true },

    // Public: Whether to squish more rows and cells in the same space.
    compactCells: { type: Boolean, default: false },

    // Public: If enabled the top row is locked when scrolling vertically.
    stickyHeaders: { type: Boolean, default: false },

    // Public: If enabled the first cell is locked when scrolling horizontally.
    stickyScrolling: { type: Boolean, default: false },
  },
  computed: {
    translatedHeaders () {
      if (!isString(last(this.headers))) return this.headers // Optimization for Dashboard
      return this.headers.map(header => isString(header) ? { label: translateHeader(header) } : header)
    },
    stickToLeft () {
      return this.stickyScrolling && { showSeparator: true }
    },
  },
  methods: {
    cssForHeader (header) {
      return header.label && `${kebabCase(header.label)}-header`
    },
  },
}
</script>

<template>
  <thead class="table-headers" :class="{ compact: compactCells }">
    <tr class="table-header-row">
      <slot name="preHeadersContent"/>
      <th
        v-for="(header, index) in translatedHeaders"
        :key="index"
        v-stick-to-left="index === 0 && stickToLeft"
        :class="[cssForHeader(header), stickyHeaders && 'sticky-head']"
        class="table-cell"
      >
        <slot name="headerContent" v-bind="header">{{ header.label }}</slot>
      </th>
    </tr>
  </thead>
</template>

<style lang="scss" scoped>
$header-padding: 16px;

.table-headers {
  background: inherit; /* Ensure content doesn't show under the fixed cells when scrolling */
  font-size: $fs-large;
  font-weight: $fw-bold;
  letter-spacing: 0.025px;
  text-align: left;
  vertical-align: bottom;
  white-space: nowrap;

  &.compact {
    font-size: $fs-normal;
    padding-bottom: $cell-comfortable-padding;
    padding-top: $cell-comfortable-padding;
  }

  th.table-cell.sticky-head {
    @include z-index(above);

    position: sticky;
    top: 0;
  }
}

.table-header-row {
  background: inherit; /* Ensure content doesn't show under the fixed cells when scrolling */
}

.table-cell {
  padding: $header-padding;
}
</style>
