import { observeElementResize } from '@helpers/ResizeHelper'

function inserted (el, { value }) {
  el._resizeObserver = observeElementResize(el, value)
}

function update (el, { value, oldValue }) {
  if (value !== oldValue) {
    unbind(el)
    inserted(el, { value })
  }
}

function unbind (el) {
  el._resizeObserver.disconnect()
  delete el._resizeObserver
}

// v-on-resize: Allows to call a function every time the element is resized.
export default {
  inserted,
  update,
  unbind,
}
