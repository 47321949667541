<script>
export default {
  name: 'GroupInTwoColumns',
}
</script>

<template functional>
  <div class="group-in-two-columns" :class="data.staticClass">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.group-in-two-columns {
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
}
</style>
