<script>
export default {
  name: 'RemoveButton',
  props: {
    size: { type: String, default: 'medium' },
  },
}
</script>

<template functional>
  <ActionLink
    icon="remove"
    :size="props.size"
    :class="data.staticClass"
    class="remove-button"
    v-bind="data.attrs"
    v-on="listeners"
  />
</template>

<style lang="scss" scoped>
.remove-button {
  color: $button-color-normal;
  transition: color 0.1s ease;

  &:hover {
    color: $delete-color;
  }
}
</style>
