<script>
import VNodes from '@components/VNodes'
import WindowStore from '@stores/WindowStore'
import { hasProp, updateComponentOptions } from '@helpers/NodeHelper'
import { isEqual } from 'lodash'

export default {
  name: 'TitleSectionActions',
  components: {
    VNodes,
  },
  props: {
    // Public: Amount of actions that should be displayed outside the dropdown.
    // If possible, leave at 3, so as to have a consistent design across the platform
    displayUpTo: { type: Number, default: 3 },
  },
  data () {
    return {
      actions: [],
    }
  },
  computed: {
    dropdownOnly: WindowStore.isScreen('only-tablet'),
    actionsCount () {
      return this.actions.length
    },
    noDropdown () {
      return !this.dropdownOnly && this.actionsCount <= this.displayUpTo
    },
    expandedActions () {
      if (this.dropdownOnly) return []
      const actions = this.noDropdown ? this.actions : this.actions.slice(0, this.displayUpTo - 1)
      return this.enhanceActions(actions, { actionComponent: 'Button', displayLabel: true })
    },
    dropdownActions () {
      if (this.noDropdown) return []
      const actions = this.dropdownOnly ? this.actions : this.actions.slice(this.displayUpTo - 1)
      return this.enhanceActions(actions, { actionComponent: 'ActionLink' })
    },
  },
  methods: {
    // Internal: Allows to prevent double clicks and provide visual feedback for
    // long running operations.
    isActionAsync: hasProp('async'),

    // Internal: Updates the props of the TitleSectionAction components.
    enhanceActions (actionNodes, extraProps) {
      return actionNodes.map(action => {
        const isPromiseButton = extraProps.actionComponent === 'Button' && this.isActionAsync(action)
        const propsData = isPromiseButton ? { ...extraProps, actionComponent: 'PromiseButton' } : extraProps
        return updateComponentOptions(action, { propsData })
      })
    },
    // Internal: This is where the magic happens, every time the component is
    // rendered, we assign the slot content to a data property, causing the
    // computed properties that depend on it to be re-evaluated.
    slotIsPresent () {
      // Check for slots rendered. Those with v-if="false" are sent with 'isComment = true',
      // so we can filter them out
      const actions = (this.$slots.default || []).filter(node => !node.isComment)
      if (!isEqual(this.actions, actions)) this.actions = actions
      return this.actionsCount > 0 || this.$slots.expandedActions
    },
  },
}
</script>

<template>
  <div v-if="slotIsPresent()" class="title-section-actions-container">
    <slot name="expandedActions"/>
    <VNodes v-if="expandedActions.length" :content="expandedActions"/>
    <Dropdown v-if="dropdownActions.length" class="actions-dropdown" position="right">
      <Button slot="dropdownToggle" class="actions-toggle" icon="more"/>
      <template slot="dropdownContent">
        <VNodes :content="dropdownActions"/>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.title-section-actions-container {
  display: flex;
  flex-wrap: wrap;

  ::v-deep .button.title-section-action,
  .actions-dropdown {
    margin-bottom: 1px;
    margin-left: 24px;
  }
}
</style>
