import IE11KeyNames from '@evolve/legacy/IE11KeyNames'
import KeyCodes from '@constants/KeyCodes'
import KeyNames from '@constants/KeyNames'
import KeySymbols from '@constants/KeySymbols'
import { isIE, isMac } from '@helpers/BrowserHelper'

function isKey (event, keyName) {
  return normalizeKey(event.key, event) === keyName
}

// Public: Maps a standardized key String to its standard physical keyboard symbol.
export function keyNameToSymbol (keyName) {
  return KeySymbols[keyName] || keyName.toUpperCase()
}

export function normalizeKey (keyAlias, event) {
  keyAlias = (isIE && IE11KeyNames[keyAlias]) || keyAlias // TODO: Remove once we drop IE11 support.
  switch (keyAlias) {
    case 'cmd': return isMac ? KeyNames.meta : KeyNames.ctrl
    case KeyNames.backspace: return KeyNames.del
    default:
      // Only necessary because Selenium creates synthetic events without "key".
      if (keyAlias === 'Unidentified') {
        return KeyCodes[event.keyCode] || console.warn(`You must define '${event.keyCode}' in KeyCodes`) || keyAlias
      } else {
        return KeyNames[keyAlias] || keyAlias
      }
  }
}

// Public: Returns a Function that will only call the handler when the event is
// related to the specified key.
export function wrapKeyboardListener (handler, keyName) {
  const normalizedKey = normalizeKey(keyName)
  return event => {
    if (isKey(event, normalizedKey)) handler(event)
  }
}

// Public: returns all the key modifiers of an event
export function modifierKeysFor (event) {
  return [
    event.altKey && KeyNames.alt,
    event.ctrlKey && KeyNames.ctrl,
    event.metaKey && KeyNames.meta,
    event.shiftKey && KeyNames.shift,
  ].filter(x => x)
}
