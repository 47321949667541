import { flatMap, get, mergeWith, zipWith } from 'lodash'

// Public: Returns the vNode for the specified vm.
export function getVueInstance (el) {
  return el && (el._isVue ? el : el.__vue__)
}

// Public: Returns true if the element or instance is a component of the given name.
export function isVueComponent (el, name) {
  return get(getVueInstance(el), '$options.name') === name
}

// Public: Returns the vNode for the specified vm.
export function getVueNode (vm) {
  return vm && (vm.$vnode || vm._vnode)
}

// Public: Returns an identifier for the given node.
export function vNodeId (vNode) {
  return vNode.context._uid
}

// Public: Returns true if the vNode is an instance of the component of the given name.
export function isComponentVNode ({ componentOptions }, componentTag) {
  return componentOptions && componentOptions.tag === componentTag
}

// Public: Returns the text content of the given nodes.
export function getChildrenTextContent (children) {
  if (children) {
    return children.map(node => node.children ? getChildrenTextContent(node.children) : node.text).join('')
  }
}

// When merging component options for a VNode, we want to combine listeners and
// propsData, but not any other property.
//
// Combining props such as `value` would be incorrect, for example in Checkboxes
// it wouldn't be possible to unselect an option, since the arrays would be merged.
const COMPONENT_OPTIONS_PROPS = new Set(['listeners', 'propsData'])

// Internal: Merge only listeners and propsData, but not the inner objects and arrays.
const mergeTopLevel = (objValue, srcValue, key) => COMPONENT_OPTIONS_PROPS.has(key) ? undefined : srcValue

// Public: Updates a VNode from Vue with the specified properties.
//
// NOTE: Returning a shallow copy is necessary so that Vue can detect changes,
// since it compares nodes by using a strict comparison.
//
// NOTE: We modify the componentOptions in place, because otherwise Vue will
// detect that the node was modified, and trigger a re-render.
export function updateComponentOptions (vNode, componentOptions) {
  componentOptions = mergeWith(vNode.componentOptions, componentOptions, mergeTopLevel)
  return { ...vNode, componentOptions }
}

// Public: Updates a VNode from Vue with the specified children, cloning it if
// necessary, so that Vue can detect changes.
//
// Optimized for performance by returning the same node if unchanged.
export function updateChildren (vNode, children) {
  const sameChildren = zipWith(vNode.children, children).every(pair => pair[0] === pair[1])
  return sameChildren ? vNode : { ...vNode, children }
}

// Public: Updates a VNode from Vue with the specified children, cloning it if
// necessary, so that Vue can detect changes.
//
// Optimized for performance by returning the same node if unchanged.
export function updateComponentOptionsChildren (vNode, children) {
  if (!vNode.componentOptions) return vNode
  const sameChildren = zipWith(vNode.componentOptions.children, children).every(pair => pair[0] === pair[1])
  return sameChildren ? vNode : { ...vNode, componentOptions: { ...vNode.componentOptions, children } }
}

// Public: Returns a property from the vNode if not undefined.
export function getProp ({ componentOptions, data }, propName) {
  const prop = componentOptions.propsData[propName]
  return prop !== undefined ? prop : data.attrs[propName]
}

// Public: Returns a listener for the vNode, if any.
export function getListener ({ componentOptions, data }, listenerName) {
  return (componentOptions && componentOptions.listeners && componentOptions.listeners[listenerName]) ||
    (data && data.on && data.on[listenerName])
}

// Public: Matches a vNode if it has the specified listener.
export function hasListener (vNode, listenerName) {
  return Boolean(getListener(vNode, listenerName))
}

// Public: Attaches listeners object to the component. Decides whether it should go into componentOptions or data.
export function attachListeners (vNode, listeners) {
  const vNodeClone = { ...vNode }
  if (vNodeClone.componentOptions) {
    vNodeClone.componentOptions.listeners = { ...vNodeClone.componentOptions.listeners, ...listeners }
  } else {
    vNodeClone.data.on = { ...vNodeClone.data.on, ...listeners }
  }
  return vNodeClone
}

// Public: Returns a function that matches a vNode if it has the specified prop.
export function hasProp (propName) {
  return ({ componentOptions, data }) =>
    componentOptions.propsData.hasOwnProperty(propName) || data.attrs.hasOwnProperty(propName)
}

// Public: Finds and returns nodes of a named slot within the vNode.
export function getNamedSlotContent ({ componentOptions }, slotName) {
  const children = componentOptions.children
  if (!children) return null

  const slot = children.reduce((foundSlotNodes, child) => {
    if (child.data && child.data.slot === slotName) {
      if (child.tag === 'template') {
        foundSlotNodes.push.apply(foundSlotNodes, child.children || [])
      } else {
        foundSlotNodes.push(child)
      }
    }
    return foundSlotNodes
  }, [])

  return slot.length ? slot : null
}

// Public: Returns the list of VNodes of a given type among the node passed as parameter and
// any of it's descendants
function getNodesOfType (component, nodeType, { nested = true } = {}) {
  if (!component.$vnode) return []

  let result = []
  if (isComponentVNode(component.$vnode, nodeType)) {
    result.push(component.$vnode)
  }

  if (component.$children && (nested || result.length === 0)) {
    result.push(...flatMap(component.$children,
      childComponent => getNodesOfType(childComponent, nodeType)
    ))
  }

  return result
}

// Public: Returns the list of VNodes of a given type among the descendants of a given node type
export function getDescendantVNodes (component, nodeType, options = {}) {
  if (!component.$vnode) return []
  return flatMap(component.$children, childComponent => getNodesOfType(childComponent, nodeType, options))
}
