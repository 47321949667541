<script>
import InputMixin from '@mixins/InputMixin'
import { clone, keys, last } from 'lodash'
import { formatDate } from '@helpers/DateHelper'
import { isBlank, isPresent } from '@helpers/ObjectHelper'
import { removeBy } from '@helpers/ArrayHelper'

const AllowedInputs = {
  phone: 'PhoneInput',
  number: 'NumberInput',
  date: 'DateTimeInput',
}

const TypeValidator = value => {
  const isValidType = !!AllowedInputs[value]
  if (!isValidType) {
    console.error(`${value} is not an allowed type for MultiInput component. Allowed types are: ${keys(AllowedInputs).join(', ')}. For 'text' or 'select' check the SelectInput component`)
  }
  return isValidType
}

export default {
  name: 'MultiInput',
  mixins: [
    InputMixin,
  ],
  props: {
    type: { type: String, required: true, validator: TypeValidator },
    value: { type: Array, default: () => [] },
    isObject: { type: Boolean, default: false },
    labelProp: { type: String, default: 'label' },
  },
  data () {
    return {
      // Internal: Current value of the user input.
      // On add, the inputValue is added to the values list and the inputValue is set to empty.
      inputValue: null,
      // Internal: A copy of the the selected item being edited.
      itemEdited: null,
      // Internal: Index of the item being edited. On save, the item on the itemIndex position
      // is replaced by the updated one.
      indexEdited: null,
      // Internal: List of selected items that populate the input
      values: this.value,
      // Internal: for styling purposes
      focused: false,
    }
  },
  computed: {
    isDateInput () {
      return this.type === 'date'
    },
    isPhoneInput () {
      return this.type === 'phone'
    },
    inputListeners () {
      return {
        ...this.$listeners,
        input: () => { if (this.isDateInput) this.addItem() },
        focus: this.focusInput,
        blur: this.blurInput,
        'on-open': this.focusInput,
        'on-close': this.blurInput,
      }
    },
    dateEditListeners () {
      return this.isDateInput ? this.updateAndStopEdit : null
    },
    attributesForInput () {
      const attrs = {
        ...this.$attrs,
        placeholder: this.placeholder,
        disabled: this.disabled,
      }
      if (this.isDateInput) {
        attrs.type = 'date'
        attrs.hideClearButton = true
      }
      return attrs
    },
    componentForInput () {
      return AllowedInputs[this.type]
    },
  },
  methods: {
    isBlank,
    focusInput () {
      this.focused = true
    },
    blurInput () {
      this.focused = false
    },
    isSelected (item) {
      return this.values.includes(item)
    },
    addItem () {
      if (isBlank(this.inputValue)) return

      const newItem = this.isObject ? { [this.labelProp]: this.inputValue } : this.inputValue
      if (!this.isSelected(newItem)) this.values.push(newItem)

      this.inputValue = null
      this.notifyValueChange()
    },
    removeItem (item) {
      removeBy(this.values, item)
      this.notifyValueChange()
    },
    onDeletePressed () {
      if (!this.inputValue) {
        const lastSelectedItem = last(this.values)
        if (isPresent(lastSelectedItem)) this.removeItem(lastSelectedItem)
      }
    },
    notifyValueChange () {
      const values = this.isObject ? this.values.map(item => ({ ...item, value: item[this.labelProp] })) : this.values
      this.$emit('input', [...values])
    },
    getItem (item) {
      if (this.isObject) return item[this.labelProp]
      return item
    },
    formatItem (item) {
      const value = this.getItem(item)
      if (this.isDateInput) return formatDate(value, { format: 'date' })
      return value
    },
    edit (item) {
      this.indexEdited = this.values.indexOf(item)
      this.itemEdited = clone(this.getItem(item))
    },
    isEditing (index) {
      return this.indexEdited === index
    },
    updateAndStopEdit () {
      if (this.isObject) {
        this.values[this.indexEdited][this.labelProp] = this.itemEdited
      } else {
        this.values[this.indexEdited] = this.itemEdited
      }
      this.stopEdit()
      this.notifyValueChange()
    },
    stopEdit () {
      this.indexEdited = null
      this.itemEdited = null
    },
  },
}
</script>

<template>
  <div class="multi-input">
    <Label v-if="label" v-bind="{ required, hint }" :class="{ error }">{{ label }}</Label>

    <div class="multi-input-wrapper">
      <template v-for="(selectedItem, index) in values">
        <div v-if="!isEditing(index)" :key="getItem(selectedItem)" :class="{ disabled }" class="selected-item">
          <span>{{ formatItem(selectedItem) }}</span>
          <Button
            v-if="!disabled"
            icon
            compact
            name="Edit"
            class="selected-item-edit"
            @click="edit(selectedItem)"
          />
          <Button
            v-if="!disabled"
            icon
            compact
            name="Remove"
            class="selected-item-remove"
            @click="removeItem(selectedItem)"
          />
        </div>

        <div v-else :key="`${getItem(selectedItem)}-edit`" class="input-container edit-component" @blur="stopEdit">
          <component
            :is="componentForInput"
            ref="editInputComponent"
            v-model="itemEdited"
            v-autofocus
            v-bind="attributesForInput"
            class="input-component"
            @keydown.enter.exact.prevent="updateAndStopEdit"
            @keyup.esc="stopEdit"
            @blur="stopEdit"
            @input="dateEditListeners"
          />
        </div>
      </template>

      <div
        class="input-container create-component"
        :class="{ error, focused }"
        :disabled="disabled"
        @keyup.delete="onDeletePressed"
      >
        <component
          :is="componentForInput"
          ref="inputComponent"
          v-model="inputValue"
          v-bind="attributesForInput"
          :class="{ error }"
          class="input-component"
          v-on="inputListeners"
          @keydown.enter.exact.prevent="addItem"
        />
        <div v-if="!disabled" class="multi-add-button" :class="{ 'disabled': isBlank(inputValue) }" @click="addItem"><span>Add</span></div>
      </div>
    </div>
    <InputError v-if="error" :error="error"/>
  </div>

</template>

<style lang="scss" scoped>
.multi-input {
  position: relative;
}

.multi-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;

  .selected-item {
    align-items: center;
    animation: fade 0.2s normal forwards ease-out;
    background-color: $bg-tag;
    border: $br-tag;
    border-radius: $radius-normal;
    display: flex;
    margin: 0 8px 8px 0;
    padding: 5px 2px 6px 8px;

    &.disabled {
      padding: 5px 8px 6px;
    }

    .selected-item-edit {
      margin-left: 10px;
    }

    .selected-item-remove {
      font-size: $fs-small;
      margin-left: 2px;
      margin-right: 2px;
    }

    .selected-item-edit,
    .selected-item-remove {
      @include clickable;

      color: $tundora-l-1;

      &:hover {
        color: $fc-html;
      }
    }
  }
}

.input-container {
  border: 1px solid $input-border-color;
  border-radius: $radius-normal;
  display: flex;
  margin: 0 8px 0 0;
  min-width: 200px;
  padding: $chip-padding;

  &:hover {
    border-color: $input-border-color-hover;
  }

  &.create-component {
    flex: auto;
    min-width: 200px;

    // Hide the displayed date.
    ::v-deep .flatpickr-input {
      color: $WHITE;
    }
  }

  &.edit-component,
  &.focused,
  &.focused:hover {
    border-color: $input-border-color-active;
  }

  &.error,
  &.error:hover {
    background-color: $input-error-background-color;
    border-color: $input-error-color;

    & ::v-deep .input::placeholder {
      color: $input-error-placeholder-color;
    }
  }

  &[disabled] {
    background-color: $input-disabled-background-color;

    &:hover {
      border-color: $input-disabled-border-color;
    }
  }

  .input-component {
    flex: auto;

    & ::v-deep .input {
      border: none;
      padding: 0;
    }
  }
}

.multi-add-button {
  align-items: center;
  background-color: $tundora-l-3;
  border-radius: 14px;
  display: flex;
  font-size: $fs-secondary;
  font-weight: $fw-regular;
  margin-left: auto;
  margin-right: -5px;
  padding: 0 10px;
  transition: all 0.3s ease-out;

  &.disabled {
    background-color: $WHITE;
    color: $disabled-color;
  }

  &:hover:not(.disabled) {
    @include clickable;

    background-color: $tundora-l-2;
  }
}
</style>
