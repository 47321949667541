<script>
export default {
  name: 'Hint',
  props: {
    // Public: Hint content to be shown next to the input label, as a Tooltip trigger by click or hover
    content: { type: [String, Boolean], default: false },
    // Optional: The size to use for the icon.
    size: { type: String, default: 'small' },
  },
}
</script>

<template functional>
  <Icon
    v-if="props.content"
    v-once
    v-tooltip="{ content: props.content, allowHTML: true }"
    :class="data.staticClass"
    :size="props.size"
    class="hint"
    name="info"
  />
</template>

<style lang="scss" scoped>
.icon.hint {
  cursor: pointer;
  fill: $tundora-l-1;
  margin: 0 6px;
  opacity: 0.7;
}
</style>
