import Vue from 'vue'
import Vuex from 'vuex'
import { isProductionMode } from '@helpers/EnvironmentHelper'

Vue.use(Vuex)

const store = new Vuex.Store({
  strict: !isProductionMode,
})

export default store
