<script>
// Public: This is a split button that is a hybrid between a button and a menu: it groups related commands together into a dropdown, but also offers one-click access to a default choice that doesn't require opening the menu.
export default {
  name: 'ButtonWithOptions',
  inheritAttrs: false,
  props: {
    // Public: When loading, the button label is replaced with a loading indicator.
    loading: { type: Boolean, default: false },

    // Public: The label that appears on the button
    label: { type: String, default: '' },

    // Public: Expects an array of hashes that details each dropdown options. Only the label and action fields need to be included in each option.
    // Example: [{ label: 'Required: Dropdown Line Name', action: 'Required: message to send in emitted event', disabled: false, hint: 'Wanna know a hint?' }]
    options: { type: Array, required: true },

    // Public: The listener that will be called when a user clicks on the main portion of the button.
    mainButtonAction: { type: String, default: 'option:main' },

    // Public: Button Style, see the design system for more info (CDS)
    primary: { type: Boolean, default: false },

    // Public: Button Style, see the design system for more info (CDS)
    secondary: { type: Boolean, default: false },

    // Public: Button Style, see the design system for more info (CDS)
    danger: { type: Boolean, default: false },
  },
  computed: {
    buttonProps () {
      return { primary: this.primary, secondary: this.secondary, danger: this.danger }
    },
  },
}
</script>

<template>
  <div class="dropdown-button-wrapper">
    <Button
      ref="mainButton"
      class="main-button"
      :class="{ loading, 'save-button': loading }"
      v-bind="{ ...$attrs, ...buttonProps }"
      :label="label"
      :loading="loading"
      :rel="safeRel"
      @click="$emit(mainButtonAction)"
    >
      <LoadingIndicator v-if="loading" class="save-button__loading-indicator" white/>
      <span class="main-button__label"><slot>{{ translateIfKey(label) }}</slot></span>
    </Button>
    <Dropdown position="right" expandUpward class="dropdown-options">
      <Button
        slot="dropdownToggle"
        ref="dropdownToggle"
        class="dropdown-options-button"
        rightIcon
        icon="downSmall"
        label=""
        v-bind="buttonProps"
      />
      <template slot="dropdownContent">
        <div v-for="(option, index) in options" :key="index" class="dropdown-option-container">
          <ActionLink
            :label="option.label"
            class="dropdown-option"
            :disabled="option.disabled"
            @click="$emit(option.action)"
          />
          <Hint v-if="option.hint" :content="option.hint" size="regular" class="form-header-hint"/>
        </div>
      </template>

    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
.dropdown-button-wrapper {
  display: flex;
}

.dropdown-button-wrapper .main-button {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  min-width: auto;
  padding: 0 8px 0 16px;
}

.full.dropdown-options-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  min-width: auto;
  padding: 0 16px 0 0;

  &::before {
    content: ' |';
  }

  &.primary {
    background: $primary-color;

    &:focus,
    &:hover {
      background: $primary-color-light;
    }
  }
}

.full ::v-deep .button-icon.icon-downSmall.right {
  display: inline-block;
  height: $icon-size-regular;
  margin-left: 2px;
  width: $icon-size-regular;

  .icon-svg {
    vertical-align: text-top;
  }
}

.dropdown-option-container {
  display: flex;
  padding: 4px 16px 0 16px;

  .hint.icon-info {
    display: block;
    margin: 0 0 4px 16px;
  }
}

.save-button.loading {
  @include save-button-loading;
}

</style>
