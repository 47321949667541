import LocalStorage from '@services/LocalStorageService'
import { each, isNil, keys, map } from 'lodash'

export const keySeparator = ':'
export const defaultNamespace = ['cipherhealth', 'evolve'].join(keySeparator)

// Public: Returns a convenient cache object that allows to scope keys to the
// specified prefix.
export default class CacheService {
  constructor (namespace = defaultNamespace) {
    this.namespace = namespace
  }

  // Internal: May be overriden by subclasses.
  cacheNamespace (namespaceOptions) {
    const prefixes = map(namespaceOptions, (value, key) => [key, value].join(keySeparator))
    return [this.namespace, ...prefixes, ''].join(keySeparator)
  }

  // Public: Retrieves a cached value from local storage.
  get (key, namespaceOptions) {
    return LocalStorage.getJSON(`${this.cacheNamespace(namespaceOptions)}${key}`)
  }

  // Public: Returns an object with the cached value for each of the keys.
  getAll (keys, namespaceOptions) {
    return keys.reduce((attrs, key) => {
      const value = this.get(key, namespaceOptions)
      if (!isNil(value)) attrs[key] = value
      return attrs
    }, {})
  }

  // Public: Stores an object (serializing it to JSON) to local storage.
  set (key, value, namespaceOptions) {
    LocalStorage.setJSON(`${this.cacheNamespace(namespaceOptions)}${key}`, value)
  }

  // Public: Stores each key in the object as a separate entry.
  setAll (attrs, namespaceOptions) {
    each(attrs, (value, key) => this.set(key, value, namespaceOptions))
  }

  // Public: Removes all cached values from local storage.
  clear (namespaceOptions) {
    const keysToRemove = keys(window.localStorage).filter(key => key.startsWith(this.cacheNamespace(namespaceOptions)))
    keysToRemove.forEach(key => LocalStorage.remove(key))
  }
}
