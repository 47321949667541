import AuthStore from '@stores/AuthStore'
import Vue from 'vue'
import { registerAndGetStore } from '@helpers/StoreHelper'

export const state = () => ({
  issues: [],
  messagesByChatRoomId: {},
  currentActivePatient: null,
  collapsed: false,
})

export const getters = {
  openIssues (state, getters) {
    return state.issues.filter(issue => issue.participantIds.length === 0)
  },
  activeIssues (state) {
    return state.issues.filter(issue => issue.participantIds.includes(AuthStore.currentUser.id))
  },
  unreadActiveIssuesById (state, getters) {
    return getters.activeIssues.reduce((acc, curr, i) => {
      let messages = state.messagesByChatRoomId[curr.chatRoom.id]
      let lastMessage = messages && messages[messages.length - 1]
      let lastReadTime = curr.chatRoom.lastReadBy[AuthStore.currentUser.id]
      if (!lastReadTime) {
        acc[curr.id] = true
      } else if (lastMessage && lastMessage.createdAt > lastReadTime) {
        acc[curr.id] = true
      }
      return acc
    }, {})
  },
  unreadOpenIssuesById (state, getters) {
    return getters.openIssues.reduce((acc, curr, i) => {
      let messages = state.messagesByChatRoomId[curr.chatRoom.id]
      let lastMessage = messages && messages[messages.length - 1]
      let lastReadTime = curr.chatRoom.lastReadBy[AuthStore.currentUser.id]
      if (!lastReadTime) {
        acc[curr.id] = true
      } else if (lastMessage && lastMessage.createdAt > lastReadTime) {
        acc[curr.id] = true
      }
      return acc
    }, {})
  },
}

export const mutations = {
  SET_ISSUES (state, { issues }) {
    state.issues = issues
  },
  SET_CURRENT_PATIENT (state, patient) {
    state.currentActivePatient = patient
  },
  TOGGLE_CHAT (state) {
    state.collapsed = !state.collapsed
  },
  ADD_ISSUE (state, issue) {
    state.issues = state.issues.concat(issue)
  },
  REMOVE_ISSUE (state, issueToRemove) {
    state.issues = state.issues.filter(issue => issue.id !== issueToRemove.id)
  },
  UPDATE_ISSUE (state, issue) {
    state.issues = [
      ...state.issues.filter(iss => iss.id !== issue.id),
      issue,
    ]
  },
  ADD_PARTICIPANT (state, { issue, userId }) {
    const updatedIssue = { ...issue, participantIds: [...issue.participantIds, userId] }
    state.issues = [
      ...state.issues.filter(iss => iss.id !== issue.id),
      updatedIssue,
    ]
  },
  SET_MESSAGES_FOR_ISSUE_CHAT_ROOM (state, { issue, messages }) {
    Vue.set(state.messagesByChatRoomId, issue.chatRoom.id, messages)
  },
  READ_CHAT_ROOM (state, chatRoom) {
    let issueIndex = state.issues.findIndex(issue => issue.chatRoom.id === chatRoom.id)
    Vue.set(state.issues[issueIndex], 'chatRoom', chatRoom)
  },
  ADD_MESSAGE_TO_CHAT_ROOM (state, { chatRoomId, message }) {
    state.messagesByChatRoomId[chatRoomId].push(message)
  },
}

export const actions = {
  setIssues ({ commit }, { issues }) {
    commit('SET_ISSUES', { issues })
  },
  setCurrentActivePatient ({ commit }, patient) {
    commit('SET_CURRENT_PATIENT', patient)
  },
  toggleChat ({ commit }) {
    commit('TOGGLE_CHAT')
  },
  addIssue ({ commit }, issue) {
    commit('ADD_ISSUE', issue)
  },
  removeIssue ({ commit }, issue) {
    commit('REMOVE_ISSUE', issue)
  },
  updateIssue ({ commit }, issue) {
    commit('UPDATE_ISSUE', issue)
  },
  addParticipant ({ commit }, { issue, userId }) {
    commit('ADD_PARTICIPANT', { issue, userId })
  },
  setMessagesForIssueChatRoom ({ commit }, { issue, messages }) {
    commit('SET_MESSAGES_FOR_ISSUE_CHAT_ROOM', { issue, messages })
  },
  readChatRoom ({ commit }, chatRoom) {
    commit('READ_CHAT_ROOM', chatRoom)
  },
  addMessagetoChatRoom ({ commit }, { chatRoomId, message }) {
    commit('ADD_MESSAGE_TO_CHAT_ROOM', { chatRoomId, message })
  },
}

export default registerAndGetStore('chat', { state, getters, mutations, actions })
