import { isFunction, sortBy } from 'lodash'

// Internal: Allows to use most of the helpers with a predicate Function (like
// in lodash) or with an element that must be an exact match.
function toPredicate (predicate) {
  return isFunction(predicate) ? predicate : item => item === predicate
}

// Public: Returns the first non-empty result of evaluating the predicate.
//
// Example:
//   translations = { en: 'Hello', es: 'Hola' }
//   mapFind(['se', 'en'], (value) => translations[value]) returns 'Hello'
//
//   which is equivalent to:
//     translations['se'] || translations['en']
//
//   mapFind(['es', 'en'], (value) => translations[value]) returns 'Hola'
export function mapFind (array, predicate, { hasFound = Boolean } = {}) {
  for (let i = 0; i < array.length; i++) {
    const result = predicate(array[i])
    if (hasFound(result)) return result
  }
}

// Public: Removes an element from the Array using 'splice`, which Vue's
// reactive system can detect.
//
// Returns the removed item, or undefined.
export function removeBy (array, itemOrPredicate) {
  const index = array.findIndex(toPredicate(itemOrPredicate))
  if (index !== -1) return array.splice(index, 1)[0]
}

// Public: Replaces an element in the Array using 'splice`, which Vue's reactive
// system can detect.
//
// Returns the replaced element, or undefined.
export function replaceWith (array, itemToReplace, itemWhichReplaces) {
  const index = array.indexOf(itemToReplace)
  if (index !== -1) return array.splice(index, 1, itemWhichReplaces)[0]
}

// Public: Returns a new Array toggling the presence of the item:
//   - If the item is in the array, it removes it.
//   - If the item is not in the array, it adds it at the end.
export function toggleBy (array, item) {
  return array.includes(item) ? array.filter(e => e !== item) : array.concat(item)
}

// Public: Returns an Array with the items in the specified `page`.
//
// page - The number of the page to return, first page is 0.
// pageSize - The number of items per page.
export function paginate (array, { page, pageSize = 10 }) {
  const start = page * pageSize
  return array.slice(start, start + pageSize)
}

// Public: Sorts the array so that the items that match the predicate are first.
export function sortByCondition (array, predicate) {
  return sortBy(array, item => !predicate(item))
}
