import AuthStore from '@stores/AuthStore'
import ExperimentalRequests from '@requests/ExperimentalRequests'
import buildURL from 'axios/lib/helpers/buildURL'
import { deepDecamelizeKeys } from '@helpers/ObjectHelper'
import { interpolate } from '@helpers/StringHelper'
import { notifyError } from '@helpers/BugsnagHelper'

// Public: Returns the current care provider id based on the current url.
export function extractCareProviderIdFromUrl (url = window.location.href) {
  const urlSegment = url.split('/care_providers/')[1]
  return urlSegment?.split('/')[0]
}

// Public: Formats a url, replacing segments like /:id/ with the parameter of
// that name.
//
// Example:
//   formatUrl('/users/:id', { id: '5' }) returns '/users/5'
//   formatUrl('/users', { query: { id: '5' } }) returns '/users?id=5'
export function formatUrl (urlTemplate, { query, ...params } = {}) {
  const url = interpolate(urlTemplate, params)
  return query ? buildURL(url, deepDecamelizeKeys(query)) : url
}

// Public: Formats a URL from the base URL
export function evolveUrl (...path) {
  return `${window.location.origin}/${path.join('/')}`
}

// Public: Formats a URL taking care of adding the care provider path
export function evolveCareProviderUrl (...path) {
  const careProviderId = AuthStore.careProviderId || notifyError('Care provider not set.', { path })
  return evolveUrl('care_providers', careProviderId, ...path)
}

// Public: Formats a beta URL taking care of adding the care provider path
export function betaCareProviderUrl (...path) {
  const careProviderId = AuthStore.careProviderId || notifyError('Care provider not set.', { path })
  return evolveUrl('beta/care_providers', careProviderId, ...path)
}

// Public: Returns the URL to switch permanently to the old version of a page.
export function switchToClassicUrl (page, options) {
  return ExperimentalRequests.switchToClassicPath({ page, ...options })
}

export function switchToSlickUrl (page, options) {
  return ExperimentalRequests.switchToSlickPath({ page, ...options })
}
