import AuthStore from '@stores/AuthStore'
import { compact, isString } from 'lodash'

// Public: Checks if the current user has any of the specified permissions.
//
// Example:
//   hasPermission('user_edit user_delete')
export function hasPermission (arrayOrString) {
  if (arguments.length > 1) {
    throw new Error('hasPermission only accepts a single argument, space-separated Strings, or an Array of permissions.')
  }

  const permissions = compact(isString(arrayOrString) ? arrayOrString.split(' ') : arrayOrString)
  if (permissions.length === 0) return true

  const userPermissions = AuthStore.permissions
  if (!userPermissions) return false

  return permissions.some(permission => userPermissions.includes(permission))
}

// Public: Checks if the current user participates in the specified experiment has any of the specified permissions.
//
// Example:
//   hasExperiment('search_sidebar')
export function hasExperiment (name) {
  return hasPermission(`experiment_${name}`)
}
