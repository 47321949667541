<script>
import { keys } from 'lodash'

const customValueComponentsByType = {
  text: 'TextInput',
  currency: 'CurrencyInput',
}

const CustomValueTypeValidator = value => {
  const isValidType = !!customValueComponentsByType[value]
  if (!isValidType) {
    console.error(`${value} is not an allowed type for the custom value. Allowed types are: ${keys(customValueComponentsByType).join(', ')}`)
  }
  return isValidType
}

export default {
  name: 'ButtonGroupSelectInputWithCustomValue',
  inheritAttrs: false,
  props: {
    // Public: Data Type of the custom field value
    customValueType: { type: String, default: 'text', validator: CustomValueTypeValidator },

    // Public: Selected item that populates the input.
    value: { type: String, default: null },

    // Public: Required fields display a visual indicator, handled by <Label>.
    required: { type: Boolean, default: false },

    // Public: Errors to display below the input, handled by <InputError>.
    error: { type: [Boolean, String, Array], default: false },
  },
  data () {
    return {
      customValue: '',
      customValueSelected: false,
    }
  },
  computed: {
    customValueComponent () {
      return customValueComponentsByType[this.customValueType] || throw new Error(`Unknown 'customValue' component type`)
    },
    disabled () {
      return this.$attrs.hasOwnProperty('disabled')
    },
  },
  methods: {
    onInput (newValue) {
      this.$emit('input', newValue)
    },
    selectItem (newValue) {
      this.customValueSelected = false
      this.onInput(newValue)
    },
    selectOther () {
      if (this.disabled) return
      this.customValueSelected = true
      this.onInput(this.customValue)
    },
  },
}
</script>

<template>
  <ButtonGroupSelectInput
    v-bind="$attrs"
    :value="customValueSelected ? null : value"
    :disabled="disabled"
    :error="!customValueSelected && error"
    :required="required"
    :rel="safeRel"
    v-on="$listeners"
    @input="selectItem"
  >
    <div class="custom-value-wrapper" @click="selectOther">
      <component
        :is="customValueComponent"
        v-model="customValue"
        class="custom-value-input"
        placeholder="Other"
        :class="{ selected: customValueSelected, disabled }"
        :disabled="disabled"
        :error="customValueSelected && error"
        @focus="selectOther"
        @input="onInput"
      />
    </div>
  </ButtonGroupSelectInput>
</template>

<style lang="scss" scoped>
.custom-value-wrapper {
  display: inline-block;
}

.custom-value-input ::v-deep {
  .input {
    max-width: 80px;
    padding: 8px;
  }

  .wrapper {
    .currency {
      padding: 8px;
    }
  }
}

.custom-value-input.selected ::v-deep {
  .wrapper:not(.error),
  .input:not(.error) {
    border-color: $cipher-green-l-0;
  }

  .currency {
    background-color: $cipher-green-l-0;
  }
}
</style>
