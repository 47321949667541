<script>
import { flatMap } from 'lodash'

/* Public: Container that allows to easily implement a button or multiple buttons intended to add new entity to the list.
It can also display appropriate message and change the design if the list is currently empty.

Example:
  <AddSection :noItems="trueIfListIsCurrentlyEmpty" :entitiesName="items">
    <Button icon="add" :to="pathToCreatePage">Add item</Button>
  </AddSection>
*/
export default {
  name: 'AddSection',
  props: {
    // Public: Name of the entity that this AddSection is for.
    entitiesName: { type: String, default: 'items' },

    // Public: Custom header
    header: { type: String, default: null },

    // Public: Boolean indicating whether this section is currently empty which changes its layout.
    noItems: { type: Boolean, default: false },

    // Public: Boolean indicating whether this section should use compact layout.
    compact: { type: Boolean, default: false },
  },
  methods: {
    hasSingleAction () {
      return this.$slots.default.length === 1
    },
  },
  render (h) {
    const content = flatMap(
      this.$slots.default,
      (item, index) => [
        item && !item.isComment && index > 0 ? h('span', { class: 'button-separator' }, 'or') : null,
        item,
      ]
    )

    if (this.noItems) {
      content.unshift(h('h2', { class: 'empty-message' }, this.header ? this.header : `No ${this.entitiesName} found`))
    }

    return h(
      'section',
      { class: ['add-section', { vertical: this.noItems, 'add-section--single-action': this.hasSingleAction(), compact: this.compact }] },
      content
    )
  },
}
</script>

<style lang="scss" scoped>
.button-separator {
  color: $tundora-l-1;
  display: inline-block;
  margin: 0 16px;
}

.add-section {
  @include bordered-box;

  align-items: center;
  border-style: dashed;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  justify-items: center;
  padding: 8px 24px 24px;

  .button-separator,
  .button {
    margin-top: 16px;
  }

  &.compact {
    padding: 0 16px 16px;

    .button-separator,
    .button {
      margin-top: 16px;
    }
  }

  &.vertical {
    flex-direction: column;
    min-height: 128px;

    .button-separator {
      margin: 8px;
    }
  }
}

.add-section--single-action {
  cursor: pointer;
  position: relative;

  // Allows to interact with the entire box instead of just the button inside it
  > .button::before {
    background: transparent;
    bottom: 0;
    content: "";
    display: block;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &:hover {
    border-style: solid;
  }
}

.empty-message {
  color: $fc-html;
}
</style>
