import { buildPlural, singularize, snakeCaseWithNumbers as snakeCase } from '@helpers/StringHelper'
import { isString, reject, startCase } from 'lodash'
import { lazyLoadView } from '@router/RouteHelpers'

// Public: Defines an index route, and optionally a few nested routes.
//
// Example:
//   indexPage('IssuePanels', { nested: ['Edit', 'Show', 'New', 'Other'] }),
//
// will generate 5 accessible routes, which will use the following components:
//   - index page => pathTo('IssuePanels') => @views/IssuePanels
//   - new page => pathTo('IssuePanels/New') => @views/IssuePanel/Edit (@views/IssuePanel/New if Edit is not defined)
//   - edit page => pathTo('IssuePanel', issuePanel, 'Edit') => @views/IssuePanel/Edit
//   - show page => pathTo('IssuePanel', issuePanel, 'Show') => @views/IssuePanel/Show
//   - custom page => pathTo('IssuePanel', issuePanel, 'Other') => @views/IssuePanel/Other
//
// If you run into a 404, pay attention to the pluralization, routes that depend
// on an id (member in Rails) use the singular instead.
export function indexPage (name, { nested = [], collection = [], title, ...options } = {}) {
  const includeNewPage = nested.some(hasRouteNamed('New'))
  const includeEditPage = nested.some(hasRouteNamed('Edit'))
  const indexPage = asyncPage(name, { ...options, nested: collection })
  nested = reject(nested, hasRouteNamed('New'))
  return [
    indexPage,
    includeNewPage && asyncPage(`${name}/New`, {
      componentName: `${singularize(name)}/${includeEditPage ? 'Edit' : 'New'}`,
      path: `${options.path || snakeCase(name)}/new`,
    }),
    nested.length > 0 && asyncShowPage(singularize(name), { title, nested }),
  ].filter(x => x)
}

function buildNestedRoute (ancestorName, nestedRoute) {
  if (isString(nestedRoute)) nestedRoute = { name: nestedRoute }
  const { name, ...nestedOptions } = nestedRoute
  return asyncPage(`${ancestorName}/${name}`, {
    path: name === 'Show' ? '' : snakeCase(name),
    ...nestedOptions,
  })
}

function addNestedRoutes (ancestorName, { nested = [], children = [], ...options }) {
  nested.forEach(nestedRoute => { children.push(buildNestedRoute(ancestorName, nestedRoute)) })
  if (children.length > 0) {
    options.children = children
    options.redirect = '/404' // If there's no show page, ensure it's a 404.
  } else {
    options.name = ancestorName
  }
  return options
}

// Public: Defines a route in the vue-router format.
// The component is not fetched until the user navigates to the route.
export function asyncPage (name, { path = snakeCase(name), redirect = null, componentName, component, ...options } = {}) {
  return {
    path,
    redirect,
    component: component || (() => lazyLoadView(import(`@views/${componentName || name}`))),
    ...addNestedRoutes(name, options),
  }
}

// Public: Defines a route in the vue-router format.
// The component is not fetched until the user navigates to the route.
export function asyncShowPage (name, { path = `${snakeCase(name)}/:id`, ...options } = {}) {
  return asyncPage(name, {
    path,
    component: options.nested ? withDefaultView(name, options) : undefined,
    ...options,
  })
}

function hasRouteNamed (routeName) {
  return route => route.name ? route.name === routeName : route === routeName
}

// Private: Creates a default component for displaying child routes with a specified name.
function withDefaultView (name, options) {
  const pageTitle = `${options.title || buildPlural(startCase(name))} · Evolve by CipherHealth`
  return {
    name: name,
    page: {
      // All subcomponent titles will be injected into this template.
      titleTemplate (title) {
        return title ? `${title} · ${pageTitle}` : pageTitle
      },
    },
    render (h) {
      return h('router-view')
    },
  }
}
