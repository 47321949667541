<script>
import ColorSwatches from '@components/ColorSwatches'
import InputMixin from '@mixins/InputMixin'
import { isHexColor } from '@helpers/ColorHelper'

export default {
  name: 'ColorInput',
  components: {
    ColorSwatches,
  },
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: A hexadecimal code that represents the selected color.
    value: { type: String, default: null },

    // Public: Whether to hide an editable input that displays the hex code.
    hideHexInput: { type: Boolean, default: false },

    // Public: Whether to display the HTML color picker.
    allowCustomColors: { type: Boolean, default: false },

    // Optional: A default color for the custom input.
    defaultCustomColor: { type: String, default: '#D32F2F' },
  },
  data () {
    return {
      // Internal: Whether the color picker dropdown is expanded.
      expanded: false,
    }
  },
  computed: {
    validationErrors () {
      return !this.value || isHexColor(this.value) ? null : 'Invalid color'
    },
    // Internal: To avoid displaying `#` in the text input.
    hexValue () {
      return this.value && this.value.replace(/^#/, '')
    },
  },
  watch: {
    // Internal: Propagate whether the value is valid or not.
    validationErrors (errors) {
      this.$emit('input:customErrors', errors)
    },
  },
  methods: {
    closeColorPicker () {
      this.expanded = false
    },
    // Internal: Sets the specified color and closes the color picker.
    selectColor (hexColor) {
      this.setColor(hexColor)
      this.closeColorPicker()
    },
    // Internal: Propagates the selected color in hexadecimal code.
    setColor (hexColor) {
      this.$emit('input', hexColor && hexColor.toUpperCase())
    },
    // Internal: Allows pasting a color that starts with #.
    onColorTextInput (event) {
      const value = event.target.value.trim()
      const prefixedValue = `#${value}`
      return this.setColor(isHexColor(prefixedValue) ? prefixedValue : value || null)
    },
  },
}
</script>

<template>
  <div v-hotkey="expanded && { 'esc': closeColorPicker }" class="color-input" :data-value="value">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <div class="color-picker-wrapper">
      <Dropdown v-model="expanded" position="left" contentClass="color-input__dropdown-content" :closeOnClick="false">
        <template slot="dropdownToggle">
          <button
            class="input selected-color"
            type="button"
            :disabled="disabled"
            :class="{ expanded, error }"
            :style="{ background: value }"
            @click="expanded = !expanded"
          >
            <div v-if="!value" class="no-color-line"/>
          </button>
        </template>

        <template slot="dropdownContent">
          <ColorSwatches :value="value" @input="selectColor"/>
          <template v-if="allowCustomColors">
            <Label class="custom-color-label">Custom</Label>
            <input
              :value="value || defaultCustomColor"
              type="color"
              class="input custom-color-input"
              @input="setColor($event.target.value)"
            >
          </template>
          <div class="color-swatches__overflow"/>
        </template>
      </Dropdown>
      <div v-if="!hideHexInput" class="color-hex-input-wrapper">
        <input
          :class="{ error }"
          :disabled="disabled"
          :value="hexValue"
          :placeholder="value ? null : 'None'"
          type="text"
          class="input color-hex-input"
          @input="onColorTextInput"
        >
        <Button
          v-if="!disabled && !required && value"
          icon="remove"
          compact
          class="clear-color-button"
          name="Unselect Color"
          @click="setColor(null)"
        />
      </div>
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
@include input;

$dropdown-padding: 12px;

.color-input ::v-deep .color-input__dropdown-content {
  height: 240px;
  overflow-y: scroll;
  padding: $dropdown-padding $dropdown-padding 0;
  width: 320px;
}

.color-swatches__overflow {
  background: $bg-gradient-overflow;
  bottom: 0;
  height: $dropdown-padding;
  margin: 0 (-$dropdown-padding);
  position: sticky;
}

.input {
  height: 32px;
}

.selected-color {
  @include clickable;

  height: 24px;
  overflow: hidden;
  position: relative;
  width: 24px;

  &.expanded {
    border-color: $input-border-color-active;
  }
}

.no-color-line {
  border-bottom: 1px solid $cabaret-l-0;
  bottom: 0;
  height: 32px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%) translateX(-50%) rotate(-45deg);
  width: 32px;
}

.color-picker-wrapper {
  display: flex;
}

.color-hex-input-wrapper {
  margin-left: 4px;
  position: relative;
}

.clear-color-button {
  position: absolute;
  right: 5px;
  top: 6px;
}

.custom-color-label {
  margin-top: 16px;
}

.custom-color-input {
  cursor: pointer;
}
</style>
