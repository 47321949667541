<script>
import InputMixin from '@mixins/InputMixin'

// Based off of http://codepen.io/BandarRaffah/pen/ibwje
export default {
  name: 'BooleanSlider',
  mixins: [ InputMixin ],
  props: {
    // Public: Determines whether the boolean is "checked" or not.
    value: { type: Boolean, default: false },
    // Public: Label to be shown when the value is "true".
    yesLabel: { type: [String, Boolean], default () { return this.label } },
    // Public: Label to be shown when the value is "false".
    noLabel: { type: [String, Boolean], default () { return this.label } },
    // Public: Whether to show the label before the slider as opposed to the default after.
    labelPosition: { type: String, default: 'right', validator: v => v === 'right' || v === 'left' },
  },
  computed: {
    displayedLabel () {
      return this.value ? this.yesLabel : this.noLabel
    },
  },
  methods: {
    onChange (event) {
      this.$emit('input', event.target.checked)
    },
  },
}
</script>

<template>
  <label class="boolean-slider" :class="{ checked: value, disabled, error }">
    <input
      class="boolean-slider__input"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @click="onChange"
    >
    <div class="boolean-slider__track">
      <div class="boolean-slider__knob"/>
    </div>

    <div class="boolean-slider__label" :class="{ 'boolean-slider__label--before': labelPosition === 'left', 'with-label': displayedLabel }">
      <div class="label">
        <span v-if="displayedLabel">{{ displayedLabel }}</span>
        <Hint :content="hint"/>
      </div>
      <InputError v-if="error" :error="error"/>
    </div>
  </label>
</template>

<style lang="scss" scoped>
.boolean-slider__input {
  display: none;
}

.boolean-slider {
  @include clickable;

  align-items: center;
  display: flex;
  margin: 0;
  position: relative;
  user-select: none;
}

$label-margin: 12px;

.boolean-slider__label {
  @include clickable;

  &.with-label {
    margin-left: $label-margin;
  }

  .boolean-slider.disabled & {
    color: $input-disabled-color;
  }

  .boolean-slider.error & {
    color: $input-error-color;
  }
}

.boolean-slider__label .label {
  align-items: center;
  display: inline-flex;
  font-size: $fs-label;
  font-weight: $fw-regular;
}

.boolean-slider__label--before {
  margin-left: 0;
  margin-right: $label-margin;
  order: -1;
}

.boolean-slider__track {
  background-color: $WHITE;
  border: 1px solid $input-border-color;
  border-radius: 999px;
  display: inline-block;
  height: 25px;
  padding: 2px 2px 3px 2px;
  transition: background-color 0.3s ease-out 0s;
  vertical-align: middle;
  width: 45px;

  .boolean-slider.checked & {
    background-color: $cipher-green-l-0;
    border-color: $cipher-green-l-0;
  }

  .boolean-slider.disabled & {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
  }

  .boolean-slider.error & {
    background: $input-error-background-color;
    border-color: $input-error-color;
  }
}

.boolean-slider__knob {
  background: $unselected-boolean-slider-color;
  border-radius: inherit;
  height: 19px;
  pointer-events: none;
  transition: all 0.25s ease-out 0s;
  width: 19px;

  .boolean-slider.checked & {
    background: $WHITE;
    transform: translateX(21px);
  }

  .boolean-slider.disabled & {
    background: $input-disabled-border-color;
  }

  .boolean-slider.error & {
    background: $input-error-color;
  }
}

.boolean-slider:not(.disabled):hover .boolean-slider__knob {
  transform: translateX(2px);
}

.boolean-slider.checked:not(.disabled):hover .boolean-slider__knob {
  transform: translateX(19px);
}
</style>
