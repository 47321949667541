<script>
import InputMixin from '@mixins/InputMixin'
// eslint-disable-next-line local/no-global-component-imports
import Checkbox from '@components/global/Checkbox'

import { toggleBy } from '@helpers/ArrayHelper'

export default {
  name: 'Checkboxes',
  components: {
    Checkbox,
  },
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Selected item or list of selected items that populate the input.
    value: { type: Array, default: () => [] },

    // Public: Items that can be selected and will be displayed each as checkbox.
    items: { type: Array, default: null },

    // Public: Item property to use when displaying items as checkboxes.
    labelProp: { type: String, default: 'label' },

    // Public: Item property to use as the internal item value (and input id).
    valueProp: { type: String, default: 'value' },

    // Public: Displayed when no items are available as checkboxes.
    emptyItemsMessage: { type: String, default: 'No items available' },

    // Public: Whether to display a loading placeholder until items are provided.
    placeholderItems: { type: Number, default: 0 },
  },
  computed: {
    // Public: Whether the items have been loaded, but they are empty.
    shouldDisplayNoItems () {
      return this.items && this.items.length === 0
    },
    // Internal: We don't want to require the value to be present.
    values () {
      return this.value || []
    },
  },
  methods: {
    // Internal: Propagates the change event as an input event.
    // NOTE: We listen to "change" for compatibility with old browsers.
    onChange (item) {
      const itemValue = item[this.valueProp]
      this.$emit('input', toggleBy(this.values, itemValue))
    },
    isSelected (item) {
      return this.values.includes(item[this.valueProp])
    },
  },
}
</script>

<template>
  <div class="checkboxes">
    <Label v-if="label" :required="required" :hint="hint">{{ label }}</Label>
    <template v-if="items">
      <Checkbox
        v-for="item in items"
        :key="`${item[labelProp]}-${item[valueProp]}`"
        :value="isSelected(item)"
        :disabled="disabled || item.disabled"
        :label="item[labelProp]"
        @input="onChange(item, $event)"
      >
        <template slot="inputLabel">
          <slot :item="item" :label="item[labelProp]" name="inputLabel">{{ item[labelProp] }}</slot>
        </template>
      </Checkbox>
      <span v-if="shouldDisplayNoItems" class="checkboxes__empty-message" v-text="emptyItemsMessage"/>
    </template>
    <LoadingPlaceholder v-else :size="placeholderItems"/>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
.checkboxes > .input-error {
  margin-top: 12px;
  position: static;
}

.checkbox {
  margin-top: 12px;
}
</style>
