import { each, isEqual } from 'lodash'

function inserted (el, { value: handlersByEventName }) {
  // To simplify wrapping and cleanup, we create a single generic wrapper.
  el._outsideHandler = event => {
    if (!event.defaultPrevented && !el.contains(event.target)) handlersByEventName[event.type](event, el)
  }
  // And we attach it to all events that should be listened, ignoring empty handlers.
  each(handlersByEventName, (handler, eventName) => {
    if (handler) document.addEventListener(eventName, el._outsideHandler)
  })
}

function update (el, { value, oldValue }) {
  if (!isEqual(value, oldValue)) {
    unbind(el, { value: oldValue })
    inserted(el, { value })
  }
}

function unbind (el, { value: handlersByEventName }) {
  each(handlersByEventName, (handler, eventName) => {
    if (handler) document.removeEventListener(eventName, el._outsideHandler)
  })
  delete el._outsideHandler
}

export default {
  inserted,
  update,
  unbind,
}
