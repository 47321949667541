import { assign } from 'lodash'
import { getVueInstance, getVueNode } from '@helpers/NodeHelper'
import { presence } from '@helpers/ObjectHelper'

// Public: Returns an event handler registered in Vue for the specified event.
export function getVueEventHandler (el, eventName) {
  const vNode = getVueNode(getVueInstance(el))
  return getVueListeners(vNode)[eventName]
}

// Public: Returns the listeners registered for a Vue component.
export function getVueListeners (vNode) {
  if (!vNode) return {}
  return presence(vNode.componentOptions && vNode.componentOptions.listeners) ||
    presence(vNode.context.$listeners) ||
    presence(vNode.data.on) ||
    {}
}

export function createNewEvent (eventName, { bubbles, cancelable, ...options } = {}) {
  let event
  if (typeof (Event) === 'function') {
    event = new Event(eventName, { bubbles, cancelable })
  } else {
    event = document.createEvent('CustomEvent')
    event.initEvent(eventName, bubbles, cancelable)
  }
  return assign(event, options)
}

export function preventDefaultAndStopPropagation (e) {
  e.preventDefault()
  e.stopPropagation()
}
