<script>
import AuthStore from '@stores/AuthStore'
import LocalStorage from '@services/LocalStorageService'
import NavigationBarAccountMenu from '@components/NavigationBarAccountMenu'
import NavigationBarCareProvidersMenu from '@components/NavigationBarCareProvidersMenu'
import NavigationBarDropdownMenu from '@components/NavigationBarDropdownMenu'
import NavigationBarHelpMenu from '@components/NavigationBarHelpMenu'
import NavigationBarIcon from '@components/NavigationBarIcon'
import NavigationBarModalMenu from '@components/NavigationBarModalMenu'
import NavigationBarNotificationsMenu from '@components/NavigationBarNotificationsMenu'
import NavigationBarQuicksearchMenu from '@components/NavigationBarQuicksearchMenu'

import AdminControlPanel from '@components/AdminControlPanel'
import UserImpersonationWidget from '@components/UserImpersonationWidget'

import ManageMenuItems from '@constants/ManageMenuItems'
import WindowStore from '@stores/WindowStore'

import { flatMap } from 'lodash'

const MAX_OPTIONS_FOR_MANAGE_DROPDOWN = 7

export default {
  name: 'TheNavigationBar',
  components: {
    AdminControlPanel,
    UserImpersonationWidget,
    NavigationBarAccountMenu,
    NavigationBarModalMenu,
    NavigationBarDropdownMenu,
    NavigationBarIcon,
    NavigationBarNotificationsMenu,
    NavigationBarHelpMenu,
    NavigationBarCareProvidersMenu,
    NavigationBarQuicksearchMenu,
  },
  constants: {
    MAX_OPTIONS_FOR_MANAGE_DROPDOWN,
  },
  computed: {
    ...AuthStore.mapState('currentUser', 'navigationBar'),
    manageMenu () {
      return { title: 'Manage', hotkey: 'm', groups: this.manageMenuAllowedGroups }
    },
    manageMenuAllowedGroups () {
      return ManageMenuItems.map(group => ({
        name: group.group,
        items: group.items.filter(item => {
          if (!this.hasPermission(item.permission)) {
            return false
          }

          if (item.if && !item.if(AuthStore.careProvider)) {
            return false
          }

          return true
        }),
      })).filter(group => group.items.length > 0)
    },
    minimizedManageMenu () {
      const submenu = flatMap(this.manageMenuAllowedGroups, 'items')
      return { title: 'Manage', hotkey: 'm', submenu }
    },
  },
  mounted () {
    // Removes the "fake" navbar rendered in vue.html.haml
    const fakeNavbar = document.getElementById('fake_navbar')
    if (fakeNavbar) fakeNavbar.remove()
  },
  methods: {
    updateNavbarHeight ({ contentRect: { height } }) {
      WindowStore.setNavbarHeight(height)
    },
    removeLocalStorage () {
      LocalStorage.clear()
    },
  },
}
</script>

<template>
  <nav v-on-resize="updateNavbarHeight" class="navigation-bar evolve-navigation slick">
    <NavigationLink to="/" class="logo">
      <Icon name="cipher"/>
    </NavigationLink>

    <!-- NOTE: Move these two to App once we migrate all Rails pages and drop the evolve/navbar.js pack -->
    <UserImpersonationWidget/>
    <AdminControlPanel v-if="navigationBar.controlPanel" :controlPanel="navigationBar.controlPanel"/>

    <div v-if="navigationBar.menus" class="menus">
      <NavigationBarDropdownMenu
        v-for="menu in navigationBar.menus"
        :key="menu.title"
        :name="menu.title"
        :menu="menu"
        class="menu"
      />
      <NavigationBarModalMenu
        v-if="minimizedManageMenu.submenu.length > MAX_OPTIONS_FOR_MANAGE_DROPDOWN"
        :menu="manageMenu"
        class="menu"
        name="Manage"
      />
      <NavigationBarDropdownMenu v-else :menu="minimizedManageMenu" class="menu" name="Manage"/>
    </div>
    <div class="auxiliary-items">
      <NavigationBarCareProvidersMenu v-if="navigationBar.careProviders" class="care-providers-dropdown" :menu="navigationBar.careProviders"/>
      <NavigationBarIcon v-if="hasPermission('internal_only')" to="TheLab" icon="lab" title="The Lab" class="the-lab-navbar-icon"/>
      <NavigationBarNotificationsMenu/>
      <NavigationBarQuicksearchMenu v-if="hasPermission('view_patient_details')"/>
      <NavigationBarHelpMenu/>
      <NavigationBarAccountMenu :navbar="navigationBar"/>
      <NavigationBarIcon key="logout" :to="evolveUrl('users/sign_out')" title="Logout" method="delete" @click.native="removeLocalStorage"/>
    </div>
  </nav>
</template>

<style lang="scss" scoped>
$single-row-breakpoint: $navbar-breakpoint;

.logo {
  @include ie11 {
    height: $height-navbar;
  }

  fill: $WHITE;
  grid-area: logo;
  height: $width-logo;
  position: relative;
  width: $width-logo;
}

.menus {
  display: flex;
  grid-area: menus;
  height: $height-navbar;
}

.auxiliary-items {
  align-items: center;
  display: flex;
  grid-area: icons;
  -ms-grid-column-align: end;
  height: $height-navbar;
  justify-self: end;
}

.navigation-bar {
  align-items: center;
  background: $bg-gradient-nav;
  box-shadow: 0 2px 2px $tertiary-color;
  color: $WHITE;
  display: grid;
  font-size: $fs-html;
  grid-template:
    "logo icons" auto
    "menus menus" auto /
    $width-logo 1fr;
  padding: 0 $padding-horizontal-navbar;
  user-select: none;

  .menu {
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
  }

  & ::v-deep .blur-area {
    @include z-index(above-sticky-title-section);
  }

  & ::v-deep .dropdown__content {
    background-color: $bg-card;
  }
}

@include media(only-tablet) {
  .the-lab-navbar-icon {
    display: none;
  }
}

@include media-min($single-row-breakpoint) {
  .navigation-bar {
    grid-template:
      "logo menus icons" auto /
      $width-logo 1fr auto;
  }

  .menus {
    padding: 0 $padding-horizontal-navbar;
  }

  .menu {
    max-width: 100px;
  }
}

@include media-min($care-providers-breakpoint) {
  .menu {
    max-width: 128px;
  }
}

.navigation-bar .dropdown.use-inset-shadow {
  height: 100%;

  ::v-deep .dropdown-toggle.expanded {
    @include z-index(dropdown);
    @include inset-shadowy;
  }
}
</style>
