<script>
import InputMixin from '@mixins/InputMixin'
import { toggleBy } from '@helpers/ArrayHelper'

export default {
  name: 'BoxGroupSelectInput',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Items that can be selected and will be displayed as buttons.
    items: { type: Array, required: true },

    // Public: Item property to use when displaying items as button groups.
    labelProp: { type: String, default: 'label' },

    // Public: Item property to use when appending a description to each button
    descriptionProp: { type: String, default: 'description' },

    // Public: Selected item that populates the input.
    value: { type: [String, Number, Array], default: null },

    // Public: Item property to use as the internal item value (and input id).
    valueProp: { type: String, default: 'value' },

    // Public: Makes the control single row and stretches the items to full width
    singleRow: { type: Boolean, default: false },

    // Public: Allow multiple selection mode
    multi: { type: Boolean, default: false },

    // Public: Show a `none` option with empty value as first. Works only for single select
    showNone: { type: Boolean, default: false },

    // Public: Show a description for each item, using the description prop
    showDescription: { type: Boolean, default: false },

    // Public: Option that allows a user to unselect if they press the selected item
    allowUnselect: { type: Boolean, default () { return this.multi } },

    // Public: Grid template columns used for display the boxes that hold the options
    gridTemplateColumns: { type: String, required: true },

    // Public: Grid template rows used for display the boxes that hold the options
    gridTemplateRows: { type: String, required: true },

  },
  computed: {
    values () {
      return this.value || []
    },
  },
  methods: {
    // Public: Selects or unselects the specified item and emits the change.
    toggleItem (item) {
      if (this.disabled || (this.isSelected(item) && !this.allowUnselect)) return

      const itemValue = item[this.valueProp]

      const newValue = this.multi
        ? toggleBy(this.values, itemValue)
        : (itemValue === this.value ? null : itemValue)

      this.$emit('input', newValue)
    },
    selectNone () {
      if (!this.multi) this.$emit('input', null)
    },
    isSelected (item) {
      if (item === null) return this.value === null
      const itemValue = item[this.valueProp]
      return this.multi ? this.values.includes(itemValue) : this.value === itemValue
    },
  },
}
</script>

<template>
  <div class="box-select">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <div class="box-select__input-wrapper">
      <div class="box-select__options" :style="{ gridTemplateRows, gridTemplateColumns }">
        <div
          v-if="showNone && !multi"
          :class="{ disabled, selected: isSelected(null) }"
          class="box-select__option"
          @click="selectNone"
        >
          None
        </div>
        <div
          v-for="item in items"
          :key="`${item[labelProp]}-${item[valueProp]}`"
          :class="{ disabled, selected: isSelected(item), 'box-select__option--multi': multi }"
          class="box-select__option"
          @click="toggleItem(item)"
        >
          <Checkbox v-if="multi" class="box-select__option__checkbox" light :value="isSelected(item)"/>
          {{ item[labelProp] }}

          <div v-if="showDescription" class="box-select__option__description">
            {{ item[descriptionProp] }}
          </div>
        </div>
        <slot/>
      </div>
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>

.box-select__option__description {
  color: $fc-html-light;
  font-size: $fs-secondary;
  margin-top: 8px;
}

.box-select__options {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
}

.box-select__option {
  @include clickable;
  @include bordered-box;

  border-width: 1px;
  padding: 8px 16px;
  position: relative;

  &.box-select__option--multi {
    padding-left: 32px;
  }

  .box-select__option__checkbox {
    left: 6px;
    margin-top: 1px;
    pointer-events: none;
    position: absolute;
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background: $tundora-l-4;
    border-color: $input-border-color-active;
  }

  &.selected {
    background: $tertiary-color;
    border-color: $input-border-color-active;
  }

  &.disabled {
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
  }

  &.disabled.selected {
    border-color: $input-disabled-border-color;
  }
}
</style>
