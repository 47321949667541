import ArraysMap from '@classes/ArraysMap'
import NavigationStrategy from '@constants/NavigationStrategy'
import { ensureId, isTypable } from '@helpers/DomHelper'
import { eventKeyboardShortcut, toKeyboardShortcut } from '@helpers/KeyboardShortcutHelper'
import { find, isEqual, mapKeys } from 'lodash'
import { parentHasScrollbar, scrollIntoView } from '@helpers/ScrollHelper'

// Default actions that are handled by the service.
const defaultActions = {
  focusOnMouseover: true,
  up: true,
  down: true,
  left: false,
  right: false,
  enter: true,
  select: false,
  remove: false,
  next: false,
  previous: false,
}

// Map each keystroke to a navigation action.
const keyToAction = mapKeys({
  'up': 'up',
  'down': 'down',
  'left': 'left',
  'right': 'right',
  'enter': 'enter',
  'space': 'select',
  'del': 'remove',
  'tab': 'next',
  'shift+tab': 'previous',
}, (value, key) => toKeyboardShortcut(key))

// Keep track of which services are active, indexed by element. Only the last
// one will handle
const activeNavigationServices = new ArraysMap()

export default class KeyboardNavigationService {
  navigationItems = {}
  currentItem = undefined

  constructor ($vm, actionsConfig) {
    if ($vm) {
      $vm.$once('hook:mounted', () => this.setup($vm.$el, actionsConfig))
      $vm.$once('hook:beforeDestroy', () => this.teardown())
    }
  }

  // Public: Ensures this navigation service is the active one for the container or window.
  prioritize () {
    activeNavigationServices.moveToLast(this.keyboardContainer.id, this)
  }

  // Public: Focuses the preferred item or the first one.
  focusPreferredItemOrFirst () {
    const preferredItem = find(this.navigationItems, item => item.options.prefer)
    preferredItem ? this.navigateTo(preferredItem) : this.focusFirstItem()
  }

  // Public: Focuses the first navigation item.
  focusFirstItem () {
    this.navigateTo(this.getFirstNavigationItem(this.navigationItems))
  }

  // Public: Focuses the navigation item with a specific id.
  focusItemById (id) {
    this.navigateTo(this.getNavigationItemById(id))
  }

  // Public: Blurs the current navigation item, if any.
  blurCurrentItem () {
    if (this.currentItem) this.currentItem.blur()
  }

  // Directive: Retrieves a registered navigation item for the specified element.
  getNavigationItemFor (el) {
    if (el && el.id) return this.getNavigationItemById(el.id)
  }

  // Directive: Adds the specified navigation item to the focusable set.
  registerNavigationItem (item) {
    this.navigationItems[item.id] = item
  }

  // Directive: Removes the specified navigation item from the focusable set.
  unregisterNavigationItem (item) {
    delete this.navigationItems[item.id]
    item.destroy()
    if (this.currentItem === item) this.currentItem = undefined
  }

  // Internal: Clicks the current item (if any). Works with native listeners.
  clickCurrentItem () {
    if (this.currentItem) this.currentItem.enter()
  }

  // Internal: Attempts to navigate to a registered navigation item.
  navigateTo (item, { scroll = this.scrollOnNavigation } = {}) {
    if (item) {
      if (item.options && item.options.onlyForParentScroll && !parentHasScrollbar(item.$el)) { return }
      if (item !== this.currentItem) {
        this.blurCurrentItem()
        item.focus()
        this.currentItem = item
      }
      if (item && scroll) scrollIntoView(item.$el, { animate: false })
    }
  }

  // Navigation: Move focus to the navigation item placed on the left of this one.
  left () {
    return this.performNavigationAction('left', this.previousNavigationItemFor)
  }

  // Navigation: Move focus to the navigation item placed on the right of this one.
  right () {
    return this.performNavigationAction('right', this.nextNavigationItemFor)
  }

  // Navigation: Move focus to the navigation item placed above this one.
  up () {
    return this.performNavigationAction('up', this.previousNavigationItemFor)
  }

  // Navigation: Move focus to the navigation item placed below this one.
  down () {
    return this.performNavigationAction('down', this.nextNavigationItemFor)
  }

  // Navigation: Move focus to the navigation item placed before this one.
  previous () {
    return this.performNavigationAction('previous', this.previousNavigationItemFor)
  }

  // Navigation: Move focus to the navigation item placed after this one.
  next () {
    return this.performNavigationAction('next', this.nextNavigationItemFor)
  }

  // Internal: Handles a directional navigation action.
  performNavigationAction (direction, autoNavigation) {
    const navigationItem = this.currentItem
    const id = navigationItem.getNavigationStrategyFor(direction)
    const nextItem = id === NavigationStrategy.AUTO
      ? autoNavigation.call(this, navigationItem)
      : this.getNavigationItemById(id)

    if (nextItem) {
      this.navigateTo(nextItem)
      return navigationItem.emit('keyboardNavigation', { direction, nextItem })
    }
  }

  // Internal: Returns the first navigation element that should be focused when
  // no item has focus. Optimized for performance.
  getFirstNavigationItem () {
    for (const id in this.navigationItems) {
      let currentItem = this.getNavigationItemById(id)
      let firstFocusableItem = null
      while (currentItem) {
        if (!currentItem.options.skip) firstFocusableItem = currentItem
        currentItem = this.previousNavigationItemFor(currentItem)
      }
      if (firstFocusableItem) return firstFocusableItem
    }
  }

  // Internal: Finds a navigation item that is registered for an HTML element
  // with the specified id.
  getNavigationItemById (id) {
    return this.navigationItems[id]
  }

  // Internal: Returns the navigation item that comes after the specified one.
  // NOTE: For now, we only support navigation between sibling elements.
  nextNavigationItemFor (item) {
    let el = item.$el
    while (el) {
      const item = this.getNavigationItemFor(el = el.nextElementSibling)
      if (item) return item
    }
  }

  // Internal: Returns the navigation item that comes before the specified one.
  // NOTE: For now, we only support navigation between sibling elements.
  previousNavigationItemFor (item) {
    let el = item.$el
    while (el) {
      const item = this.getNavigationItemFor(el = el.previousElementSibling)
      if (item) return item
    }
  }

  // Internal: Called on keydown, detecs the conditions under which a navigation
  // action should be performed, and stops event propagation if needed.
  onKeyDown (keyboardOptions, event) {
    if (!this.isActiveFor(event)) return

    // Skip if the pressed key is not a navigation shortcut for one of the
    // configured actions (see the defaults in keyboardDefaults).
    const action = keyboardOptions.keyToAction[eventKeyboardShortcut(event)]
    if (!keyboardOptions[action]) return

    // Avoid navigation if using global keystrokes and typing on an input.
    if (this.windowKeystrokes && isTypable(event.target)) return

    event.preventDefault() // Could cause scrolling or submit.

    if (this.currentItem) {
      // Perform the navigation action by calling a directional handler defined
      // in the service, or an action handler defined in NavigationItem.
      this[action] ? this[action](event) : this.currentItem[action](event)
    } else {
      // By default, if no navigation item is selected, focus the first one.
      this.focusFirstItem()
    }
  }

  // Internal: Find the navigation item that matches or is an ancestor of the
  // specified element.
  findNavigationItemForNodeOrAncestor (el) {
    if (!el || el === this.mouseContainer) return undefined
    return this.getNavigationItemFor(el) || this.findNavigationItemForNodeOrAncestor(el.parentNode)
  }

  // Internal: Adds a mouse event listener and passes a NavigationItem to it.
  addMouseHandler (eventName, handler) {
    this.mouseContainer.addEventListener(eventName, event => {
      if (!this.isActiveFor(event)) return

      const currentXY = { x: event.x, y: event.y }
      // Prevent mouseover navigation when scrolling the window.
      if (this.lastXY && !isEqual(currentXY, this.lastXY)) {
        const item = this.findNavigationItemForNodeOrAncestor(event.target)
        if (item) handler(item)
      }
      this.lastXY = currentXY
    })
  }

  // Internal: Allows to avoid navigation if an navigation service for the
  // same container and event was added later.
  //
  // NOTE: For now we have decided that only one navigation service can be
  // active at the same time for a given container.
  isActiveFor (event) {
    return !event.defaultPrevented &&
      this.keyboardContainer &&
      activeNavigationServices.isLast(this.keyboardContainer.id, this)
  }

  // Public: Configures the navigation service to target a particular container,
  // enabling the specified events for navigation.
  setup (container, actionsConfig) {
    const {
      windowKeystrokes,
      focusOnMouseover,
      scrollOnNavigation = !windowKeystrokes,
      ...keyboardActions
    } = { ...defaultActions, ...actionsConfig }

    this.windowKeystrokes = windowKeystrokes
    this.scrollOnNavigation = scrollOnNavigation

    // Setup the necessary logic so that mouse hovering plays well with keyboard navigation.
    this.mouseContainer = container
    if (focusOnMouseover) {
      this.addMouseHandler('mouseover', item => this.navigateTo(item, { scroll: false }))
    }

    // Register the navigation service to allow only one per element (or window).
    this.keyboardContainer = this.windowKeystrokes ? window : ensureId(container, 'keyboard_navigation')
    activeNavigationServices.push(this.keyboardContainer.id, this)

    // Combine any custom keystrokes that are provided with the default ones.
    keyboardActions.keyToAction = {
      ...keyToAction,
      ...mapKeys(keyboardActions.keyToAction, (value, key) => toKeyboardShortcut(key)),
    }

    // Bind the handler to allow easy removal in teardown.
    this.onKeyDown = this.onKeyDown.bind(this, keyboardActions)
    this.keyboardContainer.addEventListener('keydown', this.onKeyDown)
  }

  // Internal: Cleans up any state related to a Vue instance.
  teardown () {
    // Unregister the event listener and remove from the global registry.
    if (this.keyboardContainer) {
      this.keyboardContainer.removeEventListener('keydown', this.onKeyDown)
      activeNavigationServices.remove(this.keyboardContainer.id, this)
    }

    // Standard cleanup to help the garbage collector.
    this.navigationItems = {}
    this.currentItem = undefined
    this.mouseContainer = undefined
    this.keyboardContainer = undefined
  }
}
