/**
* Helpers for date and time zone manipulation, based on date-fns and date-fns-tz
**/
import AuthStore from '@stores/AuthStore'
import { parse as dateFnsParse, parseISO as dateFnsParseISO, isValid, format as notTimeZoneAwareFormat } from 'date-fns'
import { endsWith, isDate, isString } from 'lodash'
import { formatForAlias, isPlainDateFormat } from '@helpers/DateFormatHelper'
import { format as timeZoneAwareFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'

// matches dates in the ISO 8601 format:
// 2018-09-27, 2018-09-27T18:43, 2018-09-27T18:43:30Z
const DATE_REGEX = /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])(T(2[0-3]|[01][0-9]):([0-5][0-9])(:([0-5][0-9]))?(\.[0-9]+)?Z?)?/
const TIME_ZONE_REGEX = /(-|\+)[0-5][0-9]:[0-5][0-9]Z?$/

// Feature Detection: IE11 does not support time zone names.
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
let supportsTimeZones = Boolean(userTimeZone)
const dateFnsFormat = supportsTimeZones ? timeZoneAwareFormat : notTimeZoneAwareFormat

// Public: Returns a UTC-based date from a date with time.
export function asPlainDate (dateTime) {
  return parsePlainDate(formatDate(dateTime, { format: 'date_iso' }))
}

// Public: Formats an ISO8601 date string or a Date object in the specified format.
//
// date - Date object or ISO8601 date string
// format - Format to be applied
// defaultValue - The value to return in case the date is empty
// shiftTimeZone - Boolean to determine if returned value needs a timeZone shift
//
// Returns a String with the date formatted as specified.
export function formatDate (dateStr, { defaultValue = '', format = 'date_time_timezone', timeZone, shiftTimeZone = true, ...options } = {}) {
  if (!dateStr) return defaultValue

  const asDate = isPlainDateFormat(format)
  const date = isString(dateStr) ? asDate ? parsePlainDate(dateStr) : parseDateTime(dateStr) : dateStr

  // NOTE: Since in JSON dates are UTC-based, we need to make an exception.
  timeZone = isString(dateStr) && asDate ? 'UTC' : timeZone
  const dateForDisplay = shiftTimeZone ? shiftTimeZoneToDisplay(date, timeZone) : date
  options.timeZone = convertTimeZone(timeZone)
  return dateFnsFormat(dateForDisplay, formatForAlias(format), options)
}

// Public: Checks if the specified date of the year is happening today.
export function isAnniversary (dateStr) {
  if (!dateStr) return false
  const date = parsePlainDate(dateStr)
  const today = asPlainDate(new Date())
  return today.getDate() === date.getDate() && today.getMonth() === date.getMonth()
}

// Public: Checks if the date is in the past.
export function isPast (value) {
  return toDate(value) < new Date()
}

// Public checks is a date is valid using with regular expression
// We cannot use isValid from date-fns as it would return true for any string containing a number
export function isValidDate (value) {
  return isDate(value) || DATE_REGEX.test(value)
}

// Public: Checks if a date is a strict ISO date with time zone information.
export function isStrictISODate (dateStr) {
  if (!isString(dateStr)) return false
  if (!DATE_REGEX.test(dateStr)) return false
  if (containsTimeInfo(dateStr) && !includesTimeZone(dateStr)) return false
  return true
}

// Public: Checks if a date is a strict ISO date with time zone information.
export function containsTimeInfo (dateStr) {
  return isString(dateStr) && dateStr.includes(':') && !endsWith(dateStr, 'T00:00:00.000Z') && !endsWith(dateStr, 'T00:00:00.000+0000')
}

// Public: Strict ISO date parser, removes time information from an ISO string.
//
// Returns a Date which is at 00:00 in UTC (similar to the JSON for a Ruby date)
// or fails if the date is not valid.
export function parsePlainDate (dateStr, { format = 'date_iso' } = {}) {
  if (!isString(dateStr)) throw new Error(`Invalid Date: ${dateStr}, expected a string`)
  const date = dateFnsParseISO(removeTime(dateStr))
  if (!isValid(date)) throw new Error(`Unable to parse date: ${dateStr}, expected and ISO string`)
  return shiftFromLocalToZone(date, 'UTC')
}

// Public: Strict ISO DateTime parser, requires the string to provide a time zone.
//
// Returns a Date, or fails if the date is not valid.
export function parseDateTime (dateStr) {
  if (!isString(dateStr)) throw new Error(`Invalid DateTime: ${dateStr}, expected a string`)
  if (!includesTimeZone(dateStr)) throw new Error(`Invalid DateTime: ${dateStr}, the string does not include a time zone`)
  const date = dateFnsParseISO(dateStr)
  if (!isValid(date)) throw new Error(`Unable to parse date: ${dateStr}, expected and ISO string`)
  return date
}

// Public: Shifts a date so that when formatted in the browser's local time, the
// displayed date and time are the ones corresponding to the specified time zone.
export function shiftTimeZoneToDisplay (date, timeZone) {
  if (!supportsTimeZones) return date
  return utcToZonedTime(date, convertTimeZone(timeZone))
}

// Public: Shifts a date specified in the browser's local time so that it works
// as if the same date and time were selected in the specified time zone.
export function shiftFromLocalToZone (date, timeZone) {
  if (!supportsTimeZones) return date
  return zonedTimeToUtc(date, convertTimeZone(timeZone))
}

// Public: Returns a Date object, using the default browser parsing.
export function toDate (dateStr, options) {
  return new Date(dateStr)
}

// Public: Returns an ISO String for the specified Date object.
export function toISOString (date, options) {
  const dateStr = date.toISOString()
  return options.dateOnly ? removeTime(dateStr) : dateStr
}

// Public: Returns a Date object, expects the string to be in ISO8601 format, or
// in the standard US format by default.
//
//  - format - Can be `date` or `date_time
export function tryParseDate (dateStr, options) {
  if (options.format === 'date') try { return parsePlainDate(dateStr) } catch { }
  if (options.format === 'date_time') try { return parseDateTime(dateStr) } catch { }
  return tryParse(dateStr, options)
}

// Internal: Light encapsulation around date-fns parse method, but returning
// null instead of an invalid date, and shifting the time zone as needed if
// parsing locally.
function tryParse (dateStr, { format, timeZone, referenceDate = new Date() }) {
  const date = dateFnsParse(dateStr, formatForAlias(format), referenceDate)
  if (!isValid(date)) return null
  return shiftFromLocalToZone(date, isPlainDateFormat(format) ? 'UTC' : timeZone)
}

// Internal: The current care provider's time zone.
export function careProviderTimeZone () {
  return AuthStore.careProvider[supportsTimeZones ? 'timeZone' : 'timeZoneOffset']
}

// Internal: Converts our shortcuts to the actual time zone.
function convertTimeZone (timeZone) {
  if (!timeZone || timeZone === 'careProviderTimeZone') return careProviderTimeZone()
  if (timeZone === 'userTimeZone') return userTimeZone
  return timeZone
}

// Internal: Returns true if the string contains time zone information.
function includesTimeZone (dateStr) {
  return dateStr.includes('Z') || TIME_ZONE_REGEX.test(dateStr)
}

// Internal: Removes the timezone of a Date string.
function removeTime (value) {
  return value ? value.split('T')[0] : value
}
