<script>
export default {
  name: 'Label',
  props: {
    // Public: Specifies if the input is required
    required: { type: Boolean, default: false },

    // Public: Hint to display as tooltip next to the label
    hint: { type: [String, Boolean], default: false },
  },
}
</script>

<template>
  <div class="label">
    <label class="label-text">
      <slot/>
      <span v-if="required" v-tooltip="translate('global.general.required')" class="label-required">*</span>
    </label>
    <Hint :content="hint"/>
    <slot name="labelExtras"/>
  </div>
</template>

<style lang="scss" scoped>
.label-text {
  font-size: $fs-label;
  font-weight: $fw-regular;
  line-height: $lh-label;
  margin-bottom: 0;
}

.label-required {
  @include input-required;
}

.label {
  align-items: center;
  color: $fc-html; /* TODO: Remove when classic is gone. */
  display: flex;
  margin-bottom: 4px;
  text-align: left;

  &.error .label-required {
    color: $input-error-color;
  }
}
</style>
