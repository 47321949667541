<script>
export default {
  name: 'ImageThumbnail',
  props: {
    // Public: Width of the displayed image
    width: { type: [String, Number], default: 48 },

    // Public: Height of the displayed image
    height: { type: [String, Number], default: 48 },

    // Public: Displayed in the image expanded modal
    name: { type: String, default: null },

    // Public: Image Url
    url: { type: String, default: null },

    // Public: An icon to display when there is no file available
    defaultIcon: { type: String, default: null },

    // Public: Indicates whether or not the image should be cropped into a circle
    cropCircle: { type: Boolean, default: null },
  },
  computed: {
    style () {
      return { backgroundImage: `url(${this.url})`, width: `${this.width}px`, height: `${this.height}px` }
    },
    borderClass () {
      return { 'crop-circle': this.cropCircle, 'with-background': this.defaultIcon }
    },
  },
  methods: {
    openImageModal (props = {}) {
      if (this.url) this.asyncOpenModal('DisplayContent/ImageModal', { props })
    },
  },
}
</script>

<template>
  <div
    v-if="url || defaultIcon"
    :style="style"
    :name="name"
    class="image-thumbnail"
    :class="borderClass"
    @click="openImageModal({ src: url, title: $options.filters.truncate(name, { length: 30 }) })"
  >
    <Icon v-if="!url && defaultIcon" :name="defaultIcon" class="image-thumbnail-icon" :class="borderClass"/>
  </div>
</template>

<style lang="scss" scoped="true">
.image-thumbnail {
  @include clickable;

  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: $radius-normal;
  color: $bg-light;
  display: flex;
  justify-content: center;

  &.with-background {
    background-color: $bg-lighter;
  }
}

.image-thumbnail-icon {
  align-items: center;
  height: 85%;
  justify-content: center;
  width: 85%;
}

.crop-circle {
  background-size: cover;
  border-radius: 50%;
}
</style>
