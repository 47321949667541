
<script>
import VueDraggable from 'vuedraggable'

// Public: Draggables allow to create lists of items that can be sorted by the user using drag & drop.
//
// <Draggable v-model="itemsArray">
//   <div v-for="(item) in itemsArray" :key="item.id">
//    <DraggableListItemHandle/> {{ item.label }}
//   </div>
// </Draggable>
export default {
  name: 'Draggable',
  components: {
    VueDraggable,
  },
  inheritAttrs: false,
  model: {
    prop: 'items',
    event: 'input',
  },
  props: {
    // Public: Tag name of the list container.
    tag: { type: String, default: 'div' },

    // Public: Array of items that are the model for the elements being dragged
    items: { type: Array, required: true },

    // Public: Whether dragging should be only possible with the use of the drag handle
    withHandle: { type: Boolean, default: true },

    // Public: Whether dragging should be disabled or not
    disabled: { type: Boolean, default: false },
  },
  data () {
    return {
      isDragging: false,
    }
  },
  computed: {
    dragOptionsAndAttributes () {
      return {
        ...this.$attrs,
        animation: 200,
        disabled: this.disabled,
        ghostClass: 'draggable-list-item--ghost',
        chosenClass: 'draggable-list-item--chosen',
        dragClass: 'draggable-list-item--drag',
      }
    },
  },
  methods: {
    onDragStart () {
      this.isDragging = true
    },
    onDragEnd () {
      this.isDragging = false
    },
  },
}
</script>

<template>
  <VueDraggable
    :value="items"
    :tag="tag"
    class="draggable-list"
    :class="{ 'draggable-list--drag': isDragging }"
    v-bind="dragOptionsAndAttributes"
    :handle="withHandle ? '.draggable-list-item-handle' : null"
    v-on="$listeners"
    @start="onDragStart"
    @end="onDragEnd"
  >
    <transition-group type="transition" :name="isDragging ? 'animated-list' : 'static-list'">
      <slot/>
    </transition-group>
  </VueDraggable>
</template>

<style lang="scss" scoped>
.draggable {
  list-style: none;
  margin: 0;
  padding: 0;
}

.animate-list-move {
  transition: transform 0.5s;
}

.static-list-move {
  transition: transform 0s;
}

::v-deep .draggable-list-item--drag {
  // Use this class in your components to style the element dragged with the mouse
}

::v-deep .draggable-list-item--ghost {
  // Use this class in your components to style the element representing the "drop placeholder" on the list
  opacity: 0;
}
</style>
