<script>
export default {
  name: 'PromiseIndicator',
  props: {
    // Public: The promise we are waiting on
    loadingUntil: { type: [Promise, Object]/* type: Promise, but IE11 polyfill */, default: null },
  },
  data () {
    return {
      displayLoadingIndicator: !!this.loadingUntil,
    }
  },
  watch: {
    loadingUntil () {
      if (this.loadingUntil) this.displayLoadingIndicator = true
      this.handleLoadingUntil()
    },
  },
  created () {
    this.handleLoadingUntil()
  },
  methods: {
    handleLoadingUntil () {
      const promise = this.loadingUntil
      promise && promise.finally(() => { this.displayLoadingIndicator = false })
    },
  },
}
</script>

<template>
  <LoadingIndicator v-if="displayLoadingIndicator" class="promise-indicator" size="small"/>
</template>
