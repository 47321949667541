<script>
export default {
  name: 'Panel',
  props: {
    title: { type: String, default: null },
    collapsed: { type: Boolean, default: null },
    collapsible: { type: Boolean, default () { return Boolean(this.collapsed) } },

    // Adds border around panel
    border: { type: Boolean, default: true },
  },
  data () {
    return {
      expanded: !this.collapsed,
    }
  },
  computed: {
    shouldShowHeader () {
      return this.title || this.collapsible || this.$slots.title || this.$slots.actions
    },
  },
  watch: {
    collapsed () {
      this.expanded = !this.collapsed
    },
  },
  methods: {
    toggleExpanded () {
      if (this.collapsed === null) this.expanded = !this.expanded
    },
  },
}
</script>

<template>
  <div :class="{ expanded, border,'no-header': !shouldShowHeader }" :data-title="title" class="panel">
    <div v-if="shouldShowHeader" class="panel-header" @click="$emit('panel:headerClick', $event)">
      <span class="panel-title">
        <slot name="title">{{ title }}</slot>
      </span>

      <div class="panel-actions">
        <slot name="actions"/>
        <Button
          v-if="collapsible"
          :class="{ expanded }"
          icon="chevronDown"
          class="icon-collapsible"
          @click="toggleExpanded"
        />
      </div>
    </div>

    <transition name="slide-down">
      <div v-if="!collapsible || expanded" class="panel-content">
        <slot/>
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.panel {
  background: $bg-card;
  border-radius: $radius-normal;
}

.panel-header {
  @include flex-row;

  @include ie11 {
    padding: 8px 8px 0 16px;
  }

  align-items: center;
  background-color: $bg-card-header;
  border-radius: $radius-normal $radius-normal 0 0;
  font-size: $fs-large;
  font-weight: $fw-bold;
  justify-content: space-between;
  letter-spacing: 0.24px;
  min-height: 48px;
  padding: 0 8px 0 16px;
}

.panel-content {
  border-radius: 0 0 $radius-normal $radius-normal;
  padding: 32px 24px;

  .no-header & {
    border-radius: $radius-normal;
  }
}

.panel.border {
  $panel-border: 1px solid $layout-br-color;

  .panel-header {
    border: $panel-border;
  }

  .panel-content {
    border: $panel-border;
    border-top: none;
  }

  &.no-header .panel-content {
    border: $panel-border;
  }

  &:not(.expanded) .panel-header {
    border-bottom: $panel-border;
    border-radius: $radius-normal;
    transition: all 0.6s $ease-out-sine;
    transition-delay: 0.52s;
  }
}

.panel-actions {
  color: $fc-html;
  display: flex;

  & > .actionable {
    margin-left: 4px;

    &.action-link {
      height: $icon-size-regular;

      & > .icon {
        width: $icon-size-regular;
      }
    }

    &.icon-collapsible {
      transition: transform 0.6s $ease-out-quart;

      &:not(.expanded) {
        transform: rotate(-90deg);
      }
    }
  }
}

@include slide-down-transition(0.5s, '.panel-content');
</style>
