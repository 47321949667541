<script>
export default {
  page: { title: 'Loading…' },
}
</script>

<template>
  <h1 class="title">
    <transition appear>
      <LoadingIndicator size="huge"/>
    </transition>
  </h1>
</template>

<style lang="scss" scoped>
.title {
  flex-grow: 1;
  margin: 50px;
  text-align: center;
}

.loading-indicator {
  display: block;
  margin: 0 auto;
}
</style>
