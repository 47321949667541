<script>
import CareProvidersRequests from '@requests/CareProvidersRequests'

export default {
  name: 'NavigationCareProviderMenuItem',
  props: {
    careProvider: { type: Object, required: true },
    navigationService: { type: Object, default: null },
  },
  methods: {
    isTestProvider (careProvider, tag) {
      return careProvider.tags && careProvider.tags.includes('test')
    },
    pathFor (careProvider) {
      return window.hasVueRouter
        ? { name: 'ChangeCareProvider', params: { careProviderId: careProvider.id } }
        : CareProvidersRequests.legacyChangeCurrentPath(careProvider)
    },
  },
}
</script>

<template functional>
  <NavigationBarMenuItem
    v-navigation-item="{ navigationService: props.navigationService }"
    :to="$options.methods.pathFor(props.careProvider)"
    method="get"
    :class="data.staticClass"
    class="care-provider-menu-item"
    v-on="listeners"
  >
    <span class="care-provider-name"><slot/></span>
    <Icon v-if="$options.methods.isTestProvider(props.careProvider)" v-tooltip="'Test Care Provider'" size="regular" name="test" class="test-care-provider-icon care-provider-icon"/>
  </NavigationBarMenuItem>
</template>

<style lang="scss" scoped>
.navbar-submenu.care-provider-menu-item {
  display: flex;
}

.care-provider-name {
  margin-right: 8px;
  white-space: nowrap;
}

.care-provider-icon {
  color: $tundora-l-2;
  flex-shrink: 0;
}
</style>
