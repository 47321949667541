<script>
import CroutonStyles from '@constants/CroutonStyles'
import FormInputContainerMixin from '@mixins/FormInputContainerMixin'
import { camelCase, flatMap, get, initial, isEmpty, isEqual, keys, last, pick } from 'lodash'
import { deepCamelizeKeys, setValues } from '@helpers/ObjectHelper'
import { getDescendantVNodes } from '@helpers/NodeHelper'
import { isBadRequestError, isServerError } from '@helpers/ErrorHelper'
import { modalConfirmChanges } from '@helpers/ModalHelper'
import { scrollIntoView } from '@helpers/ScrollHelper'
import { translateLabel } from '@helpers/TranslationHelper'
import { translateServerErrors } from '@helpers/ValidationHelper'

// Internal: Returns true if the form input meets all validations.
function formInputIsValid ({ componentInstance }) {
  return componentInstance && componentInstance.valid
}

// Internal: Returns true if the form input has an error.
function formInputHasError (vNode) {
  return !formInputIsValid(vNode)
}

// Public: Our <Form> component, serves as a SimpleForm replacement in Vue pages.
//
//   - Takes FormInputs in its default slot, but can handle arbitrary content.
//   - Adds v-model like behavior to FormInputs, and adds translation config.
//   - Retrieves server errors and provides them to the FormInputs.
//   - Provides a submitIfValid function to aid the submit process.
//   - Displays errors returned by the server in an ErrorsSerializer format.
export default {
  name: 'Form',
  mixins: [FormInputContainerMixin],
  props: {
    // Public: A Function to call when the Form is submitted.
    onSubmit: { type: Function, default: null },

    // Public: The display values for select input items and similar fields,
    // for the confirm changes modal.
    autocomplete: { type: Object, default: () => ({}) },

    // Public: The initial state of the model being modified.
    initialModel: { type: Object, default: null },

    // Public: Whether to confirm changes before saving.
    confirmChanges: { type: Boolean, default () { return Boolean(this.initialModel) } },

    // Public: If true, a prop `isValid` is added to the model to indicate if
    // the form is valid or there are validation errors
    validationAsProp: { type: Boolean, default: false },

    // Public: If true, the save button will be disabled until the form is modified.
    disableIfPristine: { type: Boolean, default () { return this.confirmChanges } },

    // Public: Indicates if the Form exists within a settings layout
    withinSettingsLayout: { type: Boolean, default () { return this.confirmChanges } },

    // Public: Warning to display on the changes confirmation dialog
    confirmationWarningMessage: { type: String, default: null },

    // Public: A Function call when the Form has submitted completely.
    onSubmitCompleted: { type: Function, default: null },
  },
  data () {
    return {
      // Internal: True if no inputs have changed since instantiation.
      pristine: true,

      // Internal: Errors are not displayed until the form is submitted.
      submitted: false,

      // Internal: Allows other components to check on submission progress.
      submitting: false,

      // Internal: Errors received from the inputs.
      inputErrors: {},

      // Internal: Errors received from the server.
      serverErrors: {},
    }
  },
  computed: {
    // Internal: Whether this form has been modified by the user.
    isFormEdited () {
      return !isEqual(this.initialModel, this.model)
    },
    // Override: Errors to be bound to the inputs.
    errors () {
      return this.parentFormErrors || this.serverErrors
    },
    // Override: Returns if the Form was submitted
    wasSubmitted () {
      return this.submitted
    },
    // Public: Returns true if no inputs in the form have validation errors.
    isValid () {
      return isEmpty(this.inputErrors) &&
        this.collectErrorMessages(this.serverErrors).length === 0
    },
    // Internal: Returns the data attributes to insert in the <Form> tag
    // Currently only used to help tests find the right child form
    dataAttributes () {
      const attributes = {}
      if (this.nestedModelProp) {
        attributes['data-nested-form'] = this.nestedModelProp
      }

      if (this.nestedModelIndex != null) {
        attributes['data-nested-form-index'] = this.nestedModelIndex
      }

      return attributes
    },
    // Internal: Returns the camelCased name of the prop that is a nested Form within the parent Form
    nestedModelPropPrefix () {
      const nestedForms = getDescendantVNodes(this, 'Form', { nested: false })
      if (nestedForms.length <= 0) return null
      return nestedForms[0].componentOptions.propsData.nestedModelProp
    },
  },
  watch: {
    // Internal: Notify listeners when there's a change in the form validation
    isValid (value) {
      this.setIsValidProp()
    },
    model () {
      this.setIsValidProp()
    },
    // Internal: Notify listeners about the current validation state.
    // NOTE: This is necessary when nested Form items are deleted or reordered.
    nestedModelIndex (value) {
      const childInputs = getDescendantVNodes(this, 'FormInput')
      childInputs.forEach(input => {
        if (input.componentInstance.valid) {
          this.$emit('input:valid', this.fieldPropFor(input))
        } else {
          this.$emit('input:errors', this.fieldPropFor(input), input.componentInstance.validationErrors)
        }
      })
    },
  },
  mounted () {
    if (this.initialModel && this.disableIfPristine) this.pristine = !this.isFormEdited // This initialized the pristine property
    if (this.confirmChanges || this.disableIfPristine) {
      // Internal: Notifies SettingsLayout to display or hide the EditedBadge.
      this.$watch('isFormEdited', edited => {
        if (this.disableIfPristine) this.pristine = !edited // Since we have the original, we can be more precise.
        this.$emit('form:edited', edited)
      })
    }

    this.setIsValidProp()
  },
  beforeDestroy () {
    // Clear errors received from parent form, if any
    if (this.parentFormErrors) {
      keys(this.parentFormErrors).forEach(field => this.$delete(this.parentFormErrors, field))
    }
  },
  methods: {
    // Public: Validates the form and sets submitted to true to displays errors
    validate () {
      this.submitted = true
      return this.isValid
    },
    // Internal: Set if the form is valid as a model prop
    setIsValidProp () {
      // We need to have the nextTick to wait for cases in which validation of one field (for ex: required)
      // depends on the value of other field
      if (this.validationAsProp) this.$nextTick(() => { this.$set(this.model, 'isValid', this.isValid) })
    },
    // Internal: Deeply collect all error messages that the server returns, in order to verify
    // the Form validness. We can't just check that the object is empty, since only leafs are
    // removed when a field change is detected.
    collectErrorMessages (errors) {
      return flatMap(keys(errors), field => {
        return flatMap(errors[field], entry => {
          if (typeof entry === 'string') {
            return entry
          } else {
            return this.collectErrorMessages(entry)
          }
        })
      })
    },
    // Public: Validates the form, gathering errors, and handling the promise.
    //
    // If the form is valid, it will invoke onSubmit, and observe the returned
    // promise to display a loading state, or error messages if it's rejected.
    onFormSubmit (event) {
      if (event) event.preventDefault()
      this.submitted = true
      if (this.isValid) {
        if (this.onSubmit) this.submitForm(event)
      } else {
        this.scrollToFirstError()
      }
    },
    // Internal: May ask the user to confirm changes before submitting the form.
    confirmOrSubmit (...args) {
      if (this.confirmChanges) {
        return modalConfirmChanges({
          autocomplete: this.autocomplete,
          warningMessage: this.confirmationWarningMessage,
          modified: this.model,
          original: this.initialModel,
          maskedFields: this.getMaskedFields(),
          prefix: this.prefix,
          nestedModelPropPrefix: this.nestedModelPropPrefix,
          saveChanges: changesWithId =>
            this.onSubmit(changesWithId, ...args)
              .then(data => {
                if (data && data._isVue) console.error(`The onSubmit promise is resolving to the Vue component instead of data. Check that none of the promise handlers are returning the result of $emit.`)
                setValues(this.model, data)
                setValues(this.initialModel, this.model)
              })
              .finally(() => {
                if (this.onSubmitCompleted) this.onSubmitCompleted()
              }),
        })
      } else {
        return this.onSubmit(...args).then(() => {
          this.pristine = true
        }).finally(() => {
          if (this.onSubmitCompleted) this.onSubmitCompleted()
        })
      }
    },
    // Public: Calls onSubmit and handles the returned promise to display a
    // loading state and any errors returned from the server.
    submitForm (event) {
      this.submitting = true
      this.confirmOrSubmit(this.model, event)
        .catch(error => {
          const response = error && error.response
          if (!response || isServerError(error) || isBadRequestError(error)) {
            console.error(error)
            this.showFormCrouton(error)
          } else {
            response && this.onServerErrors(error)
          }
        })
        .finally(() => {
          this.submitting = false
        })
    },
    // Internal: Processes server errors in order to display them.
    onServerErrors (error) {
      const errors = error.response.data
      const childInputs = getDescendantVNodes(this, 'FormInput')
      const childForms = getDescendantVNodes(this, 'Form')
      const inputFieldsWithErrors = keys(errors.messages).filter(fieldProp => {
        return childInputs.some(input => input.componentOptions.propsData.field === fieldProp) ||
          childForms.some(form => this.nestedModelPropFor(form) === fieldProp)
      })

      this.showCroutonForErrorsWithoutInput(errors.messages, inputFieldsWithErrors)
      const errorMessages = deepCamelizeKeys(pick(errors.messages, inputFieldsWithErrors))
      this.serverErrors = translateServerErrors(errorMessages)

      this.$nextTick(this.scrollToFirstError)
    },
    // Internal: Display a Crouton for errors related to fields not present in the Form
    showCroutonForErrorsWithoutInput (messages, inputFieldsWithErrors) {
      const errorMessage = keys(messages)
        .filter(fieldProp => !inputFieldsWithErrors.includes(fieldProp))
        .map(fieldProp => fieldProp === 'base'
          ? messages[fieldProp]
          : [translateLabel(fieldProp, this.translationOptions), messages[fieldProp]].join(' ')
        ).join('\n')
      if (errorMessage) this.showFormCrouton(errorMessage)
    },
    // Internal: Scrolls to the first input that has an error.
    scrollToFirstError () {
      const inputWithError = getDescendantVNodes(this, 'FormInput').find(formInputHasError)
      if (inputWithError) {
        scrollIntoView(inputWithError.elm, this.withinSettingsLayout ? { animate: true, offset: -100 } : undefined)
      }
    },
    // Internal: Displays an in-form error crouton
    showFormCrouton (message) {
      const crouton = this.showCrouton(message, { ...CroutonStyles.innerFormStyle, container: this.$el, prepend: true })
      scrollIntoView(crouton)
    },
    // Override: Handle 'input:valid' event
    // NOTE: We need to update the validness on the local Form and
    // forward the event to parent Form, if any
    onInputValid (field) {
      this.$emit('input:valid', field)
      this.$delete(this.inputErrors, field)
      this.cleanServerErrorsFor(field)
    },
    // Override: Handle 'input:errors' event
    // NOTE: We need to update the validness on the local Form and
    // forward the event to parent Form, if any
    onInputErrors (field, errors) {
      this.$emit('input:errors', field, errors)
      this.$set(this.inputErrors, field, errors)
    },
    // Internal: Updates the model value, which will cause an update on the
    // input "value" prop and clear any previous server errors.
    // Also, if the FormInput triggers @change handler, if set.
    onFormInputValueChange (fieldProp, value) {
      const fieldPropFields = fieldProp.split('.')
      const modelPropToModify = (fieldPropFields.length <= 1) ? fieldProp : last(fieldPropFields)
      const modelToModify = (fieldPropFields.length <= 1) ? this.model : get(this.model, initial(fieldPropFields).join('.'))

      if (!this.initialModel && this.disableIfPristine) this.pristine = false
      this.$set(modelToModify, modelPropToModify, value)
      this.cleanServerErrorsFor(fieldProp)
      this.$emit('model:change', this.model, fieldProp)
    },
    // Internal: Cleans server errors, either received from the parent Form or
    // obtained while submitting the current Form
    cleanServerErrorsFor (fieldProp) {
      if (this.parentFormErrors && this.parentFormErrors[fieldProp]) {
        this.$delete(this.parentFormErrors, fieldProp)
      } else if (this.serverErrors[fieldProp]) {
        this.$delete(this.serverErrors, fieldProp)
      }
    },
    getMaskedFields () {
      return getDescendantVNodes(this, 'FormInput')
        .filter(child => child.componentInstance.$options.propsData.type === 'password')
        .map(child => camelCase(child.componentInstance.$options.propsData.field))
    },
  },
  render (h) {
    // MUST be called before isValid to ensure we have purged the errors.
    let children = this.initializeFormInputChildren()

    // Make it easy to implement this behavior without having to manually
    // implement dirty-checking on every component.
    if (this.$scopedSlots.formActions) {
      children = children.concat(this.$scopedSlots.formActions({ edited: this.isFormEdited }))
    } else if (this.confirmChanges) {
      children = children.concat([h('FormActions', { attrs: { noCancel: true, sticky: this.isFormEdited } })])
    }

    return h('form', {
      class: [
        'form',
        this.submitted && 'submitted',
        this.submitting && 'submitting',
        this.disableIfPristine && this.pristine && 'disable-save',
        this.isValid ? 'valid' : 'invalid',
      ],
      attrs: {
        novalidate: true,
        ...this.dataAttributes,
        ...this.$attrs,
      },
      on: {
        ...this.$listeners,
        submit: event => this.onFormSubmit(event),
      },
    }, children)
  },
}
</script>

<style lang="scss" scoped>
.form {
  display: flex;
  flex-direction: column;
  width: 100%;

  .save-button::after {
    left: 0;
    right: 0;
    top: -24px;
  }

  .form-control + .save-button {
    margin-top: 8px;
  }

  .form-control:last-child {
    &.has-errors {
      margin-bottom: 48px;
    }
  }

  &.disable-save ::v-deep .save-button {
    opacity: 0.5;
    pointer-events: none;
  }

  &.submitting ::v-deep .save-button {
    @include save-button-loading;
  }

  &.submitted.invalid ::v-deep .save-button {
    opacity: 0.5;

    &::after {
      content: "Please review the errors above";
    }
  }
}
</style>
