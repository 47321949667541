// Public: Check if an element is currently focused.
export function hasFocus (element) {
  return element === document.activeElement && document.hasFocus()
}

// Public: Focuses or blurs the specified element based on a Boolean value.
export function setFocus (element, shouldBeFocused) {
  const isFocused = hasFocus(element)
  if (shouldBeFocused && !isFocused) element.focus()
  if (!shouldBeFocused && isFocused) element.blur()
}
