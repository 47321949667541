<script>
export default {
  name: 'Headline',
  props: {
    title: { type: String, required: true },
  },
}
</script>

<template>
  <h1 class="headline">{{ title }}</h1>
</template>

<style lang="scss" scoped>
  .headline {
    font-size: $fs-headline;
  }
</style>
