import { modifierKeysFor, normalizeKey } from '@helpers/KeyboardHelper'
import { sortedUniq } from 'lodash'

// Internal: To make it more readable.
const ALIASES = { ' ': 'Spacebar' }

// Public: The separator to use between keys. Example: ctrl+s
export const KEYS_SEPARATOR = '+'

// Public: Returns a String representation of the shortcut using the browser
// KeyEvent key names.
export function toKeyboardShortcut (keys) {
  return keys.split(KEYS_SEPARATOR).map(prettyNormalizedKey).sort().join(KEYS_SEPARATOR)
}

// Public: Returns a String representation of the pressed keys in the event.
export function eventKeyboardShortcut (event) {
  const keys = [
    ...modifierKeysFor(event),
    event.key && prettyNormalizedKey(event.key, event),
  ].filter(x => x)
  return sortedUniq(keys.sort()).join(KEYS_SEPARATOR)
}

// Internal: Some keys are replaced to make them more readable.
function prettyNormalizedKey (keyName, event) {
  const key = normalizeKey(keyName, event)
  return ALIASES[key] || key
}
