// CacheKey 3ace437a5675b0ef17e5fe39f11550e7
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { formatUrl } from '@helpers/UrlHelper'
import { request } from '@services/EvolveApiService'

export default {
  changeCurrent: options =>
    request('get', '/vue/care_providers/:id/change_current', options),

  legacyChangeCurrentPath: options =>
    formatUrl('/vue/care_providers/:id/legacy_change_current', options),

  allForUser: options =>
    request('get', '/vue/care_providers/all_for_user', options),

  criteriaConditionOptions: options =>
    request('post', '/vue/care_providers/criteria_condition_options', options),

  create: options =>
    request('post', '/vue/care_providers', options),
}
