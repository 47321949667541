<script>
import AuthStore from '@stores/AuthStore'
import { ActionCableEvents, subscribe, unsubscribe } from '@helpers/ActionCableHelper'

// Public: Exposes an ActionCable consumer instance through slot-scope.
export default {
  name: 'ActionCableProvider',
  props: {
    // Public: Name of the channel for the ActionCable subscription.
    channelName: { type: String, required: true },

    // Public: Parameters for the channel subscription.
    params: { type: Object, default: () => ({}) },

    // Public: Listeners for the ActionCable events.
    listeners: { type: Object, default: () => ({}) },

    // Public: Whether it should subscribe to the specified channel.
    enabled: { type: Boolean, default: true },

    // Public: Whether it should attempt to reconnect when disconnected.
    autoReconnect: { type: Boolean, default: true },
  },
  data () {
    return {
      // Public: If enabled, an ActionCable.Subscription.
      subscription: null,
    }
  },
  computed: {
    // Internal: The ActionCable.Consumer instance for the current subscription.
    consumer () {
      return this.subscription && this.subscription.consumer
    },
    // Internal: Wraps listeners to also emit Vue events.
    subscriptionListeners () {
      return ActionCableEvents.reduce((listeners, eventName) => {
        listeners[eventName] = data => this.onActionCableEvent(eventName, data)
        return listeners
      }, {})
    },
    // Internal: Arguments for the ActionCable Subscription constructor.
    subscriptionParams () {
      return {
        channel: this.channelName,
        listeners: this.subscriptionListeners,
        careProviderId: AuthStore.careProviderId,
        ...this.params,
      }
    },
  },
  watch: {
    enabled: {
      immediate: true,
      handler (enabled) {
        if (enabled) {
          this.subscription = subscribe(this.subscriptionParams)
          this.consumer.ensureActiveConnection()
        } else {
          if (this.subscription) unsubscribe(this.subscription)
          this.subscription = null
        }
      },
    },
  },
  beforeDestroy () {
    if (this.subscription) unsubscribe(this.subscription)
  },
  methods: {
    // Public: Allows to reconnect to the cable when disconnected.
    reconnect ({ willAttemptReconnect } = {}) {
      if (!willAttemptReconnect) this.consumer.ensureActiveConnection()
    },
    // Internal: Calls a registered listener for the event, if any.
    // NOTE: Always emits a component event.
    onActionCableEvent (eventName, data) {
      const args = data === undefined ? [this.subscription, this.consumer] : [data, this.subscription, this.consumer]
      this.$emit(`actionCable:${eventName}`, ...args)

      // If the user provided a handler using `listeners`, we call it directly.
      const listener = this.listeners[eventName]
      if (listener) listener(...args)

      // Attempt to reconnect by default.
      if (this.enabled && this.autoReconnect && eventName === 'disconnected') {
        this.reconnect(data)
      }
    },
  },
}
</script>

<template>
  <div class="action-cable-provider">
    <slot :cableConsumer="consumer" :cableSubscription="subscription"/>
  </div>
</template>
