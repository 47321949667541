<script>
import Audio from '@components/Audio'

export default {
  components: {
    Audio,
  },
  inheritAttrs: false,
  props: {
    // Public: Whether to display the file duration when paused or elapsed time while playing
    showDuration: { type: Boolean, default: false },
    // Public: Whether to allow mp3 files to be uploaded
    allowMp3: { type: Boolean, default: false },
  },
  computed: {
    allowedFileFormats () {
      return `audio/wav${this.allowMp3 ? ', audio/mp3' : ''}`
    },
  },
}
</script>

<template>
  <FileInput
    :accept="allowedFileFormats"
    class="audio-input"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template slot="labelExtras">
      <slot name="labelExtras"/>
    </template>
    <template slot="file" slot-scope="{ file }">
      <Audio :file="file" :showDuration="showDuration"/>
    </template>
    <template slot="fileExtras" slot-scope="slotScope">
      <slot name="fileExtras" v-bind="slotScope"/>
    </template>
  </FileInput>
</template>

<style lang="scss" scoped>

</style>
