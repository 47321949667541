<script>
import ImageExtensions from '@constants/ImageExtensions'

export default {
  name: 'ImageInput',
  inheritAttrs: false,
  constants: {
    imageExtensions: Array.from(ImageExtensions).map(extension => `image/${extension}`).join(','),
  },
  props: {
    // Public: Whether a preview slot of the uploaded file should be provided.
    showPreview: { type: Boolean, default: false },
  },
}
</script>

<template>
  <FileInput class="image-input" :accept="imageExtensions" v-bind="$attrs" v-on="$listeners">
    <template v-if="showPreview" slot="file" slot-scope="{ file }">
      <slot name="preview" v-bind="{ file }">
        <FilePreview :file="file" type="image"/>
      </slot>
    </template>
  </FileInput>
</template>

<style lang="scss" scoped>
</style>
