import autosize from 'autosize'

// Internal: Applies the action Function if the directive element is a textarea.
const ifTextArea = action => el => el.tagName === 'TEXTAREA' && action(el)

// v-autosize: Allows textareas to grow when the user presses enter.
export default {
  bind: ifTextArea(autosize),
  unbind: ifTextArea(autosize.destroy),
  inserted: ifTextArea(autosize.update),
  componentUpdated: ifTextArea(autosize.update),
}
