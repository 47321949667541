<script>
export default {
  name: 'DataField',
  props: {
    // Public: Label to display next to the value.
    label: { type: String, required: true },

    // Optional: A value to display for the field.
    value: { type: [String, Number], default: '' },

    // Optional: Configures the styling for the field.
    styling: { type: Object, default: () => ({ horizontal: true, rightAligned: true }) },
  },
  computed: {
    // NOTE: We could use mapKeys and kebabCase instead of building it manually,
    // but being explicit makes it easier to search for usages of CSS classes.
    stylingClasses () {
      return {
        horizontal: this.styling.horizontal,
        'right-aligned': this.styling.rightAligned,
      }
    },
    hasActions () {
      return !!this.$slots.actions
    },
  },
}
</script>

<template>
  <!-- TODO: Introduce a `layout` prop that allows different presets (left-align, non-justify, etc) -->
  <dl class="data-field" :class="stylingClasses">
    <dd class="data-field-label">{{ label }}</dd>
    <dt class="data-field-value"><slot>{{ value }}</slot></dt>
    <dt v-if="hasActions" class="data-field-actions"><slot name="actions"/></dt>
  </dl>
</template>

<style lang="scss" scoped>
.data-field {
  margin-bottom: 16px;
}

.data-field:last-child {
  margin-bottom: 0;
}

.data-field-label {
  color: $fc-html-light;
  font-weight: $fw-regular;
}

.data-field-value {
  font-weight: $fw-bold;
  text-align: left;
  white-space: pre-wrap;
}

.data-field.right-aligned .data-field-value {
  margin-left: auto;
  text-align: right;
}

.data-field.horizontal {
  @include flex-row;

  align-items: flex-start;

  .data-field-label {
    margin-right: 16px;
  }
}
</style>
