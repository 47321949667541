import IconAliases from '@evolve/IconAliases'

// Internal: Raise an error that includes the missing icon name in the message.
function missingIcon (name) {
  throw new TypeError(`Icon '${name}' is not defined.`, name)
}

// Public: Return the URL for an svg image corresponding to the given name.
export function getIconUrl (name) {
  return IconAliases[name] || missingIcon(name)
}
