import { map, uniq } from 'lodash'
import { mapFind } from '@helpers/ArrayHelper'
import { notifyError } from '@helpers/BugsnagHelper'
import { titleize } from '@helpers/StringHelper'

// Public: Returns the label that matches the value in the specified list of
// options.
export function labelFromOptions (value, items) {
  return mapFind(items, option => value === option.value && option.label) ||
    notifyError(`Unknown value ${value} in ${JSON.stringify(items)}`)
}

// Public: Similar to labelForOption but for a list of values.
export function labelsFromOptions (values, items) {
  return map(values, value => labelFromOptions(value, items))
}

export function toOption (label, value) {
  return { label, value }
}

// Public: Returns options constructed from a list of labels
export function valuesToOptions (values) {
  return map(uniq(values), value => valueToOption(value))
}

export function valueToOption (value) {
  return { label: value, value }
}

export function modelsToOptions (models) {
  return map(models, model => modelToOption(model))
}

export function modelToOption (model) {
  return { label: model.name, value: model.id }
}

// Public: Returns the label that should be used for a given option
export function labelForOption (value) {
  return value.label ? value.label : titleize(value.id)
}
