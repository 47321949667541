<script>
import { isFunction } from 'lodash'

// Public: A Button that will trigger an async action and display a loading
// state until that action is complete.
//
// NOTE: The click listener function must return a Promise, which controls the
// loading state of the button.
export default {
  name: 'PromiseButton',
  inheritAttrs: false,
  props: {
    // Public: The preferred way to specify the content when it's a String.
    label: { type: String, default: '' },

    // Public: The name of an icon to render inside the button.
    icon: { type: [Boolean, String], default: false },
  },
  data () {
    return {
      // Internal: Whether the action is in progress, in which case the button
      // is disabled and displays a loading indicator.
      inProgress: false,
    }
  },
  computed: {
    // Internal: Replace the icon with a loading indicator if the button has an
    // icon, otherwise we will use the loading ellipsis instead.
    buttonIcon () {
      return this.icon && this.inProgress ? 'loading' : this.icon
    },
    buttonListeners () {
      return { ...this.$listeners, click: this.performAsyncAction }
    },
  },
  created () {
    if (!isFunction(this.$listeners.click)) {
      this.notifyError(new Error(`You must provide a @click listener in order to use PromiseButton`), this)
    }
  },
  methods: {
    // Internal: Ignores repeated clicks while it's being performed, and
    // displays a small loading indicator
    performAsyncAction (event) {
      if (!this.inProgress) {
        this.inProgress = true
        const promise = this.$listeners.click && this.$listeners.click(event)
        if (promise && promise.finally) {
          promise.finally(() => { this.inProgress = false })
        } else {
          this.inProgress = false
          this.notifyError(new Error(`@click listeners in PromiseButton must return a promise. If passing additional parameters make sure to wrap with a lambda () => ...`), { promise, ...this })
        }
      }
    },
  },
}
</script>

<template>
  <Button :icon="buttonIcon" :label="label" v-bind="$attrs" v-on="buttonListeners">
    <slot v-if="label">{{ translateIfKey(label) }}</slot>
    <LoadingEllipsis v-if="inProgress && !icon"/>
  </Button>
</template>
