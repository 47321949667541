<script>
import ElementKeyboardShortcuts from '@components/ElementKeyboardShortcuts'

import HelpStore from '@stores/HelpStore'
import { elementsWithKeyboardShortcuts } from '@directives/hotkey'

// Public: Allows to mask all page elements so that shortcuts are more visible.
// Clicking it makes all the overlays disappear.
export default {
  name: 'KeyboardShortcutsOverlay',
  components: {
    ElementKeyboardShortcuts,
  },
  computed: {
    shouldDisplay () {
      return HelpStore.displayingKeyboardOverlay
    },
    elementsWithKeyboardShortcuts ({ shouldDisplay }) {
      return shouldDisplay ? elementsWithKeyboardShortcuts() : []
    },
  },
  methods: {
    ...HelpStore.mapActions('displayKeyboardOverlay', 'hideKeyboardOverlay'),
    // NOTE: Because we use v-hotkey.push, we get an event when the key is down,
    // and another one when the key is up.
    toggleKeyboardOverlay (event) {
      event.keyPressed ? this.displayKeyboardOverlay() : this.hideKeyboardOverlay()
    },
  },
}
</script>

<template>
  <div
    v-show="shouldDisplay"
    v-on-outside="shouldDisplay && { click: hideKeyboardOverlay, keypress: hideKeyboardOverlay }"
    v-hotkey.push="{ 'shift+?': toggleKeyboardOverlay, '?': toggleKeyboardOverlay }"
    class="keyboard-shortcuts-overlay"
    data-hotkey-overlay="{ hide: true }"
    @click="hideKeyboardOverlay"
  >
    <span class="keyboard-shortcuts-overlay__mask"/>
    <template v-for="[element, shortcuts] in elementsWithKeyboardShortcuts">
      <ElementKeyboardShortcuts :key="shortcuts + element.className" :element="element" :shortcuts="shortcuts"/>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.keyboard-shortcuts-overlay {
  @include z-index(keyboard-shortcuts-overlay);
}

.keyboard-shortcuts-overlay__mask {
  background-color: $bg-mask-light;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;
}
</style>
