<script>
import InputMixin from '@mixins/InputMixin'
import { groupBy } from 'lodash'

export default {
  name: 'RadioButtons',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Used to indicate the HTML name of the radio buttons, which can be
    // used to link separate RadioInputs in the page.
    name: { type: String, required: true },

    // Public: Selected item or list of selected items that populate the input.
    value: { type: [Object, Array, String], default: '' },

    // Public: Items that can be selected and will be displayed each as radio buttons.
    items: { type: Array, default: () => [] },

    // Public: Item property to use when displaying items as radio buttons.
    labelProp: { type: String, default: 'label' },

    // Public: Item property to use as the internal item value (and input id).
    valueProp: { type: String, default: 'value' },

    // Public: Used to indicate if the radio buttons should be placed inline
    inline: { type: Boolean, default: false },

    // Public: Whether to display a loading placeholder until items are provided.
    placeholderItems: { type: Number, default: 0 },

    // Public: This allows a user to group similar radio buttons under a label. Visually separating them, even though they are still part of the same input field.
    // When this is enabled the items should include a group key to indicate which grouping the button should fall under.
    // [{ value: :fish, label: 'Fish', group: 'Water Animals'}]
    enableGrouping: { type: Boolean, default: false },
  },
  computed: {
    inputGroups () {
      return this.enableGrouping ? Object.keys(this.groupedItems) : ['ignore_grouping']
    },
    groupedItems () {
      return groupBy(this.items, 'group')
    },
  },
  methods: {
    // Internal: Propagates the change event as an input event.
    // NOTE: We listen to "change" for compatibility with old browsers.
    onChange (event) {
      this.$emit('input', event.target.value)
    },
    checked (item) {
      return item[this.valueProp] === this.value
    },
    itemsByGroup (group) {
      return this.groupedItems[group]
    },
    itemsList (group) {
      return this.enableGrouping ? this.itemsByGroup(group) : this.items
    },
  },
}
</script>

<template>
  <div class="radio-buttons">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <div :class="{ inline: !isIE && inline }" class="radio-buttons__container">
      <template v-if="items.length">
        <template v-for="(group, groupIndex) in inputGroups">
          <Label v-if="group != 'ignore_grouping' && !!group" :key="groupIndex" class="grouping-label">{{ group }}</Label>
          <template v-for="(item) in itemsList(group)">
            <div :key="`${item[labelProp]}-${item[valueProp]}${group}`" class="radio-button__item">
              <label
                :disabled="disabled || item.disabled"
                :class="{ checked: checked(item), error: item.error }"
                class="radio-button"
              >
                <input
                  :checked="checked(item)"
                  :disabled="disabled || item.disabled"
                  :name="name"
                  :value="item[valueProp]"
                  type="radio"
                  :class="{ error: item.error }"
                  class="radio-button__input"
                  @change="onChange"
                >
                <span class="radio-button__highlight"/>
                <span class="radio-button__label item__label">
                  <slot :item="item" :label="item[labelProp]" name="inputLabel">{{ item[labelProp] }}</slot>
                </span>
              </label>
              <Hint :content="item.hint" class="radio-button__hint"/>
              <InputError v-if="item.error" :error="item.error"/>

            </div>
            <slot :item="item" name="contentExtras"/>
          </template>
        </template>
      </template>
      <LoadingPlaceholder v-else :size="placeholderItems"/>
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
$radio-height: 16px;

.grouping-label {
  margin-top: 16px;
}

.radio-buttons {
  position: relative;
}

.radio-buttons__container.inline {
  @include flex-row;

  align-items: center;
  flex-wrap: wrap;

  .loading-placeholder {
    flex-grow: 1;
    margin-bottom: 1px;
    margin-top: 12px;
  }
}

.radio-button__item {
  align-items: center;
  display: flex;
  margin-top: 12px;
  position: relative;

  .radio-buttons__container.inline & {
    margin-bottom: 0;
    margin-right: 32px;

    &:last-child {
      flex: auto;
    }
  }

  & > .input-error {
    left: 24px;
    margin-top: 16px;
  }
}

.radio-button {
  @include clickable;

  display: block;
  font-weight: $fw-html;
  margin-bottom: 0;
  padding-left: 24px;
  position: relative;
  user-select: none;

  &.error {
    color: $input-error-color;
  }
}

.radio-button__input {
  left: 0;
  opacity: 0;
  position: absolute;
  top: 4px;
}

.radio-button__highlight {
  background-color: $WHITE;
  border: 1px solid $input-border-color;
  border-radius: 50%;
  height: $radio-height;
  left: 0;
  position: absolute;
  top: 2px;
  transition: all 0.3s ease-out;
  width: $radio-height;

  &::before {
    background: $WHITE;
    border-radius: $radius-normal;
    content: '';
    display: none;
    height: 6px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 6px;
  }

  .radio-button:hover & {
    border-color: $primary-color;
  }

  .radio-button__input:checked ~ & {
    background-color: $primary-color;
    border-color: $primary-color;

    &::before {
      display: block;
    }
  }

  .radio-button__input.error ~ & {
    background-color: $input-error-background-color;
    border-color: $input-error-color;

    &::before {
      background: $input-error-color;
    }
  }

  .radio-button__input[disabled] ~ & {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
    box-shadow: none;

    &::before {
      background: $input-disabled-color;
    }

    & ~ .radio-button__label {
      color: $input-disabled-color;
    }
  }

  .radio-button__input.error:hover ~ & {
    background-color: $input-error-background-color;
    border-color: $input-error-color;
  }

  .radio-button__input[disabled]:hover ~ & {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
  }
}
</style>
