<script>
import AuthStore from '@stores/AuthStore'
import BackButton from '@components/BackButton'

export default {
  name: 'TitleSection',
  components: {
    BackButton,
  },
  page () {
    return { title: this.title }
  },
  props: {
    // Public: The title to display as a heading. You may also use the `title` slot.
    // NOTE: By default it is also used as the page title.
    title: { type: String, default: undefined },

    // Public: Display a back button at the beginning of the title.
    // Optionally, the route it navigates to when there's no previous page.
    backBtn: { type: [Boolean, String, Array, Object], default: false },

    // Public: Whether a loading indicator should be displayed next to the title.
    loading: { type: Boolean, default: false },

    // Public: Similar to loading, but waits until the Promise is resolved.
    loadingUntil: { type: [Promise, Object]/* type: Promise, but IE11 polyfill */, default: null },

    // Public: Display a dropdown with the related pages next to the title.
    relatedPages: { type: Array, default: () => [] },

    // Optional: The url of the old version of the page.
    oldPageUrl: { type: String, default: null },

    // Public: Amount of actions that should be displayed outside the dropdown.
    displayUpTo: { type: Number, default: 3 },
  },
  computed: {
    allowedRelatedPages () {
      return this.relatedPages.filter(page => this.hasPermission(page.permission))
    },
    canSwitchToClassic () {
      return !AuthStore.careProvider.useSlickPages || this.hasPermission('slick_user')
    },
  },
}
</script>

<template>
  <div class="title-section">
    <BackButton v-if="backBtn" :to="backBtn === true ? null : backBtn"/>

    <span class="title">
      <slot name="title">{{ title || pageTitle }}</slot>
      <LoadingIndicator v-if="loading" size="small"/>
      <PromiseIndicator v-else :loadingUntil="loadingUntil"/>
    </span>

    <slot/>

    <Dropdown v-if="allowedRelatedPages.length" position="left" class="related-pages">
      <Button slot="dropdownToggle" class="related-pages-toggle" icon="downSmall"/>
      <template slot="dropdownContent">
        <ActionLink
          v-for="page in allowedRelatedPages"
          :key="page.title"
          :to="page.to"
          :label="page.title"
          class="related-page"
        />
      </template>
    </Dropdown>

    <NavigationLink v-if="oldPageUrl && canSwitchToClassic" :to="oldPageUrl" class="old-page underline">Legacy Version</NavigationLink>

    <slot name="wizardProgress"/>

    <div class="actions">
      <TitleSectionActions v-if="$slots.actions || $slots.expandedActions" :displayUpTo="displayUpTo">
        <template slot="expandedActions">
          <slot name="expandedActions"/>
        </template>
        <slot name="actions"/>
      </TitleSectionActions>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.title-section {
  @include media(desktop) {
    padding: 24px $padding-horizontal-html;
  }

  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 24px $padding-horizontal-html-compact;
  position: relative;
}

.title {
  align-items: center;
  display: inline-flex;
  font-size: $fs-title;
  font-weight: $fw-bold;
  letter-spacing: 0.03em;
  line-height: $button-full-height;
  margin-right: 8px;

  & > .loading-indicator {
    display: inline-flex;
    margin: 0 8px 0;
  }
}

.related-pages {
  margin-left: 16px;
}

.related-pages-toggle {
  fill: $fill-title-section-icon;
}

.related-page {
  display: block;
}

.old-page {
  color: $fc-html-medium;
  flex-shrink: 0; //avoid wrapping on narrower screens
  margin-left: 32px;
}

.actions {
  align-items: center;
  display: flex;
  fill: currentColor;
  font-size: $fs-larger;
  font-weight: $fw-bold;
  margin-left: auto;
}

.back-button {
  color: $fill-title-section-icon;
  margin: -8px 8px -8px -8px;
}

@include media(only-tablet-landscape) {
  .related-pages,
  .old-page {
    display: none;
  }
}
</style>
