<script>
import { highlightItemLabel } from '@helpers/SelectInputHelper'

export default {
  name: 'SelectInputItemLabel',
  props: {
    // Public: Item we are showing
    item: { type: Object, required: true },

    // Public: User input to filter the displayed options.
    filteringBy: { type: String, default: '' },

    // Public: Item property to use when displaying items in the dropdown.
    labelProp: { type: String, default: 'label' },

    // Public: Item property to use when displaying a badge in the dropdown item
    badgeProp: { type: String, default: null },
  },
  computed: {
    // Helper: Returns the HTML for a highlighted version of the label.
    highlightedItemLabel () {
      return highlightItemLabel(this.item, this.labelProp, this.filteringBy)
    },
    badgeText () {
      return this.badgeProp && this.item[this.badgeProp]
    },
  },
}
</script>

<template>
  <div class="input-item-title-wrapper" :class="{ disabled: item.disabledSelection }">
    <div class="input-item-title" v-html="highlightedItemLabel"/>
    <Badge v-if="badgeText" :label="badgeText" class="input-item-badge" :highlightedString="filteringBy"/>
  </div>
</template>

<style lang="scss" scoped>

.input-item-title-wrapper {
  background-color: auto;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px 4px;
  vertical-align: middle;

  &.disabled {
    color: $input-disabled-color;
    cursor: not-allowed;
  }

  .input-item.unselect & {
    color: $fc-html-light;
  }

  .input-item-badge {
    align-self: baseline;
    text-transform: none;
  }
}

</style>
