import CacheService, { defaultNamespace, keySeparator } from '@services/CacheService'
import LocalStorage from '@services/LocalStorageService'
import { pick } from 'lodash'

// NOTE: Not concatenating prefix so that it's more evident that this key is
// used in welcome.js.coffee.
const userIdKey = 'cipherhealth:evolve:user:currentUserId'

const KEYS_TO_STORE = ['careProvider', 'currentUser', 'currentSignInAt', 'csrfToken', 'navigationBar', 'trackPageVisits']

// Public: Simplifies storing information that is specific to the current user.
class UserCache extends CacheService {
  constructor () {
    super()
    // NOTE: In order to be able to make this assumption safely, it's vital that
    // the sign in form clears this key. Else we could attempt to load data
    // cached for a different user. Right now this is done in welcome.js.coffee
    const userId = LocalStorage.get(userIdKey)
    this.setNamespace(userId)
  }

  // Internal: Updates the default cache namespace.
  setNamespace (userId) {
    this.namespace = [defaultNamespace, 'user', userId].join(keySeparator)
  }

  // Public: Stores data specific to the user and the care provider.
  storeAll (userInfo) {
    const userInfoToPersist = pick(userInfo, KEYS_TO_STORE)

    // Ensures that next page visits can detect this current user from local storage.
    const userId = userInfoToPersist.currentUser.id
    LocalStorage.set(userIdKey, userId)
    this.setNamespace(userId)

    // Allow inferring the current care provider on the next page visit.
    const careProviderId = userInfoToPersist.careProvider.id
    this.set('careProviderId', careProviderId)

    // Store information relative to this care provider (permissions, navbar, etc).
    this.setAll(userInfoToPersist, { within: careProviderId })
  }

  // Public: Retrieves the stored data for the specified user id.
  retrieveAll (careProviderId) {
    // Won't be able to infer the current user on the first page after login.
    if (!this.namespace) return

    // Infer the current care provider if the URL is not provider-specific.
    if (!careProviderId) careProviderId = this.get('careProviderId')
    if (!careProviderId) return

    // Now that we have the user and care provider, retrieve the information.
    return this.getAll(KEYS_TO_STORE, { within: careProviderId })
  }
}

export default new UserCache()
