import { last } from 'lodash'

// Public: Returns the extension of the given file
export function toExtension (fileName) {
  if (fileName.indexOf('.') === -1) return ''
  return last(fileName.split('.')).toLowerCase()
}

// Public: Tranforms a FileList element into an Array of Files.
export function filesToArray (fileList) {
  return fileList ? Array.from(fileList) : []
}

// Public: Returns true if the file object has a `type` property.
//
// NOTE: This corresponds to the MIME type of the file. Browsers usually set it
// for all files, but not for directories.
export function hasFileType (file) {
  return Boolean(file.type)
}
