<script>
import HelpStore from '@stores/HelpStore'
import NavigationBarIcon from '@components/NavigationBarIcon'
import NavigationBarMenuItem from '@components/NavigationBarMenuItem'

export default {
  name: 'NavigationBarHelpMenu',
  components: {
    NavigationBarIcon,
    NavigationBarMenuItem,
  },
  methods: {
    ...HelpStore.mapActions('displayKeyboardOverlay'),
  },
}
</script>

<template>
  <Dropdown name="Help" position="right" class="navbar-help-dropdown use-inset-shadow">
    <template slot="dropdownToggle">
      <NavigationBarIcon title="Help"/>
    </template>

    <template slot="dropdownContent">
      <NavigationBarMenuItem title="Get Support" to="Support"/>
      <NavigationBarMenuItem title="Help Center" :to="evolveUrl('help')" class="navbar-submenu"/>
      <NavigationBarMenuItem title="Keyboard Shortcuts" @click="displayKeyboardOverlay"/>
      <NavigationBarMenuItem title="Share Feedback" @click="asyncOpenModal('Help/UserFeedbackModal')"/>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
</style>
