<script>
import ActionCableProvider from '@providers/ActionCableProvider'
import ChatStore from '@stores/ChatStore'

export default {
  name: 'PatientIssuesCableProvider',
  components: {
    ActionCableProvider,
  },
  methods: {
    connected (cableSubscription) {
    },
    received (data, cableSubscription) {
      // we receive new created issues here, to update the message center on real time
      ChatStore.addIssue(data)
    },
  },
}
</script>

<template>
  <ActionCableProvider
    channelName="PatientIssuesChatChannel"
    :listeners="{ connected, received }"
    :autoReconnect="false"
  />
</template>

<style lang="scss" scoped>
</style>
