<script>
import { random } from 'lodash'

// Public: Useful to make room for a list of items that has not loaded yet.
export default {
  name: 'LoadingPlaceholder',
  props: {
    // Public: The amount of placeholder items to display.
    size: { type: Number, default: 1 },

    // Public: If the parent component is rendered inline
    inline: { type: Boolean, default: false },

    // Public: The minimum width of a placeholder item.
    minWidth: { type: Number, default: 30 },

    // Public: The maximum width of a placeholder item.
    maxWidth: { type: Number, default () { return this.inline ? 70 : 80 } },
  },
  methods: {
    // Internal: Generate a width variation for the different placeholder items.
    randomWidth () {
      return random(this.minWidth, this.maxWidth)
    },
  },
}
</script>

<template>
  <div class="loading-placeholder">
    <div v-for="i in size" :key="i" class="loading-placeholder__item" :style="{ width: randomWidth() + '%' }"/>
  </div>
</template>

<style lang="scss" scoped>
$placeholder-height: 12px;
$placeholder-max-width: 1000px;

.loading-placeholder__item {
  animation-duration: 1.8s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, $tundora-l-3 8%, $tundora-l-2 38%, $tundora-l-3 54%);
  background-size: $placeholder-max-width 640px;
  height: $placeholder-height;
  margin-bottom: 8px;
  max-width: $placeholder-max-width;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}
</style>
