<script>
import KeySymbols from '@constants/KeySymbols'
import ProTip from '@components/ProTip'
import QuicksearchConstants from '@constants/QuicksearchConstants'
import { isBlank } from '@helpers/ObjectHelper'

export default {
  name: 'QuicksearchProTips',
  components: {
    ProTip,
  },
  constants: {
    KeySymbols,
  },
  props: {
    searchField: { type: String, required: true },

    // Internal: The current search query.
    filteringBy: { type: String, default: null },

    // Internal: The search results.
    patients: { type: Array, required: true },

    // Internal: Whether the user typed a new search but the delay has not ellapsed.
    searchIsPending: { type: Boolean, required: true },

    // Internal: Whether the last search failed.
    lastSearchError: { type: Boolean, required: true },
  },
  computed: {
    queryIsEmpty () {
      return isBlank(this.filteringBy)
    },
    queryIsTooShort () {
      return this.filteringBy.trim().length < QuicksearchConstants.minimumQueryLength[this.searchField]
    },
    noResults () {
      return this.patients.length === 0
    },
    oneResult () {
      return this.patients.length === 1
    },
    moreThanOneResult () {
      return this.patients.length > 1
    },
  },
}
</script>
<template>
  <ProTip v-if="queryIsEmpty">
    <span>Search {{ translate('models.patients') }} by name or {{ translate('encounter.mrn') }}.</span>
  </ProTip>
  <ProTip v-else-if="queryIsTooShort" icon="error">
    <span>The search query is too short.</span>
  </ProTip>
  <ProTip v-else-if="searchIsPending" icon="search">
    Searching results for <span class="pro-tip-query">'{{ filteringBy }}'</span>
  </ProTip>
  <ProTip v-else-if="lastSearchError" icon="error">
    {{ translate('search.defaults.errors.summary') }}
  </ProTip>
  <ProTip v-else-if="noResults" icon="emptySearch">
    No results matching <span class="pro-tip-query">'{{ filteringBy }}'</span>
  </ProTip>
  <ProTip v-else-if="oneResult">
    Navigate by pressing <kbd class="kbd" v-text="KeySymbols.Enter"/>
  </ProTip>
  <ProTip v-else-if="moreThanOneResult">
    Navigate list by pressing <kbd class="kbd" v-text="KeySymbols.ArrowUp"/><kbd class="kbd" v-text="KeySymbols.ArrowDown"/>
  </ProTip>
  <ProTip v-else icon="search">
    Results for <span class="pro-tip-query">'{{ filteringBy }}'</span>
  </ProTip>
</template>

<style lang="scss" scoped>
</style>
