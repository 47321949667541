// CacheKey 1f05b166b8edbbe02312bc7236d5333c
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { request } from '@services/EvolveApiService'

export default {
  read: options =>
    request('patch', '/vue/user_notifications/:id/read', options),

  readAll: options =>
    request('put', '/vue/user_notifications/read_all', options),

  destroyAll: options =>
    request('delete', '/vue/user_notifications/destroy_all', options),

  list: options =>
    request('get', '/vue/user_notifications', options),

  destroy: options =>
    request('delete', '/vue/user_notifications/:id', options),
}
