import MediaBreakpoints from '@constants/MediaBreakpoints'
import { navbarHeight } from '@style/JsVariables.scss'
import { registerAndGetStore } from '@helpers/StoreHelper'

export const state = {
  // Whether the navigation bar and the footer should be hidden.
  isFullscreen: false,

  // Whether the layout is currently maximized.
  isLayoutMaximized: false,

  // Whether a loading indicator should be shown.
  isNavigating: false,

  // Whether we should hide the navbar
  navbarHidden: false,

  // The current height of the navigation bar.
  navbarHeight,

  // The current height of the window.
  windowHeight: window.innerHeight,

  // The current width of the window.
  windowWidth: window.innerWidth,
}

export const getters = {
  windowSize (state) {
    return state.windowHeight * state.windowWidth
  },

  // Public: Returns a function that should be invoked with a media screen name,
  // which returns true if the media breakpoint condition is fulfilled.
  isScreen (state) {
    return breakpoint => {
      const breakpointFn = MediaBreakpoints.screen[breakpoint] || MediaBreakpoints.smallScreen[breakpoint] || throw new Error(`Unknown MediaBreakpoint screen: ${breakpoint}`)
      return () => breakpointFn({ width: state.windowWidth, height: state.windowHeight })
    }
  },
}

export const mutations = {
  SET_FULLSCREEN (state, isFullscreen) {
    state.isFullscreen = isFullscreen
  },
  SET_LAYOUT_MAXIMIZED (state, isLayoutMaximized) {
    state.isLayoutMaximized = isLayoutMaximized
  },
  SET_NAVIGATING (state, isNavigating) {
    state.isNavigating = isNavigating
  },
  SET_NAVBAR_HEIGHT (state, navbarHeight) {
    state.navbarHeight = navbarHeight
  },
  SET_WINDOW_SIZE (state, { height, width }) {
    state.windowHeight = height
    state.windowWidth = width
  },
  SET_HIDE_NAVBAR (state, hidden) {
    state.navbarHidden = hidden
  },
}

export const actions = {
  // Toggles the navbar display
  hideNavbar ({ commit }, hidden) {
    commit('SET_HIDE_NAVBAR', hidden)
  },

  // Changes to fullscreen mode.
  setFullscreen ({ commit }, isFullscreen) {
    commit('SET_FULLSCREEN', isFullscreen)
    commit('SET_HIDE_NAVBAR', isFullscreen)
  },

  // Allows nested components to detect changes to the layout size.
  setLayoutMaximized ({ commit }, isLayoutMaximized) {
    commit('SET_LAYOUT_MAXIMIZED', isLayoutMaximized)
  },

  // Shows/hides a progress bar that indicates navigation.
  setNavigating ({ commit }, isNavigating) {
    if (state.isNavigating !== isNavigating) commit('SET_NAVIGATING', isNavigating)
  },

  // Updates the size of the navigation bar on a resize event.
  setNavbarHeight ({ commit, state }, navbarHeight) {
    if (state.navbarHeight !== navbarHeight) commit('SET_NAVBAR_HEIGHT', navbarHeight)
  },

  // Updates the screen size when the window is resized.
  updateWindowSize ({ commit }) {
    commit('SET_WINDOW_SIZE', { height: window.innerHeight, width: window.innerWidth })
  },
}

const WindowStore = registerAndGetStore('window', { state, getters, mutations, actions })
window.addEventListener('resize', WindowStore.updateWindowSize, { passive: true })
export default WindowStore
