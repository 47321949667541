<script>
import SearchStatus from '@constants/SearchStatus'
import { titleize } from '@helpers/StringHelper'
import { translate, translateWithFallback } from '@helpers/TranslationHelper'

export default {
  name: 'Blankslate',
  props: {
    // Public: The status governs the state for the blankslate.
    //   - SearchStatus.EMPTY: Displays a title and message for empty content.
    //   - SearchStatus.LOADING: Displays a loading spinner.
    //   - SearchStatus.ERROR: Displays an error title and message.
    //   - SearchStatus.READY: The blankslate is hidden.
    status: { type: String, default: SearchStatus.EMPTY },

    // Public: Whether to use the normal or the search-specific translations.
    // See the "search" section in search.en.yml for examples.
    //   true - "We could not find any items".
    //   false - "There are no items"
    isSearching: { type: Boolean, default: false },

    // Public: Whether to use the normal or the empty page specific translations.
    // See the "search" section in search.en.yml for examples.
    //   true - "No items in the current page".
    //   false - "There are no items"
    isPaginating: { type: Boolean, default: false },

    // Public: The text to use in the {{item}} placeholder of the translations.
    // See the "search" section in search.en.yml for examples.
    itemsLabel: { type: String, default: null },

    // Public: The translation scope to be used for the title and message.
    // See the "search" section in search.en.yml for examples.
    translationScope: { type: String, default: null },

    // Public: True to make the blankslate show only text, no icon
    textOnly: { type: Boolean, default: false },

    // Public: Custom message to show instead of translations for EMPTY or ERROR status
    customMessage: { type: String, default: null },
  },
  computed: {
    // Internal: The fallbacks for translations performed in this component.
    translationOptions () {
      return [
        (this.translationScope && { prefix: `search.${this.translationScope}` }),
        { prefix: 'search.defaults' },
      ].filter(x => x)
    },
    // Internal: The translated 'items' phrase or word. Example: 'patients'
    translatedItems () {
      if (this.itemsLabel) return this.itemsLabel
      return this.translationScope
        ? translate('items', this.translationOptions[0]) || titleize(this.translationScope).toLowerCase()
        : translateWithFallback('items', this.translationOptions)
    },
    // Internal: Set the translation parameters for the empty and error messages.
    translationOptionsWithItems () {
      return this.translationOptions.map(options => ({ ...options, item: this.translatedItems }))
    },
    loading () {
      return this.status === SearchStatus.LOADING
    },
    empty () {
      return this.status === SearchStatus.EMPTY
    },
    ready () {
      return this.status === SearchStatus.READY
    },
    emptySummaryKey () {
      if (this.isSearching) return 'empty.searchSummary'
      if (this.isPaginating) return 'empty.pageSummary'
      return 'empty.summary'
    },
    emptyMessageKey () {
      if (this.isSearching) return 'empty.searchMessage'
      if (this.isPaginating) return 'empty.pageMessage'
      return 'empty.message'
    },
  },
  methods: {
    translate (key) {
      return translateWithFallback(key, this.translationOptionsWithItems)
    },
  },
}
</script>

<template>
  <div v-if="!ready" class="blankslate" :class="`blankslate-${status}`">
    <template v-if="loading">
      <LoadingIndicator size="huge"/>
    </template>
    <template v-else-if="empty">
      <slot>
        <Icon v-if="!textOnly" :name="translate('empty.icon') || 'search'" size="large"/>
        <h2 class="blankslate-summary" v-html="translate(emptySummaryKey)"/>
        <span v-html="customMessage || translate(emptyMessageKey)"/>
      </slot>
    </template>
    <template v-else>
      <Icon v-if="!textOnly" :name="translate('errors.icon') || 'error'" size="large"/>
      <h2 class="blankslate-summary" v-html="translate('errors.summary')"/>
      <span v-html="customMessage || translate('errors.message')"/>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.blankslate {
  font-size: $fs-html;
  padding: 30px;
  position: relative;
  text-align: center;
}

.blankslate-summary {
  font-size: $fs-headline;
  line-height: 1.5;
  margin: 0 0 0.5rem 0;
}

.icon {
  margin: 0 auto;
}
</style>
