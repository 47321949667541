<script>
import { differenceInMinutes } from 'date-fns'
import { exactTimeFromNow, fromNow } from '@helpers/TimeAgoHelper'
import { formatDate } from '@helpers/DateHelper'

const DATE_NOT_AVAILABLE = 'n/a'

export default {
  name: 'TimeAgo',
  props: {
    value: { type: [Date, String, Object], default: null },

    // Calculates exact time ago in hours and minutes. Otherwise gives rounded times.
    precise: { type: Boolean, default: false },

    // Whether to display the time in the tooltip in the browser's time zone,
    // by default all date times are displayed in the care provider's time zone.
    inUserTimeZone: { type: Boolean, default: false },
  },
  data () {
    return {
      activeTimeout: null,
      currentTimeAgo: null,
    }
  },
  computed: {
    timeZone () {
      return this.inUserTimeZone ? 'userTimeZone' : 'careProviderTimeZone'
    },
    humanTimeString () {
      return formatDate(this.value, { timeZone: this.timeZone })
    },
    isoTimeString () {
      return formatDate(this.value, { format: 'date_time', timeZone: this.timeZone })
    },
  },
  watch: {
    value () {
      this.updateCurrentTimeAgo()
    },
  },
  created () {
    this.updateCurrentTimeAgo()
  },
  beforeDestroy () {
    this.cancelTimer()
  },
  methods: {
    cancelTimer () {
      clearTimeout(this.activeTimeout)
    },
    currentTime () {
      return this.precise ? exactTimeFromNow(this.value, { countDays: false, defaultValue: DATE_NOT_AVAILABLE }) : fromNow(this.value, { addSuffix: true })
    },
    secondsUntilUpdate () {
      const howOld = Math.abs(differenceInMinutes(new Date(), this.timestamp))
      if (howOld < 1) return 1
      if (howOld < 60) return 30
      if (this.precise) return 60
      if (howOld < 180) return 300
      return 3600
    },
    updateCurrentTimeAgo () {
      this.cancelTimer()
      this.currentTimeAgo = this.currentTime()
      if (this.currentTimeAgo) {
        this.activeTimeout = this.setTimeoutAndClear(this.updateCurrentTimeAgo, this.secondsUntilUpdate() * 1000)
      }
    },
  },
}
</script>

<template>
  <time v-tooltip="humanTimeString" :date-time="isoTimeString">{{ currentTimeAgo }}</time>
</template>
