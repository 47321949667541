// Public: True if the app is running on a Mac platform.
export const isMac = navigator.platform.match('Mac')

// Public: True if the browser is Internet Explorer.
// NOTE: The first check is for IE10 or lower, second check is for IE11.
export const isIE = navigator.userAgent.includes('MSIE ') || navigator.userAgent.includes('Trident/')

// Public: True if the browser is Safari. Check mac and mobile
export const isSafari = (navigator.userAgent.includes('Safari') && navigator.userAgent.includes('Version/')) ||
  (navigator.userAgent.includes('AppleWebKit') && navigator.userAgent.includes('Mobile/'))

// Public: True if the app is running in an Android phone.
export const isAndroid = navigator.userAgent.includes('Android')

// Public: True if the app is running on a an Apple device.
export const isAppleDevice = !!navigator.platform && !!(/iPad|iPhone|iPod|iPhone Simulator|iPod Simulator|iPad Simulator/g).test(navigator.platform)
