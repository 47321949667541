<script>
import RoutesStore from '@stores/RoutesStore'

export default {
  name: 'BackButton',
  props: {
    size: { type: String, default: 'regular' },
    to: { type: [String, Array, Object], default: null },
  },
  methods: {
    goBack () {
      // We need to call this before because `RoutesStore.navigatingBack` pops the stack.
      const canGoBack = RoutesStore.canGoBack
      RoutesStore.navigatingBack()

      if (this.to) {
        this.navigateTo(this.to)
      } else {
        canGoBack ? this.$router.back() : this.navigateTo(document.referrer || '/')
      }
    },
  },
}
</script>

<template>
  <ActionLink class="back-button" icon="arrowLeft" :size="size" @click="goBack"/>
</template>

<style lang="scss" scoped>
.back-button {
  padding: 8px;

  &:hover {
    animation: bounceBack 0.8s $ease-out-sine infinite;
  }
}
</style>
