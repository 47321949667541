import {
  addDays,
  addHours,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInYears,
  formatDistance,
} from 'date-fns'
import { pluralize } from '@helpers/StringHelper'
import { toDate } from '@helpers/DateHelper'

// Public: Return the exact distance between the given date and the current time in precise hours and minutes
//
// date - ISO8601 date string
// defaultValue - The value to return in case the date is empty
export function exactTimeFromNow (date, { countDays = true, defaultValue = '' } = {}) {
  if (date) {
    let pastDate = toDate(date)
    const currentDate = new Date()

    const timeAgoInWords = []

    // calculate days
    if (countDays) {
      const days = differenceInDays(currentDate, pastDate)
      pastDate = addDays(pastDate, days)
      if (days > 0) { timeAgoInWords.push(`${days} ${pluralize(days, 'day')}`) }
    }

    // calculate hours
    const hours = differenceInHours(currentDate, pastDate)
    pastDate = addHours(pastDate, hours)
    if (hours > 0) { timeAgoInWords.push(`${hours} ${pluralize(hours, 'hour')}`) }

    // calculate minutes
    const minutes = differenceInMinutes(currentDate, pastDate)
    if (minutes > 0) { timeAgoInWords.push(`${minutes} ${pluralize(minutes, 'min')}`) }

    // combine text to string
    const timeText = timeAgoInWords.join(' ')

    return timeText || 'less than a minute'
  } else {
    return defaultValue
  }
}

// Public: Return the distance between the given dates in words.
//
// date - ISO8601 date string
// defaultValue - The value to return in case the date is empty
export function fromNow (date, { until, defaultValue = '', ...options } = {}) {
  return date ? customFormatDistance({ dateFrom: toDate(date), dateTo: until, ...options }) : defaultValue
}

// Internal: Custom algorithm to format the distance between two dates in words.
// Similar to the date-fns one but with slight modifications.
function customFormatDistance ({ dateFrom, dateTo = new Date(), addSuffix = false }) {
  const seconds = differenceInSeconds(dateTo, dateFrom)

  // For future dates keep on using time ago
  if (seconds < 0) return formatDistance(dateFrom, dateTo, { addSuffix })

  if (seconds <= 59) return 'less than 1 minute ago'

  const minutes = differenceInMinutes(dateTo, dateFrom)
  const secondsOverMinute = seconds % 60
  if (minutes <= 1 && secondsOverMinute < 30) return '1 minute ago'
  if (minutes < 45) {
    if (secondsOverMinute < 30) return `${minutes} minutes ago`
    if (secondsOverMinute >= 30) return `${minutes + 1} minutes ago`
  }

  if (minutes >= 45 && minutes < 90) return 'about 1 hour ago'
  const hours = differenceInHours(dateTo, dateFrom)
  if (hours < 24) return `${hours} hours ago`

  const days = differenceInDays(dateTo, dateFrom)
  const hoursOverDay = hours % 24
  if (days <= 1 && hoursOverDay <= 12) return 'about 1 day ago'
  if (days <= 2) return 'about 2 days ago'
  if (days <= 3) return 'about 3 days ago'
  if (days <= 4) return 'about 4 days ago'
  if (days <= 5) return 'about 5 days ago'

  // weeks
  if (days < 11) return 'about 1 week ago'
  if (days < 18) return 'about 2 weeks ago'
  if (days < 25) return 'about 3 weeks ago'

  const months = differenceInMonths(dateTo, dateFrom)
  if (months <= 1) return 'about 1 month ago'
  if (months > 1 && months <= 6) return `about ${months} months ago`
  if (months < 12) return `${months} months ago`

  const years = differenceInYears(dateTo, dateFrom)
  const s = years > 1 ? 's' : ''
  const monthsOverYear = months % 12
  if (monthsOverYear <= 3) return `about ${years} year${s} ago`
  if (monthsOverYear > 3 && monthsOverYear <= 9) return `over ${years} year${s} ago`
  return `almost ${years + 1} years ago`
}
