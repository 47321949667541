<script>
import SelectionStatus from '@constants/SelectionStatus'
import { values } from 'lodash'

export default {
  name: 'TableCellCheckbox',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    // Internal: Also supports a Number to provide an easy way to address the
    // indeterminate state of a checkbox in a simpler way.
    // 1: true, 0: false, -1: indeterminante
    value: { type: [Number, Boolean], default: 0 },

    // Public: Whether to render a `th` instead of a `td`.
    header: { type: Boolean, default: false },

    // Public: Whether the Checkbox is disabled or not.
    disabled: { type: Boolean, default: false },

    // Internal: Set by TableRow to determine if the table cell should be fixed
    // when scrolling the table horizontally.
    stickyScrolling: { type: [Boolean, Object], default: null },
  },
  data () {
    return {
      checked: false,
      indeterminate: false,
    }
  },
  computed: {
    cellType () {
      return this.header ? 'th' : 'td'
    },
    isSelectionStatus () {
      return values(SelectionStatus).includes(this.value)
    },
  },
  watch: {
    value (value) {
      this.updateCheckboxState(value)
    },
  },
  created () {
    this.updateCheckboxState(this.value)
  },
  methods: {
    // Internal: Emits a Number if value is a Number, or a Boolean otherwise.
    onChange (isChecked) {
      this.$emit('change', this.changeValue(isChecked))
    },
    // Internal: Which value to propagate for the change, returns a Number if
    // value is a Number, or a Boolean otherwise.
    changeValue (isChecked) {
      if (isChecked) return this.isSelectionStatus ? SelectionStatus.ALL : true
      return this.isSelectionStatus ? SelectionStatus.NONE : false
    },
    // Internal: Updates the indeterminate and checked properties of the checkbox.
    updateCheckboxState (value) {
      this.checked = value === SelectionStatus.ALL || value === true
      this.indeterminate = value === SelectionStatus.INDETERMINATE
    },
  },
}
</script>

<template>
  <component :is="cellType" v-stick-to-left="stickyScrolling" class="table-cell table-cell-checkbox">
    <Checkbox :value="checked" :indeterminate="indeterminate" :disabled="disabled" @input="onChange"/>
  </component>
</template>

<style lang="scss" scoped>
.table-cell-checkbox {
  max-width: $padding-horizontal-navbar * 2;
  padding-right: 0;
  width: $padding-horizontal-navbar * 2;

  & ::v-deep .checkbox__inner {
    padding-bottom: 20px;
  }
}
</style>
