import { render, staticRenderFns } from "./ColumnsContainer.vue?vue&type=template&id=31f9f538&scoped=true&"
import script from "./ColumnsContainer.vue?vue&type=script&lang=js&"
export * from "./ColumnsContainer.vue?vue&type=script&lang=js&"
import style0 from "./ColumnsContainer.vue?vue&type=style&index=0&id=31f9f538&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31f9f538",
  null
  
)

export default component.exports