<script>
export default {
  name: 'TableCell',
  props: {
    // Internal: Set by TableRow to determine if the table cell should be fixed
    // when scrolling the table horizontally.
    stickyScrolling: { type: [Boolean, Object], default: null },
  },
}
</script>

<template>
  <td v-stick-to-left="stickyScrolling" class="table-cell"><slot/></td>
</template>

<style lang="scss" scoped>
.table-cell {
  padding: $cell-padding;

  &:empty::after {
    content: '-';
  }

  & > ::v-deep .navigation-link:not(.non-link) {
    @include link-style;

    color: $cell-link-color;
  }

  & > ::v-deep .badge {
    display: inline-block;
  }

  &.table-cell-actions {
    text-align: right;
  }
}
</style>
