<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'FormInputErrorPlaceholder',
  mixins: [
    InputMixin,
  ],
  props: {
    value: { type: [String, Number, Array, Object], default: null },
  },
  watch: {
    value: {
      deep: true,
      handler () {
        this.$emit('input:valid')
      },
    },
  },
}
</script>

<template>
  <Crouton
    v-if="error"
    error
    :closeBtn="false"
    :duration="false"
    class="input-error"
  >
    {{ error.join ? error.join(', ') : error }}
  </Crouton>
</template>

<style lang="scss" scoped>

</style>
