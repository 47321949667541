<script>
import material from 'material-colors'

const defaultColorGroups = [
  'red',
  'pink',
  'purple',
  'deepPurple',
  'indigo',
  'blue',
  'lightBlue',
  'cyan',
  'teal',
  'green',
  'lightGreen',
  'yellow',
  'amber',
  'orange',
  'deepOrange',
  'brown',
  'blueGrey',
]

const colorLevels = ['900', '700', '500', '300', '100']

const defaultColors = defaultColorGroups.map(type => colorLevels.map(level => material[type][level].toUpperCase()))
defaultColors.unshift(['#000000', '#4D4D4D', '#898989', '#ADADAD', '#FFFFFF'])

export default {
  name: 'Swatches',
  props: {
    // Public: The selected color in hexadecimal code.
    value: { type: String, default: null },

    // Optional: An array of arrays of colors.
    palette: { type: Array, default () { return defaultColors } },
  },
  methods: {
    isSelected (color) {
      return color.toUpperCase() === String(this.value).toUpperCase()
    },
    selectColor (color) {
      this.$emit('input', color)
    },
  },
}
</script>

<template>
  <div class="color-swatches">
    <div v-for="(group, index) in palette" :key="index" class="color-palette-group">
      <div
        v-for="color in group"
        :key="color"
        class="color-swatch"
        :data-color="color"
        :class="{ 'color-swatch--white': color === '#FFFFFF' }"
        :style="{ background: color }"
        @click="selectColor(color)"
      >
        <Icon v-if="isSelected(color)" name="checkmark" class="color-swatch__selected-icon"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.color-swatches {
  display: grid;
  grid-column-gap: 8px;
  grid-row-gap: 12px;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: auto;
}

.color-swatch {
  @include clickable;

  border-radius: 2px 2px 0 0;
  height: 24px;
  margin-bottom: 1px;
  width: 40px;
}

.color-swatch__selected-icon {
  color: $WHITE;
}

.color-swatch--white {
  border: 1px solid $br-color-light;

  .color-swatch__selected-icon {
    color: $tundora-l-0;
  }
}
</style>
