<script>
import ModifierKeys from '@constants/ModifierKeys'
import { createPopper } from '@popperjs/core'
import { isVisible } from '@helpers/StyleHelper'
import { jsStringToObject } from '@helpers/JsonHelper'
import { keyNameToSymbol } from '@helpers/KeyboardHelper'
import { KEYS_SEPARATOR as separator } from '@helpers/KeyboardShortcutHelper'
import { sortBy } from 'lodash'

// Public: Returns an Array of human-friendly names for the keyboard shortcut.
function shortcutToKeySymbols (shortcut) {
  // Handle the special case for the '+' shortcut.
  const keyNames = shortcut === separator ? [separator] : shortcut.split(separator)

  const keys = keyNames.map(name => ({ name, symbol: keyNameToSymbol(name) }))

  // Always display modifier keys first, `ctrl C` instead of `C ctrl`.
  return sortBy(keys, key => !ModifierKeys.includes(key.name))
}

// Public: Renders a list of keys positioned with Popper.JS next to the element
// that is triggered with the specified keyboard shortcuts.
export default {
  name: 'ElementKeyboardShortcuts',
  props: {
    // Public: The element that receives the shortcut, used to position the key
    // in the overlay.
    element: { required: true }, // eslint-disable-line

    // Public: The shortcut strings. Example: ['Ctrl+S', 'Meta+S'].
    shortcuts: { type: Array, required: true },
  },
  data () {
    return {
      // Internal: An instance of Popper.js to position the keys next to the
      // element that has the keyboard shortcut assigned.
      popper: null,
    }
  },
  computed: {
    shouldDisplayShortcuts ({ element }) {
      return isVisible(element) && !this.popperOptions.hide
    },
    keyboardShortcuts ({ shortcuts }) {
      return shortcuts.map(shortcutToKeySymbols)
    },
    // Internal: Options such as `{ placement: 'left', y: '5%', x: -3 }`.
    popperOptions () {
      const options = jsStringToObject(this.element.dataset.hotkeyOverlay || '{}', {
        errorMessage: `The provided data-hotkey-overlay does not have the expected format.`,
      })

      // Popper.JS has a very unfriendly API, so we allow to pass options in a simpler way.
      // For reference: https://popper.js.org/popper-documentation.html
      const { hide = false, placement = 'bottom', distance = 0 } = options

      return { hide, placement, modifiers: [{ name: 'offset', options: { offset: [0, distance] } }] }
    },
  },
  mounted () {
    // Position the shortcut keys next to the element.
    if (this.shouldDisplayShortcuts) {
      this.popper = createPopper(this.element, this.$el, this.popperOptions)
    }
  },
  beforeDestroy () {
    if (this.popper) this.popper.destroy()
  },
}
</script>
<template>
  <div v-if="shouldDisplayShortcuts" class="element-keyboard-shortcuts">
    <div v-for="shortcutKeys in keyboardShortcuts" :key="shortcutKeys.map(key => key.symbol).join()" class="element-keyboard-shortcut">
      <template v-for="key in shortcutKeys">
        <kbd :key="key.symbol" class="element-keyboard-shortcut__key" :class="{ smaller: key.symbol.length > 1 }">{{ key.symbol }}</kbd>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$key-size: 28px;
$key-border-width: 1px;
$key-padding-top: 2px; // Makes keys optically centered.

.element-keyboard-shortcuts {
  position: absolute; // To avoid the layout to change until Popper.JS sets position: absolute
}

.element-keyboard-shortcut + .element-keyboard-shortcut {
  margin-top: 8px;
}

.element-keyboard-shortcut__key {
  color: $tundora-l-0;
  font-size: $fs-normal;
  line-height: $key-size - $key-border-width * 2 - $key-padding-top;
  min-width: $key-size;
  padding: $key-padding-top 6px 0;
  text-align: center;

  &.smaller {
    font-size: $fs-secondary;
  }
}

.element-keyboard-shortcut__key + .element-keyboard-shortcut__key {
  margin-left: 2px;
}
</style>
