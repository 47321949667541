<script>
import NavigationBarMenuItem from '@components/NavigationBarMenuItem'

export default {
  name: 'NavigationQuicksearchMenuItem',
  components: {
    NavigationBarMenuItem,
  },
  props: {
    menuItem: { type: Object, required: true },
    navigationService: { type: Object, default: null },
  },
}
</script>

<template>
  <NavigationBarMenuItem
    v-navigation-item="{ navigationService: navigationService }"
    :to="menuItem.path"
    class="quicksearch-menu-item"
    v-on="$listeners"
  >
    <div class="patient-fields"><slot/></div>
  </NavigationBarMenuItem>
</template>

<style lang="scss" scoped>
.quicksearch-menu-item {
  align-items: center;
  display: flex;

  .patient-fields {
    display: flex;
    justify-content: space-between;
    margin-right: 8px;
    white-space: nowrap;
    width: 100%;
  }
}
</style>
