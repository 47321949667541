import WindowStore from '@stores/WindowStore'
import { debounce, each } from 'lodash'

const FIXED_CLASS = 'fixed-cell'
const SEPARATOR_CLASS = 'with-separator'

// Public: Ensures that we adjust the offset as the media responsive queries
// affect the padding in the table cells.
WindowStore.watch('windowWidth', debounce(adjustAllCells, 500))

function adjustLeftOffset (el) {
  el.style.left = 'auto' // reset before fetching the value
  el.style.left = `${el.offsetLeft}px`
}

function inserted (el, { value }) {
  if (!value) return

  el.classList.add(FIXED_CLASS)
  if (value.showSeparator) el.classList.add(SEPARATOR_CLASS)

  adjustLeftOffset(el)
}

function unbind (el) {
  el.classList.remove(FIXED_CLASS)
  el.classList.remove(SEPARATOR_CLASS)
}

export default {
  inserted,
  unbind,
}

export function adjustAllCells () {
  each(document.querySelectorAll(`.${FIXED_CLASS}`), adjustLeftOffset)
}

window.adjustAllCells = adjustAllCells
