<script>
// Public: If the condition is met, renders a link. Else, a plain span.
export default {
  name: 'ConditionalLink',
  inheritAttrs: false,
  props: {
    // Public: Path for the link, it might be one of:
    //   - String with a leading '/', will render a simple <a href>.
    //   - Object, will be passed straight to <router-link>.
    //   - String or Array, will use 'pathTo' to obtain the name and parameters
    //     of the route, and then hand it over to <router-link>.
    to: { type: [String, Array, Object], default: undefined },

    // Public: The condition that would cause it to render a link.
    condition: { type: Boolean, default: false },
  },
}
</script>

<template>
  <component
    :is="condition ? 'NavigationLink' : 'span'"
    :to="condition ? to : undefined"
    v-bind="$attrs"
    v-on="$listeners"
  ><slot/></component>
</template>
