<script>

export default {
  name: 'StatusPageLink',
}
</script>

<template>
  <p>Go to <a href="https://status.cipherhealth.com/">Status Page</a></p>
</template>

<style lang="scss" scoped>
p {
  text-align: center;
}
</style>
