<script>
// Public: A delete button to be used when the action to be perfomed is a delete, like in a destroy request.
export default {
  name: 'DeleteButton',
}
</script>

<template functional>
  <SaveButton
    v-bind="data.attrs"
    :class="data.staticClass"
    class="delete-button"
    danger
    v-on="listeners"
  ><slot>Delete</slot></SaveButton>
</template>
