import RoutesStore from '@stores/RoutesStore'
import { assign, isFunction } from 'lodash'
import { asyncOpenModal, closeModal, confirmAction, confirmDeletion, confirmRemoval, modalConfirm, modalMessage } from '@helpers/ModalHelper'
import { curryThis } from '@helpers/FunctionHelper'
import { evolveCareProviderUrl, evolveUrl, switchToClassicUrl } from '@helpers/UrlHelper'
import { hasExperiment, hasPermission } from '@helpers/PermissionHelper'
import { hasOldNavigationBar } from '@evolve/legacy/navbar'
import { isIE } from '@helpers/BrowserHelper'
import { navigateTo, pathTo } from '@helpers/RoutingHelper'
import { notifyError } from '@helpers/BugsnagHelper'
import { showCrouton, showPageCrouton } from '@helpers/CroutonHelper'
import { translate, translateIfKey } from '@helpers/TranslationHelper'

// Public: Allows to add an event listener to window, and register a hook to
// clean it on beforeDestroy.
function attachWindowListener (eventName, handler, params) {
  window.addEventListener(eventName, handler, params)
  this.$once('hook:beforeDestroy', () => window.removeEventListener(eventName, handler, params))
}

// Public: Allows to add an event listener to document, and register a hook to
// clean it on beforeDestroy.
function attachDocumentListener (eventName, handler, params) {
  document.addEventListener(eventName, handler, params)
  this.$once('hook:beforeDestroy', () => document.removeEventListener(eventName, handler, params))
}

// Public: Registers a beforeunload handler that cleans up automatically.
// NOTE: Compatible with every browser implementation.
function beforeLeaving (handler) {
  this.attachWindowListener('beforeunload', event => {
    const handlerReturnValue = handler(event)
    if (handlerReturnValue) event.returnValue = handlerReturnValue
    return handlerReturnValue
  })
}

// Public: Allows to set a timeout, but register a before destroy hook to
// clear it in case it hasn't finished when the component is removed.
//
// Returns a function that clears the timeout (and removes the destroy hook).
function setTimeoutAndClear (handler, delay) {
  const timeoutID = setTimeout(handler, delay)
  const cleanTimeout = () => clearTimeout(timeoutID)
  this.$once('hook:beforeDestroy', cleanTimeout)
  return () => {
    cleanTimeout()
    this.$off('hook:beforeDestroy', cleanTimeout)
  }
}

// Public: Allows to set an interval, but register a before destroy hook to
// clear it in case it hasn't finished when the component is removed.
//
// Returns a function that clears the interval (and removes the destroy hook).
function setIntervalAndClear (handler, delay) {
  const intervalID = setInterval(handler, delay)
  this.$once('hook:beforeDestroy', () => clearInterval(intervalID))
  const cleanInterval = () => clearInterval(intervalID)
  this.$once('hook:beforeDestroy', cleanInterval)
  return () => {
    cleanInterval()
    this.$off('hook:beforeDestroy', cleanInterval)
  }
}

// Internal: Used in many form modals, enough that we should encapsulate it.
// Emits a `saved` event for the modal with the provided arguments, and closes
// the modal.
function saveAndCloseFormModal (requestsObject, requestOptions) {
  const optionsAreExplicit = requestOptions.params || requestOptions.data
  const params = optionsAreExplicit || requestOptions
  const requestFn = isFunction(requestsObject) ? requestsObject : requestsObject[params.id ? 'update' : 'create']
  return requestFn(optionsAreExplicit ? requestOptions : { data: requestOptions })
    .then((...args) => {
      this.closeModal()
      this.$emit('saved', ...args)
      return args
    })
}

// Public: Allows to JS-refresh the current view - re-rendering all the components etc. just like the user would navigate to it.
function reloadView () {
  RoutesStore.refresh()
}

// Public: This mixin is added globally to the Vue instance, to provide methods
// that are commonly used by components.
export default {
  computed: {
    isIE () {
      return isIE
    },
    safeRel () {
      if (this.$attrs.target === '_blank') return 'noopener noreferrer'
      return null
    },
  },
  methods: {
    asyncOpenModal,
    attachWindowListener,
    attachDocumentListener,
    beforeLeaving,
    closeModal: curryThis(closeModal),
    confirmAction,
    confirmDeletion,
    confirmRemoval,
    evolveUrl,
    evolveCareProviderUrl,
    hasPermission,
    hasExperiment,
    isLegacy: hasOldNavigationBar,
    modalConfirm,
    modalMessage,
    navigateTo: curryThis(navigateTo),
    notifyError,
    pathTo,
    saveAndCloseFormModal,
    setTimeoutAndClear,
    setIntervalAndClear,
    showCrouton: curryThis(showCrouton),
    showPageCrouton: curryThis(showPageCrouton),
    switchToClassicUrl,
    translate,
    translateIfKey,
    reloadView,
  },
  // Internal: Allows to define constants in a separate section of the component.
  beforeCreate () {
    assign(this, this.$options.constants)
  },
}
