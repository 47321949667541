<script>
export default {
  name: 'SearchInput',
  inheritAttrs: false,
  props: {
    // Public: Whether the search input should be focused.
    focused: { type: Boolean, default: null },

    // Public: The content of the input.
    value: { type: String, default: null },

    // Public: A keyboard shortcut that should cause the input to gain focus.
    hotkey: { type: [Boolean, String], default: null },
  },
  computed: {
    inputListeners () {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
      }
    },
  },
}
</script>

<template>
  <div class="search-input-wrapper">
    <input
      v-focused="focused"
      v-hotkey.focus="hotkey"
      :value="value"
      v-bind="$attrs"
      class="search-input"
      type="text"
      v-on="inputListeners"
    >
    <div v-if="$slots.searchIndicator" class="search-indicator"><slot name="searchIndicator"/></div>
    <Icon v-else class="search-icon" name="search" size="medium"/>
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
@include input('.search-input');

.search-indicator,
.search-icon {
  display: flex;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

// A white fade overlay to nicely indicate that the content is overflowing the input.
.search-icon::before {
  @include z-index(above);

  background: linear-gradient(to right, #fff0 0%, #ffff 50%);
  content: '';
  height: 100%;
  position: absolute;
  right: 20px;
  width: 100%;
}

.search-input {
  @include z-index(default);

  padding-right: 32px;

  &::-ms-clear { display: none; } /* IE11 displays a cross on top of the search icon */

  & + .search-icon {
    color: $primary-color;
  }
}

.search-input-wrapper {
  display: inline-block;
  position: relative;
}
</style>
