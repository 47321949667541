<script>
export default {
  name: 'MessageBox',
  props: {
    // Public: The name of an icon to be shown inside the message box
    icon: { type: [Boolean, String], default: false },

    // Public: Defines the type of the message box
    hint: { type: Boolean, default () { return !(this.alert || this.warning || this.info || this.success) } },
    alert: { type: Boolean, default: false },
    warning: { type: Boolean, default: false },
    info: { type: Boolean, default: false },
    success: { type: Boolean, default: false },
  },
}
</script>

<template>
  <div class="message-box" :class="{ hint, alert, warning, info, success }">
    <Icon v-if="icon" :name="icon" size="medium"/>
    <div class="message-box__body">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.message-box {
  align-items: center;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  display: flex;
  margin-bottom: 32px;
  padding: 8px;

  .message-box__body {
    margin-left: 8px;
  }
}

.hint {
  background-color: $bg-hint-color;
  border-color: darken($bg-hint-color, 10%);
}

.alert {
  background-color: $bg-error-color;
  border-color: darken($bg-error-color, 15%);
  color: $bg-html-light;
}

.warning {
  background-color: $bg-warning-color;
  border-color: darken($bg-warning-color, 10%);
  color: $bg-html-light;
}

.info {
  background-color: $bg-info-color;
  border-color: darken($bg-info-color, 10%);
  color: $bg-html-light;
}

.success {
  background-color: $bg-success-color;
  border-color: darken($bg-success-color, 10%);
  color: $bg-html-light;
}
</style>
