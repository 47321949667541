import { parseJSON } from '@helpers/JsonHelper'

function get (key) {
  try {
    return window.localStorage.getItem(key)
  } catch {
    return null
  }
}

function set (key, value) {
  try {
    window.localStorage.setItem(key, value)
  } catch {
  }
}

function remove (key) {
  try {
    window.localStorage.removeItem(key)
  } catch {
  }
}

function getJSON (key) {
  const jsonState = get(key)
  return jsonState && parseJSON(jsonState)
}

function setJSON (key, value) {
  if (value) {
    set(key, JSON.stringify(value))
  } else {
    remove(key)
  }
}

function clear () {
  try {
    window.localStorage.clear()
  } catch {
  }
}

export default {
  get,
  set,
  remove,
  getJSON,
  setJSON,
  clear,
}
