<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'PhoneInput',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Text content of the input.
    value: { type: String, default: null },
  },
  computed: {
    inputListeners () {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
      }
    },
  },
}
</script>

<template>
  <div class="phone-input">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <input
      :class="{ error }"
      :disabled="disabled"
      :placeholder="placeholder || '(201) 555-0123'"
      :value="value"
      type="tel"
      class="input text"
      v-on="inputListeners"
    >
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
@include input;

.phone-input {
  position: relative;
}
</style>
