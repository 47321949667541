<script>
export default {
  name: 'CipherLoadingIndicator',
  inheritAttrs: false,
  props: {
    // Public: Decides whether the logo should be black or white
    white: { type: Boolean, default: false },

    // Public: Setting this flag starts the reveal animation, setting it to false will reverse the animation to hide the logo.
    reveal: { type: Boolean, default: true },

    // Public: Sets infinite status so it can be used as infinite spinner.
    infinite: { type: Boolean, default: false },
  },
}
</script>

<template>
  <Icon
    v-bind="$attrs"
    class="cipher-logo-indicator"
    :class="{ infinite, reveal, white, hide: !reveal }"
    name="cipher"
  />
</template>

<style lang="scss" scoped>

.cipher-logo-indicator {
  &.small {
    $length: 20px;

    height: $length;
    width: $length;
  }

  &.medium {
    $length: 30px;

    height: $length;
    width: $length;
  }

  &.huge {
    $length: 60px;

    display: block;
    height: $length;
    margin: $length auto;
    width: $length;
  }

  &.poster {
    $length: 500px;

    display: block;
    height: $length;
    margin: $length auto;
    width: $length;
  }
}

@keyframes dot-grow {
  0% {
    transform: scale(0);
  }

  30% {
    transform: scale(0);
  }

  84% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes dot-shrink {
  0% {
    transform: scale(1);
  }

  16% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(0);
  }

  100% {
    transform: scale(0);
  }
}

.cipher-logo-indicator ::v-deep {
  .dot-anim {
    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
    transform-box: fill-box;
    transform-origin: center;
  }

  .dot-9 {
    animation-delay: 0.1s;
  }

  .dot-10 {
    animation-delay: 0.2s;
  }

  .dot-4 {
    animation-delay: 0.3s;
  }

  .dot-16 {
    animation-delay: 0.4s;
  }

  .dot-6 {
    animation-delay: 0.5s;
  }

  .dot-8 {
    animation-delay: 0.6s;
  }

  .dot-12 {
    animation-delay: 0.7s;
  }

  .dot-13 {
    animation-delay: 0.8s;
  }

  .dot-14 {
    animation-delay: 0.9s;
  }

  .dot-11 {
    animation-delay: 1s;
  }

  .dot-7 {
    animation-delay: 1.1s;
  }

  .dot-5 {
    animation-delay: 1.2s;
  }

  .dot-15 {
    animation-delay: 1.3s;
  }

  .dot-3 {
    animation-delay: 1.4s;
  }

  .dot-1 {
    animation-delay: 1.5s;
  }

  .dot-2 {
    animation-delay: 1.6s;
  }
}

.reveal ::v-deep .dot-anim {
  animation-duration: 1.2s;
  animation-name: dot-grow;
  transform: scale(0);
}

.hide ::v-deep .dot-anim {
  animation-direction: normal;
  animation-duration: 0.9s;
  animation-name: dot-shrink;
  animation-play-state: play;
}

.infinite ::v-deep {
  .dot-anim {
    animation-direction: alternate;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-name: dot-grow;
    transform: scale(0);
  }

  .dot-9 {
    animation-delay: 0.2s;
  }
}
</style>
