<script>
// Syntax Sugar: Avoid repeating the same thing over and over.
export default {
  name: 'FormModalBody',
}
</script>

<template functional>
  <div class="modal-body">
    <CroutonPlaceholder defaultStyle="innerFormStyle"/>
    <slot/>
  </div>
</template>

<style scoped>
.modal-body ::v-deep + .modal-footer {
  margin-top: 0;
}
</style>
