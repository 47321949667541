<script>
import { getGridTemplate } from '@helpers/StyleHelper'
import { isArray } from 'lodash'

export default {
  name: 'FormGrid',
  inheritAttrs: false,
  props: {
    columns: { type: [Number, Array], default: 1 },

    // Public: The model that will be data-bound to the form.
    // Adding this prop here so that it can be easily manipulated by parent Forms
    model: { type: Object, default: null },

    // Internal: Errors received from a parent form
    // Adding this prop here so that it can be easily manipulated by parent Forms
    parentFormErrors: { type: Object, default: null },

    // Public: If true, the form will show errors immediately, without waiting for submit
    // Adding this prop here so that it can be easily manipulated by parent Forms
    showErrorsBeforeSubmit: { type: Boolean, default: false },
  },
  computed: {
    gridStyle () {
      return isArray(this.columns) || this.columns > 1 ? getGridTemplate(this.columns) : ''
    },
  },
  methods: {
    validate () {
      return this.$refs.form.validate()
    },
    isValid () {
      return this.$refs.form.isValid
    },
    scrollToFirstError () {
      return this.$refs.form.scrollToFirstError()
    },
  },
}
</script>

<template>
  <Form
    ref="form"
    :style="gridStyle"
    :model="model"
    :parentFormErrors="parentFormErrors"
    :showErrorsBeforeSubmit="showErrorsBeforeSubmit"
    v-bind="$attrs"
    :class="isIE ? 'form-grid-unsupported' : 'form-grid'"
    v-on="$listeners"
  ><slot/></Form>
</template>

<style lang="scss" scoped>
.form-grid {
  align-items: center;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr;
  grid-template-rows: auto;

  ::v-deep .form-control.checkbox {
    align-self: end;
    margin-bottom: 36px;
  }
}

</style>
