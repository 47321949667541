<script>
import { debounce, differenceBy, identity, isEmpty, merge, unionBy } from 'lodash'
import { ignorePreviousCalls } from '@helpers/PromiseHelper'
import { isPresent } from '@helpers/ObjectHelper'

export default {
  name: 'RemoteSelectInput',
  inheritAttrs: false,
  props: {
    // Public: The request function to call to retrieve the options. We expect the data returned
    // to be the serialized options.
    fetchOptions: { type: Function, default: null },

    // Public: Any additional data that the fetchOption request needs.
    fetchOptionsParams: { type: Object, default: () => ({}) },

    // Public: The message to display when the user has yet to filter the search options
    typeToSearchMessage: { type: String, default: 'Type to search for available options…' },

    // Public: The delay to wait before searching for results after the user is done typing
    delay: { type: Number, default: 500 },

    // Public: The value of the input
    //
    // NOTE: This will always be in `wholeItem` format e.g. { someLabel: 'label', someValue: 'value' } or [{ someLabel: 'label', someValue: 'value' }]
    // Since we are not making a request to obtain all original options, we cannot take this in any other format.
    value: { type: [Object, Array], default: () => [] },

    // Public: Use this prop to exclude any option(s) fetched by the fetchOptions prop
    optionsToExclude: { type: Array, default: () => ([]) },

    // Public: If supplied, we search the remote input with an initial query
    initialSearchQuery: { type: String, default: undefined },

    // Public: Function that takes input options and sorts or organizes them
    sortOptions: { type: Function, default: identity },
  },
  data () {
    return {
      // Public: The options retrieved from the request that should fill the select input
      fetchedOptions: [],

      // Public: The item(s) that are currently selected by the user
      selectedItems: null,

      // Public: Indicates if a request is currently being sent
      currentlyFetching: false,

      // Public: Indicates if the user has something typed in the input
      searchQuery: '',
    }
  },
  computed: {
    // Internal: items prop for the SelectInput. We add the selected items to the options fetched from
    // the server and remove those included in `optionsToExclude`. We then re-sort options, if a custom optionSorter is specified.
    options () {
      const fetchedAndSelected = unionBy(this.selectedItemsArray, this.fetchedOptions, this.valueProp)
      const options = differenceBy(fetchedAndSelected, this.optionsToExclude, this.valueProp)

      return this.sortOptions(options)
    },
    // Internal: Indicates if the SelectInput has the multi prop set
    multi () {
      return this.$attrs.hasOwnProperty('multi') && this.$attrs.multi !== false
    },
    // Internal: Returns the valueProp of the SelectInput
    valueProp () {
      return (this.$refs.selectInput && this.$refs.selectInput.valueProp) ||
        this.$attrs.valueProp || 'value'
    },
    // Public: Returns the currently selected options in array format
    selectedItemsArray () {
      if (isEmpty(this.selectedItems)) return []
      return this.multi ? this.selectedItems : [this.selectedItems]
    },
    // Internal: Returns the promise for the request to retrieve the options. Debounces the request.
    debouncedFetchOptions () {
      return debounce(ignorePreviousCalls(this.request), this.delay)
    },
    hasSearchQuery () {
      return this.searchQuery.length > 0
    },
  },
  watch: {
    searchQuery (newQuery) {
      if (isPresent(newQuery)) {
        this.currentlyFetching = true
        this.debouncedFetchOptions(newQuery)
      } else {
        this.debouncedFetchOptions.cancel()
      }
    },
    value: {
      immediate: true,
      handler (newValue) {
        this.selectedItems = newValue
      },
    },
    fetchOptionsParams (newValue) {
      this.fetchedOptions = []
    },
  },
  created () {
    // Load initial options if necessary
    if (this.initialSearchQuery !== undefined) {
      this.currentlyFetching = true
      this.debouncedFetchOptions(this.initialSearchQuery)
    }
  },
  methods: {
    // Internal: Sends the request to fetch the options, returns the promise
    request (filter) {
      return this.fetchOptions(merge({ data: { query: filter } }, this.fetchOptionsParams))
        .then(data => {
          if (this.hasSearchQuery || isEmpty(this.fetchedOptions)) this.fetchedOptions = data
        })
        .finally(() => { this.currentlyFetching = false })
    },
    // Internal: Propagates the input event. The value type is determined by `multi`.
    notifyValueChange () {
      this.$emit('input', this.selectedItems)
    },
  },
}
</script>

<template>
  <div class="remote-select-input" :class="{ multi }" :data-field="$attrs['data-field']">
    <SelectInput
      ref="selectInput"
      v-model="selectedItems"
      v-bind="$attrs"
      :items="options"
      wholeItem
      v-on="$listeners"
      @input:change="searchQuery = $event"
      @input="notifyValueChange"
    >
      <template slot="multiSelectedItem" slot-scope="slotScope">
        <slot name="multiSelectedItem" v-bind="slotScope"/>
      </template>

      <span v-if="!hasSearchQuery && options.length > 0" slot="customItemsMessage" class="remote-select-input__placeholder">{{ typeToSearchMessage }}</span>
      <span v-if="!hasSearchQuery" slot="noItems" class="remote-select-input__placeholder">{{ typeToSearchMessage }}</span>
      <LoadingIndicator v-else-if="currentlyFetching" slot="noItems" size="small" class="loading-items"/>
    </SelectInput>
  </div>
</template>

<style lang="scss" scoped>
.remote-select-input__placeholder {
  color: $fc-html-light;
}
</style>
