<script>
export default {
  name: 'NavigationBarMenuDivider',
}
</script>

<template>
  <div class="navbar-menu-divider"/>
</template>

<style lang="scss" scoped>
.navbar-menu-divider {
  border-bottom: $br-light;
  margin: 4px 0;
}
</style>
