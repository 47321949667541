export const nodeEnv = process && process.env && process.env.NODE_ENV
export const railsEnv = process && process.env && process.env.RAILS_ENV

// Public: True if the JS app is COMPILED in production mode.
export const isProductionMode = nodeEnv === 'production'

// Public: True if the JS app is COMPILED in development mode.
export const isDevelopmentMode = nodeEnv === 'development'

// Public: True if the JS app is RUNNING in CircleCI.
export const isCI = process && process.env && process.env.CI

// Public: True if the JS app is RUNNING in development, testing or CI.
export const isLocal = railsEnv === 'test' || railsEnv === 'development' || (isDevelopmentMode && railsEnv === undefined)

// Public: True if the JS app is Running in test mode
export const isTest = railsEnv === 'test'

// Public: True if the JS app is RUNNING in sandbox.
export const isSandbox = railsEnv === 'sandbox'

// Public: True if the JS app is RUNNING in staging.
export const isStaging = railsEnv === 'staging'

// Public: True if the JS app is RUNNING in parallel.
export const isParallel = railsEnv === 'parallel'

// Public: True if the JS app is RUNNING in production.
export const isProduction = railsEnv === 'production'

// Public: True if the JS app is RUNNING in any of our servers.
export const isServer = !isLocal
