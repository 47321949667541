<script>
import KeyboardNavigationService from '@services/KeyboardNavigationService'
import NavigationBarMenuDivider from '@components/NavigationBarMenuDivider'
import NavigationBarMenuItem from '@components/NavigationBarMenuItem'
import NavigationBarMenuTitle from '@components/NavigationBarMenuTitle'

import { filterItems, highlightString } from '@helpers/FilterHelper'

export default {
  name: 'NavigationBarDropdownMenu',
  components: {
    NavigationBarMenuItem,
    NavigationBarMenuDivider,
    NavigationBarMenuTitle,
  },
  props: {
    // Public: Configuration for the menu item, usually rendered in Ruby.
    menu: { type: Object, default: () => ({}) },

    // Public: The label to display in the menu item.
    title: { type: String, default () { return this.menu.title } },

    // Optional: Path to navigate to when the menu item is clicked.
    to: { type: [String, Object], default: null },

    // Optional: Applies button-like styling to bar menu title.
    buttonStyling: { type: Boolean, default: false },
  },
  data () {
    return {
      filteringBy: null,
      navigationService: new KeyboardNavigationService(this),
      expanded: false,
      showAll: false,
    }
  },
  computed: {
    items () {
      return this.menu.submenu || []
    },
    // Internal: Menu items that are not dividers
    menuItems () {
      return this.items.filter(item => !this.isDivider(item))
    },
    canExpand () {
      return this.items.length > 0
    },
    // Internal: Filtering menu items by title. We leave out dividers on search mode
    filteredItems () {
      if (!this.filteringBy) return this.items
      return filterItems(this.menuItems, { query: this.filteringBy, searchableProp: 'title' })
    },
  },
  watch: {
    // Ensure we focus the first item when the filter changes.
    filteringBy () {
      this.$nextTick(() => this.navigationService.focusFirstItem())
    },
  },
  methods: {
    highlightTitle (text) {
      return highlightString(text, { query: this.filteringBy })
    },
    // Internal: Allow to provide the Vue Router url as "to" in NavLinks in
    // presenters, so that we can avoid full page reloads and navigate quickly.
    pathToMenuItem (menuItem) {
      return (menuItem.options && menuItem.options.to) || menuItem.path || menuItem.to
    },
    isDivider (item) {
      return item.type === 'divider'
    },
    setExpanded (expanded) {
      this.expanded = expanded
      if (!expanded) this.filteringBy = null
    },
    collapseDropdown () {
      this.setExpanded(false)
    },
    toggleDropdown () {
      this.setExpanded(!this.expanded)
    },
    // Internal: The reason we do it this way instead of using `toggleDropdown`
    // is to ensure that two menus can't be open at the same time.
    clickMenuToggle () {
      this.$refs.menuToggle.$el.click()
    },
  },
}
</script>

<template>
  <SearchDropdown
    v-if="canExpand"
    v-model="filteringBy"
    v-hotkey="{ [expanded ? 'esc' : menu.hotkey]: clickMenuToggle }"
    data-hotkey-overlay="{ distance: -8 }"
    compact
    mask
    :show="expanded"
    @searchDropdown:expanded="setExpanded"
    @keyup.esc="collapseDropdown"
  >
    <NavigationBarMenuTitle
      slot="dropdownToggle"
      ref="menuToggle"
      :title="title"
      :expanded="expanded"
      :buttonStyling="buttonStyling"
      @click="toggleDropdown"
    />

    <div class="menu-items">
      <template v-for="(menuItem, index) in filteredItems">
        <NavigationBarMenuDivider v-if="isDivider(menuItem)" :key="index"/>
        <NavigationBarMenuItem
          v-else
          :key="index"
          v-navigation-item="{ navigationService }"
          :to="pathToMenuItem(menuItem)"
          class="menu-item no-underline"
          @navigated="collapseDropdown"
          v-html="highlightTitle(menuItem.title)"
        />
      </template>
      <div v-if="!filteredItems.length" class="empty-message">No items match the current search.</div>
    </div>
  </SearchDropdown>
</template>

<style lang="scss" scoped>
@include navigation-dropdown;

.empty-message {
  padding: 8px 12px;
  text-align: left;
}
</style>
