import { isBlank } from '@helpers/ObjectHelper'
import { isString } from 'lodash'
import { tokenize } from '@helpers/StringHelper'

const ESCAPE_REGEX = /[-/\\^$*+?.()|[\]{}]/g

// Extract the value of the specified property from an object,
// or simply return the item if no property is given.
function getSearchableValue (item, searchableContent) {
  return searchableContent(item)
}

// Internal: Build a regular expression for the given string,
// escaping where necessary and specifying case insensitivity by default.
function buildRegExp (token) {
  return new RegExp(`(${token.replace(ESCAPE_REGEX, '\\$&')})`, 'i')
}

// Internal: Tokenize the query and sort the tokens from longest to shortest.
// It's important that we start with the largest tokens, because we want to skip
// ahead as far as possible when a match is found, but we don't want to skip
// matching any smaller substrings in the process.
function sortedTokensFor (query) {
  return tokenize(query).sort((a, b) => b.length - a.length)
}

// Public: Tokenizes the string, and returns an Array of Regex, sorted from
// largest to smallest.
export function tokenMatcherFor (query) {
  const tokens = sortedTokensFor(query).map(buildRegExp)
  // Guard against falsy values because e.g. null is being cast to 'null' string giving false positives
  return text => tokens.every(tokenRegex => text && tokenRegex.test(text))
}

// Filter a list of items given a query string
// and (optionally) a searchable prop, which signifies the item property
// to compare against the search query. If no searchable prop is given,
// the query will be compared against the item itself.
export function filterItems (items, { query, searchableProp, searchableContent = item => (searchableProp ? item[searchableProp] : item), itemToPreserve }) {
  if (!query) return items

  const matchesTokensInQuery = tokenMatcherFor(query)
  return items.filter(item => item === itemToPreserve || matchesTokensInQuery(getSearchableValue(item, searchableContent)))
}

// Adds a colored span to the token matches inside the string.
export function highlightString (value, { query }) {
  if (isBlank(value)) return '&nbsp;' // Display whitespace to prevent breaking the layout when data is broken
  if (!isString(value)) value = value.toString()
  const tokens = sortedTokensFor(query)
  let i = 0
  let renderedItems = []
  let queue = []

  const dequeue = () => {
    if (queue.length) {
      renderedItems.push(`<span key="${renderedItems.length}">${queue.join('')}</span>`)
      queue = []
    }
  }

  while (i < value.length) {
    const token = tokens.find(token => buildRegExp(token).test(value.substr(i, token.length)))
    if (token) {
      dequeue()
      renderedItems.push(`<span class="search-highlight" key="${renderedItems.length}">${value.substr(i, token.length)}</span>`)
      i += token.length
    } else {
      queue.push(value[i++])
    }
  }
  dequeue()

  return renderedItems.join('')
}
