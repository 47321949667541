import { escape, times } from 'lodash'
import { highlightString } from '@helpers/FilterHelper'

// Public: Get the HTML for the label of the select input
export function highlightItemLabel (item, labelProp, filteringBy) {
  const label = escape(item[labelProp])
  return item.creatable || item.unselect ? label : highlightString(label, { query: filteringBy })
}

// Public: Generates select items that are numbers from min to max (inclusive).
export function generateNumberedItemsRange (min, max) {
  return times(max - min + 1, i => ({ label: String(min + i), value: min + i }))
}

// Public: Generates select items that are numbers from 1 to max.
export function generateNumberedItems (max) {
  return generateNumberedItemsRange(1, max)
}

// Public: Creates a select input item
export function createItem (label, value = label) {
  return { label, value }
}
