// Public: Returns true if the HTML element is an input.
export function isInput (el) {
  return el.nodeName === 'INPUT'
}

// Public: Returns true if the HTML element is a text input
export function isTextInput (el) {
  return isInput(el) && el.type === 'text'
}

// Public: Returns true if it the HTML element is a textarea.
export function isTextarea (el) {
  return el.nodeName === 'TEXTAREA'
}

// Public: Returns true if it's an HTML element that can receive text input.
export function isTypable (el) {
  return isInput(el) || isTextarea(el)
}

// Public: Returns true if it's a focusable HTML element.
export function isFocusable (el) {
  return el.tabIndex !== -1 || isTypable(el)
}

// Public: Returns true if element's content is longer than the element's width. Useful to detect if ellipsis kicked in.
export function isEllipsisActive (el) {
  return el.offsetWidth < el.scrollWidth
}

// Public: If the element does not have an id, it generates a random one for it.
export function ensureId (el, prefix = 'element') {
  if (!el.id) el.id = `${prefix}_${Math.random().toString(36).replace(/[^a-z]+/g, '').substr(0, 10)}`
  return el
}

// Public: Prepends an html dom node in the passed element
export function prependIn (el, node) {
  el.insertBefore(node, el.firstChild)
  return el
}

// Public: Calculates the scale that the inner content of the dom node needs
// to be able to fit within the node without bleeding out of it.
export function calculateInnerScale (el, { rotation = 0 }) {
  const children = Array.from(el.children)
  if (!children.length) return 1

  const elWidth = el.getBoundingClientRect().width
  const elHeight = el.getBoundingClientRect().height

  const childrenWidth = children.reduce((accumWidth, child) => {
    return accumWidth + child.getBoundingClientRect().width
  }, 0)

  const childrenHeight = children.reduce((accumHeight, child) => {
    return accumHeight + child.getBoundingClientRect().height
  }, 0)

  let scale = 1
  if ((rotation < 45 && childrenWidth > elWidth) || (rotation >= 45 && childrenHeight > elHeight)) {
    scale = rotation < 45 ? (elWidth / childrenWidth) : (elHeight / childrenHeight)
  }
  return scale
}

// Public: Calculates the top and left position of the element taking scroll position into account.
export function elementOffset (element) {
  let left = 0
  let top = 0
  do {
    left += element.offsetLeft - element.scrollLeft
    top += element.offsetTop - element.scrollTop
  } while ((element = element.offsetParent) != null)

  return {
    left,
    top,
  }
}
