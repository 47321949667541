import { assign } from 'lodash'
import { registerAndGetStore } from '@helpers/StoreHelper'

export const state = () => ({
  // The history stack (since HTML5 doesn't do that for us).
  routesHistory: [],
  refreshCount: 0,

  // String containing a "before leaving" message that prevents the navigation. Navigation will proceed as usual if falsy.
  preventNavigationReason: false,
})

export const getters = {
  canGoBack (state) {
    return state.routesHistory.length > 1
  },
}

export const mutations = {
  NAVIGATED_TO (state, route) {
    state.routesHistory.push(route)
  },
  NAVIGATING_BACK (state) {
    state.routesHistory.pop()
  },
  CLEAR (currentState) {
    assign(currentState, state())
  },
  REFRESH (state) {
    state.refreshCount++
  },
  SET_PREVENT_NAVIGATION_REASON (state, preventNavigationReason) {
    state.preventNavigationReason = preventNavigationReason
  },
}

export const actions = {
  // Adds the current route to the history stack.
  navigatedTo ({ commit }, route) {
    commit('NAVIGATED_TO', route)
  },
  // Pops the last route from the history stack.
  navigatingBack ({ commit }) {
    commit('NAVIGATING_BACK')
  },
  // Clear the history stack.
  clear ({ commit }) {
    commit('CLEAR')
  },
  // Increment the counter to force router-view re-render
  refresh ({ commit }) {
    commit('REFRESH')
  },

  setPreventNavigationReason ({ commit }, preventNavigationReason) {
    if (state.preventNavigationReason !== preventNavigationReason) commit('SET_PREVENT_NAVIGATION_REASON', preventNavigationReason)
  },
}

export default registerAndGetStore('routes', { state, getters, mutations, actions })
