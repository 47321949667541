export default {
  // Public: We use a different minimum length based on the performance problems
  // when searching by Name.
  minimumQueryLength: { MRN: 3, Name: 3 },

  // Public: We debounce the search request to prevent the user to trigger
  // several searches while typing.
  searchDelayInMillis: 500,

  // Public: Names longer than 22 chars will also show a tooltip on hover with full name
  maxDisplayedQueryLength: 22,
}
