import SidebarStore from '@stores/SidebarStore'
import { ensureId } from '@helpers/DomHelper'
import { scrollIntoView } from '@helpers/ScrollHelper'

function inserted (el, { value }) {
  ensureId(el, 'scrollable-section')
  el._scrollableSection = {
    id: el.id,
    title: value,
    scrollToSection (scrollOptions) {
      scrollIntoView(el, scrollOptions)
    },
  }
  SidebarStore.registerSection(el._scrollableSection)
}

function unbind (el) {
  SidebarStore.unregisterSection(el._scrollableSection)
  delete el._scrollableSection
}

// v-on-resize: Allows to call a function every time the element is resized.
export default {
  inserted,
  unbind,
}
