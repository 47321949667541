import AuthStore from '@stores/AuthStore'
import { each, isArray, isPlainObject, set, some } from 'lodash'

// Internal: Makes a request to Rails using a method other than GET by using a
// hidden form element with the specified url and method.
//
// Extracted from: https://github.com/rails/rails/blob/11341fdb3a1664ba58edf729ed46e04cd0e20ed6/actionview/app/assets/javascripts/rails-ujs/features/method.coffee
export function fakeFormSubmit (url, { method, target }) {
  const form = document.createElement('form')
  let formContent = `<input name="_method" value="${method}" type="hidden" />`

  const param = csrfParam()
  const token = csrfToken()

  if (param && token) {
    formContent += `<input name="${param}" value="${token}" type="hidden" />`
  }

  // Must trigger submit by click on a button, else "submit" event handler won't work!
  // https://developer.mozilla.org/en-US/docs/Web/API/HTMLFormElement/submit
  formContent += '<input type="submit" />'

  form.method = 'post'
  form.action = url
  if (target) form.target = target
  form.innerHTML = formContent
  form.style.display = 'none'

  document.body.appendChild(form)
  form.querySelector('[type="submit"]').click()
}

export function csrfParam () {
  return document.querySelector('meta[name=csrf-param]')?.content || 'authenticity_token'
}

export function csrfToken () {
  return document.querySelector('meta[name=csrf-token]')?.content || AuthStore.csrfToken
}

// Public: Checks whether the provided data field of an object (or an object, basically) contains a file to be uploaded
export function isFileField (field) {
  return field && field.file && field.file instanceof File
}

// Public: Checks whether there are files to be uploaded within the provided data structure.
export function hasFiles (data) {
  if (!isArray(data) && !isPlainObject(data)) return false
  return isFileField(data) || some(data, field => hasFiles(field))
}

// Public: Converts normal object that would be sent as JSON to a FormData instance
// where all JSON-able fields are inside the `json_params` FormData field and each file
// that we want to upload is a FormData field with the name it originally had in the
// original data structure.
export function asFormData (data) {
  const jsonParams = {}
  const files = {}
  function constructFormDataTemplate (currentData, keyPrefix = '') {
    if (isPlainObject(currentData) || isArray(currentData)) {
      if (isFileField(currentData)) {
        files[keyPrefix] = currentData
      } else {
        each(currentData, (value, keyOrIndex) => {
          constructFormDataTemplate(value, keyPrefix ? `${keyPrefix}[${keyOrIndex}]` : keyOrIndex)
        })
      }
    } else {
      set(jsonParams, keyPrefix, currentData)
    }
  }

  if (isPlainObject(data) || isArray(data)) constructFormDataTemplate(data)
  else throw new Error('First parameter must be an object in order to construct the FormData.')

  // NOTE: Check the FormData native compatibility table before using functions
  // like `set`.
  //
  // https://developer.mozilla.org/en-US/docs/Web/API/FormData
  const formData = new FormData()
  each(files, (value, key) => {
    formData.append(key, value.file)
  })
  formData.append('json_params', JSON.stringify(jsonParams))

  return formData
}
