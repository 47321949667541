<script>
export default {
  name: 'EditedBadge',
  inheritAttrs: false,
}
</script>

<template>
  <Badge v-bind="$attrs" label="Edited" class="edited-badge"/>
</template>

<style lang="scss" scoped>
.edited-badge {
  animation: simple-fade 0.3s normal forwards ease-out;
  font-size: $fs-secondary;
  font-weight: $fw-html;
}
</style>
