import tippy, { followCursor } from 'tippy.js'
import '@style/tooltip.scss'
import 'tippy.js/animations/perspective-subtle.css'
import 'tippy.js/dist/tippy.css'

import { isEllipsisActive } from '@helpers/DomHelper'
import { isPlainObject, map } from 'lodash'
import { isPresent } from '@helpers/ObjectHelper'

// Internal: Default options for tooltips, overwritten by any specified options.
//
// NOTE: For the full list: https://atomiks.github.io/tippyjs/v6/all-props/
tippy.setDefaultProps({
  duration: 100,
  animation: 'perspective-subtle',
  theme: 'ch',
})

// Internal: Allows the user to provide a String to display as the tooltip, or
// an options object with additional configuration.
//
export function normalizeOptions (binding) {
  const options = isPlainObject(binding.value) ? binding.value : { content: binding.value }
  if (options.followCursor) {
    options.plugins = [followCursor]
  }
  return options
}

export function setupTooltipVisibility (el, binding, tooltipOptions) {
  if (tooltipOptions.content && (!binding.modifiers.ellipsisOnly || (binding.modifiers.ellipsisOnly && isEllipsisActive(el)))) {
    el._tippy.enable()
  } else {
    el._tippy.disable()
  }
}

// Public: Creates or updates a tooltip associated to the trigger element 'el'.
export function createTooltip (el, binding) {
  const tooltipOptions = normalizeOptions(binding)
  tippy(el, tooltipOptions)
  if (tooltipOptions.showOnLoad) {
    el._tippy.show()
  }
  setupTooltipVisibility(el, binding, tooltipOptions)
}

function titleFromObject (fields) {
  return map(fields, (value, label) => isPresent(value) && `${label}: <b>${value}</b>`).filter(x => x).join('<br/>')
}

// Public: Creates a tooltip with the fields of the given object(fields), on separate lines
export function tooltipFromObject (fields) {
  return { content: titleFromObject(fields), allowHTML: true }
}
