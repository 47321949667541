<script>
export default {
  name: 'ColumnsLayout',
}
</script>

<template functional>
  <CardLayout :class="data.staticClass" v-bind="data.attrs" class="columns-layout" v-on="listeners">
    <template slot="title">
      <slot name="title"/>
    </template>
    <template slot="solo">
      <slot/>
    </template>
  </CardLayout>
</template>

<style lang="scss" scoped>
.columns-layout > ::v-deep .columns-container {
  margin: 0 $padding-horizontal-html $padding-horizontal-html;
}
</style>
