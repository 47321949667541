import { last } from 'lodash'
import { removeBy } from '@helpers/ArrayHelper'

// Public: A map where each value is an Array, and both the add and remove
// operation push and remove elements from the Array.
//
// Once the Array is empty, the key is automatically removed.
export default class ArraysMap {
  constructor (map = {}) {
    this.map = map
  }

  // Public: Appends the item to the Array for the given key.
  push (key, item) {
    this.map[key] ? this.map[key].push(item) : this.map[key] = [item]
  }

  // Public: Removes the item from the Array for the given key.
  remove (key, item) {
    if (this.map[key]) {
      removeBy(this.map[key], item)
      if (this.map[key].length === 0) delete this.map[key]
    }
  }

  // Public: Moves the item to the end of the Array for the given key.
  moveToLast (key, item) {
    this.remove(key, item)
    this.push(key, item)
  }

  // Public: Returns true if the item is the last item in the Array for the
  // given key.
  isLast (key, item) {
    const items = this.map[key]
    return last(items) === item
  }
}
