<script>
// Internal: Preset sizes that are useful.
const tagSizes = [
  'small',
  'medium',
]

export default {
  name: 'Tag',
  props: {
    // Public: The content to display in the tag, can also be provided in the
    // default slot.
    label: { type: [String, Number], default: null },

    // Optional: A URL to navigate to when clicking the tag.
    to: { default: null }, // eslint-disable-line vue/require-prop-types

    // Optional: Configure the link, for example, target="_blank".
    target: { type: String, default: undefined },

    // Public: Whether to display a button to remove the tag.
    removeBtn: { type: Boolean, default: false },

    // Public: Whether to display a red tag.
    danger: { type: Boolean, default: false },

    // Public: The size of the tag, can be 'small' or 'medium'.
    size: {
      type: String,
      default: 'medium',
      validator: function (value) {
        return tagSizes.includes(value)
      },
    },
  },
}
</script>

<template>
  <div v-if="label || $slots.default" class="tag" :class="[size, { danger, 'with-remove': removeBtn }]" @click="$emit('click', $event)">
    <NavigationLink v-if="to" :to="to" class="tag-label" :target="target"><slot>{{ label }}</slot></NavigationLink>
    <span v-else class="tag-label"><slot>{{ label }}</slot></span>
    <Button
      v-if="removeBtn"
      icon
      compact
      name="Remove"
      class="tag-remove"
      @click="$emit('removed')"
    />
  </div>
</template>

<style lang="scss" scoped>
.tag {
  align-items: center;
  background-color: $bg-tag;
  border: $br-tag;
  border-radius: $radius-normal;
  display: inline-flex;
  font-weight: $fw-html;
  padding: 2px 8px;
  white-space: nowrap;

  &.with-remove {
    padding-right: 4px;
  }

  &.small {
    padding: 2px 4px;

    &.with-remove {
      padding-right: 0;
    }
  }

  &.danger {
    background-color: $cabaret-l-3;
  }
}

.tag-remove {
  @include clickable;

  color: $tundora-l-1;
  margin-left: 2px;
  transition: color 0.3s ease;

  &:hover {
    color: $tundora-l-0;
  }
}

.tag-label {
  margin-right: auto;

  & > .navigation-link {
    color: $jelly-bean-l-0;
    text-decoration: underline;
  }
}
</style>
