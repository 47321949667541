<script>
export default {
  name: 'SearchDropdown',
  inheritAttrs: false,
  props: {
    // Public: The text in the search input.
    value: { type: String, default: null },

    // Public: Less padding in the dropdown searchable content
    compact: { type: Boolean, default: false },

    // Public: Placeholder for the search filter
    placeholder: { type: String, default: 'Type here to search' },

    // Public: If true, current search string is cleared when the dropdown is collapsed
    clearSearchOnCollapse: { type: Boolean, default: true },
  },
  methods: {
    onExpand (expanded) {
      if (!expanded && this.clearSearchOnCollapse) this.$emit('input', '')
      this.$emit('searchDropdown:expanded', expanded)
    },
  },
}
</script>

<template>
  <Dropdown
    class="search-dropdown"
    :class="{ compact }"
    :closeOnClick="false"
    contentClass="search-dropdown-content"
    v-bind="$attrs"
    @dropdown:expanded="onExpand"
  >
    <template slot="dropdownToggle"><slot name="dropdownToggle"/></template>

    <template slot="dropdownContent">
      <div class="search-dropdown-filter">
        <SearchInput
          v-autofocus="!isIE"
          :value="value"
          :placeholder="placeholder"
          class="search-dropdown-input"
          @input="$emit('input', $event)"
          v-on="$listeners"
        >
          <template slot="searchIndicator"><slot name="searchIndicator"/></template>
        </SearchInput>
        <slot name="dropdownSearch"/>
      </div>
      <slot/>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
.search-dropdown ::v-deep .search-dropdown-content {
  padding: 0;
}

.search-dropdown-filter {
  display: flex;
  padding: 16px;

  .search-dropdown.compact & {
    padding: 8px;
  }
}

.search-dropdown-input.search-input-wrapper {
  display: block;
  flex-grow: 1;

  ::v-deep .search-input {
    @include bordered-box;

    background: $WHITE;
    padding: $input-padding;
    padding-right: $search-input-padding-right;
    width: 100%;

    &:focus {
      background: $WHITE;
      color: $fc-html;

      &::placeholder {
        color: $fc-html-light;
      }

      & + .search-icon {
        color: $primary-color;
      }
    }
  }
}
</style>
