<script>
import NavigationBarIcon from '@components/NavigationBarIcon'
import NavigationBarMenuItem from '@components/NavigationBarMenuItem'

export default {
  name: 'NavigationBarAccountMenu',
  components: {
    NavigationBarIcon,
    NavigationBarMenuItem,
  },
  props: {
    navbar: { type: Object, required: true },
  },
}
</script>

<template>
  <Dropdown name="My Account" position="right" class="use-inset-shadow">
    <template slot="dropdownToggle">
      <NavigationBarIcon title="View account and more" icon="user"/>
    </template>

    <template slot="dropdownContent">
      <NavigationBarMenuItem title="My Account" to="MyAccount"/>
      <NavigationBarMenuItem title="My Badges" to="MyBadges"/>
      <NavigationBarMenuItem v-if="hasPermission('export_views')" title="My Exports" to="MyExports"/>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
</style>
