<script>
import InputMixin from '@mixins/InputMixin'
import { isBoolean } from 'lodash'

export default {
  name: 'TextInput',
  mixins: [InputMixin],
  props: {
    // Public: Text content of the input.
    value: {
      type: [String, Number, Boolean],
      default: null,
    },

    // Public: Max length of the input
    maxLength: {
      type: [Number, String],
      default: 1600, // As per https://www.twilio.com/docs/glossary/what-sms-character-limit
    },

  },
  computed: {
    inputListeners () {
      return { ...this.$listeners, input: this.onInput }
    },
    valueLength () {
      return this.value === null || isBoolean(this.value) ? 0 : String(this.value).length
    },
    isOverMaxLength () {
      return this.valueLength > Number(this.maxLength)
    },
  },
  methods: {
    onInput (event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<template>
  <div class="sms-input">
    <Label v-if="label" v-bind="{ required, hint }" :class="{ error }">
      {{ label }}
      <template slot="labelExtras">
        <slot name="labelExtras"/>
      </template>
    </Label>
    <div class="input-wrapper">
      <textarea
        ref="input"
        v-autosize
        class="input textarea"
        :class="{ error }"
        :disabled="disabled"
        :placeholder="placeholder"
        type="text"
        :value.prop="value"
        v-bind="$attrs"
        v-on="inputListeners"
      />
      <div
        v-tooltip="'Be aware that length of the actual message may differ if you use playback fields.'"
        class="input-length"
        :class="{ 'input-length--exceeded': isOverMaxLength }"
      >{{ valueLength }}/{{ maxLength }}</div>
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
@include input;

.input {
  padding-right: 72px;
}

.input.textarea {
  display: block;
  resize: none;
}

.sms-input {
  position: relative;
}

.input-wrapper {
  position: relative;
}

.input-length {
  bottom: 0;
  color: $fc-html-light;
  display: block;
  font-size: $fs-secondary;
  position: absolute;
  right: 8px;
  transform: translateY(-20%);
}

.input-length--exceeded {
  color: $cabaret-l-0;
}
</style>
