export default {
  RANGE_SEPARATOR: ' - ',
  BACKEND_RANGE_SEPARATOR: '..',
  SHORTCUTS: [
    { label: 'Today', value: 'today' },
    { label: 'Yesterday', value: 'yesterday' },
    { label: 'This Week', value: 'this_week' },
    { label: 'Last Week', value: 'last_week' },
    { label: 'This Month', value: 'this_month' },
    { label: 'Last Month', value: 'last_month' },
  ],
  NEVER: { label: 'None', value: 'never' },
}
