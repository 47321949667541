<script>
export default {
  name: 'InputError',
  props: {
    error: { type: [String, Array], required: true },
  },
}
</script>

<template>
  <div class="input-error">{{ error.join ? error.join(', ') : error }}</div>
</template>

<style lang="scss" scoped>
.input-error {
  @include input-error;

  line-height: 1;
  margin-top: 4px;
}
</style>
