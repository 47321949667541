import Vue from 'vue'
import {
  camelCase,
  cloneDeep,
  each,
  isArray,
  isEmpty,
  isEqual,
  isFunction,
  isNaN,
  isNumber,
  isPlainObject,
  isString,
  mapKeys,
  mapValues,
  pickBy,
  reduce,
  snakeCase,
} from 'lodash'

const FIRST_CHARACTER_IS_NUMBER_REGEXP = /^\d/

function convertKeys (object, keyConverter) {
  if (isPlainObject(object)) {
    return mapKeys(object, (value, key) => keyConverter(key))
  } else {
    return object
  }
}

function deepConvertKeys (object, keyConverter, decamelizer) {
  if (isPlainObject(object)) {
    return mapValues(keyConverter(object, decamelizer), value => deepConvertKeys(value, keyConverter, decamelizer))
  }
  if (isArray(object)) {
    return object.map(item => deepConvertKeys(item, keyConverter, decamelizer))
  }
  return object
}

function camelCaseIfNotObjectId (key) {
  if (key.match(FIRST_CHARACTER_IS_NUMBER_REGEXP)) {
    return key
  } else {
    return camelCase(key)
  }
}

// Public: Converts all object keys to camelCase, preserving the values.
export function camelizeKeys (object) {
  return convertKeys(object, camelCaseIfNotObjectId)
}

// Public: Converts all object keys to snake_case, preserving the values.
export function decamelizeKeys (object, decamelizer = snakeCase) {
  return convertKeys(object, decamelizer)
}

// Public: Converts all object keys to camelCase, as well as nested objects, or
// objects in nested arrays.
export function deepCamelizeKeys (object) {
  return deepConvertKeys(object, camelizeKeys)
}

// Public: Converts all object keys to snake_case, as well as nested objects, or
// objects in nested arrays.
export function deepDecamelizeKeys (object, decamelizer = snakeCase) {
  return deepConvertKeys(object, decamelizeKeys, decamelizer)
}

// Public: Retrieve an object property, or if it doesn't exist, set the property
// with the return value of the callback, and returns it.
export function fetch (object, key, defaultValue) {
  if (object.hasOwnProperty(key)) return object[key]
  const value = object[key] = defaultValue(key)
  return value
}

// Public: Retrieve an object property, or if it doesn't exist, set the property
// with the return value of the callback or promise, and return it.
export async function fetchAsync (object, key, promiseFactory) {
  if (object.hasOwnProperty(key)) return object[key]
  const value = await promiseFactory(key)
  object[key] = value
  return value
}

// Public: Intersects attributes for every object, keeping only the properties
// that have the same value for every object in the collection.
export function intersectObjects (objects) {
  return reduce(objects, (intersection, object) =>
    pickBy(intersection, (value, field) => isEqual(object[field], value))
  )
}

// Public: Assigns the new values to the object keys, mantaining Vue's reactivity.
export function setValues (object, values) {
  each(values, (value, key) => {
    Vue.set(object, key, value === undefined ? null : cloneDeep(value))
  })
}

// Public: Consistent with the Rails definition.
export function isBlank (object) {
  if (object === true || (isNumber(object) && !isNaN(object))) return false
  return isEmpty(isString(object) ? object.trim() : object)
}

// Public: Consistent with the Rails definition.
export function isPresent (object) {
  return !isBlank(object)
}

// Public: Consistent with the Rails definition.
export function presence (object) {
  return isPresent(object) ? object : null
}

// Public: Returns value of a field in the object. If the field is
export function executeOrGet (object, key) {
  return isFunction(object[key]) ? object[key]() : object[key]
}
