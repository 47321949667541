import { registerAndGetStore } from '@helpers/StoreHelper'

export const state = {
  // Whether the keyboard shortcuts that are available in the page should be
  // displayed.
  displayingKeyboardOverlay: false,
}

export const getters = {
}

export const mutations = {
  SET_DISPLAY_KEYBOARD_OVERLAY (state, displayingKeyboardOverlay) {
    state.displayingKeyboardOverlay = displayingKeyboardOverlay
  },
}

export const actions = {
  // Displays an overlay with all the keyboard shortcuts that are available in the page.
  displayKeyboardOverlay ({ commit }) {
    commit('SET_DISPLAY_KEYBOARD_OVERLAY', true)
  },

  // Hides the keyboard shortcuts overlay.
  hideKeyboardOverlay ({ commit }) {
    commit('SET_DISPLAY_KEYBOARD_OVERLAY', false)
  },
}

export default registerAndGetStore('help', { state, getters, mutations, actions })
