<script>
import KeyboardNavigationService from '@services/KeyboardNavigationService'
import NavigationBarIcon from '@components/NavigationBarIcon'
import NavigationQuicksearchMenuItem from '@components/NavigationQuicksearchMenuItem'
import QuicksearchConstants from '@constants/QuicksearchConstants'
import QuicksearchPatientsRequests from '@requests/QuicksearchPatientsRequests'
import QuicksearchProTips from '@components/QuicksearchProTips'
import truncate from '@filters/truncate'
import { debounce } from 'lodash'
import { highlightString } from '@helpers/FilterHelper'
import { isPresent } from '@helpers/ObjectHelper'
import { quickFormatDate } from '@helpers/FormatHelper'
import { translate } from '@helpers/TranslationHelper'

export default {
  name: 'NavigationBarQuicksearchMenu',
  components: {
    QuicksearchProTips,
    NavigationBarIcon,
    NavigationQuicksearchMenuItem,
  },
  data () {
    return {
      filteringBy: '',
      navigationService: new KeyboardNavigationService(this),
      expanded: false,

      // Internal: Whether a new search is in progress (delay already elapsed).
      currentlyLoading: false,

      // Internal: Whether the last search failed.
      lastSearchError: false,

      // Internal: The search results.
      patients: [],

      // Internal: Whether the user typed a new search but the delay has not ellapsed.
      searchIsPending: false,

      // Internal: Allows the user to press ENTER after typing to navigate automatically.
      autoNavigate: false,
    }
  },
  computed: {
    queryIsNotEmpty () {
      return isPresent(this.filteringBy)
    },
    debouncedRequest () {
      return debounce(this.request, QuicksearchConstants.searchDelayInMillis)
    },
    mrnMode () {
      return this.queryIsNotEmpty && this.hasNumbers(this.filteringBy)
    },
    searchField () {
      return this.mrnMode ? translate('encounter.mrn') : 'Name'
    },
    isViewUser () {
      return this.hasPermission('default_blueprint_patient_page')
    },
  },
  watch: {
    // Ensure we focus the first item when the filter changes.
    filteringBy (newFilteringBy) {
      if (newFilteringBy && newFilteringBy.trim().length >= QuicksearchConstants.minimumQueryLength[this.searchField]) {
        this.searchIsPending = true
        this.debouncedRequest()
      } else {
        this.searchIsPending = false
        this.debouncedRequest.cancel()
        this.patients = []
      }
      this.autoNavigate = false // A new search requires ENTER to be pressed again to auto-navigate.
      this.lastSearchError = false
      this.$nextTick(() => this.navigationService.focusFirstItem())
    },
  },
  methods: {
    hasNumbers (string) {
      const regex = /\d/g
      return regex.test(string)
    },
    request () {
      this.currentlyLoading = true
      const data = { [this.mrnMode ? 'mrn' : 'name']: this.filteringBy }
      return QuicksearchPatientsRequests.search({ data })
        .then(patients => {
          patients.forEach(patient => {
            patient.path = this.isViewUser && patient.workflowsUpdatedAt
              ? `/view/patients/${patient.id}`
              : `/patients/${patient.id}`
          })
          this.patients = patients
          this.navigateIfSingleResult()
        })
        .catch(error => {
          this.lastSearchError = true
          console.error(error)
        })
        .finally(() => {
          this.currentlyLoading = false
          this.searchIsPending = false
        })
    },
    // Internal: If the user pressed ENTER after typing, navigate automatically.
    // Convenient for MRN search where there's usually a single result.
    navigateIfSingleResult () {
      if (this.autoNavigate && this.patients.length === 1) {
        this.navigateTo(this.patients[0].path)
        this.collapseDropdown()
      }
    },
    highlightText (text) {
      return highlightString(truncate(text, { length: QuicksearchConstants.maxDisplayedQueryLength }), { query: this.filteringBy })
    },
    tooltipIfContentOverflows (patient) {
      const content = [`${patient.firstName} ${patient.lastName}`, this.currentPatientField(patient)]
      if (content.some(text => text.length > QuicksearchConstants.maxDisplayedQueryLength)) {
        return { content: content.join(' - '), placement: 'left' }
      }
    },
    currentPatientField (patient) {
      const field = this.mrnMode && patient.mrn && patient.mrn.includes(this.filteringBy)
        ? patient.mrn
        : patient.dateOfBirth && quickFormatDate(patient.dateOfBirth)
      return field || ''
    },
    setExpanded (expanded) {
      this.expanded = expanded
      if (!expanded) this.filteringBy = null
    },
    collapseDropdown () {
      this.setExpanded(false)
    },
    toggleDropdown () {
      this.setExpanded(!this.expanded)
    },
    // Internal: The reason we do it this way instead of using `toggleDropdown`
    // is to ensure that two menus can't be open at the same time.
    clickMenuToggle () {
      this.$refs.menuToggle.$el.click()
    },
  },
}
</script>

<template>
  <SearchDropdown
    v-model="filteringBy"
    v-hotkey="{ [expanded ? 'esc' : 'q']: clickMenuToggle }"
    data-hotkey-overlay="{ distance: -8 }"
    compact
    mask
    :show="expanded"
    class="patient-quicksearch-dropdown"
    :class="{ loading: currentlyLoading, searching: queryIsNotEmpty }"
    position="right"
    @searchDropdown:expanded="setExpanded"
    @keydown.enter="autoNavigate = true"
    @keyup.esc="collapseDropdown"
  >
    <NavigationBarIcon
      ref="menuToggle"
      slot="dropdownToggle"
      class="quicksearch-icon"
      :expanded="expanded"
      icon="search"
      title="Quick Patients Search"
      @click="toggleDropdown"
    />
    <template v-if="queryIsNotEmpty || currentlyLoading" slot="searchIndicator">
      <LoadingIndicator v-if="currentlyLoading" size="small" class="loading-indicator"/>
      <Badge v-else compact :label="searchField"/>
    </template>
    <div class="menu-items" :class="{ 'with-content': patients.length > 0 }">
      <NavigationQuicksearchMenuItem
        v-for="patient in patients"
        :key="patient.id"
        v-tooltip="tooltipIfContentOverflows(patient)"
        :menuItem="patient"
        :navigationService="navigationService"
        class="all-patients-item"
        @navigated="collapseDropdown"
      >
        <div class="patient-name" v-html="highlightText(`${patient.firstName} ${patient.lastName}`)"/>
        <div class="patient-field" v-html="highlightText(currentPatientField(patient))"/>
      </NavigationQuicksearchMenuItem>
    </div>
    <QuicksearchProTips v-bind="{ searchField, filteringBy, patients, searchIsPending, lastSearchError }"/>
  </SearchDropdown>
</template>

<style lang="scss" scoped>
$navbar-menu-padding: 16px;

@include navigation-dropdown;

.menu-items.with-content {
  margin-bottom: 4px; // To give the protips more space to breathe.
}

@include media-min($navbar-breakpoint) {
  .patient-quicksearch-dropdown .menu-items {
    max-height: 60vh;
  }
}

.patient-quicksearch-dropdown {
  &.searching {
    ::v-deep .search-input {
      padding-right: 64px;
    }
  }

  &.searching.loading ::v-deep {
    .search-indicator {
      right: 4px;
      top: 0;
      transform: none;
    }

    .search-input {
      padding-right: 40px;
    }
  }
}

.search-dropdown ::v-deep .search-dropdown-content {
  animation: none;
  max-width: 320px;
  min-width: 280px;
  padding-bottom: 8px; // To give the protips more space to breathe.
}
</style>
