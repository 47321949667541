<script>
export default {
  name: 'EnterKey',
}
</script>

<template>
  <kbd v-once class="enter-key"><Icon class="enter-icon" name="levelDown" size="small"/></kbd>
</template>

<style lang="scss" scoped>
.enter-key {
  fill: currentColor;
}

.enter-icon {
  transform: rotate(90deg);
}
</style>
