<script>
export default {
  name: 'ToggleButton',
  inheritAttrs: false,
  props: {
    toggled: { type: Boolean, default: false },
  },
}
</script>

<template>
  <Button
    v-bind="$attrs"
    :class="{ toggled }"
    class="toggle-button"
    @click="$emit('toggle', !toggled)"
  >
    <slot/>
  </Button>
</template>

<style lang="scss" scoped>
</style>
