// Public: Specifies the main pages used within Outreach.
const CallPages = [
  {
    title: 'Upcoming Calls',
    shortTitle: 'Upcoming',
    permission: 'calls_view',
    to: 'UpcomingCalls',
  },
  {
    title: 'Current Calls',
    shortTitle: 'Current',
    permission: 'calls_view',
    to: 'CurrentCalls',
  },
  {
    title: 'Completed Calls',
    shortTitle: 'Completed',
    permission: 'calls_view',
    to: 'CompletedCalls',
  },
  {
    title: 'Missed Outreach Attempts',
    permission: 'internal_only',
    to: 'MissedCalls',
  },
]

export default CallPages

// Public: Specifies the related pages for Outreach, including the legacy cross
// care provider pages.
export const AllCallPages = [
  {
    group: 'Calls',
    items: [
      ...CallPages,
      {
        title: 'All Care Providers Completed Calls',
        permission: 'internal_only',
        to: '/calls/completed',
      },
      {
        title: 'All Care Providers Current Calls',
        permission: 'internal_only',
        to: '/calls/current',
      },
      {
        title: 'All Care Providers Upcoming Calls',
        permission: 'internal_only',
        to: '/calls/upcoming',
      },
      {
        title: 'All Care Providers Calls Summary',
        permission: 'internal_only',
        to: '/calls/snapshot',
      },
    ],
  },
]
