<script>
import KeyboardNavigationService from '@services/KeyboardNavigationService'
import NavigationBarIcon from '@components/NavigationBarIcon'
import NavigationBarMenuItem from '@components/NavigationBarMenuItem'
import NavigationBarMenuTitle from '@components/NavigationBarMenuTitle'
import NavigationCareProviderMenuItem from '@components/NavigationCareProviderMenuItem'
import ProTip from '@components/ProTip'
import WindowStore from '@stores/WindowStore'

import truncate from '@filters/truncate'
import { careProvidersBreakpoint } from '@style/JsVariables.scss'
import { filterItems, highlightString } from '@helpers/FilterHelper'
import { take } from 'lodash'

// Internal: In order to prevent performance issues, since we expect users to
// search rather than scroll on very long lists, we don't render all of the items.
const MAX_RENDERED_ITEMS = 50

// Internal: Longer names will be truncated and show a tooltip with the full name.
const MAX_LENGTH_CP_NAME = 30

export default {
  name: 'NavigationBarCareProvidersMenu',
  components: {
    NavigationBarIcon,
    NavigationBarMenuTitle,
    // eslint-disable-next-line
    NavigationBarMenuItem,
    NavigationCareProviderMenuItem,
    ProTip,
  },
  props: {
    menu: { type: Object, required: true },
  },
  data () {
    return {
      filteringBy: null,
      navigationService: new KeyboardNavigationService(this),
      expanded: false,
      showAll: false,
      MAX_LENGTH_CP_NAME,
    }
  },
  computed: {
    displayCareProviderName () {
      return WindowStore.windowWidth >= careProvidersBreakpoint
    },
    recentCareProviders () {
      return this.menu.recent || []
    },
    allCareProviders () {
      return this.menu.allCareProviders || []
    },
    canExpand () {
      return this.allCareProviders.length > 0
    },
    searchMode () {
      return !!this.filteringBy
    },
    filteredCareProviders () {
      if (!this.searchMode) return this.allCareProviders
      return filterItems(this.allCareProviders, { query: this.filteringBy, searchableProp: 'name' })
    },
    displayedCareProviders () {
      return take(this.filteredCareProviders, MAX_RENDERED_ITEMS)
    },
    // Internal: Avoid showing the recents if in search mode and if it's the same list as allCareProviders
    shouldShowRecents () {
      return !this.filteringBy && this.recentCareProviders.length > 0 &&
        (this.allCareProviders.length !== this.recentCareProviders.length)
    },
    noItemsMatch () {
      return this.searchMode && !this.filteredCareProviders.length
    },
    moreItemsToShow () {
      return this.filteredCareProviders.length > this.displayedCareProviders.length
    },
  },
  watch: {
    // Ensure we focus the first item when the filter changes.
    filteringBy () {
      this.$nextTick(() => this.navigationService.focusFirstItem())
    },
  },
  methods: {
    highlightName (name) {
      return highlightString(truncate(name, { length: MAX_LENGTH_CP_NAME }), { query: this.filteringBy })
    },
    tooltip (name) {
      return name.length > MAX_LENGTH_CP_NAME && name
    },
    setExpanded (expanded) {
      if (!this.canExpand) return
      this.expanded = expanded
      if (!expanded) this.filteringBy = null
    },
    collapseDropdown () {
      this.setExpanded(false)
    },
    toggleDropdown () {
      this.setExpanded(!this.expanded)
    },
    // Internal: The reason we do it this way instead of using `toggleDropdown`
    // is to ensure that two menus can't be open at the same time.
    clickMenuToggle () {
      this.$refs.menuToggle.$el.click()
    },
  },
}
</script>

<template>
  <SearchDropdown
    v-model="filteringBy"
    v-hotkey="{ [expanded ? 'esc' : menu.hotkey]: clickMenuToggle }"
    data-hotkey-overlay="{ distance: -8 }"
    compact
    mask
    :show="expanded"
    class="care-providers-dropdown"
    position="right"
    @searchDropdown:expanded="setExpanded"
    @keyup.esc="collapseDropdown"
  >
    <template slot="dropdownToggle">
      <NavigationBarMenuTitle
        v-if="displayCareProviderName"
        slot="dropdownToggle"
        ref="menuToggle"
        :expanded="expanded"
        class="care-providers-menu-title"
        :class="{ 'not-expandable': !canExpand }"
        @click="toggleDropdown"
      >
        <template slot="title">
          {{ menu.title }}
          <span v-if="canExpand" class="navbar-menu__button-down-arrow">
            <Icon name="chevronDown" size="regular"/>
          </span>
        </template>
      </NavigationBarMenuTitle>
      <NavigationBarIcon
        v-else
        ref="menuToggle"
        icon="careProvider"
        title="Care Providers"
        class="care-providers-icon"
        :class="{ 'not-expandable': !canExpand }"
        @click="toggleDropdown"
      />
    </template>

    <div class="menu-items">
      <template v-if="shouldShowRecents">
        <div class="care-provider-group">Recent</div>
        <NavigationCareProviderMenuItem
          v-for="careProvider in recentCareProviders"
          :key="`recent-${careProvider.id}`"
          :careProvider="careProvider"
          :navigationService="navigationService"
          class="recent-item"
          @navigated="collapseDropdown"
        >
          <span v-tooltip="tooltip(careProvider.name)">{{ careProvider.name | truncate({length: MAX_LENGTH_CP_NAME}) }}</span>
        </NavigationCareProviderMenuItem>
      </template>

      <div v-if="!searchMode" class="care-provider-group all-care-providers">
        All Care Providers
        <Button
          to="CareProviders"
          label="Show All"
          class="show-all"
          compact
          @navigated="collapseDropdown"
        />
      </div>

      <NavigationCareProviderMenuItem
        v-for="careProvider in displayedCareProviders"
        :key="careProvider.id"
        :careProvider="careProvider"
        :navigationService="navigationService"
        class="all-care-providers-item"
        @navigated="collapseDropdown"
      >
        <span v-tooltip="tooltip(careProvider.name)" v-html="highlightName(careProvider.name)"/>
      </NavigationCareProviderMenuItem>

      <template v-if="noItemsMatch">
        <ProTip icon="emptySearch">
          No care providers match <span class="pro-tip-query">'{{ filteringBy }}'</span>
        </ProTip>
        <div class="visit-all-container">
          <Button to="CareProviders" label="Visit all care providers" @navigated="collapseDropdown"/>
        </div>
      </template>

      <ProTip v-else-if="moreItemsToShow">
        There are more care providers, type to narrow them down.
      </ProTip>
    </div>
  </SearchDropdown>
</template>

<style lang="scss" scoped>
$navbar-menu-padding: 16px;

@include navigation-dropdown;

@include media-min($navbar-breakpoint) {
  .care-providers-dropdown .menu-items {
    max-height: 60vh;
  }
}

.search-dropdown ::v-deep .search-dropdown-content {
  width: 256px;
}

.navbar-menu ::v-deep {
  .navbar-menu__link {
    align-items: center;
    border: 1px solid $WHITE;
    border-radius: 16px;
    display: flex;
    outline: none;
    padding: 4px $navbar-menu-padding;
    white-space: nowrap;
  }

  .navbar-menu__button-down-arrow {
    fill: $WHITE;
    margin-left: 8px;
    padding-top: 2px;
  }

  .navbar-menu__dropdown {
    margin: 40px 0 0;
    right: 0;
  }

  &.expanded .navbar-menu__link {
    @include background-lighten(0.3);
  }
}

.care-providers-menu-title.navbar-menu {
  &.expanded::after {
    left: unset;
    margin-left: 1px;
    right: $navbar-menu-padding + ($icon-size-regular / 2); //align dropdown tip to title chevron
  }

  &.not-expandable {
    cursor: default;
  }
}

.care-provider-group {
  align-items: center;
  color: $tundora-l-1;
  display: flex;
  font-size: $fs-secondary;
  font-weight: $fw-regular;
  justify-content: space-between;
  padding: 8px 12px 4px;

  &.all-care-providers {
    margin-top: 4px;
  }

  .show-all {
    margin-left: 48px; // Min separation between the label and the button
  }
}

.visit-all-container {
  display: flex;
  justify-content: center;
  padding: 8px 0 16px;
}
</style>
