import { isString } from 'lodash'
import { isTest } from '@helpers/EnvironmentHelper'

// Public: Sets the scrolling property of the body element.
export function setBodyScroll (value) {
  document.body.style.overflow = isString(value) ? value : (value ? 'visible' : 'hidden')
}

// Public: Scrolls the item into view.
export function scrollIntoView (element, { animate, offset = 0 } = { animate: true }) {
  if (!element) return
  const scroller = getScrollParent(element)
  const scrollTop = scrollTopToDisplay(element, scroller) + offset
  animate && !isTest ? animateScroll(scroller, scrollTop) : scroller.scrollTop = scrollTop
}

// Public: Scrolls to the top of the element (or it's first scrollable parent).
export function scrollToTop (element, offset, { animate = true } = {}) {
  if (!element) return
  const scroller = getScrollParent(element)
  animate ? animateScroll(scroller, offset) : scroller.scrollTop = offset
}

// Public: Scrolls to the bottom of the element (or it's first scrollable parent).
export function scrollToBottom (element, { animate = true } = {}) {
  if (!element) return
  const scroller = getScrollParent(element)
  const scrollTop = scroller.scrollHeight - scroller.clientHeight
  animate ? animateScroll(scroller, scrollTop) : scroller.scrollTop = scrollTop
}

// Public: Returns true if the parent has an Y Overflow and is showing a scrollbar
export function parentHasScrollbar (node) {
  return getScrollParent(node) === node.parentElement
}

// Internal: Simple function to animate the scroll motion.
function animateScroll (scroller, finalScrollTop, smoothRate = 5) {
  // Used to detect the progress of the scrolling animation.
  let lastScrollTop = scroller.scrollTop

  const scroll = () => {
    const currentScrollTop = scroller.scrollTop

    // We want to abort the automatic scrolling if we detect a change in the
    // scroll that is external to this animation to prevent "loops".
    if (currentScrollTop !== lastScrollTop) return

    const floatScrollValue = (finalScrollTop - scroller.scrollTop) / smoothRate
    if (Math.abs(floatScrollValue) < 1) {
      scroller.scrollTop = finalScrollTop
    } else {
      const scrollDistance = floatScrollValue > 0 ? Math.ceil(floatScrollValue) : Math.floor(floatScrollValue)
      scroller.scrollTop = currentScrollTop + scrollDistance
      lastScrollTop = scroller.scrollTop

      // If we haven't reached the final destination yet, animate another frame.
      if (scroller.scrollTop !== currentScrollTop) window.requestAnimationFrame(scroll)
    }
  }
  scroll()
}

// Internal: Detects if an element is scrollable.
function isScrollable (node) {
  const isElement = node instanceof HTMLElement
  const overflowY = isElement && window.getComputedStyle(node).overflowY
  return overflowY !== 'visible' && overflowY !== 'hidden'
}

// Internal: Returns the first ancestor that is scrollable.
function getScrollParent (node) {
  if (!node) return null
  if (isScrollable(node) && node.scrollHeight >= node.clientHeight) return node
  return getScrollParent(node.parentNode) || topScrollableElement()
}

// Internal: There seems to be a weird bug in Safari when it comes to window
// scrolling, which requires us to use `body` instead of `html`.
function topScrollableElement () {
  return document.body.parentElement
}

// Internal: Returns the scroll position necessary for the item to be visible.
function scrollTopToDisplay (element, scroller) {
  const distanceToTop = element.offsetTop - scroller.scrollTop
  const distanceToBottom = distanceToTop + element.offsetHeight - scroller.offsetHeight

  if (scroller === topScrollableElement()) return element.offsetTop
  if (distanceToTop < 0) return scroller.scrollTop + distanceToTop
  if (distanceToBottom > 0) return scroller.scrollTop + distanceToBottom
  return scroller.scrollTop
}
