import AuthStore from '@stores/AuthStore'
import HttpStatus from '@constants/HttpStatus'
import axios from 'axios'
import { asFormData, csrfToken, hasFiles } from '@helpers/SubmitHelper'
import { cloneDeep, isEmpty } from 'lodash'
import { deepCamelizeKeys, deepDecamelizeKeys } from '@helpers/ObjectHelper'
import { formatUrl } from '@helpers/UrlHelper'
import { isLocal } from '@helpers/EnvironmentHelper'
import { isUnauthorizedError } from '@helpers/ErrorHelper'
import { snakeCaseWithNumbers } from '@helpers/StringHelper'

// NOTE: Allows to target only API requests with the interceptors.
export const axiosApi = axios.create()

// Default options for the requests, JSON is used as the default MIME type.
export const defaultRequestHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

export const defaultFormDataRequestHeaders = cloneDeep(defaultRequestHeaders)
defaultFormDataRequestHeaders['Content-Type'] = 'multipart/form-data'

if (isLocal) {
  // Used in testing to determine whether there are pending requests we should wait for
  window.PENDING_REQUESTS = 0

  axiosApi.interceptors.request.use(function (config) {
    window.PENDING_REQUESTS++
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  axiosApi.interceptors.response.use(function (response) {
    if (window.PENDING_REQUESTS > 0) window.PENDING_REQUESTS--
    return response
  }, function (error) {
    if (window.PENDING_REQUESTS > 0) window.PENDING_REQUESTS--
    return Promise.reject(error)
  })
}

// Internal: Handles AJAX redirects from the rails-ajax_redirect gem, and
// receives information from headers rendered in Vue::BaseController.
function onSuccessfulResponse (response) {
  if (response.status === HttpStatus.AJAX_REDIRECT_STATUS_CODE) {
    window.location = response.headers.location
  }
  AuthStore.extractMetadataFromHeaders(response)
  return response
}

// Internal: Handles 401 responses when the user is logged out.
function onRequestError (error) {
  if (isUnauthorizedError(error)) {
    window.location = window.location.origin
  }
  throw error
}

export const apiResponseInterceptor = axiosApi.interceptors.response.use(onSuccessfulResponse, onRequestError)

// Public: Makes an AJAX request to the Rails server.
//
// method - One of 'get', 'post', 'path', 'put', 'delete'.
// url - The url of the request.
// options - Can be the "params" as a shorthand, or an Object with the following:
//   params: The query string parameters to interpolate in the URL.
//   data: The body of the request, should be a plain Object.
//   camelize: Whether to camelize the keys of the data obtained from the server.
//   decamelize: The function used to snakeCase the keys of the data sent to the server.
//   responseType: The type of data that the server will respond with.
//
// Returns a Promise for the request.
export function request (method, url, options = {}) {
  const { params = (options.data || options), data = {}, camelize = true, decamelize = snakeCaseWithNumbers, responseType, ...otherOptions } = options

  if (isLocal && options.data !== undefined && !isEmpty(otherOptions)) {
    throw new Error(`URL parameters not present in 'data' must be specified explicitly using the 'params' option.\nOptions provided:\n${JSON.stringify(otherOptions)}`)
  }

  // NOTE: Prevent events from being accidentally passed as data.
  // The property isTrusted generally means that the object is a browser event.
  // https://developer.mozilla.org/en-US/docs/Web/API/Event/isTrusted
  if (data.hasOwnProperty('isTrusted')) throw new Error(`data should be a plain JS object: ${data}`)

  const sendAsFormData = hasFiles(data)
  const decamelizedData = decamelize ? deepDecamelizeKeys(data, decamelize) : data

  const requestOptions = {
    data: sendAsFormData ? asFormData(decamelizedData) : decamelizedData,
    method,
    responseType,
    url: formatUrl(url, params),
    headers: {
      ...(sendAsFormData ? defaultFormDataRequestHeaders : defaultRequestHeaders),
      'X-CSRF-TOKEN': csrfToken() || '',
      'X-CARE-PROVIDER-ID': AuthStore.careProviderId || '',
      'X-REQUESTED-WITH': 'XMLHttpRequest',
    },
  }

  return axiosApi.request(requestOptions)
    .then(response => {
      if (responseType) return response // Let the caller handle the conversion.
      return camelize ? deepCamelizeKeys(response.data) : response.data
    })
}
