<script>
// Public: Provides the funcionality of showing a tip with an icon
//
// Example: QuicksearchProTIps in NavigationBarQuicksearchMenu.
export default {
  name: 'ProTip',
  props: {
    // Public: Icon to be shown before the tip
    icon: { type: [Boolean, String], default: 'bulb' },
  },
}
</script>

<template>
  <div class="pro-tip">
    <template v-if="icon">
      <Icon class="pro-tip__icon" :name="icon" size="medium"/><span><slot/></span>
    </template>
    <slot v-else/>
  </div>
</template>

<style lang="scss" scoped>
.pro-tip {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 8px 14px 8px;
  word-break: break-word;

  ::v-deep .kbd {
    margin-left: 5px;
  }

  ::v-deep .pro-tip-query {
    font-weight: bold;
    margin-left: 4px;
  }
}

.pro-tip__icon {
  margin-right: 4px;
}
</style>
