import Crouton from '@components/global/Crouton'
import UserEvents from '@constants/UserEvents'
import { errorToMessage, isForbiddenError } from '@helpers/ErrorHelper'
import { isString } from 'lodash'
import { mountComponent } from '@helpers/ComponentHelper'
import { parseJSON } from '@helpers/JsonHelper'
import { prependIn } from '@helpers/DomHelper'
import { scrollToTop } from '@helpers/ScrollHelper'

// Internal: Notifies of a missing container. Because throwing an error inside
// a promise handler will not bubble up the error in development, we also log it.
function missingContainerError (placeholder) {
  const message = `Crouton container not provided. placeholder: ${placeholder}`
  console.warn(message)
  return new TypeError(message)
}

// Internal: Returns the parent element for the Crouton component.
function getContainer ($vm, placeholder) {
  return $vm.$el.querySelector(placeholder) || document.querySelector(placeholder) || throw missingContainerError(placeholder)
}

// Public: Creates a Crouton component and appends it to the DOM.
//
// Returns the Crouton instance.
export function showCrouton ($vm, messageOrObject, { component = Crouton, placeholder = '.crouton-placeholder', container = getContainer($vm, placeholder), prepend = false, ...options } = {}) {
  if (isForbiddenError(messageOrObject)) return // We handle access denied errors with a router plugin.
  if (messageOrObject === UserEvents.USER_CANCELLATION) return // Ignore cancellations, they fire catch guards but are not actual errors.
  const message = isString(messageOrObject) ? messageOrObject : errorToMessage(messageOrObject)
  const placeholderOptions = container.dataset.crouton ? parseJSON(container.dataset.crouton) : {}
  const crouton = mountComponent($vm, component, { propsData: { message, ...placeholderOptions, ...options } })
  if (prepend) {
    prependIn(container, crouton.$el)
  } else {
    container.appendChild(crouton.$el)
  }
  return crouton
}

// Public: Creates a Crouton component and appends below the navbar.
//
// Returns the Crouton instance.
export function showPageCrouton ($vm, message, options = {}) {
  scrollToTop($vm.$el)
  return showCrouton($vm, message, { placeholder: '#page_crouton_placeholder', ...options })
}
