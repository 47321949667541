<script>
import { isComponentVNode, updateComponentOptions } from '@helpers/NodeHelper'

export default {
  name: 'TableRow',
  computed: {
    // Internal: Whether the first column should be fixed when scrolling horizontally.
    stickyScrolling () {
      return this.$parent.stickyScrolling // NOTE: We rely on <Table> as a parent to simplify prop passing.
    },
    // Internal: Whether the table should have striped odd/even rows.
    stripedTable () {
      return this.$parent.striped // NOTE: We rely on <Table> as a parent to simplify prop passing.
    },
  },
  methods: {
    // Internal: Passes stickyScrolling to TableCellCheckbox and TableCell.
    stickyChildren (children) {
      let lastStickyCell = false
      return children.map(cellVNode => {
        if (lastStickyCell) {
          return cellVNode
        } else {
          // Lock TableCellCheckbox for selectable rows, but keep going.
          if (isComponentVNode(cellVNode, 'TableCell')) lastStickyCell = true
          return updateComponentOptions(cellVNode, {
            propsData: {
              stickyScrolling: { showSeparator: lastStickyCell },
            },
          })
        }
      })
    },
  },
  render (h) {
    return h('tr',
      {
        class: ['table-row', this.stripedTable && 'table-row--striped'],
        on: {
          click: $event => this.$emit('click', $event),
        },
      },
      this.stickyScrolling ? this.stickyChildren(this.$slots.default) : this.$slots.default,
    )
  },
}
</script>

<style lang="scss" scoped>
.table-row--striped:nth-child(odd) {
  background: $row-odd-bg;
}

.table-row--striped:nth-child(even) {
  background: $row-even-bg;
}

.table-row {
  background: inherit; /* Ensure content doesn't show under the fixed cells when scrolling */

  &.focused ::v-deep {
    @include non-ie11 {
      .table-cell:first-of-type::before {
        background: $row-focused-indicator-bg;
        content: "";
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 3px;
      }
    }

    background: $row-hover-bg;
    color: $BLACK;
    fill: $BLACK;
  }
}

.table.bordered .table-row {
  border-top: $row-border;

  &:last-of-type {
    border-bottom: $row-border;
  }
}
</style>
