<script>
import WindowStore from '@stores/WindowStore'
import { adjustAllCells } from '@directives/stick-to-left'
import { ifEverChanged } from '@helpers/WatchHelper'

export default {
  name: 'Table',
  props: {
    // Public: Whether TableRows should draw a border.
    bordered: { type: Boolean, default: false },

    // Public: Whether to squish more rows and cells in the same space.
    compactCells: { type: Boolean, default: false },

    // Public: Labels for the table headers. Can also be translation keys.
    headers: { type: Array, default: () => [] },

    // Public: If enabled the first column is locked when scrolling horizontally.
    stickyScrolling: { type: Boolean, default: false },

    // Public: If enabled the top row is locked when scrolling vertically.
    stickyHeaders: { type: Boolean, default: false },

    // Public: Whether odd TableRows should have a different background color.
    striped: { type: Boolean, default: false },
  },
  data () {
    return {
      // Internal: Whether the table should be able to scroll horizontally.
      scrollable: false,

      // Internal: We reduce the padding on the first and last cells if there's
      // too much content. To avoid flicker, once it's set, we don't unset it.
      ...ifEverChanged(this, { watch: 'scrollable', everChanged: 'everScrollable' }),
      ...ifEverChanged(this, { watch: 'scrollable', everChanged: 'compactSidePadding' }),
    }
  },
  computed: {
    isTablet: WindowStore.isScreen('only-tablet'),
    ...WindowStore.mapState('isLayoutMaximized'),
  },
  watch: {
    // Internal: Since the size of the cell might change after the initial
    // insertion and calculation, we need to adjust manually.
    compactSidePadding () {
      this.$nextTick(adjustAllCells)
    },
    isTablet: {
      handler (isTablet) {
        if (isTablet) this.compactSidePadding = true
      },
      immediate: true,
    },
    isLayoutMaximized (isLayoutMaximized) {
      this.compactSidePadding = isLayoutMaximized || this.everScrollable || this.isTablet
    },
  },
  methods: {
    // Internal: Allow scrolling the table if it's too wide for its container.
    toggleSideScrolling ({ contentRect, target: table }) {
      const tableContent = table.querySelector('tbody')
      const tableContainer = table.parentNode
      if (tableContainer && tableContent) {
        this.scrollable = tableContent.scrollWidth > tableContainer.offsetWidth
      }
      // Ensure we also make the padding compact if the user expands the layout.
      if (this.isLayoutMaximized) this.compactSidePadding = true
    },
  },
}
</script>

<template>
  <table
    v-on-resize="toggleSideScrolling"
    class="table"
    :class="{
      'compact-side-padding': compactSidePadding,
      bordered,
      compact: compactCells,
      scrollable,
      sticky: stickyScrolling,
      striped,
    }"
  >
    <TableHeader v-bind="{ headers, stickyScrolling, compactCells, stickyHeaders }">
      <template #preHeadersContent>
        <slot name="preHeadersContent"/>
      </template>
      <template #headerContent="slotScope">
        <slot name="headerContent" v-bind="slotScope"/>
      </template>
    </TableHeader>

    <tbody class="table-rows">
      <slot/>
    </tbody>
  </table>
</template>

<style lang="scss" scoped>
.table-rows {
  background: inherit; /* Ensure content doesn't show under the fixed cells when scrolling */
}

.table {
  background: $bg-card; /* Ensure content doesn't show under the fixed cells when scrolling */
  position: relative;
  width: 100%;

  &.scrollable {
    display: block;
    overflow-y: auto;
  }
}

// We specify it before .table so that the :first-of-type and :last-of-type rules override this padding.
.compact ::v-deep {
  .table-cell {
    @include media(desktop-narrow) { padding: $cell-comfortable-padding; }

    padding: $cell-compact-padding;
  }

  .badge {
    @include badge-compact;
  }

  .tag {
    font-size: $fs-secondary;
  }
}

.vertically-compact ::v-deep .table-cell {
  padding-bottom: $cell-padding / 2;
  padding-top: $cell-padding / 2;
}

.table ::v-deep {
  .table-cell {
    background: inherit; /* Ensure content doesn't show under the fixed cells when scrolling */
  }

  .table-cell:first-of-type {
    @include non-ie11 {
      background-clip: padding-box; /* To force top and bottom borders to show within firefox */
      position: relative; /* To prevent the focused indicator from overflowing */
    }

    padding-left: $padding-horizontal-html;
  }

  .table-cell:last-of-type {
    padding-right: $padding-horizontal-html;
  }

  /* Make the link in the first content cell bolder */
  .table-cell:first-of-type,
  .table-cell-checkbox + .table-cell {
    .navigation-link {
      font-weight: $fw-regular;
    }
  }
}

@include non-ie11 {
  .table.scrollable ::v-deep {
    .table-cell.fixed-cell {
      position: sticky;
    }

    /* Display a separator after the fixed cells to make scrolling more evident */
    .fixed-cell.with-separator::after {
      background: linear-gradient(to right, $row-border-color, transparent);
      bottom: 0;
      content: "";
      display: block;
      position: absolute;
      right: -3px;
      top: 0;
      width: 3px;
    }
  }
}

// Ensure we reduce the amount of horizontal space if the content does not fit.
.compact-side-padding ::v-deep .table-cell {
  padding-left: $cell-compact-padding;
  padding-right: $cell-compact-padding;
}

// We need these specific rules to avoid giving it too much specifity, as it
// would interfere with the `.table-cell-checkbox + .table-cell` styling rule.
.compact-side-padding ::v-deep .table-cell:first-of-type {
  padding-left: $cell-compact-padding;
}

// We need these specific rules to avoid giving it too much specifity, as it
// would interfere with the `.table-cell-checkbox + .table-cell` styling rule.
.compact-side-padding ::v-deep .table-cell:last-of-type {
  padding-right: $cell-compact-padding;
}

/* The first content cell after a checkbox should have no left padding */
.table ::v-deep .table-cell-checkbox + .table-cell {
  padding-left: 0;
}

.table ::v-deep .table-cell.table-cell-checkbox {
  padding-right: 0;
}

.compact-side-padding ::v-deep .table-cell-checkbox {
  width: 36px;
}

.table.no-left-padding ::v-deep .table-cell:first-of-type {
  padding-left: 0;
}

.table.no-right-padding ::v-deep .table-cell:last-of-type {
  padding-right: 0;
}
</style>
