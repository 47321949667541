<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'TextInput',
  mixins: [InputMixin],
  props: {
    // Public: Text content of the input.
    value: { type: String, default: null },

    // Public: Whether it's a text input or a textarea.
    multiline: { type: Boolean, default: false },

    // Public: Whether it's a text input or a password input.
    password: { type: Boolean, default: false },

    // Public: Whether the label of the component is visible, or not.
    showLabel: { type: Boolean, default: true },

    // Public: Override the input type
    inputType: { type: String, default: null },

    // Public: Override the input style
    inputStyle: { type: [Array, Object], default: null },

    // Public: Number of initial rows when a textarea.
    rows: { type: Number, default: 3 },
  },
  computed: {
    inputListeners () {
      return { ...this.$listeners, input: this.onInput }
    },
    inputTag () {
      return this.multiline ? 'textarea' : 'input'
    },
    type () {
      return this.inputType || (this.password ? 'password' : 'text')
    },
  },
  methods: {
    focus () {
      this.$refs.input.focus()
    },
    onInput (event) {
      this.$emit('input', event.target.value)
    },
  },
}
</script>

<template>
  <div class="text-input">
    <Label v-if="showLabel && label" v-bind="{ required, hint }" :class="{ error, multiline }">
      {{ label }}
      <template slot="labelExtras">
        <slot name="labelExtras"/>
      </template>
    </Label>
    <component
      :is="inputTag"
      ref="input"
      v-autosize
      class="input"
      :class="[multiline ? 'textarea' : 'text', { error }]"
      :disabled="disabled"
      :placeholder="placeholder"
      :rows="rows"
      :style="inputStyle"
      :type="type"
      :value.prop="value"
      v-bind="$attrs"
      v-on="inputListeners"
    />
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
@include input;

.text-input {
  position: relative;
}

.input.textarea {
  display: block;
  resize: none;
}
</style>
