import { assign, isArray, isNumber } from 'lodash'

// Internal: Returns the transform matrix with the horizontal and vertical
// transformations applied
function transform (element, transformX = null, transformY = null) {
  const transform = window.getComputedStyle(element, null).getPropertyValue('transform')
  if (!transform) return console.warn(`Element does not have a transform property ${element}`)

  const transformMatrix = transform.replace(/ |\(|\)|matrix/g, '').split(',')
  transformMatrix[4] = Math.ceil(transformX || transformMatrix[4])
  transformMatrix[5] = Math.ceil(transformY || transformMatrix[5])

  return `matrix(${transformMatrix.join()})`
}

// Public: centers the element in the Y axis according to its height
export function centerVertically (element, { marginPreferred = 0, marginMin = 0 } = {}) {
  if (!element) return
  const bodyHeight = document.body.offsetHeight
  const elementHeight = parseInt(window.getComputedStyle(element, null).getPropertyValue('height'))

  if (elementHeight + 2 * marginPreferred < bodyHeight) {
    // If there's enough room, use the preferred margin.
    element.style.top = `${marginPreferred}px`
    element.style.transform = ''
  } else if (elementHeight + 2 * marginMin < bodyHeight) {
    // If it fits inside the viewport center it vertically.
    element.style.top = '50%'
    element.style.transform = transform(element, null, -elementHeight / 2)
  } else {
    // Else apply the minimum margin and let it overflow.
    element.style.top = `${marginMin}px`
    element.style.transform = ''
  }
}

// Public: Checks if the element is currently visible (quick'n'dirty check).
export function isVisible (el) {
  return el.style && el.style.display !== 'none' && el.style.visibility !== 'hidden'
}

// Public: calculates the the grid-template-columns attribute from the given columns
export function getGridTemplate (columns = 1) {
  if ((isNumber(columns) && columns < 1) || (isArray(columns) && !columns.length)) {
    throw new TypeError(`Invalid number of columns: ${columns}`)
  }
  return { gridTemplateColumns: isNumber(columns) ? `repeat(${columns}, 1fr)` : columns.join(' ') }
}

export function setFullRowSpan (element) {
  assign(element.style, { gridColumn: '1 / -1' })
}
