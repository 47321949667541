<script>
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'CurrencyInput',
  mixins: [
    InputMixin,
  ],
  props: {
    // Public: Content of the input.
    currency: { type: String, default: '$' },

    // Public: Minimum value allowed for the input.
    min: { type: [String, Number], default: null },

    // Public: Maximum value allowed for the input.
    max: { type: [String, Number], default: null },

    // Public: How much the value increases when the up/down arrows are pressed.
    step: { type: [String, Number], default: 0.01 },

    // Public: Content of the input.
    value: { type: [String, Number], default: null },
  },
  data () {
    return {
      focus: false,
    }
  },
  computed: {
    inputListeners () {
      return {
        ...this.$listeners,
        input: event => this.$emit('input', event.target.value),
      }
    },
  },
}
</script>

<template>
  <div class="currency-input">
    <Label v-if="label" v-bind="{ required, hint }">{{ label }}</Label>
    <div class="wrapper" :class="{ focus, error, disabled }" @click="focus = true">
      <span class="currency">{{ currency }}</span>
      <input
        :class="{ error }"
        :disabled="disabled"
        :max="max"
        :min="min"
        :placeholder="placeholder"
        :step="step"
        :value="value"
        type="number"
        class="input"
        v-on="inputListeners"
        @blur="focus = false"
      >
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  @include input-border;

  align-items: center;
  display: flex;

  .currency {
    background-color: $tundora-l-1;
    color: $WHITE;
    margin-bottom: 0;
    padding: 4px 12px 5px;
  }

  .input {
    @include input-placeholder;

    background: none;
    border: none;
  }

  &:hover {
    border-color: $input-border-color-hover;
  }

  &.error,
  &.error:hover {
    background-color: $input-error-background-color;
    border-color: $input-error-color;
    color: $input-error-color;

    .currency {
      background-color: $input-error-color;
    }
  }

  &.focus,
  &.focus:hover {
    border-color: $input-border-color-active;
    outline: none;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
    color: $input-disabled-color;
  }
}

@include input;
</style>
