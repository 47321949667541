// Public: IE11 uses different names for certain keys.
// Related: https://github.com/vuejs/vue/commit/4378fc5124067c2b3a3517dd7f527edd9be2ad37#diff-cd513113ac480c30cffa092f5fe266edR25
export default {
  Up: 'ArrowUp',
  Left: 'ArrowLeft',
  Right: 'ArrowRight',
  Down: 'ArrowDown',
  Spacebar: ' ',
  Esc: 'Escape',
  Del: 'Delete',
}
