import NProgress from 'nprogress/nprogress'
import RoutesStore from '@stores/RoutesStore'
import WindowStore from '@stores/WindowStore'

// NOTE: We use sync: true because by default watchers are asynchronous, which
// would prevent the progress bar from being displayed (navigation is too fast).
//
// See https://forum.vuejs.org/t/vue-watchers-ticks-reactivity/40003/2
WindowStore.watch('isNavigating',
  isNavigating => isNavigating ? NProgress.start() : NProgress.done(),
  { sync: true },
)

// Public: Adds a loading bar at the top during page loads.
export default function NavigationRouterPlugin ({ router, progressContainer }) {
  NProgress.configure({ trickleSpeed: 50, parent: progressContainer })
  if (!router) return

  // Start the route progress bar if this isn't an initial page load.
  router.beforeEach((routeTo, routeFrom, next) => {
    if (routeFrom.name) WindowStore.setNavigating(true)
    next()
  })

  // Complete the animation of the progress bar once the route has evaluated.
  router.afterEach((routeTo, routeFrom) => WindowStore.setNavigating(false))

  // Record the page change to track page changes for back button navigation.
  router.afterEach((routeTo, routeFrom) => RoutesStore.navigatedTo(routeTo))
}
