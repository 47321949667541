import MyAccountRequests from '@requests/MyAccountRequests'
import UserCache from '@services/UserCache'
import { assign, each, isNil, isObject } from 'lodash'
import { extractCareProviderIdFromUrl } from '@helpers/UrlHelper'
import { isTest } from '@helpers/EnvironmentHelper'
import { registerAndGetStore } from '@helpers/StoreHelper'
import { setBugsnagUser } from '@evolve/BugsnagIntegration'
import { setPendoUser } from '@evolve/PendoIntegration'
import { tryParseJSON } from '@helpers/JsonHelper'

export function state () {
  return {
    // Public: The current care provider. Can be changed by the user.
    careProvider: {},

    // Public: The authenticated user. Might change when using impersonation.
    currentUser: {},

    // Public: The last time the user signed in. We keep it separate from the
    // user because we are not busting the user cache key on login (intentionally).
    currentSignInAt: new Date().toISOString(),

    // Public: The navigation bar. Changes when ther user or care provider changes.
    navigationBar: {},

    // Internal: Necessary to prevent CSRF attacks, used for any non-GET request.
    csrfToken: null,

    // Legacy: Remove when there are no more Rails pages.
    flashMessages: [],

    // Public: Allows the NotificationsMenu to detect new notifications.
    unreadNotificationsCount: '0',

    // Public: Indicates if we need to track page visits.
    trackPageVisits: !isTest,
  }
}

// Internal: Whether the provided data allows us to fully load the app.
function allDataAvailable (data) {
  return Boolean(data && data.currentUser?.cacheKey && data.careProvider?.cacheKey && data.navigationBar?.menus)
}

export const getters = {
  // Public: Whether the store is fully initialized.
  initialized: allDataAvailable,
  // Helper: Simplifies watching for user changes.
  careProviderId (state) {
    return state.careProvider.id || extractCareProviderIdFromUrl()
  },
  // Helper: Simplifies watching for user changes.
  currentUserId (state) {
    return state.currentUser.id
  },
  // Public: Permissions for the current user.
  permissions (state) {
    return state.currentUser.permissions
  },
  // Public: Checks if the local information is stale and should be refreshed.
  isCacheExpired ({ currentUser, careProvider }, getters) {
    return ({ userCacheKey, careProviderCacheKey }) => {
      if (!currentUser.cacheKey || !careProvider.cacheKey) return false // Skip if it's being fetched.
      return currentUser.cacheKey !== userCacheKey ||
        careProvider.cacheKey !== careProviderCacheKey
    }
  },
}

export const mutations = {
  STUB_FOR_TESTS (state, data) {
    assign(state, data)
  },
  CHANGE_CARE_PROVIDER (state, careProviderId) {
    state.careProvider.id = careProviderId
  },
  // NOTE: Prevents re-entrance on the info request, which could create a loop.
  CLEAR_CACHE_KEYS (state) {
    state.currentUser.cacheKey = state.careProvider.cacheKey = null
  },
  UPDATE_DISPLAY_USER_SURVEY (state, displaySurvey) {
    state.currentUser.displayPostLoginUserProfileModal = displaySurvey
  },
  UPDATE_USER_INFO (state, userInfo) {
    // Update the information in the store so that it's reflected in components.
    assign(state, userInfo)

    // Cache data locally for a faster load on future page visits.
    UserCache.storeAll(state)
  },
  UPDATE_OTHER_INFO (state, otherInfo) {
    each(otherInfo, (value, key) => {
      // NOTE: We only want to update data if it's different and non-empty.
      if (!isNil(value) && value !== state[key]) state[key] = value
    })
  },

}

export const actions = {
  // NOTE: Also used in the evolve/navbar.js pack to set the initial state with
  // the variables rendered in beta.html.haml. Also in unit tests.
  updateUserInfo ({ commit }, userInfo) {
    commit('UPDATE_USER_INFO', userInfo)
  },
  updateDisplayUserSurvey ({ commit }, displaySurvey) {
    commit('UPDATE_DISPLAY_USER_SURVEY', displaySurvey)
  },
  // Internal: Fetches user information and loads it into the store.
  async fetchUserInfo ({ state, commit }, query) {
    commit('CLEAR_CACHE_KEYS')
    await MyAccountRequests.info({ query }).then(userInfo => {
      // If care provider doesn't exist, the server will return an HTML page as a response after a redirect, we don't want to accept that
      if (isObject(userInfo)) AuthStore.updateUserInfo(userInfo)
      else throw new Error('Unable to get user information needed to load the app.')
    })
    return state.currentUser
  },
  // Internal: Checks if the information we have in the store is fresh.
  async fetchInfoIfCacheExpired ({ state, commit, getters }, cacheKeys) {
    if (getters.isCacheExpired(cacheKeys)) await AuthStore.fetchUserInfo()
  },
  // Internal: These headers are rendered in Vue::BaseController, providing
  // an efficient way to detect stale data without making additional requests.
  async extractMetadataFromHeaders ({ state, commit }, { headers, ...response }) {
    const userCacheKey = headers['x-user-cache-key']
    const careProviderCacheKey = headers['x-care-provider-cache-key']
    if (!userCacheKey || !careProviderCacheKey) return

    const currentSignInAt = headers['x-user-current-sign-in-at']
    const csrfToken = headers['x-csrf-token']
    const unreadNotificationsCount = headers['x-unread-notifications-count'] || '0'
    let flashMessages = tryParseJSON(headers['x-flash-messages'])

    commit('UPDATE_OTHER_INFO', { currentSignInAt, csrfToken, unreadNotificationsCount, flashMessages })

    await AuthStore.fetchInfoIfCacheExpired({ userCacheKey, careProviderCacheKey })
    if (AuthStore.initialized) {
      UserCache.setAll({ currentSignInAt: state.currentSignInAt, csrfToken: state.csrfToken }, { within: state.careProvider.id })
    }
  },
  // Internal: If we know which is the current user, check the local cache, and
  // the user is visiting the same care provider than last time, use that info.
  loadUserInfo ({ commit }, careProviderId) {
    const cachedInfo = UserCache.retrieveAll(careProviderId)
    const loaded = allDataAvailable(cachedInfo)
    if (loaded) commit('UPDATE_USER_INFO', cachedInfo)
    return loaded
  },
  // Public: Loads information from local storage, or triggers a request to
  // fetch the current user, care provider, and navigation bar.
  async loadOrFetchUserInfo ({ state, getters }) {
    const loaded = await AuthStore.loadUserInfo(getters.careProviderId)
    if (!loaded) await AuthStore.fetchUserInfo({ fresh: true })
    return state.currentUser || throw new Error('Unable to get user information needed to load the app.')
  },
  // Public: Loads information for a different care provider, if necessary.
  async onCareProviderChange ({ state, commit, getters }, careProviderId) {
    if (careProviderId === state.careProviderId) return
    commit('CHANGE_CARE_PROVIDER', careProviderId)
    await AuthStore.loadOrFetchUserInfo()
  },
  stub ({ commit }, data) {
    commit('STUB_FOR_TESTS', { ...state(), ...data })
  },
}

const AuthStore = registerAndGetStore('auth', { state, getters, mutations, actions })

// Internal: Waits until user information is available.
const unwatch = AuthStore.watch('initialized', initialized => {
  if (!initialized) return
  unwatch()
  setBugsnagUser(AuthStore)
  setPendoUser(AuthStore)
}, { immediate: true })

export default AuthStore
