<script>
import WindowStore from '@stores/WindowStore'

export default {
  name: 'CardLayout',
  inheritAttrs: false,
  props: {
    // Public: Whether to limit the max width for the layout on wider viewports.
    limitWidth: { type: Boolean, default: false },

    // Public: Whether the content section should take full screen height.
    fullHeight: { type: Boolean, default: false },

    // Public: Whether the content section should be maximized by default.
    maximized: { type: Boolean, default: false },
  },
  computed: {
    // When maximized, there is no margin around the card.
    ...WindowStore.mapState('isLayoutMaximized'),
  },
  beforeCreate () {
    WindowStore.setLayoutMaximized(false)
  },
  methods: {
    ...WindowStore.mapActions('setLayoutMaximized'),
    // Internal: Allows the settings layout to adjust sticky elements on resize.
    onTitleResize (event) {
      this.$emit('layout:titleResize', event)
    },
  },
}
</script>

<template>
  <FlexLayout class="card-layout" :class="{ 'limit-width': limitWidth }" v-bind="$attrs">
    <div v-if="$slots.title" v-on-resize="onTitleResize" class="layout__title">
      <slot name="title"/>
    </div>

    <Card v-if="$slots.default" class="layout__card" :class="{ 'full-height': fullHeight, maximized: maximized || isLayoutMaximized }">
      <template v-if="!$slots.title && !maximized">
        <Button
          v-if="isLayoutMaximized"
          v-tooltip="{ content: 'Minimize view', placement: 'left' }"
          class="card-resize-button"
          icon="minimize"
          name="Minimize Layout"
          compact
          @click="setLayoutMaximized(false)"
        />
        <Button
          v-else
          v-tooltip="{ content: 'Maximize view', placement: 'left' }"
          class="card-resize-button"
          icon="maximize"
          name="Maximize Layout"
          compact
          @click="setLayoutMaximized(true)"
        />
      </template>
      <slot/>
    </Card>
    <div v-else-if="$slots.content" class="layout__card">
      <slot name="content"/>
    </div>

    <slot name="solo"/>
  </FlexLayout>
</template>

<style lang="scss" scoped>
.layout__title {
  background-color: $bg-card;
  border-radius: $radius-normal;
  margin-bottom: 16px;

  ::v-deep .title-section {
    box-shadow: $box-shadow-card;
  }
}

.layout__card {
  position: relative;
}

.card-resize-button {
  @include z-index(above);

  background-color: $jelly-bean-l-3;
  border-radius: $radius-normal;
  color: $jelly-bean-l-0;
  position: absolute;
  right: 4px;
  top: 4px;
}

@include media(only-tablet-landscape) {
  .layout__card {
    border-radius: 0;
  }

  .card-resize-button {
    display: none;
  }
}

@include media(desktop) {
  .layout__title {
    margin: $padding-horizontal-html $padding-horizontal-html 16px $padding-horizontal-html;
  }

  .layout__card {
    margin: $padding-horizontal-html;
  }

  .layout__title + .layout__card {
    margin-top: 0;
  }
}

.card.full-height {
  flex-grow: 1;
}

.card.maximized {
  border-radius: 0;
  margin: 0;
}

// We want to improve readability in large screen sizes, so we set a max width.
$max-settings-width: 1200px + 2 * $padding-horizontal-html;
$max-width-center: 800px + 2 * $padding-horizontal-html;

.center {
  ::v-deep .content {
    @include media(desktop-narrow) {
      padding: 0 $padding-horizontal-html $padding-horizontal-html;
    }

    padding: 0 $padding-horizontal-html-compact $padding-horizontal-html-compact;
  }

  .card-resize-button {
    display: none;
  }

  ::v-deep .form-actions.sticky {
    margin: -32px;
  }
}

@media (min-width: $max-width-center) {
  .center {
    margin: 0 auto;
    width: $max-width-center;
  }
}

@media (min-width: $max-settings-width) {
  .card-layout.limit-width {
    margin: 0 auto;
    width: $max-settings-width;
  }
}
</style>
