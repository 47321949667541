<script>
import EnterKey from '@components/EnterKey'
import NavigationBarMenuItem from '@components/NavigationBarMenuItem'
import NavigationBarMenuTitle from '@components/NavigationBarMenuTitle'

import KeyboardNavigationService from '@services/KeyboardNavigationService'
import SearchWeight from '@constants/SearchWeight'
import WindowStore from '@stores/WindowStore'

import { flatMap, take } from 'lodash'
import { highlightString, tokenMatcherFor } from '@helpers/FilterHelper'
import { mapFind } from '@helpers/ArrayHelper'
import { setBodyScroll } from '@helpers/ScrollHelper'

// Internal: In order to make the Manage menu more snappy, we want to avoid
// rendering too many items.
const MAX_RENDERED_PHRASES = 20

// NOTES: We use the @navigated event to ensure that any navigation causes the
// modal to close, such as clicking a router-link for the current Vue page,
// which would normally cause nothing to happen, and the modal would stay open.
export default {
  name: 'NavigationBarModalMenu',
  components: {
    EnterKey,
    NavigationBarMenuItem,
    NavigationBarMenuTitle,
  },
  props: {
    // Public: Configuration for the menu item, usually rendered in Ruby.
    menu: { type: Object, default: () => ({}) },

    // Public: The label to display in the menu item.
    title: { type: String, default () { return this.menu.title } },
  },
  data () {
    return {
      expanded: false,

      // Internal: Toggled manually by the user to display all menu items.
      viewAllGroups: false,

      filteringBy: null,
      focusedMenuItemTitle: null,
      phrasesNavigationService: new KeyboardNavigationService(this),
      navigationService: new KeyboardNavigationService(this, { up: true, down: true, left: true, right: true }),
    }
  },
  computed: {
    // Public: Returns groups with the menu items that the user can access.
    displayedGroups () {
      return this.menu.groups.map(group => {
        const items = group.items.map(item => {
          const phrases = this.filterItemPhrases(item)
          if (phrases.length > 0) return { ...item, phrases }
        }).filter(x => x)
        if (items.length > 0) return { ...group, items }
      }).filter(x => x)
    },
    displayPhrases () {
      return this.displayedPhrases.length > 0
    },
    // Internal: Returns true if we are not rendering all the phrases.
    displayMorePhrasesMessage () {
      return this.filteredPhrases.length > this.displayedPhrases.length
    },
    filteredPhrases () {
      if (!this.filteringBy) return []
      return flatMap(this.displayedGroups, group => {
        return flatMap(group.items, item => item.phrases)
      }).sort((item, otherItem) => item.weight - otherItem.weight)
    },
    displayedPhrases () {
      return take(this.filteredPhrases, MAX_RENDERED_PHRASES)
    },
    navbarHeight () {
      return WindowStore.navbarHeight - 4
    },
    // Internal: Returns the height for the modal.
    modalHeight () {
      return `calc(100vh - ${this.navbarHeight}px)`
    },
    // Internal: A function that returns true if the phrase matches the user input.
    phrasesMatchingUserInput () {
      return tokenMatcherFor(this.filteringBy)
    },
  },
  watch: {
    expanded (isExpanded) {
      if (isExpanded) document.documentElement.scrollTop = 0
      // Hide the body scrollbar when expanded, to avoid confusing the user.
      setBodyScroll(!isExpanded)
    },
    filteringBy (currentlyFiltering, wasFiltering) {
      // If filtering, change so that navigation happens between phrases.
      if (!wasFiltering) this.phrasesNavigationService.prioritize()

      // If not filtering, allow to manually navigate menu items.
      if (!currentlyFiltering) this.navigationService.prioritize()

      // Ensure we focus the first phrase when the query changes.
      if (currentlyFiltering) this.$nextTick(() => this.phrasesNavigationService.focusFirstItem())
    },
    displayPhrases (thereArePhrases) {
      // Avoid displaying two focused items at the same time.
      if (thereArePhrases) {
        this.navigationService.blurCurrentItem() // because phrasesNavigationService becomes active
      } else {
        this.focusedMenuItemTitle = null // because navigationService becomes active
      }
    },
  },
  // Internal: Fix scrolling when navigating to the StyleGuide.
  beforeDestroy () {
    setBodyScroll(true)
  },
  methods: {
    // Internal: Returns the item phrases that match the current search.
    // NOTE: No effort has been done to optimize the performance of this algorithm.
    filterItemPhrases (item) {
      const lowerCaseFilteringBy = (this.filteringBy || '').toLowerCase()
      const phrases = [item.title, ...(item.phrases || [])]
      return phrases.filter(this.phrasesMatchingUserInput).map(phrase => {
        const lowerCasePhrase = phrase.toLowerCase()
        return {
          to: item.to,
          text: phrase,
          weight: (lowerCasePhrase === lowerCaseFilteringBy && SearchWeight.EXACT_MATCH) ||
            (lowerCasePhrase.startsWith(lowerCaseFilteringBy) && SearchWeight.PREFIX_MATCH) ||
            SearchWeight.NORMAL_MATCH,
        }
      })
    },
    highlightText (text) {
      return highlightString(text, { query: this.filteringBy })
    },
    menuItemForPhrase (phrase) {
      return mapFind(this.displayedGroups, group => group.items.find(item => item.phrases.includes(phrase)))
    },
    focusItemForPhrase (phrase) {
      const menuItem = this.menuItemForPhrase(phrase)
      this.focusedMenuItemTitle = menuItem ? menuItem.title : null
    },
    selectFocusedPhrase () {
      this.phrasesNavigationService.clickCurrentItem()
    },
    closePhrasesDropdown (event) {
      if (this.filteringBy) {
        this.filteringBy = null
        if (event) event.preventDefault() // The default would be closing the modal.
      }
    },
    // NOTE: For the modal menu, we want to make sure clicking it any of the
    // background closes it, to make it easier for the user to go back.
    toggleMenu () {
      if (this.expanded) {
        this.collapseMenu()
      } else {
        this.expandMenu()
      }
    },
    expandMenu () {
      this.expanded = true
    },
    collapseMenu () {
      this.expanded = false
      this.filteringBy = null
      this.viewAllGroups = false
    },
    setExpanded (shouldBeExpanded) {
      shouldBeExpanded ? this.expandMenu() : this.collapseMenuOrPhrases()
    },
    collapseMenuOrPhrases () {
      this.displayPhrases ? this.closePhrasesDropdown() : this.collapseMenu()
    },
    onModalClick (event) {
      if (event.defaultPrevented) return
      this.collapseMenuOrPhrases()
    },
    displayAllGroups () {
      this.filteringBy = null
      this.viewAllGroups = true
    },
    getItemId (groupIndex, itemIndex = 0) {
      return `menu-group-${groupIndex}-item-${itemIndex}`
    },
    // Public: Allows to navigate between menu groups using left and right.
    getMultiColumnNavigationItem (groupIndex) {
      return {
        navigationService: this.navigationService,
        left: groupIndex > 0 && this.getItemId(groupIndex - 1),
        right: groupIndex < this.displayedGroups.length - 1 && this.getItemId(groupIndex + 1),
      }
    },
    // Internal: The reason we do it this way instead of using `toggleMenu`
    // is to ensure that two menus can't be open at the same time.
    clickMenuToggle () {
      this.$refs.menuToggle.$el.click()
    },
  },
}
</script>

<template>
  <Dropdown
    v-if="menu.groups.length > 0"
    v-hotkey="{ [expanded ? 'esc' : menu.hotkey]: clickMenuToggle }"
    data-hotkey-overlay="{ distance: -8 }"
    class="navbar-modal"
    :class="{ expanded }"
    :show="expanded"
    mask
    compact
    skipCloseIfPrevented
    :contentClass="['navbar-modal__content search-dropdown-content', { 'dropdown-displayed': displayPhrases }]"
    :contentAttrs="{ style: `height: ${modalHeight}; top: ${navbarHeight}px;` }"
    v-bind="$attrs"
    @dropdown:expanded="setExpanded"
  >
    <NavigationBarMenuTitle slot="dropdownToggle" ref="menuToggle" :title="title" :expanded="expanded" @click="toggleMenu"/>

    <template slot="dropdownContent">
      <SearchInput
        v-model="filteringBy"
        v-autofocus
        class="navbar-modal__input"
        placeholder="Type here to search"
        @keydown.esc="collapseMenuOrPhrases"
        @click.prevent
      >
        <div v-show="displayPhrases" class="navbar-modal__phrases-dropdown">
          <NavigationLink
            v-for="phrase in displayedPhrases"
            :key="phrase.text"
            v-navigation-item="{ navigationService: phrasesNavigationService }"
            :to="phrase.to"
            class="navbar-modal__phrase no-underline"
            @navigated="collapseMenu"
            @focus="focusItemForPhrase(phrase)"
            v-html="highlightText(phrase.text)"
          />
          <div v-if="displayMorePhrasesMessage" class="navbar-modal__phrase more-phrases-message">
            There are more options, type to narrow them down.
          </div>
        </div>
      </SearchInput>
      <Button class="navbar-modal__close" icon="closeMedium" name="Close Menu" @click="collapseMenu"/>
      <div v-if="displayPhrases" class="navbar-modal__navigate-to underline" @click.prevent="selectFocusedPhrase">
        Navigate to <span class="navbar-modal__navigate-to-page">{{ focusedMenuItemTitle }}</span>
        <EnterKey/>
      </div>
      <div v-else-if="!viewAllGroups" class="navbar-modal__hint">
        <h3 v-if="filteringBy" class="navbar-modal__hint__text">
          No pages or actions match the current search.
          Try using synonyms or different wording.
        </h3>
        <h3 v-else class="navbar-modal__hint__text">
          Type the name of the page you want to access,
          or the action you would like to perform.
        </h3>
        <p>or</p>
        <Button name="View all menu items" @click.prevent="displayAllGroups">View all items</Button>
      </div>
      <div v-if="viewAllGroups" class="navbar-modal__groups">
        <div v-for="(group, groupIndex) in displayedGroups" :key="group.name" class="navbar-modal__group">
          <div class="navbar-modal__group__name">{{ group.name }}</div>
          <NavigationBarMenuItem
            v-for="(item, itemIndex) in group.items"
            :id="getItemId(groupIndex, itemIndex)"
            :key="item.title"
            v-navigation-item="getMultiColumnNavigationItem(groupIndex)"
            :class="{ focused: focusedMenuItemTitle && focusedMenuItemTitle === item.title }"
            class="rounded"
            :title="item.shortTitle || item.title"
            :to="item.to"
            @navigated="collapseMenu"
          />
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
// We are forced to do this in order to guarantee that Autoprefixer will
// generate code for all the necessary rows, since IE11 is not able to
// dynamically distribute children as specified in the modern CSS Grid spec.
$manage-menu-groups: 26;

$modal-padding-horizontal: 32px;
$modal-padding-vertical: 24px;

.menu ::v-deep .navbar-modal__content {
  align-items: center;
  animation: none;
  background: $bg-card;
  border-color: $bg-card;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: -42vw;
  left: 0;
  margin-top: 0;
  overflow: hidden;
  padding: $modal-padding-vertical $modal-padding-horizontal;
  position: fixed;
  right: 0;
  width: 100vw;
}

.navbar-modal__close {
  position: absolute;
  right: 40px;
  top: 36px;
}

.modal-container {
  box-shadow: none;
  cursor: default;
  margin-bottom: 0;
  min-height: 100%;
  width: 100%;
}

.navbar-modal__phrases-dropdown {
  @include z-index(dropdown);

  @include ie11 { overflow-x: hidden; }

  animation: enter-from-above 0.15s normal forwards ease-out;
  background: $WHITE;
  border-radius: $radius-normal;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  color: $fc-html-light;
  margin-top: 4px;
  max-height: 200px;
  overflow: auto;
  padding: 4px 0;
  position: absolute;
  text-align: left;
  width: 100%;
}

.navbar-modal__phrase {
  border-radius: $radius-normal;
  color: inherit;
  display: block;
  margin: 4px;
  padding: 5px 15px 4px;
  vertical-align: middle;

  &.focused {
    background-color: $tertiary-color;
  }

  &.more-phrases-message {
    color: $fc-html-light;
    cursor: default;
    font-size: $fs-normal;
  }
}

@mixin group-columns($count) {
  $group-width: 256px;

  @media (min-width: $count * $group-width + 2 * $modal-padding-horizontal) {
    @include dynamic-grid($columns: $count, $total: $manage-menu-groups, $width: $group-width);
  }
}

.navbar-modal__groups {
  @include group-columns(1);
  @include group-columns(2);
  @include group-columns(3);
  @include group-columns(4);
  @include group-columns(5);

  display: grid;
  grid-gap: 8px;

  /* stylelint-disable */
  justify-content: center;
  margin: 30px 0 (-$modal-padding-vertical);
  overflow-y: auto;
  padding: 0 $modal-padding-horizontal;
  width: 100vw;
  /* stylelint-enable */
}

.navbar-modal__group {
  padding: 8px 16px 16px;
}

.navbar-modal__group__name {
  font-weight: $fw-bold;
  letter-spacing: 0.8px;
  padding: 8px;
  text-align: left;
  text-transform: uppercase;
  vertical-align: bottom;
}

.navbar-modal__input {
  @include z-index(dropdown);

  @include media(tablet-small) {
    ::v-deep .search-input { width: 240px; }
  }

  @include media(desktop-narrow) {
    font-size: $fs-large;

    & ::v-deep .search-input {
      padding: 9px 18px 12px 21px;
      width: 480px;
    }

    & ::v-deep .search-icon {
      height: 24px;
      right: 16px;
      top: 20px;
      width: 24px;
    }
  }

  animation: grow-from-center 0.2s normal forwards ease-out;
  font-size: $fs-normal;
  margin-top: 40px;
}

.navbar-modal__hint {
  text-align: center;
}

.navbar-modal__hint__text {
  color: $fc-html;
  letter-spacing: 0.03em;
  line-height: 1.5em;
  white-space: pre-line;
}

.navbar-modal__navigate-to {
  @include clickable;

  margin-top: -88px;
  padding: 16px;

  .enter-key {
    margin-left: 8px;
  }
}

.navbar-modal__navigate-to-page {
  font-weight: $fw-bold;
}

.navbar-submenu.focused {
  border-radius: $radius-rounded;
}
</style>
