<script>
import axios from 'axios'
import { fetch, fetchAsync } from '@helpers/ObjectHelper'
import { getIconUrl } from '@helpers/IconHelper'
import { parseToSvgElement } from '@helpers/SvgHelper'

// Internal: Preset sizes that are useful.
const iconSizes = [
  'inherit',
  'small',
  'regular',
  'medium',
  'mobile',
  'large',
  'huge',
]

// Internal: A local cache for svg nodes so that we avoid making requests all
// the time, even if they are served from memory.
const iconSvgsByUrl = {}

// Internal: Similar to the icon cache, if the same icon is used several times
// in the same page, we want to prevent making several requests for the same icon.
const svgRequestsByUrl = {}

// Public: Allows to embed inline svg, making a request only once it's used, and
// caching it aggressively. As a result, it's very efficient.
//
// NOTE: Available icons are defined in @evolve/IconAliases.js
export default {
  name: 'Icon',
  props: {
    // Public: Used to retrieve an svg url from IconHelper.
    name: { type: String, required: true },

    // Public: Whether the icon should have a rotate animation.
    spin: { type: Boolean, default: false },

    // Optional: The size to use for the icon.
    // If no size is provided, it inherits the size of its container.
    //
    // NOTE: We use classes instead of inline styles to avoid overriding the
    // specified in some scenarios.
    size: {
      type: String,
      default: 'inherit',
      validator: function (value) {
        return iconSizes.includes(value)
      },
    },
  },
  computed: {
    classNames () {
      return [this.size, this.spin && 'spin', `icon-${this.name}`]
    },
    iconUrl () {
      try {
        return getIconUrl(this.name)
      } catch (error) {
        console.warn(error.message, this.$parent.$el)
        throw error
      }
    },
  },
  watch: {
    // Internal: Update the icon if it changes (the name property can be dynamic).
    iconUrl (newIconUrl) {
      this.generateSvgElement(newIconUrl)
    },
  },
  mounted () {
    // Validation for the new refactor, can be eventually removed.
    iconSizes.some(size => {
      if (this.$attrs.hasOwnProperty(size)) {
        throw new Error(`The Icon size '${size}' was specified as a prop. Use the "size" prop instead.`)
      }
    })

    this.generateSvgElement(this.iconUrl)
  },
  methods: {
    // Internal: Looks for the svg node for the icon in the local cache, fetches
    // it if not present, and replaces the svg.
    async generateSvgElement (iconUrl) {
      const svgNode = await fetchAsync(iconSvgsByUrl, iconUrl, this.fetchSvgElement)
      // Prevent race conditions if the icon is changed before the request finishes.
      if (iconUrl === this.iconUrl) this.replaceSvgElement(svgNode)
    },
    // Internal: Returns a request to fetch the .svg file for the specified url.
    fetchSvgElement (iconUrl) {
      return fetch(svgRequestsByUrl, iconUrl, () => axios.get(iconUrl).then(response => {
        const svgNode = parseToSvgElement(response.data)
        svgNode.classList.add('icon-svg')
        return svgNode
      }))
    },
    // Internal: Replaces the inner svg element with the specified one.
    // NOTE: By using an SVG placeholder we also get more consistent layout
    // while the icon is still loading.
    replaceSvgElement (newIcon) {
      if (!this.$el) return
      if (this.$el.lastChild) this.$el.lastChild.remove()
      this.$el.appendChild(newIcon.cloneNode(true))
    },
  },
}
</script>

<template>
  <div :key="name" class="icon" :class="classNames" v-on="$listeners">
    <svg class="icon-svg"/>
  </div>
</template>

<style lang="scss" scoped>
.icon {
  box-sizing: content-box;
  display: flex;
  height: inherit;
  width: inherit;
}

.small {
  height: $icon-size-small;
  width: $icon-size-small;
}

.regular {
  height: $icon-size-regular;
  width: $icon-size-regular;
}

.medium {
  height: $icon-size-medium;
  width: $icon-size-medium;
}

.mobile {
  height: $icon-size-mobile;
  width: $icon-size-mobile;
}

.large {
  height: $icon-size-large;
  width: $icon-size-large;
}

.huge {
  height: $icon-size-huge;
  width: $icon-size-huge;
}

.spin {
  animation: spin 2s infinite linear;
}
</style>

<style lang="scss">
.icon-svg {
  height: inherit;
  width: inherit;
}

@mixin withAnimation($iconName, $animationName: $iconName) {
  & .icon-#{$iconName} .#{$animationName}-anim {
    @content;
  }
}

@mixin onHover($iconName, $animationName: $iconName) {
  &:hover {
    @include withAnimation($iconName, $animationName) { @content; }
  }
}

// `iac` is the acronym for "Icon Animation Container".
//
// The icon gets animated when the container is hovered.
//
// Button, ActionLink, and Sidebar items are `iac`.
.iac {
  .default-transition {
    transition: all 0.3s ease-in, color 0ms; // Else the color of the animated part would change later. See AddButton.
  }

  @include withAnimation('add') {
    fill-opacity: 0;
    stroke-opacity: 1;
    stroke-width: 1px;
  }

  @include onHover('add','add-plus') {
    transform: translateX(-2px) translateY(-2px) scale(1.2);
  }

  @include onHover('add') {
    stroke-opacity: 0.6;
    transform: translateX(-2px) translateY(-2px) scale(1.2);
  }

  @include onHover('copy') {
    transform: translateX(1px) translateY(1px);
  }

  @include onHover('download') {
    transform: translateY(2px);
  }

  @include onHover('preview') {
    transform: translateX(2px);
  }

  @include onHover('show','preview') {
    transform: translateX(-2px);
  }

  @include onHover('edit', 'pencil') {
    transform: rotate(-12deg) translateY(2px) translateX(-2px);
  }

  @include onHover('delete', 'lid') {
    transform: translateX(4px) translateY(-5px) rotate(15deg);
  }

  @include onHover('upload', 'arrow-up') {
    transform: translateY(-2px);
  }
}
</style>
