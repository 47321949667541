<script>
import TransitionMixin from '@mixins/TransitionMixin'

export default {
  name: 'ScaleHorizontally',
  mixins: [
    TransitionMixin,
  ],
  inheritAttrs: false,
  props: {
    // Internal: Specifies a different default value for animationTimingFunction.
    timingFunction: { type: String, default: 'cubic-bezier(0.4, 0, 0.2, 1)' },
  },
}
</script>

<template>
  <component
    :is="transitionComponent"
    :tag="tag"
    v-bind="$attrs"
    type="animation"
    enterActiveClass="scale-horizontally-in"
    moveClass="scale-horizontally-move"
    leaveActiveClass="scale-horizontally-out"
    v-on="transitionListeners"
  >
    <slot/>
  </component>
</template>

<style lang="scss" scoped>
@keyframes scaleHorizontally {
  0% {
    transform: scaleX(0);
  }

  to {
    transform: scaleX(1);
  }
}

.scale-horizontally-move {
  transition: transform 0.3s;
}

.scale-horizontally-in {
  animation-name: scaleHorizontally;
  transform-origin: center left;
}

.scale-horizontally-out {
  animation-direction: reverse;
  animation-name: scaleHorizontally;
  transform-origin: center left;
}
</style>
