<script>
import AuthStore from '@stores/AuthStore'
import ChatPanelItem from '@components/ChatPanelItem'
import ChatStore from '@stores/ChatStore'

export default {
  name: 'ChatPanel',
  components: {
    ChatPanelItem,
  },
  data () {
    return {
      // flag to control which issues are collapsed,
      showActiveIssues: true,
    }
  },
  computed: {
    ...ChatStore.mapState('issues', 'messagesByChatRoomId', 'currentActivePatient'),
    ...ChatStore.mapGetters('openIssues', 'activeIssues', 'unreadActiveIssuesById', 'unreadOpenIssuesById'),
    currentUser () {
      return AuthStore.currentUser
    },
    activeTitle () {
      return `Active (${Object.keys(this.unreadActiveIssuesById).length})`
    },
    openTitle () {
      return `Open (${this.openIssues.length})`
    },
  },
  methods: {
    isPatientSelected (patient) {
      return this.currentActivePatient && this.currentActivePatient.id === patient.id
    },
    setCurrentActivePatient (patient) {
      ChatStore.setCurrentActivePatient(patient)
    },
    // method to change the showActiveIssues flag, when changed it collapses or shows active/open issues
    toggleActiveIssues () {
      this.showActiveIssues = !this.showActiveIssues
    },
  },
}
</script>

<template>
  <div class="chat-panel-container">
    <ChatPanelItem class="active-panel" :collapsed="!showActiveIssues" :title="activeTitle" @chatPanelItem:collapsed="toggleActiveIssues">
      <template slot="actions">
        <ActionLink icon size="regular" name="Add"/>
      </template>
      <div
        v-for="issue in activeIssues"
        :key="issue.id"
        :class="{ selected: isPatientSelected(issue.patient), bold: unreadActiveIssuesById[issue.id] }"
        class="patient-row"
        @click="setCurrentActivePatient(issue.patient)"
      >
        <span class="patient-name">{{ issue.patient.name }}</span>
      </div>
      <div v-if="activeIssues.length == 0" class="empty-patients">
        You have no active rooms
      </div>
    </ChatPanelItem>
    <ChatPanelItem class="open-panel" :collapsed="showActiveIssues" :title="openTitle" @chatPanelItem:collapsed="toggleActiveIssues">
      <template slot="actions">
        <ActionLink size="regular" icon name="Add"/>
      </template>
      <div
        v-for="issue in openIssues"
        :key="issue.id"
        class="patient-row"
        :class="{ selected: isPatientSelected(issue.patient), bold: unreadOpenIssuesById[issue.id] }"
        @click="setCurrentActivePatient(issue.patient)"
      >
        <span class="patient-name">{{ issue.patient.name }}</span>
      </div>
      <div v-if="openIssues.length == 0" class="empty-patients">
        All conversations have been resolved!
      </div>
    </ChatPanelItem>
  </div>
</template>

<style lang="scss" scoped>
.chat-panel-container {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  height: 424px;
  width: 200px;
}

.empty-patients {
  align-self: center;
  font-size: $fs-html;
  font-weight: $fw-regular;
  margin: auto;
  padding: 0 12px;
  text-align: center;
}

.patient-name {
  padding-left: 16px;
}

.patient-row {
  display: flex;
  font-weight: $fw-light;
  height: 45px;
  padding: 12px 2px;
  width: 100%;

  &.bold {
    font-weight: $fw-regular;
  }

  &.selected {
    background: $jelly-bean-l-3;
  }

  &:hover:not(.selected) {
    background: $tundora-l-3;
  }
}

::v-deep .chat-panel-item {
  flex: 0 0 auto;

  &.expanded {
    flex: 1 0 auto;
  }

  .chat-panel-item-header {
    flex: 0 0 44px;
  }

  .chat-panel-item-content {
    display: flex;
    flex-direction: column;
    height: 330px;
    overflow-y: auto;
    padding: 4px 4px;
  }
}
</style>
