import { isMac } from '@helpers/BrowserHelper'

// Public: Aliases for keys that map to KeyboardEvent.key literals.
export default {
  alt: 'Alt',
  ctrl: 'Control',
  meta: 'Meta',
  cmd: isMac ? 'Meta' : 'Control',
  shift: 'Shift',
  del: 'Delete',
  delete: 'Delete',
  backspace: 'Backspace',
  up: 'ArrowUp',
  down: 'ArrowDown',
  left: 'ArrowLeft',
  right: 'ArrowRight',
  space: ' ',
  tab: 'Tab',
  enter: 'Enter',
  esc: 'Escape',
  comma: ',',
}
