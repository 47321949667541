<script>
// Public: Similar to CancelButton, but for cases where there is not a form
// involved in the modal.
//
// NOTE: Unlike CancelButton, you must add @click="closeModal".
export default {
  name: 'CloseButton',
}
</script>

<template functional>
  <Button
    v-bind="data.attrs"
    :class="data.staticClass"
    class="close-button"
    type="button"
    v-on="listeners"
  ><slot>Close</slot></Button>
</template>
