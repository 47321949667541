import ClassList from '@evolve/legacy/ClassList'

// Ensure the polyfill for Promise is loaded by allowing Babel to detect it (useBuiltIns: 'usage').
new Promise((resolve, reject) => resolve(true)).finally(() => {})

// Ensure the polyfill for Object.entries is detected by Babel.
// Used in https://github.com/meteorlxy/vue-showdown/blame/4441cae6e9a14734f0bb08191f96340f05627419/src/index.js#L6
// so we need to hint it here to ensure it's detected.
if (!Object.entries) {
  console.error('Entries is not supported which would cause Kiosk to fail.')
}

// Ensure the polyfill for Array.from is detected by Babel.
try {
  Array.from({})
} catch (error) {
  console.error('Array.from is not supported which would cause several helpers, such as `filesToArray`, to fail.')
}

// Ensure the polyfill for Map.prototype.entries is detected by Babel.
try {
  new Map().entries()
} catch (error) {
  console.error('Map.prototype.entries is not supported which would cause `elementsWithKeyboardShortcuts` to fail.')
}

// Public: A set of hacks and polyfills that are not provided by core-js.
//
// TODO: Remove once we no longer need to support IE11.
if (!Element.prototype.remove) { // Check that we don't apply twice.
  // Necessary for Crouton and legacy/navbar.js
  Element.prototype.remove = function () {
    (this.parentElement || this.parentNode).removeChild(this)
  }
  Comment.prototype.remove = function () {
    (this.parentElement || this.parentNode).removeChild(this)
  }
  NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
    for (var i = this.length - 1; i >= 0; i--) {
      if (this[i] && this[i].parentElement) {
        this[i].parentElement.removeChild(this[i])
      }
    }
  }

  // IE11 does not support classList in SVG elements, so we patch it:
  Object.defineProperty(SVGElement.prototype, 'classList', { enumerable: true,
    get () {
      if (!this.ieClassList) {
        this.ieClassList = new ClassList(this)
      }
      return this.ieClassList
    },
  })

  // IE11 doesn't support classList.toggle with the same semantics.
  DOMTokenList.prototype.toggle = ClassList.prototype.toggle
}

// IE11 implements this method with a non-standard name.
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector
}
