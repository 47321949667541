<script>
export default {
  name: 'CellTagsContainer',
}
</script>

<template>
  <div class="cell-tag">
    <slot/>
  </div>
</template>

<style lang="scss" scoped>
.cell-tag ::v-deep {
  .information-label {
    font-style: italic;
    height: 24px;
    margin-right: 8px;
    margin-top: 4px;

    &:not(:first-child) {
      margin-left: 4px;
    }
  }

  .cell-tags {
    margin: 0;
  }
}
</style>
