<script>
import CroutonStyles from '@constants/CroutonStyles'

export default {
  name: 'CroutonPlaceholder',
  props: {
    // Public: Allows to use a preset as the default style for croutons appended
    // to this placeholder.
    defaultStyle: { type: String, default: undefined },

    // Public: Default options to use in croutons appended in this placeholder.
    // These options are overriden by any explicitly provided to showCrouton.
    defaultOptions: { type: Object, default: undefined },
  },
  methods: {
    // Internal: Renders the options to JSON so that the CroutonHelper can
    // easily rehydrate them.
    optionsFromProps ({ defaultStyle, defaultOptions }) {
      if (defaultStyle) {
        const presetOptions = CroutonStyles[defaultStyle] || throw new Error(`Unknown CroutonStyle ${defaultStyle}`)
        defaultOptions = { ...presetOptions, ...defaultOptions }
      }
      return JSON.stringify(defaultOptions)
    },
  },
}
</script>

<template functional>
  <div class="crouton-placeholder" :data-crouton="$options.methods.optionsFromProps(props)"/>
</template>
