<script>
import { take } from 'lodash'

export default {
  name: 'TableCellList',
  props: {
    // Public: List of items to be shown.
    items: { type: Array, default: () => [] },
    // Public: How many items will be shown when collapsed.
    maxItems: { type: Number, default: 7 },
    // Public: If true, the items will wrap on collapsed state.
    wrapOnCollapsed: { type: Boolean, default: true },

    // Public: Sets the width to fit the content instead of the default 100%
    fitContent: { type: Boolean, default: false },

    // Public: Reduces the margin between the items
    shrink: { type: Boolean, default: false },
  },
  data () {
    return {
      collapsed: true,
    }
  },
  computed: {
    showToggle () {
      return this.items.length > this.maxItems
    },
    displayedItems () {
      return this.collapsed ? take(this.items, this.maxItems) : this.items
    },
    collapsedItemsCount () {
      return this.items.length - this.maxItems
    },
  },
  methods: {
    toggleCollapsed () {
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<template>
  <div v-if="items" class="table-cell-list" :class="{ collapsed, 'no-wrap-collapsed': !wrapOnCollapsed, shrink, fitContent }">
    <slot>
      <Tag
        v-for="(item, index) in displayedItems"
        :key="index"
        :label="item"
        class="table-cell-list-item"
      />
      <Tag
        v-if="showToggle"
        :label="collapsed ? `+${collapsedItemsCount} more` : 'Show less'"
        class="table-cell-list-item show-more"
        @click="toggleCollapsed"
      />
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.table-cell-list {
  // NOTE: We need to figure out why when animated it displays on top of the
  // fixed table cells when scrolling.
  // animation: enter-from-above 0.15s normal forwards ease-out;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: -4px;

  &.no-wrap-collapsed.collapsed {
    flex-wrap: nowrap;
  }

  &.fitContent {
    width: fit-content;
  }
}

.table-cell-list-item {
  margin-right: 8px;
  margin-top: 4px;

  .shrink & {
    margin-right: 4px;
  }
}

.show-more {
  border-color: $secondary-color;
  color: $secondary-color;
  cursor: pointer;
  font-style: italic;
}
</style>
