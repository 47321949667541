import { each } from 'lodash'
import { observeElementResize } from '@helpers/ResizeHelper'
import { transformMediaBreakpoints } from '@helpers/ResponsiveHelper'

// v-responsive: Allows to create responsive components, by automatically adding
// classes to the element based on the specified media breakpoints.
export default {
  inserted (el, { value = 'screen' }) {
    el._mediaBreakpoints = transformMediaBreakpoints(value)
    el._responsiveResizeObserver = observeElementResize(el, ({ contentRect, target }) => {
      each(target._mediaBreakpoints, (condition, breakpointName) => {
        target.classList.toggle(breakpointName, condition(contentRect))
      })
    })
  },

  update (el, { value, oldValue }) {
    if (value !== oldValue) el._mediaBreakpoints = transformMediaBreakpoints(value)
  },

  unbind (el) {
    el._responsiveResizeObserver.disconnect()
    delete el._responsiveResizeObserver
  },
}
