// Globally register all global components for convenience, because they
// will be used very frequently. Components are registered using their file name.
import GlobalMixin from '@mixins/GlobalMixin'
import Vue from 'vue'
import { eachInContext } from '@evolve/helpers/RequireHelper'

eachInContext(require.context('./components/global'), Vue.component.bind(Vue))

// Include global mixins.
Vue.mixin(GlobalMixin)
