const fileBeginning = /^\.\//
const fileExtension = /\.\w+$/
const vueOrJsFile = /[\w-]+\.(vue|js)$/

// Internal: 'default' is set by `export default`, else return the whole object.
const unwrapDefault = module => module.default || module

// Internal: Translates a file name into the component, directive, or filter name.
const fileToName = fileName => fileName.replace(fileBeginning, '').replace(fileExtension, '')

// Public: Calls the iteratee Function with each file in the given path, passing
// the file name and the file exports.
//
// https://webpack.js.org/guides/dependency-management/#require-context
export function eachInContext (context, iteratee) {
  context.keys().forEach(fileName => {
    if (fileName.match(vueOrJsFile)) {
      const fileExports = context(fileName)
      iteratee(fileToName(fileName), unwrapDefault(fileExports))
    }
  })
}
