import Vue from 'vue'
import { each, eachRight } from 'lodash'
import { registerAndGetStore } from '@helpers/StoreHelper'
import { removeBy } from '@helpers/ArrayHelper'

// SidebarStore: Keeps track of the registered v-scrollable-sections, grouping
// them by a current group id if specified.
export const state = {
  // Group that is currently selected.
  currentGroupId: null,

  // Elements that should be displayed as sidebar sections for each group.
  sectionsById: {},

  // The section that is currently highlighted for each group.
  activeSectionsById: {},
}

export const getters = {
  // Public: Checks whether it's the active section for the current group.
  activeSection (state) {
    return state.activeSectionsById[state.currentGroupId]
  },
  // Public: The sections in the specified group.
  currentSections (state) {
    return state.sectionsById[state.currentGroupId] || []
  },
}

export const mutations = {
  ADD_SECTION (state, section) {
    if (!state.sectionsById[state.currentGroupId]) Vue.set(state.sectionsById, state.currentGroupId, [])
    state.sectionsById[state.currentGroupId].push(section)
    if (!state.activeSectionsById[state.currentGroupId]) Vue.set(state.activeSectionsById, state.currentGroupId, section)
  },
  REMOVE_SECTION (state, section) {
    each(state.sectionsById, sections => { removeBy(sections, section) })
    each(state.activeSectionsById, (otherSection, groupId) => {
      if (otherSection === section) {
        Vue.delete(state.activeSectionsById, groupId)
        Vue.set(state.activeSectionsById, groupId, state.sectionsById[groupId][0])
      }
    })
  },
  SELECT_SECTION (state, section) {
    Vue.set(state.activeSectionsById, state.currentGroupId, section)
  },
  SET_CURRENT_GROUP_ID (state, groupId) {
    Vue.delete(state.activeSectionsById, state.currentGroupId)
    state.currentGroupId = groupId
    const groupSections = state.sectionsById[groupId]
    Vue.set(state.activeSectionsById, groupId, groupSections && groupSections[0])
  },
}

export const actions = {
  // Registers a section to be displayed in the current sidebar.
  registerSection ({ commit, getters }, section) {
    commit('ADD_SECTION', section)
  },
  // Unregisters a section to be displayed in the current sidebar.
  unregisterSection ({ commit, getters }, section) {
    commit('REMOVE_SECTION', section)
  },
  // Scrolls to the specified section and marks it as active.
  selectSection ({ commit, getters }, { section, scrollOptions }) {
    section.scrollToSection(scrollOptions)
    commit('SELECT_SECTION', section)
  },
  // Checks if the active section has changed based on the scroll position.
  notifyScroll ({ commit, getters, state }, { topThreshold }) {
    eachRight(state.sectionsById[state.currentGroupId], section => {
      const sectionEl = document.querySelector(`#${section.id}`)
      if (sectionEl && sectionEl.getBoundingClientRect().top <= topThreshold) {
        commit('SELECT_SECTION', section)
        return false // Exit the iteration
      }
    })
  },
  setCurrentGroupId ({ commit }, groupId) {
    commit('SET_CURRENT_GROUP_ID', groupId)
  },
}

export default registerAndGetStore('sidebar', { state, getters, mutations, actions })
