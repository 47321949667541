<script>
import { truncate } from 'lodash'

export default {
  name: 'LongText',
  props: {
    // Internal: the text we want to display.
    text: { type: String, default: undefined },
    // Internal: the maximum lenght of text we would want to display in line,
    // before trimming and displaying the full content in a tooltip.
    truncateAt: { type: Number, default: 50 },
  },
  computed: {
    fullContentIfTruncated () {
      return this.shouldTruncate ? this.sanitizedText : null
    },
    truncatedContent () {
      return truncate(this.sanitizedText, { length: this.truncateAt, omission: '…' })
    },
    // Internal: this method is here to avoid errors in the console when the text is rendered
    // with null content, for example on a remote search dashboard initialization.
    sanitizedText () {
      return this.text || '-'
    },
    shouldTruncate () {
      return Boolean(this.sanitizedText.length > this.truncateAt)
    },
  },
}
</script>

<template>
  <span v-tooltip="fullContentIfTruncated" class="long-text">{{ truncatedContent }}</span>
</template>
