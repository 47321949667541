<script>
export default {
  name: 'TitleSectionAction',
  inheritAttrs: false,
  props: {
    // Public: The label of the action.
    label: { type: String, default: null },

    // Internal: Allows to prevent double clicks and provide visual feedback for
    // long running operations. The @click function must return a promise.
    async: { type: Boolean, default: false },

    // TitleSectionActions: Renders Buttons, PromiseButtons or ActionLinks, which have compatible props APIs.
    actionComponent: { type: String, required: true },
  },
}
</script>

<template>
  <component
    :is="actionComponent"
    class="title-section-action"
    v-bind="$attrs"
    :label="label"
    :name="$attrs.name || label"
    v-on="$listeners"
  />
</template>
