<script>
import ImageExtensions from '@constants/ImageExtensions'
import InputMixin from '@mixins/InputMixin'

export default {
  name: 'Base64ImageInput',
  mixins: [
    InputMixin,
  ],
  inheritAttrs: false,
  constants: {
    imageExtensions: Array.from(ImageExtensions).map(extension => `image/${extension}`).join(','),
  },
  props: {
    // Public: Text content of the input.
    value: { type: String, default: null },
  },
  methods: {
    createBase64Image (image) {
      if (!image.name) return

      const reader = new FileReader()
      reader.onload = event => {
        this.$emit('input', event.target.result)
      }
      reader.readAsDataURL(image.file)
    },
    removeImage () {
      this.$emit('input', null)
    },
  },
}
</script>

<template>
  <div class="base-64-image-input">
    <Label v-if="label || $slots.label" v-bind="{ required, hint }" :class="{ error }">
      {{ label }}
      <template slot="labelExtras">
        <slot name="labelExtras"/>
      </template>
    </Label>
    <div v-if="value" class="base-64-image-panel">
      <img :src="value">
      <Button
        icon
        name="Remove"
        class="remove-btn"
        @click="removeImage"
      />
    </div>
    <FileInput
      v-else
      class="base64-image-input"
      :accept="imageExtensions"
      buttonIcon="camera"
      v-bind="$attrs"
      @input="createBase64Image($event)"
    />
  </div>
</template>

<style lang="scss" scoped>
  .base-64-image-panel {
    @include bordered-box;

    align-items: center;
    border-style: dashed;
    padding: 24px;

    img {
      max-width: 50%;
    }

    .remove-btn {
      @include clickable;

      color: $tundora-l-1;
      margin-left: 2px;
      transition: color 0.3s ease;
      vertical-align: middle;

      &:hover {
        color: $tundora-l-0;
      }
    }
  }

</style>
