// Public: Wraps a function that returns a promise, and returns a promise that
// will only be resolved or rejected for the promise returned by the last
// function call.
//
// Helpful for cases where only the last function call matters, and any previous
// calls should be ignored.
export function ignorePreviousCalls (promiseReturningFunc, delay) {
  let lastPromise
  return (...args) => new Promise((resolve, reject) => {
    const promise = lastPromise = promiseReturningFunc(...args)
    promise.finally(() => {
      if (promise === lastPromise) promise.then(resolve, reject)
    })
  })
}

// Public: Useful in catch statements where you want to preserve the old flow for later callbacks and execute code anyway.
export function andRethrow (callback) {
  return error => {
    callback(error)
    throw error
  }
}
