<script>
// Public: Allows to cover the entire screen with a clickable layer, which makes
// it simple to capture click-away events.
export default {
  name: 'BlurArea',
  props: {
    // Public: Whether the layer should be visible (still transparent but dark).
    mask: { type: Boolean, default: false },
  },
  methods: {
    // Internal: Only emit the click event if the blur area is clicked directly.
    onBlurAreaClick (event) {
      this.$emit('blur', event)
    },
  },
}
</script>

<template>
  <span class="blur-area" :class="{ mask }" @click.self="onBlurAreaClick"><slot/></span>
</template>

<style lang="scss" scoped>
.blur-area {
  @include z-index(blur-area);

  bottom: 0;
  left: 0;
  overflow-y: auto;
  position: fixed;
  right: 0;
  top: 0;

  &.mask {
    background-color: $bg-mask;
    transition: opacity 0.3s ease;
  }
}
</style>
