<script>
import TransitionMixin from '@mixins/TransitionMixin'
import { assign } from 'lodash'

export default {
  name: 'Collapse',
  mixins: [
    TransitionMixin,
  ],
  inheritAttrs: false,
  props: {
    // Public: A number to specify same duration for enter and leave transitions
    // or an Object { enter: 300, leave: 300 } to specify different durations.
    duration: { type: [Number, Object], default: 400 },

    // Internal: Specifies a different default value for animationTimingFunction.
    timingFunction: { type: String, default: 'cubic-bezier(0.23, 1, 0.32, 1)' },
  },
  methods: {
    transitionFromDuration (duration) {
      return ['height', 'padding-bottom', 'padding-top']
        .map(property => `${property} ${duration} ${this.timingFunction}`)
        .join(', ')
    },
    beforeEnter (el) {
      if (!el.dataset) el.dataset = {}
      el.dataset.oldPaddingTop = el.style.paddingTop
      el.dataset.oldPaddingBottom = el.style.paddingBottom
      this.setTransitionStyle(el, {
        transition: this.transitionFromDuration(this.enterDuration),
        height: '0',
        paddingTop: 0,
        paddingBottom: 0,
      })
    },
    enter (el) {
      el.dataset.oldOverflow = el.style.overflow
      assign(el.style, {
        height: `${el.scrollHeight}px`,
        overflow: 'hidden',
        paddingTop: el.dataset.oldPaddingTop,
        paddingBottom: el.dataset.oldPaddingBottom,
      })
    },
    afterEnter (el) {
      assign(el.style, {
        transition: '',
        height: '',
        overflow: el.dataset.oldOverflow,
      })
    },
    beforeLeave (el) {
      if (!el.dataset) el.dataset = {}
      el.dataset.oldPaddingTop = el.style.paddingTop
      el.dataset.oldPaddingBottom = el.style.paddingBottom
      el.dataset.oldOverflow = el.style.overflow
      el.style.height = `${el.scrollHeight}px`
      el.style.overflow = 'hidden'
      this.setTransitionStyle(el)
    },
    leave (el) {
      if (el.scrollHeight !== 0) {
        assign(el.style, {
          transition: this.transitionFromDuration(this.leaveDuration),
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
        })
      }
      this.setAbsolutePosition(el)
    },
    afterLeave (el) {
      assign(el.style, {
        transition: '',
        height: '',
        overflow: el.dataset.oldOverflow,
        paddingTop: el.dataset.oldPaddingTop,
        paddingBottom: el.dataset.oldPaddingBottom,
      })
    },
  },
}
</script>

<template>
  <component :is="transitionComponent" :tag="tag" v-bind="$attrs" v-on="transitionListeners">
    <slot/>
  </component>
</template>

<style>
.collapse-move {
  transition: transform 0.3s ease-in-out;
}
</style>
