<script>
export default {
  name: 'MessageModal',
  props: {
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    closeLabel: { type: String, default: 'Close' },
    htmlSafe: { type: Boolean, default: false },
  },
  methods: {
    close () {
      this.$emit('close')
      this.closeModal()
    },
  },
}
</script>

<template>
  <Modal closeOnBlurClick small class="message-modal">
    <span slot="modalHeader"><slot name="modalHeader">{{ title }}</slot></span>
    <p v-if="htmlSafe" slot="modalBody" v-html="message"/>
    <p v-else slot="modalBody"><slot name="modalBody">{{ message }}</slot></p>
    <template slot="modalFooter">
      <CloseButton @click="close">{{ closeLabel }}</CloseButton>
    </template>
  </Modal>
</template>
