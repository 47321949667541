<script>
import Blankslate from '@components/Blankslate'
import NavigationBarIcon from '@components/NavigationBarIcon'

import AuthStore from '@stores/AuthStore'
import NotificationsRequests from '@requests/UserNotificationsRequests'
import SearchStatus from '@constants/SearchStatus'
import { removeBy } from '@helpers/ArrayHelper'

export default {
  name: 'NavigationBarNotificationsMenu',
  components: {
    Blankslate,
    NavigationBarIcon,
  },
  data () {
    return {
      notifications: null,
      everOpened: false,
    }
  },
  computed: {
    ...AuthStore.mapState('unreadNotificationsCount'),
    // Internal: Refreshed via an HTTP request header on every request.
    serverUnreadCount () {
      return Number(this.unreadNotificationsCount)
    },
    unreadCount () {
      return this.notifications ? this.notifications.filter(n => !n.read).length : this.serverUnreadCount
    },
    hasNotifications () {
      return this.notifications && this.notifications.length > 0
    },
    searchStatus () {
      if (this.notifications === null) return SearchStatus.LOADING
      if (this.notifications === undefined) return SearchStatus.ERROR
      if (this.notifications.length === 0) return SearchStatus.EMPTY
      return SearchStatus.READY
    },
  },
  watch: {
    // Internal: Re-fetch notifications when we detect new ones.
    // NOTE: Changes to the unread count are performed through axios middleware.
    serverUnreadCount (serverUnreadCount) {
      if (serverUnreadCount !== this.unreadCount) this.fetchNotifications()
    },
  },
  methods: {
    fetchNotifications () {
      NotificationsRequests.list()
        .then(notifications => { this.notifications = notifications })
        .catch(() => { this.notifications = undefined })
    },
    onNotificationsOpen () {
      if (this.everOpened) return
      this.everOpened = true
      this.fetchNotifications()
    },
    markAsRead (event, notification) {
      if (notification.type === 'FeedbackNotification') {
        event.preventDefault()
        this.asyncOpenModal('Help/UserFeedbackModal')
        this.$refs.dropdown.hideDropdown()
      }
      if (!notification.read) {
        notification.read = true
        NotificationsRequests.read(notification)
      }
    },
    markAllAsRead () {
      this.notifications.forEach(notification => { notification.read = true })
      NotificationsRequests.readAll()
    },
    removeAll () {
      this.notifications = []
      NotificationsRequests.destroyAll()
    },
    removeNotification (notification) {
      removeBy(this.notifications, notification)
      NotificationsRequests.destroy(notification)
    },
  },
}
</script>

<template>
  <Dropdown
    ref="dropdown"
    :closeOnClick="false"
    contentClass="notifications"
    class="navbar-notifications-dropdown use-inset-shadow"
  >
    <template slot="dropdownToggle">
      <NavigationBarIcon :badge="unreadCount" title="Notifications" class="notifications-toggle" @click="onNotificationsOpen"/>
    </template>

    <template slot="dropdownContent">
      <div class="notifications-header">
        <div class="notifications-title">Notifications</div>
        <div v-if="hasNotifications" class="notifications-actions">
          <span class="header-action underline" @click="markAllAsRead()">Mark all as read</span>
          <span class="header-action">|</span>
          <span class="header-action notifications-remove-all underline" @click="removeAll()">Remove all</span>
        </div>
      </div>

      <div class="notifications-scroller">
        <div
          v-for="notification in notifications"
          :key="notification.id"
          :class="{ read: notification.read }"
          class="notification"
        >
          <Icon
            name="closeMedium"
            class="notification-remove"
            title="Remove Notification"
            size="medium"
            @click="removeNotification(notification)"
          />
          <NavigationLink :to="notification.path" class="notification-link no-underline" @navigated="markAsRead($event, notification)">
            <div class="notification-content" v-html="notification.content"/>
            <TimeAgo :value="notification.createdAt" class="notification-timestamp" inUserTimeZone/>
          </NavigationLink>
        </div>

        <Blankslate
          :status="searchStatus"
          class="notifications-blankslate"
          translationScope="user_notifications"
        />
      </div>
    </template>
  </Dropdown>
</template>

<style lang="scss" scoped>
$background: $WHITE;

.navbar-notifications-dropdown {
  display: inline-block;
  position: relative;
  text-align: left;
  vertical-align: 12px;

  ::v-deep .notifications {
    background: $background;
    border-color: $br-color-light;
    border-top: none;
    box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
    margin: 0 0 0 -272px;
    padding: 0;
    width: 400px;
  }
}

.notifications-scroller {
  max-height: 400px;
  overflow-y: auto;
}

.notifications-blankslate {
  background-color: $background;
  border: none;
  box-shadow: none;
  margin: 15px;
}

.notifications-header {
  background-color: $cipher-green-l-0;
  color: $WHITE;
  padding: 4px 16px;
  position: relative;
}

.notifications-title {
  font-weight: $fw-bold;
}

.notifications-actions {
  position: absolute;
  right: 16px;
  top: 4px;
}

.header-action {
  color: $WHITE;
  font-size: $fs-secondary;
  margin-left: 4px;
}

.notification {
  @include clickable;

  border-top: $br-lighter;
  color: $fc-html;
  display: block;
  font-size: 13px;
  padding: 8px 16px 10px;
  position: relative;

  &:first-child {
    @include inset-shadowy-strong;

    border: none;
  }

  &:hover {
    background-color: darken($background, 2%);
  }

  &.read {
    background-color: darken($background, 6%);

    &:hover {
      background-color: darken($background, 9%);
      opacity: initial;
    }

    & + .read {
      border-color: darken($background, 15%);
    }

    & + .notification:not(.read):hover {
      border-color: darken($background, 2%);
    }
  }

  &:not(.read) + .read {
    border-color: darken($background, 15%);
    box-shadow: inset 0 4px 6px -7px $shadow;
  }
}

.notification-content > b {
  font-weight: $fw-regular;
}

.notification-timestamp {
  color: $fc-html;
  display: inline-block;
}

.notification-remove {
  @include clickable;

  background: none;
  border: none;
  float: right;
  margin-right: -12px;
  margin-top: -3px;
  padding: 5px 7px;
}
</style>
