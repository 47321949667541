import { isBlank, isPresent } from '@helpers/ObjectHelper'
import { isValidDate } from '@helpers/DateHelper'
import { mapValues } from 'lodash'

// Validation: Checks that the input value is not higher than the provided one.
// For dates, validation is done in the date picker input directly, as it
// doesn't allow to pick dates outside bounds.
//
// Example:
//   <FormInput :max="10" field="example" type="number"/>
const max = {
  isValueValid: (value, maxValue) => {
    if (isBlank(value) || isValidDate(maxValue)) return true
    return !isNaN(value) && value <= maxValue
  },
  errorMessage: (value, maxValue) => `Maximum allowed is ${maxValue}`,
}

// Validation: Checks that the input value is not less than the provided one.
// For dates, validation is done in the date picker input directly, as it
// doesn't allow to pick dates outside bounds.
//
// Example:
//   <FormInput :min="10" field="example" type="number"/>
const min = {
  isValueValid: (value, minValue) => {
    if (isBlank(value) || isValidDate(minValue)) return true
    return !isNaN(value) && value >= minValue
  },
  errorMessage: (value, minValue) => `Minimum allowed is ${minValue}`,
}

// Validation: Checks that the input value is not longer than the provided number of characters.
//
// Example:
//   <FormInput :maxlength="140" field="example" />
const maxlength = {
  isValueValid: (value, maxLength) => {
    return isBlank(value) || String(value).length <= maxLength
  },
  errorMessage: (value, maxLength) => `This field cannot be longer than ${maxLength} characters`,
}

// Validation: Checks that the input value has the exact number of characters.
//
// Example:
//   <FormInput field="salesforce_top_level_account_id" :length="18"/>
const length = {
  isValueValid: (value, length) => {
    return isBlank(value) || String(value).length === length
  },
  errorMessage: (value, length) => `This field should be ${length} characters long`,
}

// Validation: Checks that the input value is not empty.
//
// Example:
//   <FormInput field="example" required/>
const required = {
  isValueValid: (value, required) => required === false || isPresent(value),
  errorMessage: () => 'This field cannot be empty',
}

// Validation: A generic validation that allows to pass a custom validation.
//
// Example:
//   <FormInput field="example" :validate="{ isValueValid: (value) => false, errorMessage: (value) => 'Never Valid' }"/>
const validate = {
  isValueValid: (value, validation) => validation.isValueValid(value, validation),
  errorMessage: (value, validation) => validation.errorMessage(value, validation),
}

// Public: A list of validations that can be used in FormInput components.
//
// The validation name will be used to detect validations in the FormInput attrs.
export const formValidators = {
  // NOTE: DO NOT ADD VALIDATIONS THAT ARE NOT USED VERY OFTEN.
  // Check AudioFileValidator for a sample usage of custom validations.
  max,
  min,
  required,
  maxlength,
  length,
  validate,
}

// Public: For now just a straightforward way to relabel Rails validations.
function translateServerError (message) {
  return message === "can't be blank" ? required.errorMessage() : message
}

// Public: For now just a straightforward way to relabel Rails validations.
// NOTE: Traverses deep into arrays and objects.
export function translateServerErrors (messages) {
  return messages.map ? messages.map(translateServerError) : mapValues(messages, translateServerErrors)
}
