<script>
import UserEvents from '@constants/UserEvents'

export default {
  name: 'ConfirmationModal',
  props: {
    title: { type: String, default: 'Are you sure?' },
    message: { type: String, default: null },
    confirmLabel: { type: String, default: 'global.buttons.confirm' },
    cancelLabel: { type: String, default: 'global.buttons.cancel' },
    htmlSafe: { type: Boolean, default: false },
    danger: { type: Boolean, default: false },
  },
  methods: {
    cancel (event) {
      this.$emit('cancel', UserEvents.USER_CANCELLATION)
      this.closeModal()
    },
    confirm (event) {
      this.$emit('confirm', UserEvents.USER_CONFIRMATION)
      this.closeModal()
    },
  },
}
</script>

<template>
  <Modal :closeBtn="false" small class="confirmation-modal" @close="cancel">
    <span slot="modalHeader">{{ title }}</span>
    <template v-if="$slots.modalContent" slot="modalBody"><slot name="modalContent"/></template>
    <p v-else-if="htmlSafe" slot="modalBody" v-html="message"/>
    <p v-else slot="modalBody">{{ message }}</p>
    <template slot="modalFooter">
      <CancelButton type="button" @click="cancel">{{ translateIfKey(cancelLabel) }}</CancelButton>
      <Button class="confirm-button" primary :danger="danger" @click="confirm">{{ translateIfKey(confirmLabel) }}</Button>
    </template>
  </Modal>
</template>
