<script>
import FormInputContainerMixin from '@mixins/FormInputContainerMixin'
import VNodes from '@components/VNodes'

export default {
  name: 'FormInputContainer',
  components: {
    VNodes,
  },
  mixins: [FormInputContainerMixin],
}
</script>

<template>
  <div>
    <VNodes :content="initializeFormInputChildren()"/>
  </div>
</template>
