<script>
import Popover from '@components/Popover'

export default {
  name: 'AdminControlPanel',
  components: {
    Popover,
  },
  props: {
    controlPanel: { type: Object, required: true },
  },
}
</script>

<template>
  <Popover class="admin-control-panel" placement="right" verticalPlacement="top">
    <template #popoverToggle>
      <Crouton
        class="control-panel-toggle"
        info
        croutonStyle="snackbar-right"
        :closeBtn="false"
        :duration="false"
        :class="controlPanel.environment"
      >
        <b class="environment">{{ controlPanel.environment }}</b>
      </Crouton>
    </template>
    <template #content>
      <ModelFields :model="controlPanel">
        <ModelField field="version">
          <NavigationLink class="underline-always" target="_blank" :to="controlPanel.githubLink">{{ controlPanel.gitVersion | truncate({ length: 20 }) }} [{{ controlPanel.infrastructureVersion }}]</NavigationLink>
        </ModelField>
        <ModelField field="branch">
          <NavigationLink class="underline-always" target="_blank" :to="controlPanel.githubBranchUrl">{{ controlPanel.deployedBranch | truncate({ length: 20 }) }}</NavigationLink>
        </ModelField>
      </ModelFields>
    </template>
  </Popover>
</template>

<style lang="scss" scoped>
.admin-control-panel.popover {
  @include z-index(fab-button);

  bottom: 0;
  position: fixed;
  right: $padding-horizontal-html;

  & ::v-deep .popover-layer {
    color: $fc-html;
    margin-top: 24px;
    min-width: 240px;

    &.right::before {
      left: 40px;
      right: auto;
    }
  }

  & ::v-deep .data-field-value {
    white-space: nowrap;
  }
}

.crouton.control-panel-toggle {
  border-radius: 8px 8px 0 0;
  bottom: 0;
  box-shadow: none;
  font-size: $fs-secondary;
  font-style: italic;
  height: 20px;
  padding: 4px 12px;

  &.parallel {
    background: linear-gradient(131deg, #00beaa 0%, #295aac 100%);
  }

  &.production {
    background: linear-gradient(134deg, #ef5eff 0%, #6839cb 100%);
  }
}
</style>
