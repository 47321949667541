import ResizeObserver from '@ponyfills/ResizeObserver'
import { isFunction, throttle } from 'lodash'

// Internal: We don't need process every resize event, so we throttle them.
const defaultResizeDelay = 200

// Public: Returns a new ResizeObserver that observes the specified element.
//
// onResize - A callback that will receive the contentRect as the argument.
export function observeElementResize (el, options) {
  if (isFunction(options)) options = { onResize: options }
  const { delay = defaultResizeDelay, onResize } = options
  const throttledOnResize = throttle(entries => onResize(entries[0]), delay)
  const observer = new ResizeObserver(throttledOnResize)
  if (el instanceof Element) observer.observe(el)
  return observer
}
