import { each } from 'lodash'
import { isLocal } from '@helpers/EnvironmentHelper'

// NOTE: This code is based on the vue-simple-svg library.
// We extracted it to be more strict with the icons, and skip unnecessary events.
// Source: https://github.com/seiyable/vue-simple-svg

// Internal: These attributes are not necessary, and can create problems when
// positioning the icons, so we want to avoid them entirely.
const unnecessarySvgAttributes = [
  'width',
  'height',
  'x',
  'y',
  'version',
  'xml:space',
  'xmlns:a',
  'xmlns:xlink',
  'enable-background',
]

// Internal: These attributes make it very difficult to style the icon using CSS.
const undesiredPathAttributes = [
  'fill',
  'stroke',
]

const permittedStyleValues = new Set([null, 'none', 'currentColor'])

// Public: Returns an SVG node created by parsing .svg data.
export function parseToSvgElement (data) {
  // Parse the data as XML and ensure it's valid.
  const svg = parseFromString(data)
  if (svg.getElementsByTagName('style')[0]) throw StyleTagError(data)

  // Clean up the SVG node and return it.
  unnecessarySvgAttributes.forEach(attrName => removeSVGAttribute(svg, attrName))
  each(svg.getElementsByTagName('*'), element =>
    undesiredPathAttributes.forEach(attrName => removeSVGAttribute(element, attrName))
  )
  return svg
}

// Public: Returns the SVG node after parsing the data as XML, which allows to
// query its elements and manipulate its properties.
function parseFromString (data) {
  const result = new DOMParser().parseFromString(data, 'text/xml')
  return result.getElementsByTagName('svg')[0] || throw NoSvgDataError(data, result)
}

// Internal: Remove an attribute unless it's explicitly set to 'none'.
function removeSVGAttribute (element, name) {
  const value = element.getAttribute(name)
  if (permittedStyleValues.has(value)) return
  if (isLocal) warnAboutSVGAttribute(element, name, value)
  element.removeAttribute(name)
}

function warnAboutSVGAttribute (element, name, value) {
  console.warn(`
An element contains the '${name}' attribute, which is unnecessary,
or prevents us from easily styling the element with CSS.

  ${name}="${value}"

Please remove it from the source file.

`, element)
}

function StyleTagError (data) {
  return new Error(`
The specified SVG file contains a <style> tag, which can cause
global namespace pollution and conflict with other SVGs.

Apply inline styles to those paths, or find a different way to style it.

${data}
  `)
}

function NoSvgDataError (data, result) {
  return new Error(`
No svg element found. Did you pass a valid .svg file?

${data}

${result}
  `)
}

// This value was created by experimenting to achieve the best curve behavior
const MIN_CURVE = 70

// Public: Returns an SVG path for a curved line between two points.
export function curvedLine (from, to) {
  const translateBy = Math.round((to.x - from.x) / Math.atan2(to.x - from.x, from.x - to.x)) || 0
  const normalizedTranslateBy = translateBy < 0 ? Math.min(translateBy, -MIN_CURVE) : Math.min(Math.max(translateBy, MIN_CURVE), MIN_CURVE * 4)
  return `M${from.x},${from.y} C${from.x + normalizedTranslateBy},${from.y} ${to.x - normalizedTranslateBy},${to.y}  ${to.x},${to.y}`
}
