import ConfirmationModal from '@components/global/ConfirmationModal'
import MessageModal from '@components/global/MessageModal'
import ModalsStore from '@stores/ModalsStore'
import UserEvents from '@constants/UserEvents'
import Vue from 'vue'
import { createNewEvent } from '@helpers/EventHelper'
import { escape, identity, kebabCase } from 'lodash'

// Public: Helper to emit a close event that will be picked up by ModalManager.
export function closeModal ($vm, originalEvent) {
  const event = createNewEvent('modal:close', { id: $vm.$el.id, originalEvent, bubbles: false, cancelable: true })
  $vm.$el.dispatchEvent(event)
}

// Public: Adds a new modal to the Vuex store with the specified configuration.
//
// NOTE: Specify an id if you need to prevent the same modal from opening twice
// (asyncOpenModal does that by default), else a dynamic unique id will be used.
//
// Returns a promise that resolves to the opened modal id.
export function openModal (ComponentConfig, { props: attrs, on: listeners, id } = {}) {
  const component = Vue.extend(ComponentConfig)
  return ModalsStore.addModal({ component, attrs, listeners, id })
}

// Public: Opens a modal component that wasn't pre-bundled.
//
// NOTE: By default the modal component path is used to create a static id,
// which prevents duplicates.
//
// NOTE: Pass `id: undefined` in the options to allow several instances of the
// same modal to be opened at the same time.
//
// Returns a promise that resolves to the opened modal id.
export function asyncOpenModal (modalComponentPath, options) {
  return import(`@modals/${modalComponentPath}`).then(ModalComponent =>
    openModal(ModalComponent.default || ModalComponent, { id: kebabCase(modalComponentPath), ...options })
  )
}

// Public: Opens a confirmation modal with the specified options.
//
// Returns a promise that resolves to the opened modal id.
export function modalConfirm ({ confirm = identity, cancel = identity, ...props }) {
  return new Promise((resolve, reject) => {
    const on = {
      confirm: () => {
        if (confirm.then) return confirm.then(resolve)
        // NOTE: Allows to use confirmAction or confirmDeletion in combination
        // with PromiseButton, to display a loading indicator while it's performed.
        const result = confirm()
        return result?.finally ? result.then(resolve).catch(reject) : resolve(result)
      },
      cancel: () => reject(cancel(UserEvents.USER_CANCELLATION)),
    }
    return openModal(ConfirmationModal, { on, props, id: kebabCase(props.title || 'confirm-modal') })
  })
}

// Public: Opens a message modal with the specified options.
//
// Returns a promise that resolves to the opened modal id.
export function modalMessage ({ close = identity, ...props }) {
  return openModal(MessageModal, { on: { close }, props, id: kebabCase(props.title || 'message-modal') })
}

// Public: Displays a confirmation modal to perform an action.
export function confirmAction (model, handler, { action, conjugation, message, messageTemplate = ':modelLabel will be :conjugatedAction.', labelProp = 'name', ...options }) {
  return modalConfirm({
    confirm: () => handler(model),
    confirmLabel: action,
    htmlSafe: !message,
    message: message || messageTemplate.replace(/:modelLabel/g, `<strong>${escape(model[labelProp])}</strong>`).replace(/:conjugatedAction/g, `${conjugation || `${action?.toLowerCase()}d`}`),
    ...options,
  })
}

// Public: Displays a confirmation modal to delete an element.
export function confirmDeletion (model, handler, options = {}) {
  return confirmAction(model, handler, { action: 'Delete', danger: true, ...options })
}

// Public: Helper to open a ConfirmChangesModal.
export function modalConfirmChanges ({ saveChanges, cancelChanges, ...props }) {
  return new Promise((resolve, reject) => {
    const on = {
      saveChanges: changesWithId => saveChanges(changesWithId).then(resolve).catch(reject),
      cancelChanges: cancelChanges || resolve,
    }
    asyncOpenModal('ConfirmChangesModal', { props, on })
  })
}
// Public: Helper to remove an item only once deletion is successful in the server.
export function confirmRemoval (model, deletionHandler, options = {}) {
  return new Promise((resolve, reject) => {
    const confirmHandler = () => deletionHandler(model).then(resolve).catch(reject)
    options = { ...options, cancel: reject }
    confirmDeletion(model, confirmHandler, options)
  })
}
