<script>
export default {
  name: 'FilePreview',
  inheritAttrs: false,
  props: {
    // Public: File uploaded using the FileInput component.
    file: { type: Object, default: null },

    // Public: Type of the preview. Used to render previews for different types of files.
    type: { type: String, required: true, validator: value => ['image'].includes(value) },
  },
  computed: {
    isImage () {
      return this.type === 'image'
    },
    // Public: The blob of the file generally comes from a file input when selecting a file.
    fileBlob () {
      return this.file?.file
    },
    // Public: The url of the file generally comes from the server.
    fileUrl () {
      return this.file?.url
    },
    // Public: The name of the file to display in the preview modal
    fileName () {
      return this.file?.name
    },
    // Public: We set the objectUrl as a computed property to leverage caching.
    fileObjectUrl () {
      return this.fileBlob && URL.createObjectURL(this.fileBlob)
    },
  },
}
</script>

<template>
  <ImageThumbnail class="file-preview" :class="{ placeholder: !fileUrl }" :name="fileName" :url="fileUrl || fileObjectUrl" v-bind="$attrs"/>
</template>

<style lang="scss" scoped="true">

.file-preview {
  margin: 6px 0;
}

</style>
