<script>
import AuthStore from '@stores/AuthStore'
import { stopImpersonating } from '@models/UserManagement'

export default {
  name: 'UserImpersonationWidget',
  computed: {
    ...AuthStore.mapState('currentUser'),
    impersonatorId () {
      return this.currentUser.impersonatedById
    },
    closeBtnAttrs () {
      return { tooltip: 'Go back to your account', name: 'Stop Impersonating', label: 'Leave', icon: false }
    },
  },
  methods: {
    stopImpersonating,
  },
}
</script>

<template>
  <Crouton
    v-if="impersonatorId"
    :key="impersonatorId"
    :duration="false"
    croutonStyle="snackbar-right"
    error
    class="user-impersonation-widget"
    :closeBtn="closeBtnAttrs"
    skipAutoDestroy
    @dismiss="stopImpersonating(currentUser)"
  >
    <span>Seeing as</span> <b class="user-name">{{ currentUser.name }}</b>
  </Crouton>
</template>

<style lang="scss" scoped>
// NOTE: Adds a red border on the entire screen, similar to screensharing apps.
.user-impersonation-widget.crouton::after {
  border: 2px solid rgba($error-color, 0.8);
  bottom: 0;
  content: '';
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
}

.user-name {
  margin-left: 4px;
  margin-right: 8px;
}
</style>
