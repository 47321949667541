<script>
export default {
  name: 'NavigationBarIcon',
  inheritAttrs: false,
  props: {
    badge: { type: Number, default: 0 },
    icon: { type: String, default: null },
    expanded: { type: Boolean, default: false },
  },
}
</script>

<template>
  <NavigationLink
    :key="$attrs.title"
    :name="$attrs.title || $attrs.name"
    v-bind="$attrs"
    class="navbar-icon"
    :class="{ expanded }"
    @navigated="$emit('click', $event)"
  >
    <Icon :name="icon || $attrs.title.toLowerCase()"/>
    <span v-if="badge > 0" class="navbar-icon-badge">{{ badge }}</span>
  </NavigationLink>
</template>

<style lang="scss" scoped>
.navbar-icon {
  @include z-index(navbar);
  @include clickable;

  color: $WHITE;
  display: inline-block;
  padding: 8px;
  position: relative;

  &[title=Logout] {
    padding-right: 0;
  }

  & > .icon {
    height: $icon-size-medium;
    width: $icon-size-medium;
  }

  .dropdown-toggle.expanded > & {
    outline: none;
  }

  &.expanded {
    @include z-index(above-navbar);
  }
}

.navbar-icon-badge {
  background: $WHITE;
  border-radius: 50%;
  color: $primary-color-light;
  font-size: $fs-small;
  font-weight: $fw-regular;
  height: 13px;
  line-height: 12px;
  position: absolute;
  right: 4px;
  text-align: center;
  top: 3px;
  width: 13px;
}

$arrow-size: $navbar-dropdown-arrow-size;

.navbar-icon.expanded::after {
  border-color: transparent transparent $bg-card transparent;
  border-style: solid;
  border-width: 0 $arrow-size $arrow-size $arrow-size;
  bottom: calc(#{$navbar-dropdown-menu-margin} - 1px);
  content: "";
  left: calc(50% - #{$arrow-size});
  margin: 5px auto 0;
  position: absolute;
  width: 0;
}
</style>
