<script>
export default {
  name: 'ChatPanelItem',
  props: {
    title: { type: String, default: null },
    collapsed: { type: Boolean, default: false },
  },
  computed: {
    expanded () {
      return !this.collapsed
    },
  },
  methods: {
    toggleExpanded () {
      this.$emit('chatPanelItem:collapsed')
    },
  },
}
</script>

<template>
  <div :class="{ expanded }" class="chat-panel-item">
    <div class="chat-panel-item-header">
      <ActionLink
        :class="{ expanded }"
        icon="chevronDown"
        name="Expand Chat Panel"
        class="icon-collapsible"
        size="small"
        @click="toggleExpanded"
      />
      <span class="chat-panel-item-title">
        <slot name="title">{{ title }}</slot>
      </span>

      <div class="chat-panel-item-actions">
        <slot name="actions"/>
      </div>
    </div>

    <div v-if="expanded" :class="expanded" class="chat-panel-item-content">
      <slot/>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chat-panel-item {
  background: $bg-card;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
}

.chat-panel-item-header {
  @include flex-row;

  align-items: center;
  background-color: $bg-card-header;
  border-bottom: $br-light;
  font-size: $fs-large;
  font-weight: $fw-bold;
  justify-content: flex-start;
  letter-spacing: 0.24px;
  padding: 12px 8px;

  & > .actionable {
    margin-right: 10px;

    &.icon-collapsible {
      color: $tundora-l-0;
      transition: transform 0.6s $ease-out-quart;

      &:not(.expanded) {
        transform: rotate(-90deg);
      }
    }
  }
}

.chat-panel-item-content {
  padding: 32px 24px;
}

.chat-panel-item-actions {
  color: $fc-html;
  display: flex;
  margin-left: auto;

  & > .actionable {
    margin-left: 16px;

    &.action-link {
      color: $tundora-l-0;
    }
  }
}

@include slide-down-transition(0.6s);
</style>
