import AuthStore from '@stores/AuthStore'
import { get, isNil, isString } from 'lodash'
import { isLocal } from '@helpers/EnvironmentHelper'
import { mapFind } from '@helpers/ArrayHelper'
import { titleize } from '@helpers/StringHelper'

const CURRENT_LOCALE = 'en'
const PLACEHOLDER = /(?:\{\{|%\{)(.*?)(?:\}\}?)/gm // Accepts `{{placeholder}}` and `%{placeholder}`.

function missingPlaceholder (name, message, options) {
  throw new TypeError(`Placeholder '${name}' was not provided for the translation: "${message}".`, { name, message, options })
}

function interpolate (message, options = {}) {
  const matches = message.match(PLACEHOLDER)

  if (!matches) {
    return message
  }

  let value

  while (matches.length) {
    const placeholder = matches.shift()
    const name = placeholder.replace(PLACEHOLDER, '$1')

    if (!isNil(options[name])) {
      value = options[name].toString().replace(/\$/gm, '_#$#_')
    } else {
      missingPlaceholder(name, message, options)
    }

    const regex = new RegExp(placeholder.replace(/\{/gm, '\\{').replace(/\}/gm, '\\}'))
    message = message.replace(regex, value)
  }

  return message.replace(/_#\$#_/g, '$')
}

export function translateIfKey (name) {
  return (name.includes('.') && translate(name)) || name
}

export function translate (name, options = {}) {
  const key = [options.prefix, name].filter(x => x).join('.')
  const locales = [
    AuthStore.careProvider.translation && `${CURRENT_LOCALE}-${AuthStore.careProvider.translation}`,
    CURRENT_LOCALE,
  ]
  const translation = mapFind(locales, locale => locale && get(window.translations, `${locale}.${key}`))
  return isString(translation) ? interpolate(translation, options) : undefined
}

export function translateWithFallback (key, fallbacks) {
  return mapFind(fallbacks, options => options && translate(key, options))
}

export function translateLabel (name, { prefix } = {}) {
  return translateWithFallback(name, [
    (prefix && { prefix: `simple_form.labels.${prefix}` }),
    { prefix: `simple_form.labels.defaults` },
  ]) || titleize(name)
}

export function translateHint (name, { prefix } = {}) {
  if (prefix) {
    return translate(name, { prefix: `simple_form.hints.${prefix}` })
  }
}

export function translatePlaceholder (name, { prefix } = {}) {
  if (prefix) {
    return translate(name, { prefix: `simple_form.placeholders.${prefix}` })
  }
}

// Public: Helper to get label, hint, and placeholder for a FormInput. Notice
// that if any were passed explicitly, such as :label="false", they are kept.
export function getTranslationsFrom (field, { hint, label, placeholder }, translationOptions = {}) {
  const formHint = hint !== undefined ? hint : translateHint(field, translationOptions)

  return {
    hint: translationOptions.usePendoHints && !isLocal ? undefined : formHint,
    label: label !== undefined ? label : translateLabel(field, translationOptions),
    placeholder: placeholder !== undefined ? placeholder : translatePlaceholder(field, translationOptions),
  }
}
