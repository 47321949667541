<script>
export default {
  name: 'TagWithIcon',
  inheritAttrs: false,
  props: {
    // Public: The content to display in the tag.
    label: { type: String, default: null },

    // Public: An icon to display in the tag.
    icon: { type: String, required: true },

    // Public: An icon to display in the tag.
    iconSize: { type: String, default: 'regular' },

    // Public: Animate the badge when showing/hiding it.
    animate: { type: Boolean, default: false },
  },
}
</script>

<template>
  <Tag :class="{ animate }" v-bind="$attrs" v-on="$listeners">
    <Icon class="tag-icon" :size="iconSize" :name="icon"/><span class="tag-text" v-text="label"/>
  </Tag>
</template>

<style lang="scss" scoped>
.tag.animate {
  animation: fade 0.2s normal forwards ease-out;
}

.tag-icon {
  display: inline-flex;
  margin-right: 4px;
  vertical-align: text-bottom;
}

// it adds margin if we have multiple tags next to each other
.tag ~ .tag {
  margin-left: 8px;
}
</style>
