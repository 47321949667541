// CacheKey 8a3cbeca2e5c38f726166f7e89364092
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { request } from '@services/EvolveApiService'

export default {
  copyUserAccessInformation: options =>
    request('get', '/vue/user_management_actions/:id/copy_user_access_information', options),

  copyUserAccess: options =>
    request('post', '/vue/user_management_actions/:id/copy_user_access', options),

  resetPassword: options =>
    request('post', '/vue/user_management_actions/:id/reset_password', options),

  resetTwoFactorTimeOut: options =>
    request('post', '/vue/user_management_actions/:id/reset_two_factor_time_out', options),

  removeBiometricToken: options =>
    request('post', '/vue/user_management_actions/:id/remove_biometric_token', options),

  seeAs: options =>
    request('put', '/vue/user_management_actions/:id/see_as', options),

  unsee: options =>
    request('get', '/vue/user_management_actions/unsee', options),

  disableAccount: options =>
    request('put', '/vue/user_management_actions/:id/disable_account', options),

  enableAccount: options =>
    request('put', '/vue/user_management_actions/:id/enable_account', options),

  unlockAccount: options =>
    request('put', '/vue/user_management_actions/:id/unlock_account', options),

  disableSso: options =>
    request('put', '/vue/user_management_actions/:id/disable_sso', options),

  enableSso: options =>
    request('put', '/vue/user_management_actions/:id/enable_sso', options),

  edit: options =>
    request('get', '/vue/user_management_actions/:id/edit', options),

  update: options =>
    request('patch', '/vue/user_management_actions/:id', options),

  destroy: options =>
    request('delete', '/vue/user_management_actions/:id', options),
}
