import KeyNames from '@constants/KeyNames'
import { isMac } from '@helpers/BrowserHelper'

// Public: Maps a key name to how a user would see the key in their keyboard.
export default {
  [KeyNames.alt]: 'alt',
  [KeyNames.ctrl]: 'ctrl',
  [KeyNames.meta]: isMac ? '⌘' : '⊞win',
  [KeyNames.shift]: 'shift',
  [KeyNames.delete]: 'delete',
  [KeyNames.backspace]: '←backspace',
  [KeyNames.up]: '↑',
  [KeyNames.down]: '↓',
  [KeyNames.left]: '←',
  [KeyNames.right]: '→',
  [KeyNames.space]: 'space',
  [KeyNames.tab]: 'tab',
  [KeyNames.enter]: '↵ enter',
  [KeyNames.esc]: 'esc',
  Spacebar: 'space',
}
