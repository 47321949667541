// Internal: Reg expression for emails. Reference: https://emailregex.com
const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Validation: Checks a file path to match the provided extensions.
//
// NOTE: The recommended way to use it is to specify `type="email"` in FormInput.
//
// Example:
//   import EmailValidator from '@services/EmailValidator'
//
//   constants: {
//     EmailValidator,
//   },
//
//   <FormInput field="transfer_not_available_message" :validate="EmailValidator"/>
export default {
  isValueValid: value => !value || emailRegEx.test(value),
  errorMessage: value => 'Invalid email',
}
