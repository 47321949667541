import {
  screenDesktopMin as desktopMin,
  screenLandscapeMax as landscapeMax,
  screenLandscapeMin as landscapeMin,
  screenLargeMin as largeMin,
  screenPhoneMax as phoneMax,
  screenPortraitMax as portraitMax,
  screenPortraitMin as portraitMin,
  screenTabletMax as tabletMax,
  screenTabletMin as tabletMin,
} from '@style/JsVariables.scss'

// Public: Functions that can receive an Element or ResizeObserverEntry and
// evaluate to true if the element meets the specified size requirements.
export const screen = {
  'tablet-small': el => el.width >= portraitMin,
  'tablet': el => el.width >= tabletMin,
  'tablet-large': el => el.width >= landscapeMin,
  'desktop': el => el.width >= desktopMin,
  'desktop-wide': el => el.width >= largeMin,
}

export const smallScreen = {
  'only-phone': el => el.width <= phoneMax,
  'only-tablet-small': el => el.width <= portraitMax,
  'only-tablet': el => el.width <= tabletMax,
  'only-tablet-landscape': el => el.width <= landscapeMax,
}

export default {
  screen,
  smallScreen,
}
