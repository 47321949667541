<script>
import { camelCase } from 'lodash'

// Public: Our powerful link that provides styling and convenient defaults.
//
// NOTE: Should be used mostly for navigation, it styles NavigationLink.
// When triggering an action in the same page consider using a Button instead.
export default {
  name: 'ActionLink',
  inheritAttrs: false,
  props: {
    // Public: The preferred way to specify the content when it's a String.
    label: { type: String, default: '' },

    // Public: The name of an icon to render inside the link.
    icon: { type: [Boolean, String], default: false },

    // Optional: The size of the rendered icon.
    size: { type: String, default: null },

    // Internal: Used to make the action easy to interact with in tests with
    // helpers such as `click_action` or `have_action`.
    name: { type: String, default: null },

    // NOTE: Check NavigationLink for more prop definitions.
  },
  computed: {
    // Internal: Infer the icon from the label or name if it was provided as a Boolean.
    iconName () {
      return this.icon === true ? camelCase(this.label || this.name) : this.icon
    },
    iconProps () {
      return this.size ? { size: this.size } : {}
    },
    withIcon () {
      return Boolean(this.iconName)
    },
    withLabel () {
      return Boolean(this.label || this.$slots.default)
    },
    contentClass () {
      if (this.withIcon && this.withLabel) return 'icon-with-label'
      return this.withIcon ? 'icon-only' : 'label-only'
    },
  },
}
</script>

<template>
  <NavigationLink
    class="action-link actionable iac"
    :class="contentClass"
    :name="name || label"
    :rel="safeRel"
    v-bind="$attrs"
    v-on="$listeners"
    @navigated="$emit('click', $event)"
  >
    <Icon v-if="withIcon" class="action-link__icon" :name="iconName" v-bind="iconProps"/>
    <span v-if="withLabel" class="action-link__label">
      <slot>{{ translateIfKey(label) }}</slot>
    </span>
  </NavigationLink>
</template>

<style lang="scss" scoped>
.action-link {
  @include clickable;

  align-items: center;
  display: inline-flex;
  fill: currentColor;
  height: inherit;
  opacity: 0.85;
  transition: opacity 0.2s ease, color 0.2s ease;

  &:hover {
    opacity: 1;
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.icon-with-label > .action-link__label {
  margin-left: 8px;
}
</style>
