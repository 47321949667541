import { assign, flatMap, isEqual, isFunction, isPlainObject, last, map } from 'lodash'
import { createNewEvent } from '@helpers/EventHelper'
import { eventKeyboardShortcut, toKeyboardShortcut } from '@helpers/KeyboardShortcutHelper'
import { isLocal } from '@helpers/EnvironmentHelper'
import { removeBy } from '@helpers/ArrayHelper'

// Internal: Indexed by keyboard shortcut, each value is an Array that contains
// all the registered hotkey objects for that shortcut.
const hotKeysByShortcut = {}

// Internal: When v-hotkey.avoid is used, we add the element to this ignore list.
const elementsAvoided = []

// Internal: Elements that can not trigger keyboard shortcuts (define them with @keydown instead).
// NOTE: trix-editor is used by the care notes editor on the View patient page
const selectorsAvoided = ['input', 'textarea', 'trix-editor', '[contenteditable=true]']

// Internal: Because sometimes we use the [] dynamic key syntax, these empty
// values might be passed as Strings, so we also need to consider them.
const emptyValues = [false, null, undefined].map(String)

// Internal: Keeps track of pressed shortcuts to implement v-hotkey.push
let lastPressedShortcuts = new Set()

// Internal: Add keyboard listeners to detect all registered shortcuts.
document.addEventListener('keydown', keyboardEvent => {
  if (keyboardEvent.defaultPrevented) return
  const shortcut = eventKeyboardShortcut(keyboardEvent)
  handleShortcutIfHotKey(keyboardEvent, shortcut, onHotKeyDown)
}, true)

document.addEventListener('keyup', keyboardEvent => {
  if (keyboardEvent.defaultPrevented) return
  lastPressedShortcuts.forEach(shortcut => handleShortcutIfHotKey(keyboardEvent, shortcut, onHotKeyUp))
  lastPressedShortcuts.clear()
}, true)

// Internal: Looks up any registered hotkeys for that shortcut, picking the last
// registered one, invoking a handler that will perform additional checks.
function handleShortcutIfHotKey (keyboardEvent, shortcut, keyboardHandler) {
  const hotKey = last(hotKeysByShortcut[shortcut])
  if (hotKey && !shouldAvoidElement(keyboardEvent.target)) {
    keyboardEvent.preventDefault()
    keyboardHandler(hotKey, { keyboardEvent, keyPressed: keyboardEvent.type === 'keydown', shortcut, target: hotKey.el })
  }
}

// Internal: Returns true if keystrokes triggered by the element should be ignored.
function shouldAvoidElement (element) {
  return elementsAvoided.find(el => el === element) ||
    // iframes in care forms don't respond to `matches`.
    (element && element.matches && selectorsAvoided.find(selector => element.matches(selector)))
}

// Handler: Called when a shortcut combination is detected on a keydown event.
function onHotKeyDown (hotKey, options) {
  lastPressedShortcuts.add(hotKey.shortcut)
  const justPressed = !options.keyboardEvent.repeat
  if (justPressed || hotKey.repeat) dispatchShortcut(hotKey, options)
}

// Handler: Called when a shortcut combination is detected on a keyup event.
function onHotKeyUp (hotKey, options) {
  if (hotKey.push) dispatchShortcut(hotKey, options)
}

// Event: Calls the shortcut handler registered for the specified hotkey, or
// triggers a `click` or `focus` event in the element if using modifiers.
function dispatchShortcut (hotKey, eventArgs) {
  if (isFunction(hotKey.handler)) {
    hotKey.handler(eventArgs)
  } else {
    const event = createNewEvent(hotKey.handler, { bubbles: true, cancelable: true, ...eventArgs })
    return hotKey.el.dispatchEvent(event)
  }
}

// Internal: Returns an Array of Object, where each Object defines a shortcut:
// - hotKey is a String representation of the keys that trigger the shortcut.
// - handler is an event handler, or `null` if it should trigger a click event.
function hotKeysFrom ({ value, modifiers }) {
  if (!isPlainObject(value)) value = { [value]: modifiers.focus ? 'focus' : 'click' } // String syntax
  return flatMap(value, (handler, shortcuts) => {
    shortcuts = (handler && shortcuts && shortcuts.split(',').map(toKeyboardShortcut)) || []
    return shortcuts.map(shortcut => !emptyValues.includes(shortcut) && { handler, shortcut }).filter(x => x)
  })
}

// Directive: Called when the directive is bound to an element.
//
// When using the 'avoid' modifier, registers the element in an ignore list.
// Else, it registers all shortcut listeners for the given element.
function bind (el, binding) {
  if (binding.modifiers.avoid) {
    elementsAvoided.push(el)
  } else {
    hotKeysFrom(binding).forEach(hotKey => {
      assign(hotKey, { el, ...binding.modifiers })
      const hotKeys = hotKeysByShortcut[hotKey.shortcut]
      if (isLocal && hotKeys && hotKey.shortcut !== 'Escape') {
        console.warn(`More than one element registered with the '${hotKey.shortcut}' keyboard shortcut.`)
      }
      hotKeys ? hotKeys.push(hotKey) : hotKeysByShortcut[hotKey.shortcut] = [hotKey]
    })
  }
}

// Directive: Called when the directive is unbound from the element.
//
// When using the 'avoid' modifier, removes the element from the ignore list.
// Else, it removes the shortcut listeners registered for the element.
function unbind (el, binding) {
  if (binding.modifiers.avoid) {
    removeBy(elementsAvoided, el)
  } else {
    hotKeysFrom(binding).forEach(({ shortcut, handler }) => {
      const hotKeys = hotKeysByShortcut[shortcut]
      removeBy(hotKeys, hotKey => hotKey.handler === handler)
      if (hotKeys.length === 0) delete hotKeysByShortcut[shortcut]
    })
  }
}

// Directive: Called when the directive evaluated value changes.
function update (el, { value, oldValue, modifiers }) {
  if (!isEqual(value, oldValue)) {
    unbind(el, { value: oldValue, modifiers })
    bind(el, { value, modifiers })
  }
}

// v-hotkey: Allows to trigger a keyboard shortcut for a component or item.
export default {
  bind,
  update,
  unbind,
}

// Public: Returns all the elements that are currently receiving a keyboard
// shortcut.
export function elementsWithKeyboardShortcuts () {
  const keyboardShortcutsByElement = map(hotKeysByShortcut, (hotKeys, shortcut) => [last(hotKeys).el, shortcut])
    .reduce((elementsWithShortcuts, [el, shortcut]) => {
      elementsWithShortcuts.set(el, (elementsWithShortcuts.get(el) || []).concat(shortcut))
      return elementsWithShortcuts
    }, new Map()).entries()
  // NOTE: We return an Array of pairs intead of the Map because Vue doesn't iterate Maps in v-for.
  return Array.from(keyboardShortcutsByElement)
}
