<script>
import { isPlainObject } from 'lodash'

// Public: Once the animation is done, it's destroyed and removed.
export function autoDestroy () {
  if (this.skipAutoDestroy) return this.$emit('dismissedOut')
  this.$destroy()
  this.$el.remove()
}

export default {
  name: 'Crouton',
  props: {
    // Public: Message to be displayed in the Crouton.
    message: { type: String, default: 'USE A SLOT OR THE MESSAGE PROP!!!' },

    // Public: Controls the crouton styling.
    croutonStyle: { type: String, default: undefined },

    // Public: Controls the crouton color and duration.
    info: { type: Boolean, default: false },

    // Public: Controls the crouton color and duration.
    warning: { type: Boolean, default: false },

    // Public: Controls the crouton color and duration.
    error: { type: Boolean, default () { return !this.info && !this.warning } },

    // Public: Controls the crouton duration.
    duration: { type: [Number, Boolean], default () { return this.info ? 5000 : 10000 } },

    // Public: Determines if the crouton will have a close button or not, and
    // allows to customize it.
    closeBtn: { type: [Boolean, Object], default: true },

    // Public: Allows to control the display of the crouton externally.
    skipAutoDestroy: { type: Boolean, default: false },

    // Public: Determines if the message should be rendered as HTML or not.
    htmlSafe: { type: Boolean, default: false },
  },
  data () {
    return {
      // Internal: To force the 'leave' animation before removing the element.
      removing: false,
    }
  },
  computed: {
    closeBtnAttrs () {
      const attrs = isPlainObject(this.closeBtn) ? this.closeBtn : {}
      if (attrs.icon !== false) attrs.icon = 'closeMedium'
      return attrs
    },
    croutonClass () {
      return [
        (this.error && 'crouton-error') || (this.warning && 'crouton-warning') || (this.info && 'crouton-info'),
        this.croutonStyle && this.croutonStyle.split(' ').map(css => `crouton-${css}`).join(' '),
        this.closeBtn && 'with-close-button',
      ]
    },
  },
  mounted () {
    // To auto-dismiss the Crouton after the specified duration.
    if (this.duration) this.setTimeoutAndClear(this.dismiss, this.duration)
  },
  methods: {
    // Public: Hides the Crouton, which will display the collapse animation.
    dismiss () {
      this.removing = true
      this.$emit('dismiss')
    },
    // Public: Once the animation is done, it's destroyed and removed.
    autoDestroy,
  },
}
</script>

<template>
  <transition appear name="slide-down" @after-leave="autoDestroy">
    <div v-if="!removing" :class="croutonClass" class="crouton">
      <span v-if="htmlSafe" class="crouton-message" v-html="message"/>
      <span v-else class="crouton-message"><slot>{{ message }}</slot></span>
      <Button
        v-if="closeBtn"
        v-tooltip="closeBtnAttrs.tooltip"
        v-bind="closeBtnAttrs"
        class="crouton-close"
        buttonStyle="crouton-button"
        @click="dismiss"
      />
    </div>
  </transition>
</template>

<style lang="scss" scoped>
$crouton-height: 45px !default;

$crouton-info: $bg-info-color !default;
$crouton-warning: $bg-warning-color !default;
$crouton-error: $bg-error-color !default;

$crouton-button-margin-horizontal: 8px;
$crouton-button-width: 28px; // Adjust if Button size changes.

.crouton {
  box-shadow: inset 0 4px 6px -7px black;
  color: white;
  display: flex;
  font-weight: 100;
  justify-content: space-between;
  padding: 12px;
  position: relative;
  text-align: center;
}

.crouton-left-aligned {
  text-align: left;
}

.crouton-message {
  align-items: center;
  display: flex;
  flex-grow: 1;
  justify-content: center;

  .with-close-button & {
    margin-left: 2 * $crouton-button-margin-horizontal;
  }

  & ::v-deep > a {
    display: contents;
  }
}

.crouton-close.button {
  align-self: center;
  background: transparent;
  color: inherit;
  flex: 0 0 auto;
  font-weight: $fw-bold;
  margin: -4px 0 -4px $crouton-button-margin-horizontal;
  text-transform: uppercase;

  &:hover {
    background: $shadow-medium;
  }
}

.crouton-info {
  background: $crouton-info;

  &.crouton-light {
    background: transparent;
    color: $crouton-info;
  }
}

.crouton-warning {
  background: $crouton-warning;

  &.crouton-light {
    background: transparent;
    color: $crouton-warning;
  }
}

.crouton-error {
  background: $crouton-error;

  &.crouton-light {
    background: transparent;
    color: $crouton-error;
  }
}

.crouton-light {
  box-shadow: none;
}

.crouton-compact {
  .crouton-message {
    font-size: $fs-secondary;
    line-height: $lh-dynamic-medium;
  }
}

.crouton-snackbar,
.crouton-snackbar-right {
  @include shadowy-material-fab;
  @include z-index(modal-blur-area);

  border-radius: $radius-normal;
  bottom: 24px;
  left: $padding-horizontal-html;
  margin-bottom: 0;
  position: fixed;
}

.crouton-snackbar-right {
  left: auto;
  right: $padding-horizontal-html;
}

.crouton-text {
  height: auto;
  min-height: $crouton-height;

  .crouton-message {
    display: block;
    line-height: $lh-dynamic-medium;
    text-align: left;
  }
}

@include slide-down-transition;
</style>
