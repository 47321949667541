<script>
export default {
  name: 'FormActions',
  props: {
    // Public: Whether the save button should be danger style
    danger: { type: Boolean, default: false },

    // Public: Whether we should hide the cancel button
    noCancel: { type: Boolean, default: false },

    // Public: The text for the save button.
    saveLabel: { type: String, default: 'global.buttons.save' },

    // Public: Whether the save button is loading.
    saving: { type: Boolean, default: false },

    // Public: If true, the element is sticky-positioned at the bottom.
    sticky: { type: Boolean, default: false },
  },
}
</script>

<template functional>
  <div v-grid-row class="form-actions" :class="[{ sticky: props.sticky }, data.staticClass]">
    <slot>
      <slot name="extraFormActions"/>
      <CancelButton v-if="!props.noCancel"/>
      <SaveButton :danger="props.danger" :loading="props.saving" :label="props.saveLabel"/>
    </slot>
  </div>
</template>

<style lang="scss" scoped>
.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  .form-control + & {
    margin-top: 0;
  }

  &.sticky {
    background: $bg-gradient-sticky;
    bottom: 0;
    padding: 32px;
    position: sticky;

    .add-section + & {
      margin-top: 0;
    }
  }

  & ::v-deep > .button {
    margin-left: 16px;
    min-width: 80px;
  }

  & ::v-deep > .save-button::after {
    left: -100%;
    text-align: right;
  }
}
</style>
