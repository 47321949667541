// Pendo Initialization.
// Initializes pendo with all the information needed to track usage of evolve.
import { isServer, railsEnv } from '@helpers/EnvironmentHelper'

// Public: Requires pendo from pendo's cdn and returns the pendo instance.
function requirePendo (p, e, n, d, o, apiKey) {
  let v, w, x, y, z
  o = p[d] = p[d] || {}
  o._q = []
  v = ['initialize', 'identify', 'updateOptions', 'pageLoad']
  for (w = 0, x = v.length; w < x; ++w) {
    (function (m) {
      o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))) }
    })(v[w])
  }
  y = e.createElement(n)
  y.async = !0
  y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js'
  z = e.getElementsByTagName(n)[0]
  z.parentNode.insertBefore(y, z)
  return o
}

// Public: Gets a pendo instance for a specific API_KEY.
function getPendoFor (apiKey) {
  return requirePendo(window, document, 'script', 'pendo', undefined, apiKey)
}

// Internal: Initializes pendo using the logged in user and the current care provider.
// As a part of the with Feedback Intitialization, pendo requested that we send the name field as full_name, hence the duplication.
export function setPendoUser ({ currentUser, careProvider }) {
  const visitor = {
    id: currentUser.id,
    email: currentUser.email,
    name: currentUser.name,
    full_name: currentUser.name,
    permissions: currentUser.permissions,
    jobFunction: currentUser.jobFunction,
    role: currentUser.role,
    jobTitle: currentUser.jobTitle,
  }
  const account = {
    id: careProvider.id,
    name: careProvider.name,
    salesforceTopLevelAccountId: careProvider.salesforceTopLevelAccountId,
    environment: railsEnv,
    customerSuccessManager: careProvider.advisorName,
  }
  if (isServer && careProvider.enablePendo) {
    getPendoFor(process.env.VUE_APP_PENDO_API_KEY).initialize({ visitor, account })
  }
}
