<script>
export default {
  name: 'NavigationBarMenuItem',
  inheritAttrs: false,
  props: {
    title: { type: String, default: null },
  },
}
</script>

<template>
  <NavigationLink
    class="navbar-submenu no-underline"
    v-bind="$attrs"
    v-on="$listeners"
    @navigated="$emit('click', $event)"
  >{{ title }}<slot/>
  </NavigationLink>
</template>

<style lang="scss" scoped>
.navbar-submenu {
  @include clickable;

  align-items: center;
  border-radius: $radius-normal;
  color: rgba($tundora-l-0, 0.9);
  display: block;
  font-weight: $fw-regular;
  min-width: $navbar-menu-item-width;
  padding: 8px 12px;
  text-align: left;

  &.focused,
  &:focus,
  &:hover {
    background-color: $tertiary-color;
    color: $BLACK;
    outline: none;
  }
}
</style>
