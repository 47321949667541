import Vue from 'vue'
import { createTooltip, normalizeOptions, setupTooltipVisibility } from '@helpers/TooltipHelper'

export default {
  inserted (el, binding) {
    Vue.nextTick(() => {
      // We wrap the element in a span if this value is passed as part of the tooltip directive
      // The wrapping element makes it possible to display a tooltip over disabled elements.
      if (binding.value && binding.value.addTooltipWrapper && el.parentNode) {
        const wrapper = document.createElement('span')
        el.parentNode.insertBefore(wrapper, el)
        wrapper.appendChild(el)
        wrapper.classList.add('tooltip-wrapper')

        delete binding.value.addTooltipWrapper // deleting this value because the create tooltip function doesn't accept it as part of it's options

        el = wrapper
      }
      createTooltip(el, binding)
    })
  },
  unbind (el) {
    el._tippy && el._tippy.destroy()
  },
  componentUpdated (el, binding) {
    if (el._tippy) {
      const tooltipOptions = normalizeOptions(binding)
      el._tippy.setProps(tooltipOptions)
      setupTooltipVisibility(el, binding, tooltipOptions)
    }
  },
}
