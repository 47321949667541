<script>
import EmailValidator from '@services/EmailValidator'
import InputMixin from '@mixins/InputMixin'
import KeyNames from '@constants/KeyNames'
import { cloneDeep, differenceBy, flatMap } from 'lodash'
import { valueToOption } from '@helpers/OptionHelper'

export default {
  name: 'EmailsInput',
  mixins: [InputMixin],
  inheritAttrs: false,
  props: {
    // Public: The request function to call to retrieve the options. We expect the data returned
    // to be the serialized options.
    fetchOptions: { type: Function, default: null },

    // Public: Any additional data that the fetchOption request needs.
    fetchOptionsParams: { type: Object, default: () => ({}) },

    // Public: The value of the input
    // As in RemoteSearch input, values will always be in the `wholeItem` format
    value: { type: Array, default: () => [] },
  },
  data () {
    return {
      focused: false,
      dropdownTop: '',
      values: [],
    }
  },
  computed: {
    hasItems () {
      return this.values && this.values.length
    },
    remoteOrSelectInput () {
      return this.fetchOptions ? 'RemoteSelectInput' : 'SelectInput'
    },
    attributesForInput () {
      if (this.remoteOrSelectInput === 'RemoteSelectInput') {
        return {
          ...this.$attrs,
          fetchOptions: this.fetchOptions,
          fetchOptionsParams: this.fetchOptionsParams,
          displayCreatedAsOptions: false,
        }
      } else {
        return { ...this.$attrs, allowCreate: true, wholeItem: true }
      }
    },
  },
  watch: {
    value: {
      immediate: true,
      handler () {
        this.values = cloneDeep(this.value)
        if (this.values) this.validateEmails(this.values)
      },
    },
  },
  methods: {
    notifyValueChanged (values) {
      this.validateEmails(values)

      const newValues = cloneDeep(values)
      newValues.forEach(value => { this.$delete(value, 'error') })
      this.$emit('input', newValues)
    },
    validateEmails (values) {
      values.forEach(email => { this.$set(email, 'error', !EmailValidator.isValueValid(email.value)) })
      this.propagateErrors(values.filter(email => email.error))
    },
    propagateErrors (errors) {
      this.$emit('input:customErrors', errors.length ? `Invalid emails: ${errors.map(error => error.label).join(', ')}` : null)
    },
    onValueChange (emails) {
      const allEmails = flatMap(emails, email => {
        if (!email.value.includes(KeyNames.comma)) return email
        // Commas are treated as emails separator, need to parse each email as a selected item
        return email.value.split(KeyNames.comma).map(x => x.trim()).filter(x => x).map(valueToOption)
      })

      const newEmails = differenceBy(allEmails, this.values, 'value')
      this.notifyValueChanged(newEmails.length ? [...(this.values || []), ...newEmails] : emails)
    },
    onFocus () {
      this.focused = true
      // using next tick so that the dropdown is visible
      this.$nextTick(this.fixDropdownPosition)
    },
    onBlur () {
      this.focused = false
    },
    // Internal: Keep track of the parent's height (bordered box) to position the component's dropdown
    inputSizeChange (event) {
      if (event.target.offsetParent) {
        // We want the dropdown to be 8px on top of the bottom of the parent's container
        this.dropdownTop = event.target.offsetParent.offsetHeight - 8
        this.fixDropdownPosition()
      }
    },
    // Internal: Fixes the position of the options dropdown to be at the bottom of the input box
    fixDropdownPosition () {
      const dropdown = document.querySelector('.input-items-dropdown')
      if (dropdown) dropdown.style.top = `${this.dropdownTop}px`
    },
  },
}
</script>

<template>
  <div class="emails-input" :data-field="$attrs['data-field']">
    <Label v-if="label" v-bind="{ required, hint }" :class="{ error }">
      {{ label }}<template slot="labelExtras"><slot name="labelExtras"/></template>
    </Label>
    <div v-on-resize="inputSizeChange" class="input-container" :class="{ focused, empty: !hasItems, error, disabled }">
      <component
        :is="remoteOrSelectInput"
        ref="remoteSelectInput"
        v-bind="attributesForInput"
        :value="values"
        :disabled="disabled"
        :placeholder="placeholder"
        multi
        class="email-input"
        @focus="onFocus"
        @blur="onBlur"
        @input="onValueChange"
      />
    </div>
    <InputError v-if="error" :error="error"/>
  </div>
</template>

<style lang="scss" scoped>
@include input('.input-container');

.emails-input {
  position: relative;
}

.input-container {
  overflow: auto;
  padding: 8px 8px 0;
  resize: vertical;
  width: 100%;

  &.focused {
    border-color: $input-border-color-active;
  }

  &.empty {
    padding: 4px 8px;
  }

  &.disabled,
  &.disabled:hover {
    background-color: $input-disabled-background-color;
    border-color: $input-disabled-border-color;
  }
}

// Overrides to make the emails input have the input looks
.email-input {
  width: 100%;

  &.select-input {
    position: static;
  }

  // By changing the relatives to static, we can make the options dropdown
  // pop out from the scrollable container
  & ::v-deep {
    .select-input,
    .select-input-container,
    .input-wrapper {
      position: static;
    }

    .input-items-dropdown {
      @include z-index(dropdown);

      left: 8px;
      margin-top: 4px;
      right: 8px;
      width: auto;
    }

    .input-wrapper .input {
      border: none;
      padding-left: 2px;
      padding-top: 6px;
    }
  }

  .error & {
    ::v-deep .input-wrapper .input {
      background-color: $input-error-background-color;

      &::placeholder {
        color: $cabaret-l-2;
      }
    }
  }
}
</style>
